import React, { RefObject, SyntheticEvent, useEffect, useState } from 'react'

import { Icon, Loader, Tab } from 'semantic-ui-react'
import styled from 'styled-components'
import AnalyticsListener from '../../api/AnalyticsListener'
import { Channel, Led, ListLeds } from '../../api/LedApi'
import { FilterSection } from './types'
import { simulationApi } from '../../api/SimulationApi'

import LedInfoModal from '../LedInfoModal'
import Modal from '../common/Modal'

import Filters from './Filters'

import TableLeds from './TableLeds'
import Search from './Search'
import HelpContent from '../help/HelpContent'
import Tour from '../help/Tour'
import { TabUI } from '../../styles/commons'

interface Props {
  MAX_CHANNELS: number
  containerPCB: RefObject<HTMLDivElement>
  PCBChannels: Channel[]
  resolution: string
  setresolution: Function
  setpaneActive: Function
  paneActive: number
  tourActive: boolean
}

export interface FilterSections {
  colorimetric: Array<FilterSection>
  nonVisual: Array<FilterSection>
  power: Array<FilterSection>
}

const LedListAvailable: React.FC<Props> = (props) => {
  const {
    MAX_CHANNELS,
    PCBChannels,
    containerPCB,
    paneActive,
    setpaneActive,
    tourActive
  } = props
  const [ModalLedShow, setModalLedShow] = useState(false)

  const [itemleds, setitemsLeds] = useState<ListLeds>({
    user: [],
    catalog: []
  })
  const [itemledsAux, setitemsLedsAux] = useState<ListLeds>({
    user: [],
    catalog: []
  })
  const [itemledsAuxString, setitemsLedsAuxString] = useState<ListLeds>({
    user: [],
    catalog: []
  })

  const [Ledinfo, setLedinfo] = useState()

  const [panesListLed, setpanesListLed] = useState<
    [
      {
        menuItem: string
        render?: () => JSX.Element
      }
    ]
  >()
  const [isLoadingUserLeds, setisLoadingUserLeds] = useState(false)
  const [isLoadingCatalogLeds, setisLoadingCatalogLeds] = useState(false)
  //const [paneActive, setpaneActive] = useState(0)

  const [openFilter, setopenFilter] = useState(false)
  const [activeNow, setActivenow] = useState('user')

  const [filtersSections, setFiltersSections] = useState<FilterSections>()

  const closeModalLed = () => {
    setModalLedShow(false)
  }

  const InitFilters = (e: any) => {
    if (filtersSections) {
      filtersSections.colorimetric.map((filterSection: FilterSection) => {
        return (filterSection.rangenow = [filterSection.min, filterSection.max])
      })
      filtersSections.nonVisual.map((filterSection: FilterSection) => {
        return (filterSection.rangenow = [filterSection.min, filterSection.max])
      })
      filtersSections.power.map((filterSection: FilterSection) => {
        return (filterSection.rangenow = [filterSection.min, filterSection.max])
      })
    }

    setitemsLeds(itemledsAux)
    return false
  }

  const onDragStart = (ev: any, led: Led, dragging: boolean) => {
    // ev.target.style.position = 'relative'
    //  ev.dataTransfer.clearData()
    if (PCBChannels.length !== MAX_CHANNELS) {
      //setstartDragging(true)
      if (
        containerPCB !== null &&
        containerPCB.current !== null &&
        dragging === true
      ) {
        ev.dataTransfer.setData('led', JSON.stringify(led))
        containerPCB.current.classList.add('draggingNow')
        // containerPCB.current.className = 'draggingNow'
      }
      if (dragging === false && containerPCB.current !== null) {
        containerPCB.current.classList.remove('draggingNow')
      }
    }
  }

  const infoLuminary = async (led: Led) => {
    const infoled = await simulationApi.getLedInfo(led.type, led.id)
    infoled.id = led.id
    AnalyticsListener.createRouteView(`led_info/${led.id}`)
    setLedinfo(infoled)
    setModalLedShow(true)
  }

  const openFilters = () => {
    const openFilterAux = !openFilter
    // InitFilters()
    setopenFilter(openFilterAux)
  }

  const getAvailableLeds = async (active: string) => {
    const typeKey = active as keyof ListLeds
    typeKey === 'user'
      ? setisLoadingUserLeds(true)
      : setisLoadingCatalogLeds(true)

    const response: Led[] = await simulationApi.getAvailableLeds(typeKey)

    const itemLedAuxResponse: ListLeds = Object.assign({}, itemleds)

    typeKey === 'user'
      ? setisLoadingUserLeds(false)
      : setisLoadingCatalogLeds(false)
    itemLedAuxResponse[typeKey] = response

    setitemsLeds(itemLedAuxResponse)
    setitemsLedsAux(itemLedAuxResponse)
    setitemsLedsAuxString(itemLedAuxResponse)
  }

  useEffect(() => {
    paneActive === 0 ? setActivenow('user') : setActivenow('catalog')
    //SetFiltersSections()
    // InitFilters()
    setitemsLeds(itemledsAux)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paneActive])

  useEffect(() => {
    const loadTabs = () => {
      let panesAux: [
        {
          menuItem: any
          render?: () => JSX.Element
        }
      ] = [
        {
          menuItem: {
            key: 'USER LEDS ',
            content: (
              <>
                USER LEDS
                <HelpContent
                  section="Fixture"
                  content="user_leds"
                  position="bottom center"
                  html={<Icon name="question circle"></Icon>}
                />
              </>
            )
          },

          render: () => (
            <Tab.Pane loading={isLoadingUserLeds}>
              <TableLeds
                itemleds={itemleds.user}
                isLoading={isLoadingUserLeds}
                onDragStart={onDragStart}
                infoLuminary={infoLuminary}
                type="user"
                active={activeNow === 'user'}
                getAvailableLeds={getAvailableLeds}
              />
            </Tab.Pane>
          )
        }
      ]

      panesAux.push({
        menuItem: {
          key: 'LED CATALOG ',
          content: (
            <>
              LED CATALOG
              <HelpContent
                section="Fixture"
                content="led_catalog"
                position="bottom center"
                html={<Icon name="question circle"></Icon>}
              />
            </>
          )
        },
        render: () => (
          <Tab.Pane loading={isLoadingCatalogLeds}>
            <TableLeds
              itemleds={itemleds.catalog}
              isLoading={isLoadingCatalogLeds}
              onDragStart={onDragStart}
              infoLuminary={infoLuminary}
              active={activeNow === 'catalog'}
              type="catalog"
              //  getAvailableLeds={() => getAvailableLeds('user')}
              getAvailableLeds={getAvailableLeds}
            />
          </Tab.Pane>
        )
      })

      panesAux.push({
        menuItem: {
          content: (
            <Search
              InitFilters={InitFilters}
              itemledsAux={itemledsAux}
              activeNow={activeNow}
              setitemsLeds={setitemsLeds}
              itemleds={itemleds}
              setitemsLedsAuxString={setitemsLedsAuxString}
            />
          ),
          key: 'Search'
        }
      })

      setpanesListLed(panesAux)
    }

    loadTabs()
  }, [
    itemleds,
    activeNow,
    itemledsAux,
    isLoadingUserLeds,
    isLoadingCatalogLeds,
    filtersSections
  ])

  return (
    <div>
      <WrapperTableLed>
        <div>
          {' '}
          {panesListLed ? (
            <TabUI
              key={'TAB-UI'}
              onTabChange={(event: SyntheticEvent, data: any) => {
                setopenFilter(false)
                if (data.activeIndex !== 2) setpaneActive(data.activeIndex)
              }}
              activeIndex={paneActive}
              panes={panesListLed}
            />
          ) : (
            <Loading>
              <Loader active inline />
            </Loading>
          )}
        </div>
        {ModalLedShow && (
          <Modal open={ModalLedShow} closeModal={closeModalLed}>
            <LedInfoModal info={Ledinfo} />
          </Modal>
        )}
        {tourActive && itemleds['catalog'].length !== 0 && paneActive && (
          <Tour section="PCB_ledTable" />
        )}
        <Filters
          openFilter={openFilter}
          openFilters={openFilters}
          paneActive={paneActive}
          itemleds={itemleds}
          itemledsAux={itemledsAux}
          itemledsAuxString={itemledsAuxString}
          setitemsLeds={setitemsLeds}
          activeNow={activeNow}
          setFiltersSections={setFiltersSections}
          filtersSections={filtersSections}
          InitFilters={InitFilters}
        />
      </WrapperTableLed>
    </div>
  )
}

const WrapperTableLed = styled.div`
  position: relative;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  height: 100%;
`

const Loading = styled.div`
  height: 250px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default LedListAvailable
