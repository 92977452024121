import React, { useState, useEffect, SyntheticEvent } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faTimesCircle
} from '@fortawesome/free-regular-svg-icons'
import {
  faExclamationCircle,
  faChartArea
} from '@fortawesome/free-solid-svg-icons'
import {
  InputText,
  Fieldset,
  InputWrapper,
  MessageInput,
  TYPES_MESSAGE,
  LabelUI,
  IconUI,
  InputUI,
  DropdownNew,
  ButtonUI,
  LoaderUI
} from '../styles/commons'
import { simulationApi } from '../api/SimulationApi'
import { Theme, CompProps } from '../styles/theme'
import Modal from './common/Modal'
import LedInfoModal from './LedInfoModal'
import { Led } from '../api/LedApi'
import SpectraLineBars from './charts/SpectraLineBars'
import { Dropdown, Icon, Radio } from 'semantic-ui-react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AnalyticsListener from '../api/AnalyticsListener'
import Tour from '../components/help/Tour'
import HelpContent from './help/HelpContent'

const ReferenceCorrectIcon = <FontAwesomeIcon icon={faCheckCircle} />
const ReferenceErrorIcon = <FontAwesomeIcon icon={faExclamationCircle} />
const ChartAreaIcon = <FontAwesomeIcon icon={faChartArea} />
const CloseIcon = <FontAwesomeIcon icon={faTimesCircle} />
interface Company {
  id: string
  reference: string
  type: string
}

interface Brand {
  id: string
  reference: string
  type: string
}

interface Props {
  closeModal: Function
  showTour?: boolean
}

interface SimulationForm {
  led_ids: string[]
  led_types: string[]
  serial: number[]
  parallel: number[]
  limit: number[]
  reference: string
}

const initialForm: SimulationForm = {
  led_ids: [],
  led_types: [],
  serial: [],
  parallel: [],
  limit: [],
  reference: ''
}

interface Channel {
  id: string
  type: string
  family: string
  brand: string
  reference: string
  serial: number
  parallel: number
  limit: number
  if_ma: number
  luminous_flux_lm: number
}

const MAX_CHANNELS = 8
const MIN_CHANNELS = 2

const NewSimulation: React.FC<Props> = (props) => {
  const [referenceIsValid, setReferenceIsValid] = useState<Boolean>()
  const [simulationForm, setSimulationForm] = useState<SimulationForm>(
    initialForm
  )
  const [referenceInput, setReferenceInput] = useState('')
  const [formErrors, setFormErrors] = useState(true)
  const [warningText, setWarningText] = useState<string>('')
  const [isSendingSimulation, setIsSendingSimulation] = useState(false)
  const [isLoadingLeds, setIsLoadingLeds] = useState(false)
  const [isLoadingPCB, setIsLoadingPCB] = useState(false)
  const [resolution, setresolution] = useState('full_combinations')
  let [ItemsLeds, setItemsLeds] = useState<Led[]>([])
  let [ItemsLedsAux, setItemsLedsAux] = useState<Led[]>([])
  let [PCBChannels, setPCBChannels] = useState<Channel[]>([])
  const [Filters, setFilters] = useState<any[]>([])

  const [ChartSPD, setChartSPD] = useState<{
    wavelength: number[]
    intensity: number[]
    line: number[][]
    lineColors: string[]
  }>({
    wavelength: [],
    intensity: [],
    line: [[]],
    lineColors: []
  })

  const [tourActive] = useState(props.showTour)

  const [Ledinfo, setLedinfo] = useState()
  const [ModalLedShow, setModalLedShow] = useState(false)

  const [optionsDropdown, setoptionsDropdown] = useState<any[]>([
    { key: 2, text: 'Company', value: 'Family', disabled: false },
    { key: 1, text: 'Brand', value: 'Brand', disabled: false },

    { key: 3, text: 'Reference', value: 'Reference', disabled: false },

    {
      key: 4,
      text: 'Power',
      value: 'if_ma',
      disabled: false,
      submenu: [
        {
          key: 5,
          text: 'Efficiency (lmw)',
          value: 'efficiency_lmw',
          disabled: false
        },
        {
          key: 6,
          text: 'Pf (W)',
          value: 'pf_w',
          disabled: false
        },
        {
          key: 7,
          text: 'Luminous Flux (lm)',
          value: 'luminous_flux_lm',
          disabled: false
        },
        {
          key: 8,
          text: 'Radiant Flux (mw)',
          value: 'radiant_flux_mw',
          disabled: false
        }

        /* { key: 6, text: 'Lumnius Flux', value: 'if_ma', disabled: false }*/
      ]
    },

    {
      key: 9,
      text: 'non-visual',
      value: 'if_ma',
      disabled: false,

      submenu: [
        {
          key: 10,
          text: 'Mp Ratio',
          value: 'mp_ratio',
          disabled: false
        },
        {
          key: 11,
          text: 'Mder',
          value: 'mder',
          disabled: false
        },
        {
          key: 12,
          text: 'Cs 100',
          value: 'cs_100',
          disabled: false
        },
        {
          key: 13,
          text: 'Cla 100',
          value: 'cla_100',
          disabled: false
        },
        {
          key: 14,
          text: 'Ul',
          value: 'ul',
          disabled: false
        }

        /* { key: 6, text: 'Lumnius Flux', value: 'if_ma', disabled: false }*/
      ]
    },

    {
      key: 15,
      text: 'colorimetric',
      value: 'if_ma',
      disabled: false,
      submenu: [
        {
          key: 16,
          text: 'CCT (K)',
          value: 'cct_k',
          disabled: false
        },
        {
          key: 17,
          text: 'Duv',
          value: 'duv',
          disabled: false
        },
        {
          key: 18,
          text: 'Rf',
          value: 'rf',
          disabled: false
        },
        {
          key: 19,
          text: 'Rg',
          value: 'rg',
          disabled: false
        },
        {
          key: 20,
          text: 'Cri',
          value: 'cri',
          disabled: false
        },
        {
          key: 21,
          text: 'R9',
          value: 'r9',
          disabled: false
        },
        {
          key: 22,
          text: 'Coi',
          value: 'coi',
          disabled: false
        }
      ]
    }
  ])

  // Hash any string into an integer value
  // Then we'll use the int and convert to hex.
  const strToARGB = (str: string) => {
    var hash = 0
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }

    return (hash >>> 0).toString(16).slice(-6)
  }

  const checkReference = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 0) {
      const response: Boolean = await simulationApi.checkReference(
        event.target.value
      )
      setReferenceIsValid(response)
    } else {
      setReferenceIsValid(false)
    }
  }

  /* DRAG DROP*/
  const onDragOver = (ev: any) => {
    ev.preventDefault()
  }

  const onDrop = (ev: any, cat: any) => {
    if (PCBChannels.length !== MAX_CHANNELS) {
      let led_info = JSON.parse(ev.dataTransfer.getData('led'))

      const new_channel: Channel = {
        id: String(led_info.id),
        type: String(led_info.type),
        family: String(led_info.family),
        brand: String(led_info.brand),
        reference: String(led_info.reference),
        limit: Number(led_info.if_ma),
        parallel: 1,
        serial: 1,
        if_ma: Number(led_info.if_ma),
        luminous_flux_lm: Number(led_info.luminous_flux_lm)
      }

      let pcbChannelsAux: Channel[] = [...PCBChannels]
      pcbChannelsAux.push(new_channel)
      setPCBChannels(pcbChannelsAux)
    } else {
      toast.info(`PCB capacity is limited to ${MAX_CHANNELS} channels.`)
    }
  }

  const onDragStart = (ev: any, led: Led) => {
    if (PCBChannels.length !== MAX_CHANNELS) {
      ev.dataTransfer.setData('led', JSON.stringify(led))
    }
  }
  /* FI DRAG DROP */

  const HandleChangeDragLed = (
    event: any,
    type: string,
    activeChannel: Channel
  ) => {
    let valueled: string = event.target.value
    if (String(valueled).trim().length < 0 || Number(valueled) <= 0) {
      valueled = '1'
    }

    let pcbChannelsAux: Channel[] = Object.assign([], PCBChannels)
    if (pcbChannelsAux !== undefined && pcbChannelsAux.length !== 0) {
      pcbChannelsAux.forEach((channel: Channel) => {
        if (channel.id === activeChannel.id) {
          switch (type) {
            case 'serial':
              channel.serial = +valueled
              break
            case 'limit':
              channel.limit = +valueled
              break
            case 'parallel':
              channel.parallel = +valueled
              break
            default:
              break
          }
        }
      })
    }

    setPCBChannels(pcbChannelsAux)
  }

  const HandleLabelsFilters = (event: SyntheticEvent, data: any) => {
    /*
      const optionsDropdownAux = optionsDropdown 
      optionsDropdownAux.find((option: any) => {
      if (option.key === data.key) {
        return (option.disabled = true)
      }
    })*/

    optionsDropdown.forEach((option) => {
      if (option.submenu !== undefined) {
        option.submenu.forEach((optiondos: any) => {
          if (optiondos.key === data.key) {
            optiondos.disabled = true
          }
        })
      } else {
        if (option.key === data.key) {
          option.disabled = true
        }
      }
    })

    // optionsDropdown.find((option) => option.key === data.key).disabled = true
    // optionsDropdown.find((option) => option.key === data.key).disabled = true

    const itemFilter = {
      name: data.text,
      namevalue: data.value,
      value: '',
      key: data.key
    }
    const FiltersAux = Filters
    //datacustom.concat(customdata).push(itemFilter)

    if (FiltersAux !== undefined) {
      setFilters(FiltersAux.concat(itemFilter))
      setoptionsDropdown(optionsDropdown)
    }

    // FiltersAux.push(itemFilter)
    // setFilters(FiltersAux)
  }

  const HandleDeleteFilter = (e: Event, props: any) => {
    const FiltersAux = Filters.filter(
      (el: any) => String(el.name) !== String(props.name)
    )

    const optionsDropdownAux = optionsDropdown
    optionsDropdownAux.forEach((option) => {
      if (option.submenu !== undefined) {
        option.submenu.forEach((optiondos: any) => {
          if (optiondos.key === props.key) {
            optiondos.disabled = false
          }
        })
      } else {
        if (option.key === props.key) {
          option.disabled = false
        }
      }
    })

    setFilters(FiltersAux)
    SearchandFind(FiltersAux)

    setoptionsDropdown(optionsDropdownAux)
  }

  const SearchandFind = (FiltersFind: any) => {
    if (ItemsLedsAux.length === 0) {
      setItemsLedsAux(ItemsLeds)
      ItemsLedsAux = ItemsLeds
    }

    let ItemsLedsAux2 = ItemsLedsAux
    if (FiltersFind.length !== 0) {
      FiltersFind.forEach((filter: any) => {
        let key = String(filter.namevalue.toLowerCase()) as keyof Led

        if (filter.submenu !== undefined) {
          key = String(filter.submenu.namevalue.toLowerCase()) as keyof Led
        }

        ItemsLedsAux2 = ItemsLedsAux2.filter((el: Led) => {
          if (key === 'reference' || key === 'family' || key === 'brand') {
            return String(el[key])
              .toLowerCase()
              .includes(String(filter.value).toLowerCase())
          } else {
            return Number(el[key]) >= Number(filter.value)
          }

          //return el.reference === e.target.value batlles
        })
        //return el.reference === e.target.value
      })
    }

    setItemsLeds(ItemsLedsAux2)
  }

  const HandleSetFilter = (e: any, itemfilter: any) => {
    /*tipo de filtro */
    if (ItemsLedsAux.length === 0) {
      setItemsLedsAux(ItemsLeds)
      ItemsLedsAux = ItemsLeds
    }
    const FiltersAux = Filters
    FiltersAux.find((option) => option.key === itemfilter.key).value = e

    // const ItemsLedsAux = ItemsLedsAux

    SearchandFind(FiltersAux)
  }

  const infoLuminary = async (led: Led) => {
    const infoled = await simulationApi.getLedInfo(led.type, led.id)
    infoled.id = led.id
    AnalyticsListener.createRouteView(`led_info/${led.id}`)
    setLedinfo(infoled)
    setModalLedShow(true)
  }

  const closeModalLed = () => {
    setModalLedShow(false)
  }

  const addToSimulationForm = (data: any) => {
    setSimulationForm({
      ...simulationForm,
      ...data
    })
  }

  useEffect(() => {
    let isMounted = true

    async function getDynamicSpdPCB() {
      setIsLoadingPCB(true)

      const SimulationFormAux: SimulationForm = {
        led_ids: [],
        led_types: [],
        parallel: [],
        serial: [],
        limit: [],
        reference: ''
      }

      const lineColors: string[] = []
      PCBChannels.forEach((channel) => {
        SimulationFormAux.led_ids.push(channel.id)
        SimulationFormAux.limit.push(channel.limit)
        SimulationFormAux.led_types.push(channel.type)
        SimulationFormAux.serial.push(channel.serial)
        SimulationFormAux.parallel.push(channel.parallel)
        lineColors.push(strToARGB(channel.reference))
      })
      SimulationFormAux.reference = simulationForm.reference

      if (PCBChannels !== undefined && PCBChannels.length > 0) {
        const response: any[] = await simulationApi.getDynamicSpdPCB(
          SimulationFormAux
        )
        const wavelength: number[] = await Promise.all(
          await response.map((coord) => {
            return coord[0]
          })
        )
        const intensity: number[] = await Promise.all(
          await response.map((coord) => {
            return coord[1]
          })
        )

        let DragLedsPromise = await PCBChannels.map(
          async (channel: Channel) => {
            const intesity = await simulationApi.getLedInfo(
              channel.type,
              channel.id
            )
            return intesity.params.irradiance_norm
          }
        )

        const line: number[][] = await Promise.all(DragLedsPromise)

        //  debugger
        setChartSPD({ wavelength, intensity, line, lineColors })
      } else {
        setChartSPD({ wavelength: [], intensity: [], line: [], lineColors: [] })
      }
      if (isMounted) {
        setSimulationForm(SimulationFormAux)
      }
      setIsLoadingPCB(false)
    }

    getDynamicSpdPCB()

    return () => {
      // clean up
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PCBChannels])

  useEffect(() => {
    let didCancel = false
    async function getAvailableLeds() {
      setIsLoadingLeds(true)
      const response: Led[] = await simulationApi.getAvailableLeds('user')
      setIsLoadingLeds(false)
      !didCancel && setItemsLeds(response) && setItemsLedsAux(response)
    }

    getAvailableLeds()
    return () => {
      didCancel = true
    }
  }, [])

  useEffect(() => {
    if (referenceIsValid) {
      addToSimulationForm({ reference: referenceInput })
    } else {
      addToSimulationForm({ reference: '' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referenceIsValid, referenceInput])

  useEffect(() => {
    validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simulationForm])

  const HandledeleteLed = (reference: number) => {
    const DragLedsAux = PCBChannels
    setPCBChannels(DragLedsAux.filter((el, key) => key !== reference))
  }

  const HandleClearAll = () => {
    setFilters([])
    //SearchandFind([])
    setItemsLeds(ItemsLeds)
    let optionsDropdownaux = optionsDropdown
    optionsDropdownaux.map((option) => (option.disabled = false))
    setoptionsDropdown(optionsDropdownaux)
  }

  const validateForm = () => {
    let errors = false

    if (simulationForm.reference === '') {
      errors = true
    }

    if (referenceIsValid === false) {
      errors = true
    }

    if (simulationForm.led_ids.length <= 0) {
      errors = true
    }

    if (
      PCBChannels.length < MIN_CHANNELS ||
      PCBChannels.length > MAX_CHANNELS
    ) {
      errors = true
    }

    let warnings: boolean = false
    PCBChannels.forEach((channel) => {
      if (channel.limit / channel.parallel > channel.if_ma) {
        // errors = true
        warnings = true
        setWarningText(
          '(*) Warning! You are setting an electrical current higher than the nominal current of the LED. This can dramatically shorten its life or even burn it!'
        )
      }
      if (channel.limit / channel.parallel < 0.5 * channel.if_ma) {
        // errors = true
        warnings = true
        setWarningText(
          '(*) Warning! You are setting an electrical current much lower than the nominal current of the LED. Maybe you should consider reducing the number of LEDs or using others'
        )
      }
    })
    !warnings && setWarningText('')

    setFormErrors(errors)
  }

  const checkValidPCB = () => {
    let total_luminous_flux_lm: number = 0

    PCBChannels.forEach((channel) => {
      const luminous_flux_lm_channel: number =
        channel.luminous_flux_lm *
        channel.serial *
        (channel.limit / channel.if_ma)
      total_luminous_flux_lm += luminous_flux_lm_channel
    })

    if (total_luminous_flux_lm < 10) {
      toast.error(
        'The total luminous flux of the PCB is too low. Please, consider increasing the number of LEDs or use more power.'
      )
      return false
    } else {
      return true
    }
  }

  const createSimulation = async () => {
    if (checkValidPCB && !isSendingSimulation) {
      setIsSendingSimulation(true)
      const response = await simulationApi.createLuminary(simulationForm)
      if (response) {
        await simulationApi.startSimulationById(response.public_id, resolution)
      }
      setIsSendingSimulation(false)
      props.closeModal(true)
    }
  }

  const handleResolutionChange = (e: any, data: any) => {
    setresolution(data.value)
  }

  return (
    <NewSimulationWrapper>
      <LeftColumnContainer>
        <PCBWrapper>
          {isLoadingPCB && (
            <LoadingContainer>
              <LoaderUI active inline="centered" />
            </LoadingContainer>
          )}
          <ReferenceContainer>
            <Fieldset name="reference">
              <label>Reference</label>
              <InputWrapper>
                <InputText
                  className="tour-pcb"
                  name="reference"
                  error={!referenceIsValid}
                  onBlur={(e: any) => {
                    checkReference(e)
                    return setReferenceInput(e.target.value)
                  }}
                  defaultValue={referenceInput}
                ></InputText>
                {referenceInput && (
                  <MessageInput
                    type={
                      referenceIsValid ? TYPES_MESSAGE.OK : TYPES_MESSAGE.ERROR
                    }
                  >
                    {referenceIsValid ? (
                      ReferenceCorrectIcon
                    ) : (
                      <p>{ReferenceErrorIcon} Duplicated</p>
                    )}
                  </MessageInput>
                )}
              </InputWrapper>
            </Fieldset>
          </ReferenceContainer>
          <PCBContainer>
            <Fieldset name="pcb" className="tour-dragleds">
              <label>PCB</label>
              <DroppableArea
                className="droppable "
                onDrop={(e) => onDrop(e, 'done')}
                onDragOver={(ev) => onDragOver(ev)}
              >
                <DroppableTableTitle>
                  <div>Company</div>
                  <div>Brand</div>
                  <div>Model</div>
                  <div>Serial</div>
                  <div>Parallel</div>
                  <div>mA</div>
                  <div> </div>
                </DroppableTableTitle>
                {PCBChannels.length === 0 && (
                  <DragLedsHelpContainer className="ui icon header">
                    <i className="microchip icon"></i>
                    <p>Drag your leds here.</p>
                  </DragLedsHelpContainer>
                )}
                {PCBChannels.map((channel: Channel, i: number) => {
                  return (
                    <DroppableTableContainer
                      key={i}
                      className="draggable "
                      style={{
                        borderLeftColor: `#${strToARGB(channel.reference)}`
                      }}
                    >
                      <div>{channel.family}</div>
                      <div>{channel.brand}</div>
                      <div>{channel.reference}</div>

                      <InputUITable
                        size="mini"
                        type="number"
                        placeholder="Serial"
                        min="1"
                        defaultValue={channel.serial}
                        onChange={(e: any) =>
                          HandleChangeDragLed(e, 'serial', channel)
                        }
                      />

                      <InputUITable
                        size="mini"
                        type="number"
                        placeholder="Parallel"
                        min="1"
                        defaultValue={channel.parallel}
                        onChange={(e: any) =>
                          HandleChangeDragLed(e, 'parallel', channel)
                        }
                      />

                      <InputUITable
                        size="mini"
                        type="number"
                        placeholder="Limit"
                        min="1"
                        defaultValue={channel.limit}
                        onChange={(e: any) =>
                          HandleChangeDragLed(e, 'limit', channel)
                        }
                      />
                      <DeleteLed onClick={() => HandledeleteLed(i)}>
                        {' '}
                        {CloseIcon}{' '}
                      </DeleteLed>
                    </DroppableTableContainer>
                  )
                })}
              </DroppableArea>
            </Fieldset>
          </PCBContainer>
          <SPDContainer className="tour-spectral-distribution">
            {ChartSPD && (
              <>
                <h5 className="">
                  Spectral Distribution{' '}
                  <HelpContent
                    section="Fixture"
                    content="spectral_distribution"
                    position="bottom center"
                    html={<Icon name="question circle"></Icon>}
                  ></HelpContent>
                </h5>
                <SpectraLineBars
                  intensity={ChartSPD.intensity}
                  wavelength={ChartSPD.wavelength}
                  line={ChartSPD.line}
                  lineColors={ChartSPD.lineColors}
                  canvas_width={'555px'}
                ></SpectraLineBars>
              </>
            )}
          </SPDContainer>
        </PCBWrapper>
        <WarningText>{warningText}</WarningText>
      </LeftColumnContainer>
      <RightColumnContainer>
        <Fieldset className="tour-filterleds">
          <LedsTitleContainer>
            <Labeltitle>Search Filters:</Labeltitle>{' '}
            <LabeltitleClear onClick={() => HandleClearAll()}>
              Clear all
            </LabeltitleClear>
          </LedsTitleContainer>
          <LedsFilterWrapper>
            <LedsFilterContainer>
              {Filters &&
                Filters.map((item: any, key: any) => {
                  return (
                    <LabelUI size="small" label={item.name} key={key}>
                      {item.name}&nbsp;
                      <div
                        onInput={(e: any) =>
                          HandleSetFilter(e.currentTarget.textContent, item)
                        }
                        contentEditable={true}
                        suppressContentEditableWarning={true}
                      >
                        Edit
                      </div>
                      <IconUI
                        name="delete"
                        onClick={(e: Event, props: any) =>
                          HandleDeleteFilter(e, item)
                        }
                      />
                    </LabelUI>
                  )
                })}
              <DropdownNew className="icon" icon="add">
                <Dropdown.Menu>
                  {optionsDropdown.map((option: any) => {
                    if (option.submenu === undefined) {
                      return (
                        <Dropdown.Item
                          key={option.key}
                          onClick={(event: SyntheticEvent, data: any) =>
                            HandleLabelsFilters(event, option)
                          }
                          text={option.text}
                          disabled={Boolean(option.disabled)}
                        />
                      )
                    } else {
                      return (
                        <Dropdown
                          className="icon"
                          key={option.key}
                          item
                          text={option.text}
                        >
                          <Dropdown.Menu>
                            {option.submenu.map((suboption: any) => {
                              return (
                                <Dropdown.Item
                                  key={suboption.key}
                                  onClick={(event: SyntheticEvent, data: any) =>
                                    HandleLabelsFilters(event, suboption)
                                  }
                                  disabled={Boolean(suboption.disabled)}
                                  text={suboption.text}
                                />
                              )
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      )
                    }
                  })}
                </Dropdown.Menu>
              </DropdownNew>
            </LedsFilterContainer>
          </LedsFilterWrapper>
        </Fieldset>
        <LedsTitleContainer>AVAILABLE LEDS</LedsTitleContainer>
        <LedTableContainer className="tour-availableleds">
          <LedTableTitle>
            <div>Company</div>
            <div>Brand</div>
            <div>Reference</div>
            <div></div>
          </LedTableTitle>
          {!isLoadingLeds &&
            ItemsLeds.map((led: Led, i: number) => {
              return (
                <LedTable
                  key={led.id}
                  onDragStart={(e) => onDragStart(e, led)}
                  draggable
                  className="draggable "
                >
                  <div>{led.family}</div>
                  <div>{led.brand}</div>
                  <div>{led.reference}</div>
                  <div onClick={() => infoLuminary(led)}>{ChartAreaIcon}</div>
                </LedTable>
              )
            })}
          {ModalLedShow && (
            <Modal open={ModalLedShow} closeModal={closeModalLed}>
              <LedInfoModal info={Ledinfo} />
            </Modal>
          )}
          {isLoadingLeds && (
            <LoadingContainer>
              <LoaderUI active inline="centered" />
            </LoadingContainer>
          )}
          {!isLoadingLeds && ItemsLeds.length === 0 && (
            <NoResultsContainer className="ui icon header">
              <i className="search icon"></i>
              <p>We don't have any leds matching your query</p>
            </NoResultsContainer>
          )}
        </LedTableContainer>
        <SettingsContainer>
          <SettingsTitle>SIMULATION RESOLUTION</SettingsTitle>
          <Radio
            label="Low"
            name="resolutionGroup"
            value="low_resolution"
            checked={resolution === 'low_resolution'}
            onChange={handleResolutionChange}
          />
          <Radio
            label="High"
            name="resolutionGroup"
            value="full_combinations"
            checked={resolution === 'full_combinations'}
            onChange={handleResolutionChange}
          />
        </SettingsContainer>
      </RightColumnContainer>
      <WrapperBottonCreate>
        <ButtonUI
          btntype="primary"
          loading={isSendingSimulation}
          disabled={formErrors}
          onClick={() => createSimulation()}
          className="tour-createfixture"
        >
          Create
        </ButtonUI>
      </WrapperBottonCreate>
      <ToastContainer />
      {tourActive && <Tour section="PCB_reference" />}
      {tourActive && referenceIsValid && <Tour section="PCB_ledTable" />}
      {tourActive && PCBChannels.length >= MIN_CHANNELS && (
        <Tour section="PCB_DragledTable" />
      )}
    </NewSimulationWrapper>
  )
}

const PCBWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 15px;
  ${(props: CompProps) => (props.theme as Theme).filters.drop_shadow};
`

const LoadingContainer = styled.div`
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  padding: 50% 0px;
`
const DragLedsHelpContainer = styled.div`
  width: 100%;
  ${(props: CompProps) => (props.theme as Theme).font_size.xxsmall}!important;
  color: ${(props: CompProps) =>
    (props.theme as Theme).color.white.darkest}!important;
  margin-top: 50px !important;
  p {
    margin-top: 15px;
    ${(props: CompProps) => (props.theme as Theme).font_size.xxsmall}!important;
  }
`
const Labeltitle = styled.label`
  color: ${(props: CompProps) => (props.theme as Theme).color.neutral._900};
`

const NoResultsContainer = styled.div`
  display: block;
  margin: 30px 0 0 28px;
  height: 250px;
  color: ${(props: CompProps) =>
    (props.theme as Theme).color.white.darkest}!important;
  p {
    width: 60%;
    margin-top: 15px;
    margin-left: 65px;
    ${(props: CompProps) => (props.theme as Theme).font_size.xxsmall}!important;
  }
`

const NewSimulationWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0px 15px 15px;
`

const LeftColumnContainer = styled.div`
  flex: 0 0 65%;
`

const ReferenceContainer = styled.div``

const PCBContainer = styled.div``

const WarningText = styled.div`
  color: ${(props: CompProps) => (props.theme as Theme).color.forms.warning};
  ${(props: CompProps) => (props.theme as Theme).font_size.xxxsmall};
  position: absolute;
  width: 600px;
  margin-top: 12px;
`

const DroppableArea = styled.div`
  min-height: 200px;
  max-height: 200px;
  overflow-y: auto;
`

const DroppableTableTitle = styled.div`
  display: grid;
  grid-template-columns: 15% 25% 30% 8% 8% 8% 6%;
  border: 1px solid #fff;
  padding: 5px;
  color: ${(props: CompProps) => (props.theme as Theme).color.neutral._900};
  ${(props: CompProps) => (props.theme as Theme).font_size.xxxsmall};
`

const DroppableTableContainer = styled(DroppableTableTitle)`
  border: 1px solid #bfbfbf;
  border-left-width: 5px;
  color: ${(props: CompProps) => (props.theme as Theme).color.black.regular};
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  div {
    text-align: left;
  }
  .input {
    margin-left: 0px !important;
    margin-right: 5px !important;
  }
`

const InputUITable = styled(InputUI)`
  max-width: 59px !important;
  height: 20px;
  input {
    margin: 0;
    -moz-appearance: textfield !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
  }
`

const DeleteLed = styled.div`
  svg {
    font-size: 17px;
    margin-top: 1px;
    margin-left: 15px;
    color: ${(props: CompProps) => (props.theme as Theme).color.third};
  }
`

const SPDContainer = styled.div`
  margin-top: 15px;
  h5 span {
    display: inline-flex;
  }
`

const RightColumnContainer = styled.div`
  flex: 0 0 33%;
  margin-left: 2%;
`

const LedsTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 15px 0px;
  color: ${(props: CompProps) => (props.theme as Theme).color.neutral._900};
  font-weight: bold;
`

const LedTableContainer = styled.div`
  position: relative;
  height: 420px;
  max-height: 420px;
  overflow-y: auto;
  padding: 5px;
`
const LedTableTitle = styled.div`
  display: grid;
  grid-template-columns: 20% 25% 50% 5%;
  border: 1px solid #fff;
  padding: 5px;
  color: ${(props: CompProps) => (props.theme as Theme).color.neutral._900};
  ${(props: CompProps) => (props.theme as Theme).font_size.xxxsmall};
`

const LedTable = styled(LedTableTitle)`
  border: 1px solid #bfbfbf;
  color: ${(props: CompProps) => (props.theme as Theme).color.black.light};
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 5px;
  div {
    text-align: left;
    word-break: break-word;
    font-size: 12px;
  }
  div:not(:first-child) {
    margin-left: 5px;
  }
`

const LedsFilterWrapper = styled.div``

const LedsFilterContainer = styled.div`
  min-height: 35px;
  max-width: 95%;
`

const LabeltitleClear = styled(Labeltitle)`
  cursor: pointer;
  color: ${(props: CompProps) => (props.theme as Theme).color.neutral._900};
`

const SettingsContainer = styled.div`
  .radio {
    margin-right: 15px;
  }
`

const SettingsTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 30px 15px 15px 0px;
  color: ${(props: CompProps) => (props.theme as Theme).color.neutral._900};
  font-weight: bold;
`

const WrapperBottonCreate = styled.div`
  flex-basis: 100%;
  display: flex;
  margin-top: 15px;
  button {
    margin-left: auto !important;
    order: 2;
  }
`

export default NewSimulation
