import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Loading from '../components/common/Loading'
import { CompProps, Theme } from '../styles/theme'
import { SmallButtonAction } from '../styles/commons'
import { ApiResponse } from '../api/api'
import { userAPI } from '../api/UserApi'
import { useHistory } from 'react-router-dom'
import { Auth } from '../App'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export interface LoginForm {
  username: string
  password: string
}

interface Props {
  auth: Auth
}

const Login: React.FC<Props> = (props) => {
  const [loginForm, setLoginForm] = useState<LoginForm>({
    username: '',
    password: ''
  })
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const submitLogin = async (event: React.MouseEvent) => {
    event.preventDefault()
    const responseLogin: ApiResponse = await userAPI.login(loginForm)
    if (responseLogin.success) {
      props.auth.user = await userAPI.getUser()
      if (props.auth.user !== null) {
        // User Logo
        props.auth.user.photo = await userAPI.getLogoOrganization()
        localStorage.setItem('photo', props.auth.user.photo)
        // Reset User Tutorial (Testing)
        // localStorage.removeItem('isTutorialActive')
      }
      props.auth.isAuthenticated = true
      history.push('/')
    } else {
      switch(responseLogin.code) {
        case 401:
          toast.error('Wrong username or password.')
        break;
        case 402:
          toast.info('License limit reached. Please contact our sales department.')
        break;
        case 500:
          toast.error('Login Error.')
        break;
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    if (props.auth.isAuthenticated) {
      history.push('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.auth])

  return (
    <LoginWrapper>
      {loading ? (
        <Loading />
      ) : (
        <LoginContainer>
          <Fieldset name="username">
            <label>Username / Email</label>
            <InputText
              name="username"
              type="text"
              value={loginForm.username}
              onChange={(e) =>
                setLoginForm({
                  ...loginForm,
                  username: String(e.target.value).trim()
                })
              }
            />
          </Fieldset>
          <Fieldset name="password">
            <label>Password</label>
            <InputText
              name="password"
              type="password"
              value={loginForm.password}
              onChange={(e) =>
                setLoginForm({
                  ...loginForm,
                  password: String(e.target.value).trim()
                })
              }
            />
          </Fieldset>
          <SubmitContainer>
            <SmallButtonAction onClick={(e) => submitLogin(e)}>
              Sign in
            </SmallButtonAction>
          </SubmitContainer>
          <RegisterContainer>
            If you don't have an account request a demo{' '}
            <a
              rel="noopener noreferrer"
              href="https://meetings.hubspot.com/javier-iglesias"
              target="_blank"
            >
              here
            </a>
            .
          </RegisterContainer>
          <ToastContainer />
        </LoginContainer>
      )}
    </LoginWrapper>
  )
}

const LoginWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 120px;
  border: 1px;
`
const LoginContainer = styled.form`
  display: flex;
  max-width: 400px;
  flex-direction: column;
  flex: 0 0 70%;
  margin: 0 auto;
  padding: 30px;
  box-shadow: ${(props: CompProps) =>
    (props.theme as Theme).filters.drop_shadow};
`
const SubmitContainer = styled.div`
  margin: 30px 7px 0px 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  button:first-child {
    width: 100%;
  }
`

const RegisterContainer = styled.div`
  margin: 15px 7px;
  a {
    cursor: pointer;
    font-weight: bolder;
  }
  a:hover {
    color: ${(props: CompProps) =>
      (props.theme as Theme).color.secondary} !important;
  }
`

const Fieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;
  label {
    font-family: ${(props: CompProps) => (props.theme as Theme).font.semibold};
    padding: 5px 0;
  }
`
const InputText = styled.input`
  vertical-align: middle;
  border-radius: 3px;
  min-width: 50px;
  width: 100%;
  min-height: 36px;
  border: 1px solid
    ${(props: CompProps) => (props.theme as Theme).color.black.lightest};
  transition: all 0.3s ease-in-out;
  padding: 0 10px;

  &:focus {
    outline: none;
    border: 1px solid ${(props: CompProps) => (props.theme as Theme).color._900};
    box-shadow: inset 0 0 0 1px
      ${(props: CompProps) => (props.theme as Theme).color._900};
  }
`

export default Login
