import React, { useState, useEffect } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import theme from '../../../styles/theme'
import 'semantic-ui-css/semantic.min.css'
import '../../../static/fonts/fonts.css'
import GlobalStyles from '../../../styles/GlobalStyles'
import Header from './header'
import { Auth } from '../../../App'
import Footer from './footer'

interface Props {
  auth: Auth
  publicUrl: boolean
}

const Layout: React.FC<Props> = (props) => {
  const [auth, setAuth] = useState(props.auth)
  const { publicUrl } = props
  useEffect(() => {
    setAuth(props.auth)
  }, [auth, props.auth])

  return (
    <ThemeProvider theme={theme}>
      <LayoutContainer>
        <GlobalStyles />
        {publicUrl === false && <Header auth={props.auth} />}
        <LayoutBodyContainer>{props.children}</LayoutBodyContainer>
        <Footer />
      </LayoutContainer>
    </ThemeProvider>
  )
}

const LayoutContainer = styled.div`
  position: relative;
  min-height: 100vh;
`

const LayoutBodyContainer = styled.div`
  position: relative;
  padding: 0 15px 80px;
  margin: 0 auto;
  width: 100%;
  max-width: 1300px
  min-width: 1300px;
`

export default Layout
