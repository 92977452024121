import API, { STATUS } from './api'
import { AxiosResponse } from 'axios'

export interface Project {
  description: string
  location_coords: number[]
  location_name: string
  id: string
  gmt: number
  reference: string
  deleteConfirm?: boolean
}

class ProjectApi {
  createProjectUser = async (ProjectForm: object): Promise<any> => {
    try {
      const response: AxiosResponse = await API.post('project/new ', {
        ...ProjectForm
      })

      if (response.status === STATUS.OK) {
        return response.data
      }
    } catch (error) {
      console.error(error)
      return false
    }

    return false
  }

  updateProjectUser = async (ProjectForm: object): Promise<any> => {
    try {
      const response: AxiosResponse = await API.post('project/update ', {
        ...ProjectForm
      })

      if (response.status === STATUS.OK) {
        return response.data
      }
    } catch (error) {
      console.error(error)
      return false
    }

    return false
  }

  GetProjects = async (): Promise<any> => {
    try {
      const response: AxiosResponse = await API.get('project/get_historic')

      if (response.status === STATUS.OK) {
        return response.data.content.projects
      }
    } catch (error) {
      console.error(error)
      return []
    }

    return []
  }

  deleteProjectById = async (publicId: string): Promise<any> => {
    try {
      const response: AxiosResponse = await API.delete(
        `project/delete/${publicId}`
      )

      if (response.status === STATUS.OK) {
        return response.data
      }
    } catch (error) {
      console.error(error)

      return false
    }

    return false
  }

  GetProject = async (projectId: string | undefined): Promise<any> => {
    try {
      const response: AxiosResponse = await API.get(`project/info/${projectId}`)

      if (response.status === STATUS.OK) {
        return response.data.content.project
      }
    } catch (error) {
      console.error(error)
      return []
    }

    return []
  }
}

export const projectApi = new ProjectApi()
