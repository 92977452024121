import React from 'react'
import styled from 'styled-components'
import { InputUI } from '../../styles/commons'
import { CompProps, Theme } from '../../styles/theme'
import Loading from '../common/Loading'
/*import Loading from '../../../components/common/Loading'
import { InputUI } from '../../../styles/commons'
import { CompProps, Theme } from '../../../styles/theme'*/

interface Props {
  filter: any
  numResults: number
  numResultsTotal: number
  loading: boolean
  callbackChangeiluminance: Function
  illuminance: number[]
}

const CombFilter: React.FC<Props> = (props) => {
  const {
    filter,
    numResults,
    loading,
    numResultsTotal,
    callbackChangeiluminance,
    illuminance
  } = props

  return (
    <ContainerCombFilter>
      {loading ? (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      ) : (
        <>
          <CombinationsContainer>
            <TitleCombinations>
              COMBINATIONS ({numResultsTotal})
            </TitleCombinations>
            <h2 style={{ margin: '0px' }}>
              {filter && !loading && (
                <b className="tour-scene_creator tour-number-results">
                  {numResults}
                </b>
              )}
            </h2>
          </CombinationsContainer>
          <div
            style={
              numResults === 1 ? { pointerEvents: 'none', opacity: '0.4' } : {}
            }
          >
            <TitleCombinations>CONFIGURATION</TitleCombinations>
            <TitleIluminance>Illuminance (E)</TitleIluminance>
            <ContainerInputsConfiguration>
              <div>
                <InputUINumber
                  onChange={(e: any) =>
                    callbackChangeiluminance(0, e.target.value)
                  }
                  type="number"
                  size="mini"
                  value={illuminance[0]}
                />
                Horizontal
              </div>
              <div>
                <InputUINumber
                  type="number"
                  size="mini"
                  onChange={(e: any) =>
                    callbackChangeiluminance(1, e.target.value)
                  }
                  value={illuminance[1]}
                />
                Vertical
              </div>
            </ContainerInputsConfiguration>{' '}
          </div>
        </>
      )}
    </ContainerCombFilter>
  )
}

const ContainerCombFilter = styled.div`
  .tour-number-results {
    color: black !important;
  }
`

const CombinationsContainer = styled.div`
  height: 125px;
  padding-top: 30px;
`

const TitleCombinations = styled.p`
  color: ${(props: CompProps) => (props.theme as Theme).color.neutral._700};
  margin-bottom: 0px !important;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
`

const TitleIluminance = styled.p`
  margin-top: 15px;
  color: ${(props: CompProps) => (props.theme as Theme).color.neutral._700};
`

const InputUINumber = styled(InputUI)`
  input {
    padding: inherit;
    height: 30px;
    line-height: 30px !important;
    font-size: 14px;
    text-align: center;
    padding: inherit !important;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    opacity: 1;
  }
`

const ContainerInputsConfiguration = styled.div`
  width: 250px;
  display: flex;
  .input {
    width: 90px;
    margin: 0px;
    margin-bottom: 10px;
  }
`

const LoadingContainer = styled.div`
  display: block;
`

export default CombFilter
