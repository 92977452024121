import React, { useState, useEffect, useRef, createRef } from 'react'
import styled from 'styled-components'

import { projectApi, Project } from '../../api/ProjectApi'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { DropdownProject, ModalConfirm } from '../../styles/commons'
import { Dropdown } from 'semantic-ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CompProps, Theme } from '../../styles/theme'
import { Link } from 'react-router-dom'
import TimelineList from './TimelineList'
import Modal from './Modal'
import NewProject from '../NewProject'
import ModalConfirmText from './ModalConfirmText'
import Tour from '../help/Tour'
const LocationIcon = <FontAwesomeIcon icon={faMapMarkerAlt} />

interface Props {
  Projects: Project[]
  Refresh: Function
  Search?: string
  projectTimelineActive: boolean
}

const ProjectList: React.FC<Props> = (props) => {
  const { Projects, Search, projectTimelineActive } = props

  const [AuxSearch, setAuxSearch] = useState<string>()
  const [EditProject, setEditProject] = useState<boolean>(false)
  const [EditProjectObject, setEditProjectObject] = useState<Project>()

  const [ProjectsItems, setProjectsItems] = useState<Project[]>(Projects)
  let [ProjectsItemsAux, setProjectsItemsAux] = useState<Project[]>(Projects)
  const [tourTimeline, setTourTimeline] = useState<boolean>(false)
  const projectsRefs = useRef<any[]>(ProjectsItems.map(() => createRef()))

  useEffect(() => {
    setAuxSearch(Search)
  }, [Search])

  useEffect(() => {
    if (ProjectsItemsAux.length <= 0) {
      setProjectsItemsAux(ProjectsItems)
    }
  }, [ProjectsItems, ProjectsItemsAux])

  useEffect(() => {
    if (AuxSearch !== undefined) {
      const ItemsProjectAuxHelper = ProjectsItemsAux.filter((item: any) =>
        item.reference
          .toLowerCase()
          .startsWith(AuxSearch.toString().toLowerCase())
      )
      setProjectsItems(ItemsProjectAuxHelper)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AuxSearch])
  
  const RemoveProject = (index: number) => {
    const itemsAux = ProjectsItems.map((item: Project, i: number) => {
      if (i === index) {
        item.deleteConfirm = true
      }

      return item
    })
    setProjectsItemsAux(itemsAux)
    setProjectsItems(itemsAux)
  }

  const acceptDeleteProject = async () => {
    const ProjectToDelete = ProjectsItems.find(
      (item: any) => item.deleteConfirm === true
    )

    if (ProjectToDelete) {
      const response = await projectApi.deleteProjectById(ProjectToDelete.id)

      if (response) {
        const itemsAux = ProjectsItems.filter(
          (item: any) => item.deleteConfirm !== true
        )
        setProjectsItemsAux(itemsAux)
        setProjectsItems(itemsAux)
      }
    }
  }
  const closeDeleteProject = () => {
    const itemsAux = ProjectsItems.map((item: Project, i: number) => {
      item.deleteConfirm = false

      return item
    })

    setProjectsItems(itemsAux)
  }

  const ProjectEdit = (project: any) => {
    setEditProject(true)
    setEditProjectObject(project)
  }

  const closeModalProject = () => {
    setEditProject(false)
    props.Refresh()
  }

  const refreshProjectList = (projectId: string) => {
    if (projectsRefs.current.length > 0) {
      const elIndex: number = ProjectsItems.findIndex((e) => e.id === projectId)
      if (projectsRefs.current[elIndex])
        projectsRefs.current[elIndex].current.refreshList()
    }
  }

  return (
    <>
      {ProjectsItems.map((item: Project, i: number) => {
        return (
          <ProjectWrapper key={item.id}>
            <ProjectName>
              <ProjectDescripction>
                <ProjectSectionTitle> {item.reference}</ProjectSectionTitle>
                <p>{item.description}</p>
              </ProjectDescripction>
              <LocationProject className="Location">
                <LocationSection>{LocationIcon}</LocationSection>
                <CityLocation>
                  <div> {item.location_name.split(',')[0]}</div>
                  <GMT> {item.location_name.split(',')[1]}</GMT>
                </CityLocation>

                <Menu>
                  <DropdownProject
                    /*    open={TourTimelineOpenDropDown}*/
                    onClick={() => setTourTimeline(false)}
                    className="icon"
                    icon="ellipsis vertical"
                  >
                    <Dropdown.Menu
                      className={
                        tourTimeline === true && ProjectsItems.length === 1
                          ? 'active visible '
                          : ''
                      }
                    >
                      <Dropdown.Item
                        key={0}
                        icon="pencil"
                        onClick={() => ProjectEdit(item)}
                        text="EDIT"
                        className="edit-timeline"
                      ></Dropdown.Item>
                      <Dropdown.Item
                        key={2}
                        icon="trash"
                        text="REMOVE"
                        className="remove-timeline"
                        onClick={() => RemoveProject(i)}
                      ></Dropdown.Item>{' '}
                      <Dropdown.Item
                        key={3}
                        icon="add"
                        text="NEW TIMELINE"
                        className="new-timeline-list"
                        as={Link}
                        to={`/timeline/${(tourTimeline) ? 1 : 0}/${item.id}`}
                      ></Dropdown.Item>{' '}
                    </Dropdown.Menu>
                  </DropdownProject>
                </Menu>
              </LocationProject>
            </ProjectName>
            <TimelineList
              projectId={item.id}
              refreshCallBack={(projectId: string) => {
                refreshProjectList(projectId)
              }}
              setTourTimelineCallback={setTourTimeline}
              ref={projectsRefs.current[i]}
              projectTimelineActive={projectTimelineActive}
            />
            {item.deleteConfirm && (
              <ModalConfirm
                size="tiny"
                closeIcon
                open={item.deleteConfirm}
                onClose={() => closeDeleteProject()}
              >
                <ModalConfirmText
                  closeModal={closeDeleteProject}
                  message="Are you sure you want to delete this Project?"
                  acceptConfirm={acceptDeleteProject}
                />
              </ModalConfirm>
            )}
          </ProjectWrapper>
        )
      })}
      {EditProject && (
        <Modal
          newproject={true}
          open={EditProject}
          closeModal={closeModalProject}
          title="Edit Project"
        >
          <NewProject
            projectTourActive={false}
            closeModal={closeModalProject}
            EditProject={EditProjectObject}
          />
        </Modal>
      )}
      {tourTimeline && <Tour section="PAGE_NEW_TIMELINE_LIST" />}
    </>
  )
}

const ProjectWrapper = styled.div`
  -webkit-box-shadow: 3px 10px 48px -25px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 3px 10px 48px -25px rgba(0, 0, 0, 0.5);
  box-shadow: 3px 10px 48px -25px rgba(0, 0, 0, 0.5);
  margin: 30px 0;
  padding: 30px;
  min-height: 225px;
`
const ProjectName = styled.div`
  display: flex;
  justify-content: space-between;
`
const ProjectSectionTitle = styled.h5`
  flex: 1 0;
  margin-right: 15px;
  ${(props: CompProps) => (props.theme as Theme).font_size.large};

  line-height: 1.28571429em;

  flex: 1 0;

  font-family: ${(props: CompProps) => (props.theme as Theme).font.semibold};
  color: ${(props: CompProps) => (props.theme as Theme).color.neutral._900};
  font-weight: bold;
`
const LocationProject = styled.div`
  display: block;
  > div {
    display: inline-block;
    padding-left: 10px;
  }
`

const CityLocation = styled.div`
  min-width: 200px;
  div {
    padding-left: 15px;
  }
`

const ProjectDescripction = styled.div`
  padding-left: 10px;
`
const Menu = styled.div`
  font-size: 30px;
  cursor: pointer;
  i {
    color: #cac5c5;
  }
`

const GMT = styled.div`
  color: rgba(0, 0, 0, 0.46);
`

const LocationSection = styled.div`
  font-size: 35px;
  color: #dadada;
`

export default ProjectList
