import styled from 'styled-components'
import { Theme, CompProps } from './theme'
import {
  Button,
  Dropdown,
  Label,
  Input,
  Accordion,
  Loader,
  Dimmer,
  Segment,
  Icon,
  Pagination,
  Table,
  Checkbox,
  Modal,
  Tab
} from 'semantic-ui-react'
import ReactSlider from 'react-slider'

interface TypesMessage {
  type: string
}

interface InputText {
  error: boolean
  ref?: any
}

export const ModalPopup = styled(Modal)`
  .close {
    cursor: pointer;
    position: absolute;
    top: 1rem !important;
    right: 0.5rem !important;
    z-index: 1;
    opacity: 0.8;
    font-size: 1.25em;
    color: black !important;
    width: 2.25rem;
    height: 2.25rem;
    padding: 0.625rem 0 0 0;
  }
  .header {
    height: 45px !important;
    margin: 15px !important;
    padding: inherit !important;
    padding-top: 5px !important;
    border-bottom: 1px solid #aba8af !important;
  }
`

export const ModalConfirm = styled(Modal)`
  .close {
    cursor: pointer;
    position: absolute;
    top: 1rem !important;
    right: 0.5rem !important;
    z-index: 1;
    opacity: 0.8;
    font-size: 1.25em;
    color: black !important;
    width: 2.25rem;
    height: 2.25rem;
    padding: 0.625rem 0 0 0;
  }
  .actions {
    text-align: center !important;
    display: flex;
    justify-content: space-evenly;
  }
  .active .title {
    font-weight: bold;
  }
  .list {
    font-weight: bold;
    margin-left: 10px;
  }
  .item.titleList {
    font-weight: lighter;
  }
`
export const DimmerUI = styled(Dimmer)``
export const LoaderUI = styled(Loader)``
export const IconUIP = styled(Icon)`
  color: red !important;
`

export const PaginationUI = styled(Pagination)``

export const SegmentUI = styled(Segment)``
export const LoadingContainer = styled.div`
  display: block;
  margin: 30px 0 0 28px;
  height: 500px;
`
export const NoResultsTextWrapper = styled.p`
  ${(props: CompProps) => (props.theme as Theme).font_size.regular};
  color: ${(props: CompProps) => (props.theme as Theme).color.white.darkest};
  padding: 15px;
`
export const NoResultsContainer = styled.div`
  text-align: center;
  border: 1px solid
    ${(props: CompProps) => (props.theme as Theme).color.black.lightest};
  padding: 10px;
  border-radius: 5px;
  color: ${(props: CompProps) => (props.theme as Theme).color.black.light};
  margin-top: 15px;
`
export const NoResultsIconWrapper = styled.div`
  font-size: 6vw;
  color: ${(props: CompProps) => (props.theme as Theme).color.white.darkest};
  margin-top: 15px;
`
export interface TypesButtons {
  btnType?: 'secondary'
  btntype?: 'secondary'
}

export const TYPES_MESSAGE = {
  OK: 'ok',
  ERROR: 'error'
}

export const AccordionUI = styled(Accordion)`
  .title {
    border-top: inherit !important;
  }
  margin-bottom: 10px;
`

export const DropdownNew = styled(Dropdown)`
  padding: 0.5rem;
  text-align: center;
  background: #f3f3f3;
  border-radius: 0.5rem;
  i.icon.add::before {
    margin-left: 3px;
  }

  margin-top: 5px;
  .add + .menu {
    right: 5px !important;
    left: inherit !important;
  }
  .ui.dropdown .menu .menu {
    left: 100% !important;
    right: auto !important;
  }

  @supports (-ms-ime-align: auto) {
    .dropdown i.icon {
      float: none !important;
    }
  }
`

export const DropdownProject = styled(Dropdown)`
  .menu.transition.visible i {
    color: #000000;
    font-size: 18px;
  }
  .item {
    margin: 5px;
  }
  .export {
    margin-top: 20px;
  }
  .menu > .item {
    font-size: 12px !important;
  }
`

export const MainButton = styled(Button)`
  background: ${(props: CompProps) =>
    (props.theme as Theme).color._900}!important;
  color: white !important;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
    background: ${(props: CompProps) =>
      (props.theme as Theme).color._500}!important;
  }
  @media (max-width: ${(props: CompProps) =>
      (props.theme as Theme).screen.md}) {
  }
  @media (max-width: ${(props: CompProps) =>
      (props.theme as Theme).screen.sm}) {
    margin-left: 0;
  }
`

export const MainTextButton = styled(Button)`
  pointer-events: auto !important;
  text-transform: uppercase !important;
  background-color: transparent !important;
  color: ${(props: CompProps) =>
    (props.theme as Theme).color.black.light}!important;
  &:hover {
    color: ${(props: CompProps) => (props.theme as Theme).color._500}!important;
  }
  @media (max-width: ${(props: CompProps) =>
      (props.theme as Theme).screen.md}) {
  }
  @media (max-width: ${(props: CompProps) =>
      (props.theme as Theme).screen.sm}) {
    margin-left: 0;
  }
  &:disabled {
    cursor: not-allowed !important;
    color: ${(props: CompProps) =>
      (props.theme as Theme).color.black.lightest}!important;
  }
  letter-spacing: 1px !important;
`

export const Phrasegrey = styled.p`
  color: #b3b0b0;
  font-size: 13px;
  margin-bottom: 30px !important;
`

export const InputUI = styled(Input)`
  margin-left: 5px;
`

export const InputF = styled(Input)`
  .error {
    color: blue !important;
  }
  .error input {
    background-color: #fff6f6 !important;
    border-color: #e0b4b4 !important;
    color: #9f3a38 !important;
  }
`
export const DropdownUI = styled(Dropdown)``

export const LabelUI = styled(Label)`
  margin: 3px!important;
  color:white!important;
   font-family: ${(props: CompProps) => (props.theme as Theme).font.bold};
  ${({ label }) => label === 'Brand' && `background:#D9EAD3!important;`}
  ${({ label }) => label === 'Family' && `background:#C2D3F0!important;`}
    ${({ label }) => label === 'Reference' && `background:#45B0D08C!important;`}
    ${({ label }) =>
      label !== '' &&
      'background:#' + String(intToARGB(hashCode(label))) + '!important'}
    ${({ label }) =>
      label === 'Lumnius Flux' && `background:#fb84c138!important;`}
    margin-right: 5px!important;
    display: inline-flex!important;
    font-size: 13px!important;
    margin-top: 5px;
    div{
    font-weight: normal !important;
font-family: ${(props: CompProps) => (props.theme as Theme).font.light};
    }
`

export const ButtonUI = styled(Button)<TypesButtons>`
  color: white !important;
  pointer-events: auto !important;
  text-transform: uppercase !important;
  max-width: 180px;
  background-color: ${(props: TypesButtons) =>
    props.btntype === 'secondary'
      ? (props: CompProps) => (props.theme as Theme).color.black.lightest
      : (props: CompProps) => (props.theme as Theme).color._900}!important;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
    background: ${(props: CompProps) =>
      (props.theme as Theme).color._500}!important;
  }
  @media (max-width: ${(props: CompProps) =>
      (props.theme as Theme).screen.md}) {
  }
  @media (max-width: ${(props: CompProps) =>
      (props.theme as Theme).screen.sm}) {
    margin-left: 0;
  }
  &:disabled {
    cursor: not-allowed !important;
    background-color: ${(props: CompProps) =>
      (props.theme as Theme).color.black.lightest}!important;
  }
  letter-spacing: 1px !important;
`

export const TextButtonUI = styled.button`
  pointer-events: auto !important;
  text-transform: uppercase !important;
  background-color: transparent !important;
  max-width: 180px;
  color: ${(props: CompProps) =>
    (props.theme as Theme).color.black.light}!important;
  &:hover {
    color: ${(props: CompProps) => (props.theme as Theme).color._500}!important;
  }
  @media (max-width: ${(props: CompProps) =>
      (props.theme as Theme).screen.md}) {
  }
  @media (max-width: ${(props: CompProps) =>
      (props.theme as Theme).screen.sm}) {
    margin-left: 0;
  }
  &:disabled {
    cursor: not-allowed !important;
    color: ${(props: CompProps) =>
      (props.theme as Theme).color.black.lightest}!important;
  }
  letter-spacing: 1px !important;
`

export const IconUI = styled(Icon)``

export const IconHelp = styled(Icon)`
  color: ${(props: CompProps) => (props.theme as Theme).color._900};
`

export const Labeltitle = styled.label`
  color: #aaa;
`
export const MainButtonAction = styled.button`
  height: 30px;
  width: 180px;
  font-weight: bold;
  font-size:  ${(props: CompProps) => (props.theme as Theme).font_size.small};
  color: white;
  letter-spacing: 1px;

  display: block;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
 /* background: ${(props: CompProps) =>
   (props.theme as Theme).color.secondary};*/
 background: ${(props: CompProps) => (props.theme as Theme).color._900};
  border-radius: 4px;
  /* padding: 0px 40px; */
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
   /* background: ${(props: CompProps) =>
     (props.theme as Theme).color.secondaryHover};*/
      background: ${(props: CompProps) => (props.theme as Theme).color._500};
  }
  @media (max-width: ${(props: CompProps) =>
    (props.theme as Theme).screen.md}) {
  }
  @media (max-width: ${(props: CompProps) =>
    (props.theme as Theme).screen.sm}) {
    margin-left: 0;
  }
`
export const SmallButtonAction = styled(MainButtonAction)<TypesButtons>`
  padding: 0 20px;
  height: 30px;
  max-width: 215px;
  background-color: ${(props: TypesButtons) =>
    props.btnType === 'secondary'
      ? (props: CompProps) => (props.theme as Theme).color.black.lightest
      : ''};
  &:disabled {
    cursor: not-allowed;
    background-color: ${(props: CompProps) =>
      (props.theme as Theme).color.black.lightest};
  }
`
export const Fieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 10px;
  label {
    font-family: ${(props: CompProps) => (props.theme as Theme).font.semibold};
    font-weight: bold;
    padding: 5px 0;
  }
  select {
    vertical-align: middle;
    border-radius: 3px;
    min-width: 210px;
    width: 100%;
    min-height: 36px;
    border: 1px solid
      ${(props: CompProps) => (props.theme as Theme).color.black.lightest};
    transition: all 0.3s ease-in-out;
    padding: 0 10px;
    appearance: none;
  }
`
export const InputWrapper = styled.div`
  display: flex;
`
export const InputText = styled.input<InputText>`
  vertical-align: middle;
  min-width: 50px;
  max-width: 250px;
  width: 100%;
  min-height: 36px;

  border: 0px solid
    ${(props: InputText) =>
      props.error
        ? (props: CompProps) => (props.theme as Theme).color.forms.error
        : (props: CompProps) => (props.theme as Theme).color.black.lightest};
  transition: all 0.3s ease-in-out;
  border-bottom: 1px solid #aba8af;
  padding: 0 10px;

  &:focus {
    outline: none;
    border: 1px solid ${(props: CompProps) => (props.theme as Theme).color._900};
    box-shadow: inset 0 0 0 1px
      ${(props: CompProps) => (props.theme as Theme).color._900};
  }
  &:invalid {
    box-shadow: 0 0 3px 1px red;
  }
  /*
  &[type=number] { 
    -moz-appearance: textfield;
    appearance: textfield;
    margin: 10; 
  }

  &[type=number]::-webkit-inner-spin-button, 
  &[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
  */
`
export const DivSearch = styled.div`
  width: 100%;
  position: relative;
  display: flex;
`
export const InputSearch = styled.input`
  /*border: 1px solid #4e5766;*/
  border: 2px solid #f1f1f1;
  font-size: 18px;
  /* width: 50%; */
  width: 530px;
`
export const ButtonSearch = styled.button`
  width: 40px;
  height: 36px;
  border: 1px solid ${(props: CompProps) => (props.theme as Theme).color._900};
  background: ${(props: CompProps) => (props.theme as Theme).color._900};
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
`

export const MessageInput = styled.div<TypesMessage>`
  display: flex;
  align-items: center;
  margin-left: 2%;
  color: ${(props: TypesMessage) =>
    props.type === TYPES_MESSAGE.OK
      ? (props: CompProps) => (props.theme as Theme).color.forms.correct
      : (props: CompProps) => (props.theme as Theme).color.forms.error};
  p {
    margin: 0;
  }
`

export const UploadImage = styled.div`
  && i {
  }
  div {
    color: ${(props: CompProps) => (props.theme as Theme).color.fourty};
    text-align: center;
  }
  display: flex;
  justify-content: center;
  align-items: center;

  height: 143px;
  width: 143px;
  background: rgba(255, 255, 255, 0.58);
  border: 2px solid #e5e5e5;

  box-sizing: border-box;
  cursor: pointer;
  ${({ title }) => title !== 'upload' && `   min-height: 128px!important;`}
  :hover {
    backgroundcolor: 'rgba(255,255,255,.8)';
  }
  :hover {
    .uploadimage {
      display: block !important;
      position: absolute;
    }
  }
  p {
    font-size: 12px;
  }
`
export const TableUI = styled(Table)`
  && th {
    background: transparent !important;
  }
  border: 0px !important;
  && td {
    border-top: 0px !important;
  }
  box-shadow: ${(props: CompProps) =>
    (props.theme as Theme).filters.drop_shadow};
  padding: 15px;
  margin-bottom: 30px !important;
`
export const Toogle = styled(Checkbox)`
  &&&&& input:checked ~ label:before {
    background-color: ${(props: CompProps) =>
      (props.theme as Theme).color._900}!important;
  }
`
export const StyledThumb = styled.div`
  height: 16px;
  width: 16px;
  text-align: center;
  background-color: ${(props: CompProps) =>
    (props.theme as Theme).color.white.regular};
  border: 3px solid ${(props: CompProps) => (props.theme as Theme).color._900};
  border-radius: 50%;
  cursor: grab;
  top: -3px;
  outline: 0;
  position: relative;
`
export const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 10px;
`
export const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: ${(props: any) =>
    props.index === 2
      ? (props: CompProps) => (props.theme as Theme).color.white.darkest
      : props.index === 1
      ? (props: CompProps) => (props.theme as Theme).color._500
      : (props: CompProps) => (props.theme as Theme).color.white.darkest};
  border-radius: 999px;
`
export const DeleteSceneIconContainer = styled.div`
  cursor: pointer;
  color: ${(props: CompProps) => (props.theme as Theme).color.neutral._900};
  :hover svg {
    color: ${(props: CompProps) => (props.theme as Theme).color._900};
  }
`
export const TabUI = styled(Tab)`
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 50px;
  &&& a.active.item {
    color: ${(props: CompProps) => (props.theme as Theme).color._900}!important;
    border-bottom: 2px solid
      ${(props: CompProps) => (props.theme as Theme).color._900}!important;
  }
  &&& span {
    padding-right: 10px !important;
  }
  &&& .item {
    color: ${(props: CompProps) =>
      (props.theme as Theme).color.neutral._500}!important;
    font-style: normal;
    font-weight: bold;
    ${(props: CompProps) => (props.theme as Theme).font_size.xxsmall};
    border-bottom: 2px solid white;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    margin-right: 10px;
  }
  &&& .item svg {
    color: ${(props: CompProps) =>
      (props.theme as Theme).color.neutral._500}!important;
  }
  &&& .item svg:hover {
    color: ${(props: CompProps) => (props.theme as Theme).color._900}!important;
  }
  .segment.active {
    border: 0px;
  }
  .ui.tabular.menu {
    border-bottom: 0px;
  }
  &&& .tabular {    
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &&& .tabular input {
    border-radius: 25px;
  }

  &&& .search {
    max-width: 165px;
    height: 35px;
    display: flex;
  }
`

// Hash any string into an integer value
// Then we'll use the int and convert to hex.
function hashCode(str: string) {
  var hash = 0
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return hash
}

// Convert an int to hexadecimal with a max length
// of six characters.
function intToARGB(i: number) {
  return (i >>> 0).toString(16).slice(-6)
}
