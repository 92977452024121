import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { CompProps, Theme } from '../../styles/theme'
import { Icon, Loader, Tab } from 'semantic-ui-react'
import { FilterSections } from './LedListAvailable'
import { Led, ListLeds } from '../../api/LedApi'
import { FilterSection } from './types'
import Slider from './Slider'
import { ButtonUI } from '../../styles/commons'

interface Props {
  setitemsLeds: Function
  openFilter: boolean
  openFilters: Function
  activeNow: string
  InitFilters: Function
  paneActive: number
  itemleds: ListLeds
  itemledsAux: ListLeds
  itemledsAuxString: ListLeds
  setFiltersSections: Function
  filtersSections: FilterSections | undefined
}
const Filters: React.FC<Props> = (props) => {
  const {
    openFilter,
    openFilters,
    itemledsAux,
    setitemsLeds,
    itemledsAuxString,
    activeNow,
    setFiltersSections,
    filtersSections,
    InitFilters
  } = props
 
  const [panesFilter, setpanesFilter] = useState<
    [
      {
        key: string
        menuItem: string
        render: () => JSX.Element
      }
    ]
  >()
  const [loadingFilters, setloadingFilters] = useState(false)

  const findMaxValue = (paramName: string, ledArray: Led[]) => {
    if(ledArray.length !== 0) {
      const valueArray: number[] = ledArray.map((led: Led) => led[paramName])
      return Math.max.apply(
        null, valueArray.filter((i:number ) => i !== -1)
      )
    } else {
      return 1000
    }
  }

  const findMinValue = (paramName: string, ledArray: Led[]) => {
    if(ledArray.length !== 0) {
      const valueArray: number[] = ledArray.map((led: Led) => led[paramName])
      return Math.min.apply(
        null, valueArray.filter((i:number ) => i !== -1)
      )
    } else {
      return 0
    }
  }

  /*
  TODO: Simplify Object creation
  const addParam = (paramName: string, paramType: string) => {
    return {
      title: paramName,
      type: paramType,
      max: itemledsAux && findMaxValue('cct_k', itemledsAux[indexled]),
      min: itemledsAux && findMinValue('cct_k', itemledsAux[indexled])
    }
  }
  filtersSectionsAux.colorimetric.push(addParam('CCT (K)','cct_k' ))
  filtersSectionsAux.colorimetric.push(addParam('DUV','duv' ))
  filtersSectionsAux.colorimetric.push(addParam('CCT (K)','cct_k' ))
  filtersSectionsAux.colorimetric.push(addParam('CCT (K)','cct_k' ))
  */
  
  const fillFiltersSections = () => {
    let filtersSectionsAux: FilterSections
    // setloadingFilters(true)

    let indexled =
      activeNow === 'user'
        ? ('user' as keyof typeof itemledsAux)
        : ('catalog' as keyof typeof itemledsAux)

   
    filtersSectionsAux = {
      colorimetric: [
        {
          title: 'CCT (K)',
          type: 'cct_k',
          max: itemledsAux && findMaxValue('cct_k', itemledsAux[indexled]),
          min: itemledsAux && findMinValue('cct_k', itemledsAux[indexled])
        },
        {
          title: 'DUV',
          type: 'duv',
          max: itemledsAux && findMaxValue('duv', itemledsAux[indexled]),
          min: itemledsAux && findMinValue('duv', itemledsAux[indexled])
        },
        {
          title: 'RF',
          type: 'rf',
          max: itemledsAux && findMaxValue('rf', itemledsAux[indexled]),
          min: itemledsAux && findMinValue('rf', itemledsAux[indexled])
        },
        {
          title: 'RG',
          type: 'rg',
          max: itemledsAux && findMaxValue('rg', itemledsAux[indexled]),
          min: itemledsAux && findMinValue('rg', itemledsAux[indexled])
        },
        {
          title: 'CRI',
          type: 'cri',
          max: itemledsAux && findMaxValue('cri', itemledsAux[indexled]),
          min: itemledsAux && findMinValue('cri', itemledsAux[indexled])
        },
        {
          title: 'R9',
          type: 'r9',
          max: itemledsAux && findMaxValue('r9', itemledsAux[indexled]),
          min: itemledsAux && findMinValue('r9', itemledsAux[indexled])
        },
        {
          title: 'COI',
          type: 'coi',
          max: itemledsAux && findMaxValue('coi', itemledsAux[indexled]),
          min: itemledsAux && findMinValue('coi', itemledsAux[indexled])
        },
        {
          title: 'Peak Wavelength (nm)',
          type: 'peak_wavelength',
          max: itemledsAux && findMaxValue('peak_wavelength', itemledsAux[indexled]),
          min: itemledsAux && findMinValue('peak_wavelength', itemledsAux[indexled])
        }
      ],
      nonVisual: [
        {
          title: 'M/P ratio',
          type: 'mp_ratio',
          max: itemledsAux && findMaxValue('mp_ratio', itemledsAux[indexled]),
          min: itemledsAux && findMinValue('mp_ratio', itemledsAux[indexled])
        },
        {
          title: 'MDER',
          type: 'mder',
          max: itemledsAux && findMaxValue('mder', itemledsAux[indexled]),
          min: itemledsAux && findMinValue('mder', itemledsAux[indexled])
        },
        {
          title: 'CS @ 100 lx',
          type: 'cs_100',
          max: itemledsAux && findMaxValue('cs_100', itemledsAux[indexled]),
          min: itemledsAux && findMinValue('cs_100', itemledsAux[indexled])
        },
        {
          title: 'CLa @ 100 lx',
          type: 'cla_100',
          max: itemledsAux && findMaxValue('cla_100', itemledsAux[indexled]),
          min: itemledsAux && findMinValue('cla_100', itemledsAux[indexled])
        },
        {
          title: '440-490nm %',
          type: 'ul',
          max: itemledsAux && findMaxValue('ul', itemledsAux[indexled]),
          min: itemledsAux && findMinValue('ul', itemledsAux[indexled])
        },
        {
          title: 'EML @ 100 lx',
          type: 'eml_100',
          max: itemledsAux && findMaxValue('eml_100', itemledsAux[indexled]),
          min: itemledsAux && findMinValue('eml_100', itemledsAux[indexled])
        },
        {
          title: 'MEDI @ 100 lx',
          type: 'medi_100',
          max: itemledsAux && findMaxValue('medi_100', itemledsAux[indexled]),
          min: itemledsAux && findMinValue('medi_100', itemledsAux[indexled])
        }
      ],
      power: [
        {
          title: 'Efficiency (lm/W)',
          type: 'efficiency_lmw',
          max: itemledsAux && findMaxValue('efficiency_lmw', itemledsAux[indexled]),
          min: itemledsAux && findMinValue('efficiency_lmw', itemledsAux[indexled])
        },
        {
          title: 'Power (W)',
          type: 'pf_w',
          max: itemledsAux && findMaxValue('pf_w', itemledsAux[indexled]),
          min: itemledsAux && findMinValue('pf_w', itemledsAux[indexled])
        },
        {
          title: 'Luminous Flux (lm)',
          type: 'luminous_flux_lm',
          max: itemledsAux && findMaxValue('luminous_flux_lm', itemledsAux[indexled]),
          min: itemledsAux && findMinValue('luminous_flux_lm', itemledsAux[indexled])
        },
        {
          title: 'Current (mA)',
          type: 'if_ma',
          max: itemledsAux && findMaxValue('if_ma', itemledsAux[indexled]),
          min: itemledsAux && findMinValue('if_ma', itemledsAux[indexled])
        },
        {
          title: 'Voltage (V)',
          type: 'vf_v',
          max: itemledsAux && findMaxValue('vf_v', itemledsAux[indexled]),
          min: itemledsAux && findMinValue('vf_v', itemledsAux[indexled])
        },
        {
          title: 'LER (lm/W)',
          type: 'ler_lmw',
          max: itemledsAux && findMaxValue('ler_lmw', itemledsAux[indexled]),
          min: itemledsAux && findMinValue('ler_lmw', itemledsAux[indexled])
        }
      ]
    }

    setFiltersSections(filtersSectionsAux)
    // setloadingFilters(false)
  }

  useEffect(() => {
    const filtersLeds = () => {
      const typeKey = activeNow as keyof ListLeds
      let itemLedFilter: Led[] = itemledsAuxString[typeKey]
    
      if (filtersSections) {
        itemLedFilter = itemledsAuxString[typeKey].filter((led: Led) => {
          let ledaux = led
          let validate = true
          for (
            let index = 0;
            index < filtersSections.colorimetric.length;
            index++
          ) {
            let key = String(
              filtersSections.colorimetric[index].type.toLowerCase()
            ) as keyof Led
            const element = filtersSections.colorimetric[index]
            if (element.rangenow && element.type === key) {
              if (
                (element.rangenow[0] <= Number(ledaux[key]) &&
                  Number(element.rangenow[1]) >= Number(ledaux[key])) ||
                (Number(ledaux[key]) === -1 && validate)
              ) {
              } else {
                validate = false
              }
            }
          }
          for (
            let index = 0;
            index < filtersSections.nonVisual.length;
            index++
          ) {
            let key = String(
              filtersSections.nonVisual[index].type.toLowerCase()
            ) as keyof Led
            const element = filtersSections.nonVisual[index]
            if (element.rangenow && element.type === key) {
              if (
                (element.rangenow[0] <= Number(ledaux[key]) &&
                  Number(element.rangenow[1]) >= Number(ledaux[key])) ||
                (Number(ledaux[key]) === -1 && validate)
              ) {
              } else {
                validate = false
              }
            }
          }
          for (let index = 0; index < filtersSections.power.length; index++) {
            let key = String(
              filtersSections.power[index].type.toLowerCase()
            ) as keyof Led
            const element = filtersSections.power[index]
            if (element.rangenow && element.type === key) {
              if (
                (element.rangenow[0] <= Number(ledaux[key]) &&
                  Number(element.rangenow[1]) >= Number(ledaux[key])) ||
                (Number(ledaux[key]) === -1 && validate)
              ) {
              } else {
                validate = false
              }
            }
          }
          if (validate) {
            return ledaux
          }
        })
        //   }
        //   })
      }

      const itemledsAuxRes: ListLeds = Object.assign({}, itemledsAux)
      itemledsAuxRes[typeKey] = itemLedFilter
      
      setitemsLeds(itemledsAuxRes)
      //setItemsUserLeds(itemLedFilter)
    }
  
    if (filtersSections !== undefined) filtersLeds()
  }, [filtersSections, activeNow, itemledsAuxString, itemledsAux])
  
  const onChangeFilter = (slider: string, values: any, category: string) => {
    const filtersSectionsAux: FilterSections = Object.assign(
      {},
      filtersSections
    )

    const keyFilter = category as keyof typeof filtersSectionsAux
    var foundIndex =
      filtersSectionsAux &&
      filtersSectionsAux[keyFilter].findIndex(
        (filterSection: FilterSection) => filterSection.type === slider
      )
    if (foundIndex !== undefined && filtersSectionsAux !== undefined) {
      filtersSectionsAux[keyFilter][foundIndex].rangenow = values
      //  filtersSectionsAux.colorimetric[foundIndex].rangemax = values[0]

      setFiltersSections(filtersSectionsAux)
    }
  }

  useEffect(() => {
    const typeKey = activeNow as keyof ListLeds

    if (itemledsAux[typeKey].length === 0) {
      setloadingFilters(true)
    } else {
      fillFiltersSections()

      setloadingFilters(false)
    }

    //  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeNow, itemledsAux, loadingFilters])

  useEffect(() => {
    let didCancel = false

    let panesFiltersAux: [
      {
        key: string
        menuItem: string
        render: () => JSX.Element
      }
    ] = [
      {
        key: 'COLORIMETRIC',
        menuItem: 'COLORIMETRIC',
        render: () => (
          <Tab.Pane loading={loadingFilters} key={'COLORIMETRIC'}>
            {' '}
            <TableFilter>
              {filtersSections ? (
                filtersSections.colorimetric &&
                filtersSections.colorimetric.map(
                  (filterSection: FilterSection, index: number) => (
                    <ContainerFilter key={filterSection.title}>
                      <div className="title">{filterSection.title}:</div>
                      <div className="filter">
                        <Slider
                          category={'colorimetric'}
                          filterSection={filterSection}
                          onChangeFilter={onChangeFilter}
                        />
                      </div>
                    </ContainerFilter>
                  )
                )
              ) : (
                <Loader active />
              )}
            </TableFilter>
          </Tab.Pane>
        )
      }
    ]

    panesFiltersAux.push({
      menuItem: 'POWER',
      key: 'POWER',
      render: () => (
        <Tab.Pane loading={loadingFilters} key={'POWER'}>
          {' '}
          <TableFilter>
            {filtersSections &&
              filtersSections.power &&
              filtersSections.power.map(
                (filterSection: FilterSection, index: number) => (
                  <ContainerFilter key={filterSection.title}>
                    <div className="title">{filterSection.title}:</div>
                    <div className="filter">
                      <Slider
                        filterSection={filterSection}
                        onChangeFilter={onChangeFilter}
                        category={'power'}
                      />
                    </div>
                  </ContainerFilter>
                )
              )}
          </TableFilter>
        </Tab.Pane>
      )
    })

    panesFiltersAux.push({
      menuItem: 'NON VISUAL',
      key: 'NON VISUAL',
      render: () => (
        <Tab.Pane loading={loadingFilters} key={'nonVisual'}>
          {' '}
          <TableFilter>
            {filtersSections ? (
              filtersSections.nonVisual &&
              filtersSections.nonVisual.map(
                (filterSection: FilterSection, index: number) => (
                  <ContainerFilter key={filterSection.title}>
                    <div className="title">{filterSection.title}:</div>
                    <div className="filter">
                      <Slider
                        category={'nonVisual'}
                        filterSection={filterSection && filterSection}
                        onChangeFilter={onChangeFilter}
                      />
                    </div>
                  </ContainerFilter>
                )
              )
            ) : (
              <Loader />
            )}
          </TableFilter>
        </Tab.Pane>
      )
    })

    !didCancel && setpanesFilter(panesFiltersAux)

    return () => {
      didCancel = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openFilter, activeNow, loadingFilters])

  return (
    <WrapperFilter>
      <HeaderFilter
        onClick={(ev: any) => {
          ev.stopPropagation()
          openFilters()
        }}
        about={openFilter ? 'block' : 'none'}
      >
        <div>{openFilter === true && <Icon name="close" />}</div>

        <div className="filterlabel">
          <div className="filtersReset">
            {openFilter && (
              <ButtonUI
                onClick={(ev: any) => {
                  ev.stopPropagation()
                  InitFilters()
                }}
                btntype="primary"
                size="mini"
              >
                <p>
                  <Icon name="undo" />
                  RESET
                </p>
              </ButtonUI>
            )}
          </div>
          <div className="filtersTitle">
            <Icon name="options" />
            <p>FILTERS</p>
          </div>
        </div>
      </HeaderFilter>
      <MenuFilter about={openFilter ? 'block' : 'none'}>
        <TabUIFilters
          className="filtersTab"
          key={'filtersTab'}
          panes={panesFilter}
        />
      </MenuFilter>
    </WrapperFilter>
  )
}

const WrapperFilter = styled.div`
  position: absolute;
  bottom: 0px;
  background: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
`

const TableFilter = styled.div`
  display: block;
  height: 280px;
`
const ContainerFilter = styled.div`
  display: inline-block;
  width: 50%;
  margin-bottom: 25px;
  text-align: center;
  .title {
    font-style: normal;
    font-weight: normal;
    color: ${(props: CompProps) => (props.theme as Theme).color.neutral._500};
  }
  .filter {
    width: 95%;
    padding-top: 3px;
    display: inline-block;
  }
`

const MenuFilter = styled.div`
  ${({ about }) =>
    about === 'block'
      ? `
  display:block;
  z-index: 999;
  background: white;
     
  `
      : `  display:none;`}
`

const HeaderFilter = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: ${(props: CompProps) => (props.theme as Theme).color.neutral._500};
  ${({ about }) =>
    about === 'block'
      ? `
   color:white;
      background:#DC765F!important
  `
      : `  background: rgba(196,196,196,0.2);`}

  display: flex;
  justify-content: space-between;
  height: 55px;
  align-items: center;

  padding: 15px;
  .filterlabel {
    display: flex;
    align-items: center;
    flex: 1;
  }
  && .filtersReset {
    flex: 1;
    padding-bottom: 3px;
    align-content: center;
    justify-content: flex-end;
    display: flex;
    i {
      font-size: 16px;
      margin: 2px 10px;
    }
  }
  && .filtersTitle {
    display: flex;
    align-content: center;
    justify-content: flex-end;
    i {
      font-size: 16px;
      margin: 2px 10px;
    }
  }

  :hover {
    background: ${(props: CompProps) => (props.theme as Theme).color._900};
    color: white;
  }
  cursor: pointer;
`
const TabUIFilters = styled(Tab)`
  &&& a.active.item {
    color: ${(props: CompProps) => (props.theme as Theme).color._900}!important;
    border-bottom: 3px solid
      ${(props: CompProps) => (props.theme as Theme).color._900}!important;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    margin-right: 10px;
  }

  &&& a {
    color: ${(props: CompProps) =>
      (props.theme as Theme).color.neutral._500}!important;
  }
  .segment.active {
    border: 0px;
  }
  .active.tab {
    padding: 0px;
  }
  .ui.tabular.menu {
    border-bottom: 0px;
  }
  &&& .tabular {
    display: flex;
    justify-content: space-between;
    width: 65%;
    margin: 0 auto;
  }
  &&& .attached.segment {
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }
`

export default Filters
