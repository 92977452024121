import React from 'react'
import styled from 'styled-components'

interface Props {
  title: string
  src: string
}

const ImageSection: React.FC<Props> = (props) => {
  return (
    <ImagesSection>
      <ImagesElement src={props.src} />
    </ImagesSection>
  )
}

const ImagesElement = styled.img`
  width: 100%;
`
const ImagesSection = styled.div`
  display: flex;
  flex-direction: column;
`
export default ImageSection
