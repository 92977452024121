import React from 'react'
import styled from 'styled-components'
import { StyledSlider, StyledThumb, StyledTrack } from '../../styles/commons'
import { Theme } from '../../styles/theme'

import { FilterSection } from './types'

interface Props {
  filterSection: FilterSection
  onChangeFilter: Function
  category: string
}

const countDecimals = function (value: any) {
  if (Math.floor(value) === value) return 0
  return value.toString().split('.')[1].length || 0
}
const AddZero = (zeros: number) => {
  let numberIncludesZero = 1
  for (let index = 0; index < zeros; index++) {
    numberIncludesZero = numberIncludesZero / 10
  }
  return numberIncludesZero
}
export const Thumb = (props: any, state: any) => (
  <StyledThumbFilter {...props}>
    <SquareNumber>{state.valueNow} </SquareNumber>
  </StyledThumbFilter>
)
export const Track = (props: any, state: any) => (
  <StyledTrackFilter {...props} index={state.index} />
)

const Slider: React.FC<Props> = (props) => {
  const { filterSection, onChangeFilter, category } = props

  return (
    <>
      {filterSection && (
        <StyledSlider
          value={[
            filterSection.rangenow !== undefined
              ? filterSection.rangenow[0]
              : filterSection.min,
            filterSection.rangenow !== undefined
              ? filterSection.rangenow[1]
              : filterSection.max
          ]}
          /*    defaultValue={[
            filterSection.rangenow !== undefined
              ? filterSection.rangenow[0]
              : filterSection.min === -1
              ? 0
              : filterSection.min,
            filterSection.rangenow !== undefined
              ? filterSection.rangenow[1]
              : filterSection.max === -1
              ? 0
              : filterSection.max
          ]}*/
          renderTrack={Track}
          max={filterSection.max}
          step={AddZero(countDecimals(filterSection.max))}
          min={filterSection.min}
          renderThumb={Thumb}
          onChange={(data) =>
            onChangeFilter(filterSection.type, data, category)
          }
          //onAfterChange={(data) => filtersLeds(filterSection.type, data)}
        />
      )}
    </>
  )
}
export const StyledThumbFilter = styled(StyledThumb)`
  background-color: ${(props: any) =>
    props.about ? '#' + props.about : (props.theme as Theme).color._300};
  border: 3px solid
    ${(props: any) =>
      props.about ? '#' + props.about : (props.theme as Theme).color._300};

  top: -1px;
`
export const SquareNumber = styled.div`
  color: transparent;

  :hover {
    visibility: visible !important;
    position: relative;
    padding: 5px;
    font-size: 12px;
    width: max-content;
    color: white;
    background: ${(props: any) =>
      props.about ? '#' + props.about : (props.theme as Theme).color._900};
    top: -38px !important;
    left: 50%;
    min-width: 45px;
    padding-top: 5px;
    transform: translateX(-50%);
  }
  :hover:before {
    border: 10px solid
      ${(props: any) =>
        props.about ? '#' + props.about : (props.theme as Theme).color._900};
    content: '';
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-right-color: transparent;
    position: absolute;
    bottom: -17px;
    left: 50%;
    transform: translateX(-50%);
    display: block !important;
  }
`
const StyledTrackFilter = styled(StyledTrack)`
  top: 5px;
`
export default Slider
