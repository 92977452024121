import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Grid, Input, TextArea, Form, Segment, Header } from 'semantic-ui-react'

import { GOOGLE_MAP_API_KEY } from '../api/api'
import '../styles/react-tabs.css'
import GMap from './Map/GMap'
import GPlace from './Map/GPlace'
import { IconUIP, ButtonUI } from '../styles/commons'
import { projectApi, Project } from '../api/ProjectApi'

import Tour from './help/Tour'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
declare const google: any

interface Props {
  closeModal: Function
  EditProject?: Project
  projectTourActive: boolean
}

interface FormProjectObj {
  location_name: string
  description: string
  location_coords: Array<number>
  reference: string
}

const deafultProject: FormProjectObj = {
  location_name: '',
  description: '',
  location_coords: [0, 0],
  reference: ''
}

interface Location {
  address: string
  lat: number
  lng: number
}

const defaultLocation: Location = {
  address: '',
  lat: -1,
  lng: -1
}

const NewProject: React.FC<Props> = (props) => {
  const { EditProject, projectTourActive } = props
  const [tourActive, setTourActive] = useState(projectTourActive)
  const [loadMap, setLoadMap] = useState(false)
  const [ErrorForm, setErrorForm] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [location, setLocation] = useState<Location>(defaultLocation)
  const [FormProject, setFormProject] = useState<FormProjectObj>(deafultProject)

  const loadGoogleMapScript = (callback: any) => {
    if (typeof google === 'object' && typeof google.maps === 'object') {
      callback()
    } else {
      const googleMapScript = document.createElement('script')
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places`
      window.document.body.appendChild(googleMapScript)
      googleMapScript.addEventListener('load', callback)
    }
  }

  const HandleOnChange = (e: any) => {
    setFormProject({ ...FormProject, [e.target.name]: e.target.value })
  }

  const validateForm = () => {
    let error = false
    if (FormProject.description === '') {
      error = true
    }
    if (FormProject.reference === '') {
      error = true
    }

    if (location.address === '') {
      error = true
    }
    if (location.lat === -1 || location.lng === -1) {
      error = true
    }

    setErrorForm(error)
  }

  useEffect(() => {
    validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })

  useEffect(() => {
    let didCancel: boolean = false

    setFormProject(deafultProject)
    setLocation(defaultLocation)

    loadGoogleMapScript(() => {
      !didCancel && setLoadMap(true)
    })
    return () => {
      didCancel = true
    }
  }, [])

  useEffect(() => {
    if (EditProject) {
      setFormProject(EditProject)
      setLocation({
        address: EditProject.location_name,
        lat: EditProject.location_coords[0],
        lng: EditProject.location_coords[1]
      })
    }
  }, [EditProject])

  useEffect(() => {
    setTourActive(props.projectTourActive)
  }, [props.projectTourActive])

  const hadleCreateProject = async () => {
    setIsLoading(true)

    const auxFormProject = Object.assign({}, FormProject)
    auxFormProject.location_coords = [location.lat, location.lng]
    auxFormProject.location_name = location.address

    const response = await projectApi.createProjectUser(auxFormProject)

    if (response) {
      setFormProject(deafultProject)
      setLocation(defaultLocation)

      props.closeModal(true)
    }
    // TODO: Handle error

    setIsLoading(false)
  }

  const handleModifyProject = async () => {
    setIsLoading(true)

    const auxFormProject = Object.assign({}, FormProject)
    auxFormProject.location_coords = [location.lat, location.lng]
    auxFormProject.location_name = location.address

    const response = await projectApi.updateProjectUser(auxFormProject)
    if (response) {
      toast.success(`Your project has been correctly modified`)
      // TODO: Close toast on modal close
      // props.closeModal(true)
    }

    setIsLoading(false)
  }

  const updateLocation = (local: any) => {
    setLocation(local)
  }

  return (
    <Form>
      {isLoading && (
        <FormLoadingContainer>
          <div className="ui active centered inline loader"></div>
        </FormLoadingContainer>
      )}
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <Form.Field className="tour-project-name">
              <label> Reference</label>
              <Input
                placeholder="Name"
                name="reference"
                value={FormProject.reference}
                onChange={(e: any) => HandleOnChange(e)}
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={8}>
            <Form.Field className="tour-project-description">
              <label> Description </label>
              <TextArea
                placeholder="Description"
                name="description"
                value={FormProject.description}
                onChange={(e: any) => HandleOnChange(e)}
              />
            </Form.Field>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={8}>
            <Form.Field className="tour-location-project">
              <label> Location </label>
              {!loadMap ? (
                <div>Loading...</div>
              ) : (
                <div className="tour-project-location">
                  <GPlace
                    EditLocation={location.address}
                    locationCallback={updateLocation}
                  />
                </div>
              )}
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <div>
              {location.lat === -1 && location.lng === -1 ? (
                <Segment placeholder>
                  <Header icon color="grey">
                    <IconUIP color="grey" name="map marker alternate" />
                    No location selected
                  </Header>
                </Segment>
              ) : (
                <GMap
                  EditLocation={[location.lat, location.lng]}
                  Location={location}
                />
              )}
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column floated="right" width={16}>
            <CreateButtonWrapper className="tour-create-project">
              <ButtonUI
                btntype="primary"
                disabled={ErrorForm}
                onClick={() => {
                  EditProject ? handleModifyProject() : hadleCreateProject()
                }}
              >
                {EditProject ? 'Modify' : 'Create'}
              </ButtonUI>
            </CreateButtonWrapper>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <ToastContainer />
      {tourActive && <Tour section="PAGE_NEW_PROJECT" />}
    </Form>
  )
}

const CreateButtonWrapper = styled.div`
  float: right;
`

const FormLoadingContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  z-index: 100;
  padding-top: 200px;
`

export default NewProject
