import React, { useEffect, useRef } from 'react'
import { Chart } from 'chart.js'

import { ChartProps } from './ChartStatic'

const CRIiBarChart: React.FC<ChartProps> = (props) => {

    const chartCanvasRef = useRef<HTMLCanvasElement>(null)

    useEffect(() => {
        const canvas: HTMLCanvasElement | null = chartCanvasRef.current
        canvas && drawChart(canvas)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.chartData])

    const drawChart = (canvasRef: HTMLCanvasElement) => {
        const ctx: CanvasRenderingContext2D | null = canvasRef.getContext("2d")
        if(ctx) {
            const chartGraph = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: ['R1', 'R2', 'R3', 'R4', 'R5', 'R6', 'R7', 'R8', 'R9', 'R10', 'R11', 'R12', 'R13', 'R14', '', 'Ra'],
                datasets: [{
                    data: props.chartData[0],
                    backgroundColor: [
                        'rgba(185, 128, 112, 1.0)',
                        'rgba(162, 135, 84, 1.0)',
                        'rgba(135, 147, 62, 1.0)',
                        'rgba(91, 149, 104, 1.0)',
                        'rgba(102, 147, 146, 1.0)',
                        'rgba(114, 138, 174, 1.0)',
                        'rgba(162, 127, 167, 1.0)',
                        'rgba(191, 125, 154, 1.0)',
                        'rgba(188, 29, 47, 1.0)',
                        'rgba(231, 188, 52, 1.0)',
                        'rgba(34, 129, 91, 1.0)',
                        'rgba(0, 68, 127, 1.0)',
                        'rgba(234, 178, 140, 1.0)',
                        'rgba(86, 95, 51, 1.0)',
                        'rgba(100, 100, 100, 1.0)',
                        'rgba(100, 100, 100, 1.0)'
                    ]
                }]
            },
            options: {
                legend: {
                    display: false
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                },
                responsive: false,
                animation: {
                    duration: 1,
                    onComplete: () => {
                        props.callBack(chartGraph.toBase64Image())
                        Object.defineProperty(chartGraph.options.animation, 'onComplete', {value: undefined})
                    }
                }
            }
            })
        }  
    }

    return(
        <canvas ref={chartCanvasRef} width={340} height={340} />
    )
}

export default CRIiBarChart