
interface ParamInfoParam {
    name: string
    value: string
}

export interface ParamsInfoColumn {
    name: string
    color: string
    params: ParamInfoParam[]
}

export interface PDFDocTexts {
    scene: string
    fixture: string
    cct: number
    duv: number
    rf: number
    rg: number
    cri: number
    r9: number
    coi: number
    x: number
    y: number
    mp: number
    mder: number
    cs_100: number
    cla_100: number
    eml_100: number
    medi_100: number
    ul: number
    efficiency_lmw: number
    pf_w: number
    luminous_flux_lm: number
}

export const defaultPDFDocTexts: PDFDocTexts = {
    scene: '',
    fixture: '',
    cct: -1,
    duv: -1,
    rf: -1,
    rg: -1,
    cri: -1,
    r9: -1,
    coi: -1,
    x: -1,
    y: -1,
    mp: -1,
    mder: -1,
    cs_100: -1,
    cla_100: -1,
    eml_100: -1,
    medi_100: -1,
    ul: -1,
    efficiency_lmw: -1,
    pf_w: -1,
    luminous_flux_lm: -1
}
