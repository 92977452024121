import React, { useEffect, useRef } from 'react'

import { Chart } from 'chart.js'
import { ChartProps } from './ChartStatic'
import backgroundSource from '../../images/saturation_chart.png'

const ColorVectorScatter: React.FC<ChartProps> = (props) => {

    const chartCanvasRef = useRef<HTMLCanvasElement>(null)

    useEffect(() => {
        const canvas: HTMLCanvasElement | null = chartCanvasRef.current

        const loadBackground = () => {
            const img: HTMLImageElement = new Image()
            img.src = backgroundSource
            img.onload = () => {
                canvas && drawChart(canvas, img)
            }
        }

        loadBackground()
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.chartData])

    const drawChart = (canvasRef: HTMLCanvasElement, backgroundImage: HTMLImageElement) => {
        const ctx: CanvasRenderingContext2D | null = canvasRef.getContext("2d")
            
        if(ctx) {
            const chartGraph = new Chart(ctx, {
            type: 'scatter',
            data: {
                datasets: [
                {
                    label: 'r',
                    data: props.chartData[1],
                    borderColor: 'black',
                    borderWidth: 2,
                    pointRadius: 0,
                    fill: false,
                    showLine: true,
                    lineTension: 0
                },
                {
                    label: 'k',
                    data: props.chartData[0],
                    borderColor: 'red',
                    borderWidth: 2,
                    pointRadius: 0,
                    fill: false,
                    showLine: true,
                    lineTension: 0
                }]
            },
            options: {
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            beginAtZero: true,
                            suggestedMin: -150,
                            suggestedMax: 150
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            suggestedMin: -150,
                            suggestedMax: 150
                        }
                    }]
                },
                responsive: false,
                animation: {
                    duration: 1,
                    onComplete: () => { 
                        props.callBack(chartGraph.toBase64Image())
                        Object.defineProperty(chartGraph.options.animation, 'onComplete', {value: undefined})
                    }
                }
            },
            plugins: [
              {
                beforeDraw: (chart: Chart) => {
                    var ctx = chart.ctx
                    if(ctx && backgroundImage) {
                        ctx.drawImage(backgroundImage, 39, 7, 289, 303)
                    }
                }
              }
            ]
            })
        }  
    }

    return(
        <canvas ref={chartCanvasRef} width={340} height={340} />
    )
}

export default ColorVectorScatter
