import React, { useEffect, useRef } from 'react'

declare var google: any

interface Props {
  Location: any
  EditLocation?: number[]
}
const GMap: React.FC<Props> = (props) => {
  const { EditLocation } = props
  const googleMapRef = useRef(null)
  
  useEffect(() => {
    let didCancel: boolean = false

    const initGoogleMap = () => {
      let location: any = googleMapRef.current

      return new google.maps.Map(location, {
        center: { lat: props.Location.lat, lng: props.Location.lng },
        zoom: 8,
        disableDefaultUI: true,
        mapTypeControl: false
      })
    }

    const createMarker = () => {
      const lat: number = EditLocation ? EditLocation[0] : props.Location.lat
      const lng: number = EditLocation ? EditLocation[1] : props.Location.lng
      new google.maps.Marker({
        position: { lat, lng },
        map: googleMap
      })
    }

    const googleMap: any = initGoogleMap()
    !didCancel && createMarker()

    return () => {
      didCancel = true
    }
  }, [props.Location, EditLocation])

  // create marker on google map
  

  return <div ref={googleMapRef} style={{ width: '100%', height: 295 }} />
}

export default GMap
