import API, { STATUS, ApiResponse } from './api'
import { AxiosResponse } from 'axios'
import { deleteCookieByName, setCookyByName } from '../utils/cookies'
import { LoginForm } from '../pages/Login'
import { PasswordForm } from '../pages/Profile'

export interface User {
  email: string
  license_expiration: number
  name: string
  public_id: string
  confirmed_legal: boolean
  org_admin: boolean
  photo: string
  tab?: number
  confirmed_email: boolean
  confirmed_terms: boolean
  is_trial: boolean
}

export interface Licenses {
  datetime_unix: number
  duration: number
  id: string
  payment: string
  reference: string
  trial: boolean
  pdf_available: boolean
  days_left: number
}

export interface UserSimulation {
  id: string
  status: -1 | 0 | 1 | 2
  unix_created: number
  unix_end: number
  unix_start: number
}

export interface LedInfo {
  id: string
  reference: string
  company: string
  brand: string
  number?: number
  serial?: number
  parallel?: number
  limit: number
}

// TODO
export interface Historic {
  leds: LedInfo[]
  date: string
  reference: string
  public_id: string
  simulation: UserSimulation
  deleteConfirm?: boolean
  scene_count: number
  total?: number
  progress?: number
}

export interface Organization {
  name: string
  account: string
  address: string
  billing_address: string
  nif: string
}

interface STATUS_SIMULATION {
  msg: string
  id: -1 | 0 | 1 | 2
  label: string
}

interface STATUS_SIMULATIONS {
  [key: string]: STATUS_SIMULATION
}

export const STATUS_SIMULATION: STATUS_SIMULATIONS = {
  error: { msg: 'error', id: -1, label: 'Restart' },
  no_init: { msg: 'no initialized', id: 0, label: 'Waiting' },
  in_progress: { msg: 'in progress', id: 1, label: 'Processing' },
  created: { msg: 'created', id: 2, label: 'View Fixture' }
}

class UserApi {
  getUser = async (): Promise<User | null> => {
    try {
      const response: AxiosResponse = await API.get('users/me')

      if (response && response.status === STATUS.OK) {
        return response.data
      }
    } catch (error) {
      console.error(error)
      return null
    }

    return null
  }

  getHistoric = async (
    page: number,
    filter: string = ''
  ): Promise<Historic[]> => {
    const limit = 5
    let pageaux = page
    pageaux = pageaux - 1
    try {
      const response: AxiosResponse = await API.get(
        'fixture/get_historic?page=' +
          pageaux +
          '&limit=' +
          limit +
          '&filter=' +
          filter
      )

      if (response.status === STATUS.OK) {
        /* GetTotal({
          total: response.data.total,
          limit: limit
        })*/
        return response.data
      }
    } catch (error) {
      console.error(error)
      return []
    }
    return []
  }

  getOrganization = async (): Promise<any | null> => {
    try {
      const response: AxiosResponse = await API.get('users/organization')

      if (response.status === STATUS.OK) {
        return response.data.content
      }
    } catch (error) {
      console.error(error)
      return null
    }

    return null
  }

  getLogoOrganization = async (): Promise<any | null> => {
    try {
      const response: AxiosResponse = await API.get('users/logo')

      if (response.status === STATUS.OK) {
        return response.data.content.src
      }
    } catch (error) {
      // console.error(error)
      return null
    }

    return null
  }

  deleteLogoOrganization = async (): Promise<any | null> => {
    try {
      const response: AxiosResponse = await API.delete('users/logo/delete')

      if (response.status === STATUS.OK) {
        return response.data.content
      }
    } catch (error) {
      console.error(error)
      return null
    }

    return null
  }

  getLicenses = async (): Promise<any | null> => {
    try {
      const response: AxiosResponse = await API.get(
        'users/licenses/get_historic'
      )

      if (response.status === STATUS.OK) {
        return response.data.content.licenses
      }
    } catch (error) {
      console.error(error)
      return null
    }

    return null
  }

  DownloadLicense = async (license_id: string): Promise<any> => {
    try {
      const response: AxiosResponse = await API.get(
        'users/licenses/pdf/' + license_id,
        { responseType: 'blob' }
      )
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', license_id + '.pdf') //or any other extension
      document.body.appendChild(link)
      link.click()

      if (response.status === STATUS.OK) {
        return response.data.content
      }
    } catch (error) {
      console.error(error)
      return null
    }

    return null
  }

  getAutoRenew = async (): Promise<any | null> => {
    try {
      const response: AxiosResponse = await API.get(
        'users/organization/auto_renew'
      )

      if (response.status === STATUS.OK) {
        return response.data.content.auto_renew
      }
    } catch (error) {
      console.error(error)
      return null
    }

    return null
  }

  updateAutoRenew = async (new_state: boolean): Promise<any> => {
    try {
      // formData.append('new_state', new_state)

      const response: AxiosResponse = await API.put(
        'users/organization/auto_renew/' + new_state,
        {}
      )

      if (response.status === STATUS.OK) {
        return response.data
      }
    } catch (error) {
      console.error(error)
      return false
    }

    return false
  }

  uploadimageorganization = async (image: FormData): Promise<any> => {
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
      const response: AxiosResponse = await API.put(
        'users/logo/upload',
        image,
        config
      )
      if (response.status === STATUS.OK) {
        return response.data
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }

  updateorganization = async (Organization: Organization): Promise<any> => {
    try {
      const response: AxiosResponse = await API.post(
        'users/organization/update',
        {
          ...Organization
        }
      )

      if (response.status === STATUS.OK) {
        return response.data
      }
    } catch (error) {
      console.error(error)
      return false
    }

    return false
  }

  login = async (userLogin: LoginForm) => {
    try {
      const response: AxiosResponse = await API.post(
        'login',
        {
          ...userLogin
        },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization:
              'Basic ' +
              window.btoa(userLogin.username + ':' + userLogin.password)
          }
        }
      )

      if (response && response.status === STATUS.OK) {
        setCookyByName('accessToken', response.data.token)
        const apiReponse: ApiResponse = {
          success: true,
          code: response.status,
          message: 'Successful login'
        }
        return apiReponse
      } else {
        const apiReponse: ApiResponse = {
          success: false,
          code: 401,
          message: 'Wrong username or password'
        }
        return apiReponse
      }
    } catch (error) {
      if (error.response) {
        const apiErrorResponse: ApiResponse = {
          success: false,
          code: error.response.status,
          message: error.response.data.content
        }
        return apiErrorResponse
      }
    }

    const apiErrorResponse: ApiResponse = {
      success: false,
      code: 500,
      message: 'Login Failed'
    }
    return apiErrorResponse
  }

  signout = (): void => {
    deleteCookieByName('accessToken')
  }

  updatePassword = async (password: PasswordForm): Promise<boolean> => {
    try {
      const response: AxiosResponse = await API.post('users/password_change', {
        new_password: password.newPassword,
        old_password: password.currentPassword
      })

      if (response.status === STATUS.OK) {
        return true
      }

      return false
    } catch (error) {
      console.error(error)

      return false
    }
  }

  acceptConfirmLegal = async (): Promise<boolean> => {
    try {
      const response: AxiosResponse = await API.put('users/confirm_legal')

      if (response.status === STATUS.OK) {
        return true
      }

      return false
    } catch (error) {
      console.error(error)

      return false
    }
  }

  acceptTermsAndConditions = async (): Promise<boolean> => {
    try {
      const response: AxiosResponse = await API.put('users/confirm_terms')
      if (response.status === STATUS.OK) {
        return true
      }

      return false
    } catch (error) {
      console.error(error)

      return false
    }
  }
}
export const userAPI = new UserApi()
