import React, { useState } from 'react'
import styled from 'styled-components'
import { Icon } from 'semantic-ui-react'
import { CompProps, Theme } from '../../styles/theme'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { Fieldset, InputUI } from '../../styles/commons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { simulationApi } from '../../api/SimulationApi'
import Modal from '../common/Modal'
import LedInfoModal from '../LedInfoModal'
import { Channel } from '../../api/LedApi'
import { strToARGB } from '../../utils/commons'

import HelpContent from '../help/HelpContent'

interface Props {
  PCBChannels: Channel[]
  setPCBChannels: Function

  TotalPF_W: number
}

interface SimulationForm {
  led_ids: string[]
  led_types: string[]
  serial: number[]
  parallel: number[]
  limit: number[]
  reference: string
}

const LedListPCB: React.FC<Props> = (props) => {
  const {
    PCBChannels,
    setPCBChannels,

    TotalPF_W
  } = props
  const InfoIcon = <FontAwesomeIcon icon={faInfoCircle} />
  const [ModalLedShow, setModalLedShow] = useState(false)
  const [Ledinfo, setLedinfo] = useState()
  const [startDragging, setstartDragging] = useState(false)
  const [nowDragging, setnowDragging] = useState(true)

  // let [PCBChannels, setPCBChannels] = useState<Channel[]>([])

  const validateNumber = (event: any) => {
    var key = event.charCode || event.keyCode || 0
    if (
      key === 8 ||
      key === 9 ||
      key === 46 ||
      key === 110 ||
      key === 190 ||
      (key >= 35 && key <= 40) ||
      (key >= 48 && key <= 57) ||
      (key >= 96 && key <= 105)
    ) {
      return true
    }
  }
  /* setInputFilter(InputUITable,function(value) {
  return /^\d*\.?\d*$/.test(value); // Allow digits and '.' only, using a RegExp
});*/
  const infoLuminary = async (channel: Channel) => {
    const infoled = await simulationApi.getLedInfo(channel.type, channel.id)
    infoled.id = channel.id
    // AnalyticsListener.createRouteView(`led_info/${led.id}`)
    setLedinfo(infoled)
    setModalLedShow(true)
  }
  const closeModalLed = () => {
    setModalLedShow(false)
  }
  /* DRAG DROP*/
  const onDragOver = (ev: any) => {
    ev.preventDefault()
  }

  const onDropToTrash = (ev: any, cat: any) => {
    let channel_info = JSON.parse(ev.dataTransfer.getData('channel'))
    const DragLedsAux: Channel[] = Object.assign([], PCBChannels)

    const DragledFilter = DragLedsAux.filter(
      (el: Channel, key) => key !== channel_info
    )

    setPCBChannels(DragledFilter)
    setstartDragging(false)
  }

  /* FI DRAG DROP */
  const onDragStart = (ev: any, channel: number) => {
    ev.dataTransfer.setData('channel', JSON.stringify(channel))
    setstartDragging(true)
  }
  const HandleChangeDragLed = (
    event: any,
    type: string,
    activeChannel: Channel,
    index: number
  ) => {
    let valueled: number = parseInt(event.target.value)

    if (String(valueled).trim().length < 0 || Number(valueled) <= 0) {
      //  valueled = '1'
    }

    let pcbChannelsAux: Channel[] = Object.assign([], PCBChannels)
    switch (type) {
      case 'serial':
        pcbChannelsAux[index].serial = +valueled
        break
      case 'limit':
        pcbChannelsAux[index].limit = +valueled
        break
      case 'parallel':
        pcbChannelsAux[index].parallel = +valueled
        break
      default:
        break
    }
  
    setPCBChannels(pcbChannelsAux)
  }
 
  return (
    <>
      <PCBContainer>
        <Fieldset name="pcb" className="tour-dragleds">
          <ContainerLabelPCB>
            <label>
              PCB
              <HelpContent
                section="Fixture"
                content="pcb"
                position="bottom center"
                html={<Icon name="question circle"></Icon>}
              />
            </label>
            <div className="totalChannels">
              {PCBChannels.length !== 0 &&
                !isNaN(TotalPF_W) &&
                `${PCBChannels.length} Channels / ${TotalPF_W} W`}
            </div>
          </ContainerLabelPCB>
          <DroppableTableTitle>
            <div> </div>
            <div>Reference</div>
            <div>Serial</div>
            <div>Paralell</div>
            <div>Driver mA</div>
            <div> </div>
          </DroppableTableTitle>
          <DroppableArea
            className="droppable "
            onDragOver={(ev) => onDragOver(ev)}
          >
            {PCBChannels.length === 0 && (
              <DragLedsHelpContainer className="ui icon header">
                {/* <i className="microchip icon"></i>
              <p>Drag your leds here.</p> */}
              </DragLedsHelpContainer>
            )}
            {PCBChannels.map((channel: Channel, i: number) => {
              return (
                <DroppableTableContainer
                  key={i}
                  onDragStart={(e) => onDragStart(e, i)}
                  onDragEnd={(e) => setstartDragging(false)}
                  draggable={nowDragging}
                  className="draggable "
                >
                  <div
                    className="iconInfo"
                    onClick={() => infoLuminary(channel)}
                  >
                    {' '}
                    {InfoIcon}{' '}
                  </div>
                  <div>
                    <div
                      className="titleLed"
                      style={{
                        color: `#${strToARGB(channel.reference)}`
                      }}
                    >
                      {' '}
                      {channel.reference}{' '}
                    </div>
                    <div className="brandReference">
                      {' '}
                      {channel.brand} / {channel.family}
                    </div>
                  </div>

                  <InputUITable
                    size="mini"
                    type="number"
                    id="serial"
                    placeholder="Serial"
                    onKeyDown={(event: any) => {
                      validateNumber(event)
                    }}
                    value={channel.serial}
                    onFocus={(e: any) => {
                      setnowDragging(false)
                    }}
                    onBlur={(e: any) => {
                      setnowDragging(true)
                    }}
                    onChange={(event: any) => {
                      HandleChangeDragLed(event, 'serial', channel, i)
                    }}
                  />

                  <InputUITable
                    size="mini"
                    type="number"
                    onKeyDown={(event: any) => {
                      validateNumber(event)
                    }}
                    placeholder="Parallel"
                    min="1"
                    value={channel.parallel}
                    onChange={(e: any) =>
                      HandleChangeDragLed(e, 'parallel', channel, i)
                    }
                    onFocus={(e: any) => {
                      setnowDragging(false)
                    }}
                    onBlur={(e: any) => {
                      setnowDragging(true)
                    }}
                  />

                  <InputUITable
                    size="mini"
                    type="number"
                    onKeyDown={(event: any) => {
                      validateNumber(event)
                    }}
                    placeholder="Limit"
                    min="1"
                    value={channel.limit}
                    onChange={(e: any) =>
                      HandleChangeDragLed(e, 'limit', channel, i)
                    }
                    onFocus={(e: any) => {
                      setnowDragging(false)
                    }}
                    onBlur={(e: any) => {
                      setnowDragging(true)
                    }}
                  />
                </DroppableTableContainer>
              )
            })}
          </DroppableArea>
          {ModalLedShow && (
            <Modal open={ModalLedShow} closeModal={closeModalLed}>
              <LedInfoModal info={Ledinfo} />
            </Modal>
          )}
        </Fieldset>
        <BarTrash
          onDragOver={(ev) => onDragOver(ev)}
          onDrop={(e) => onDropToTrash(e, 'done')}
        >
          <div className={startDragging ? 'active' : ''}>
            <div>DRAG LED HERE TO DELETE</div>
            <Icon name="trash" />
          </div>
        </BarTrash>
      </PCBContainer>
    </>
  )
}

const BarTrash = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 55px;

  background-color: rgba(196, 196, 196, 0.2);
  color: ${(props: CompProps) => (props.theme as Theme).color.neutral._500};

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 100%;
    padding: 15px;
  }

  div div {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  div.active {
    background: ${(props: CompProps) => (props.theme as Theme).color._900};
    color: white;
  }
`
const DragLedsHelpContainer = styled.div`
  width: 100%;
  ${(props: CompProps) => (props.theme as Theme).font_size.xxsmall}!important;
  color: ${(props: CompProps) =>
    (props.theme as Theme).color.white.darkest}!important;
  margin-top: 50px !important;
  p {
    margin-top: 15px;
    ${(props: CompProps) => (props.theme as Theme).font_size.xxsmall}!important;
  }
`

const PCBContainer = styled.div`
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  height: 100%;
  margin-top: 15px;
  .tour-dragleds {
    display: grid;
    padding: 15px;
  }
`
const ContainerLabelPCB = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .totalChannels {
    color: ${(props: CompProps) => (props.theme as Theme).color.neutral._500};
    font-size: 14px;
    font-weight: bold;
  }
`
const DroppableArea = styled.div`
  min-height: 200px;
  max-height: 210px;
  overflow-y: auto;
`

const DroppableTableTitle = styled.div`
  display: grid;
  grid-template-columns: 10% 44% 15% 15% 15%;
  border: 1px solid #fff;
  padding: 5px;
  align-items: center;
  color: ${(props: CompProps) => (props.theme as Theme).color.neutral._500};
  ${(props: CompProps) => (props.theme as Theme).font_size.xxxsmall};
`

const DroppableTableContainer = styled(DroppableTableTitle)`
  border: 1px solid #bfbfbf;
  cursor: pointer;
  color: ${(props: CompProps) => (props.theme as Theme).color.black.regular};

  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  div {
    text-align: left;
  }
  .input {
    margin-left: 0px !important;
    margin-right: 5px !important;
  }
  .iconInfo {
    font-size: 20px;
    text-align: center;
    cursor: pointer;
    color: ${(props: CompProps) => (props.theme as Theme).color.neutral._500};
  }
  .titleLed {
    font-style: normal;
    font-weight: bold;
    ${(props: CompProps) => (props.theme as Theme).font_size.xxxsmall};
    text-transform: uppercase;
  }
  .brandReference {
    color: ${(props: CompProps) => (props.theme as Theme).color.neutral._500};
  }
`

const InputUITable = styled(InputUI)`
  input {
    padding: inherit;
    height: 30px;
    line-height: 30px !important;
    font-size: 14px;
    text-align: center;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    opacity: 1;
  }
`

export default LedListPCB
