import React from 'react'
import Modal from './Modal'
import styled from 'styled-components'

interface Props {
  open: boolean
  acceptConfirm: Function
  closeModal?: Function
  message: string
}

const SimpleConfirm: React.FC<Props> = (props) => (
  <Modal
    open={props.open}
    closeModal={props.closeModal}
    isConfirm={true}
    acceptConfirm={props.acceptConfirm}
  >
    <Center> {props.message} </Center>
  </Modal>
)
const Center = styled.div`
  text-align: center;
  text-transform: uppercase;
`
export default SimpleConfirm
