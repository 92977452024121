import React, { useContext } from 'react'
import styled from 'styled-components'
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer'

import StacticGraphicsContext, {
  StacticGraphicsData
} from '../charts/ChartStaticContext'
import ScenePDFDoc from './ScenePDFDoc'
import { PDFDocTexts } from './types'

interface Props {
  textData: PDFDocTexts,
  isDownload: boolean
}

const ScenePDFView: React.FC<Props> = (props) => {
  const pdfData: StacticGraphicsData = useContext(StacticGraphicsContext)
  return (
    <>
    {props.isDownload ? (
      <PDFDownloadLink document={<ScenePDFDoc texts={props.textData} graphics={pdfData.graphics} />} fileName="somename.pdf">
        {({ blob, url, loading, error }) => (<ScenePDFDownload loading={loading}/>)}
      </PDFDownloadLink>
    ) : (
      <PDFViewer width="100%" height="640">
        <ScenePDFDoc texts={props.textData} graphics={pdfData.graphics} />
      </PDFViewer>
    )}
    </>
  )
}

interface LoadingProps {
  loading: boolean
}

const ScenePDFDownload: React.FC<LoadingProps> = (props) => {
  return(
  <PDFDownloadComtainer>
    {props.loading ? (
      'Generating document...' 
    ) : (
      'Click here to Download now!'
    )}
  </PDFDownloadComtainer>
  )
}

const PDFDownloadComtainer = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default ScenePDFView
