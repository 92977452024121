import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Switch from 'react-switch'
import styled from 'styled-components'
import { MainButtonAction } from '../../styles/commons'
import theme, { CompProps, Theme } from '../../styles/theme'

import { faHandHoldingUsd, faPalette } from '@fortawesome/free-solid-svg-icons'
import { FilterSection, FilterSections } from './types'
import Loading from '../common/Loading'
import FilterSectionSlider from './FilterSection'

interface Props {
  filter: any
  numResults: number
  loading: boolean
  onChangeSwitch: Function
  resetFilters: Function
  switchCri: boolean
  switchEfficiency: boolean
  initFilter: any
  filtersSections?: FilterSections | undefined
  onChangeFilter: Function
  onAfterChangeSlider: Function
  resultsFilters: any
  iluminance: number[]
  popupCreate: boolean
  CallBackPopupCreate: Function
  disableNewScene: boolean
  newSceneName: string
  dynamicSpdI: number[] | undefined
  dynamicSpdW: number[] | undefined
  HandleOnChangeCreateScene: Function

  activeTour: boolean
}

const Filters: React.FC<Props> = (props) => {
  const {
    filter,
    numResults,
    loading,
    onChangeSwitch,
    resetFilters,
    switchCri,
    switchEfficiency,
    initFilter,
    onChangeFilter,
    onAfterChangeSlider,
    iluminance,
    filtersSections,
    resultsFilters,
    popupCreate,
    CallBackPopupCreate
  } = props
  
  const CRIcon = <FontAwesomeIcon icon={faPalette} />
  const EfficiencyIcon = <FontAwesomeIcon icon={faHandHoldingUsd} />

  return (
    <div>
      {filter && !loading ? (
        <FiltersSectionContainer>
          <HeaderContainer>
            <HeaderText>FILTERS</HeaderText>
          </HeaderContainer>
          <MacrosCotainer>
            <PresetsContainer style={ numResults === 1 ? { pointerEvents: 'none', opacity: '0.4' } : {} }>
                <PresetItem>
                  <PreseItemTitle>CRI 80 {CRIcon}</PreseItemTitle>
                  <Switch
                    onColor={theme.color._700}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    checked={switchCri}
                    disabled={switchCri}
                    onChange={() => onChangeSwitch('cri')}
                    height={28*0.75}
                    width={56*0.75}
                  />
                </PresetItem>
                <PresetItem>
                  <PreseItemTitle>lm/W 80 {EfficiencyIcon}</PreseItemTitle>
                  <Switch
                    onColor={theme.color._700}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    checked={switchEfficiency}
                    disabled={switchEfficiency}
                    onChange={() => onChangeSwitch('efficiency_lmw')}
                    height={28*0.75}
                    width={56*0.75}
                  />
                </PresetItem>
            </PresetsContainer>
            <ContainerButtons>
              {numResults === 1 && !loading && popupCreate === false && (
                <ButtonSave onClick={() => CallBackPopupCreate()}>
                  Save
                </ButtonSave>
              )}
              <ButtonReset
                disabled={initFilter !== filter && !loading ? false : true}
                onClick={() => resetFilters()}
              >
                Reset
              </ButtonReset>
            </ContainerButtons>
          </MacrosCotainer>
          <FiltersWrapper>
            {filtersSections && (
              <FiltersContainer className="tour-colorimetric" style={ numResults === 1 ? { pointerEvents: 'none', opacity: '0.4' } : {} }>
                <FiltersColumn className="left">
                  <FilterTypeContainer>
                    <FilterTypeTitle>Colorimetric values</FilterTypeTitle>
                    {filtersSections!.colorimetric.map(
                      (filterSection: FilterSection, index: number) => (
                        <FilterSectionSlider
                          key={index}
                          title={filterSection.title}
                          type={filterSection.type}
                          onChangeFilter={onChangeFilter}
                          onAfterChangeSlider={() =>
                            onAfterChangeSlider(filterSection.type)
                          }
                          values={resultsFilters[filterSection.type]}
                        />
                      )
                    )}
                  </FilterTypeContainer>
                </FiltersColumn>
                <FiltersColumn className="center">
                <FilterTypeContainer>
                    <FilterTypeTitle>Non-visual values</FilterTypeTitle>
                    {filtersSections!.nonVisual.map(
                      (filterSection: FilterSection, index: number) => (
                        <FilterSectionSlider
                          key={index}
                          title={filterSection.title}
                          type={filterSection.type}
                          onChangeFilter={onChangeFilter}
                          onAfterChangeSlider={() =>
                            onAfterChangeSlider(filterSection.type)
                          }
                          values={resultsFilters[filterSection.type]}
                        />
                      )
                    )}
                  </FilterTypeContainer>
                  <FilterTypeContainer>
                    <FilterTypeTitle>
                      Human Centric Lighting
                    </FilterTypeTitle>
                    {filtersSections!.hcl.map(
                      (filterSection: FilterSection, index: number) => {
                        if (resultsFilters[filterSection.type]) {
                        return (
                        <FilterSectionSlider
                          key={index}
                          iluminance={iluminance}
                          title={filterSection.title}
                          type={filterSection.type}
                          onChangeFilter={onChangeFilter}
                          onAfterChangeSlider={() =>
                            onAfterChangeSlider(filterSection.type)
                          }
                          values={resultsFilters[filterSection.type]}
                        />)
                      } else {
                        return (null)
                      }
                    })}
                  </FilterTypeContainer>
                </FiltersColumn>
                <FiltersColumn className="right">
                <FilterTypeContainer>
                  <FilterTypeTitle>Power values</FilterTypeTitle>
                  {filtersSections!.power.map(
                    (filterSection: FilterSection, index: number) => {
                      if (resultsFilters[filterSection.type]) {
                        return (
                        <FilterSectionSlider
                          key={index}
                          title={filterSection.title}
                          type={filterSection.type}
                          onChangeFilter={onChangeFilter}
                          onAfterChangeSlider={() =>
                            onAfterChangeSlider(filterSection.type)
                          }
                          values={resultsFilters[filterSection.type]}
                        />)
                      } else {
                        return (null)
                      }
                    })}
                </FilterTypeContainer>
                </FiltersColumn>
              </FiltersContainer>
            )}
          </FiltersWrapper>
        </FiltersSectionContainer>
      ) : (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      )}
    </div>
  )
}

const FiltersSectionContainer = styled.div`
  padding-top: 30px;
`

const MacrosCotainer = styled.div`
  display: flex;
`

const ContainerButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-content: middle;
  margin-top: -8px;
  margin-right: 15px;
  button {
    margin-left: 25px;
  }
`

const ButtonSave = styled(MainButtonAction as any)`
  height: 26px;
  width: 100px;
`

const ButtonReset = styled(MainButtonAction as any)`
  height: 26px;
  width: 100px;
  :disabled {
    background: ${(props: CompProps) =>
      (props.theme as Theme).color.white.darker}!important;
  }
  &&& a {
    color: white !important;
  }
`

const HeaderContainer = styled.div`
  margin-bottom: 15px;
`

const HeaderText = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: ${(props: CompProps) => (props.theme as Theme).color.neutral._700};
`

const PresetsContainer = styled.div`
  display: flex;
  justify-content: left;
  align-content: middle;
  width: 100%;
`

const PresetItem = styled.div`
  display: flex;
  .react-switch-bg {
    margin-right: 20px !important;
  }
`

const PreseItemTitle = styled.h5`
  margin-right: 15px;
  ${(props: CompProps) => (props.theme as Theme).font_size.small};
`

const FiltersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 5px;
`

const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  .left {
    padding-right: 15px;
  }
  .center {
    padding-right: 15px;
  }
  .right {
  }
`

const FiltersColumn = styled.div`
  flex-direction: column;
  flex-grow: 1;
  padding-top: 0px;
`

const FilterTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  min-height: 219px;
`

const FilterTypeTitle = styled.p`
  margin-top: 5px !important;
  ${(props: CompProps) => (props.theme as Theme).font_size.xxsmall};
  color: ${(props: CompProps) => (props.theme as Theme).color.neutral._700};
`

const LoadingContainer = styled.div`
  display: block;
  margin: 0px;
  height: 400px;
`
export default Filters
