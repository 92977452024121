export const getCookie = (name: string): any => {
  const nameLenPlus = name.length + 1
  return (
    document.cookie
      .split(';')
      .map(c => c.trim())
      .filter(cookie => {
        return cookie.substring(0, nameLenPlus) === `${name}=`
      })
      .map(cookie => {
        return decodeURIComponent(cookie.substring(nameLenPlus))
      })[0] || null
  )
}

export const deleteCookieByName = (name: string): void => {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT; SameSite=Strict`
}

export const setCookyByName = (name: string, value: string): void => {
  document.cookie = `${name}=${value}; SameSite=Strict`
}
