import React, { memo, useEffect } from 'react'
import styled from 'styled-components'
import { CompProps, Theme } from '../../styles/theme'

import { Led } from '../../api/LedApi'

import LedAvailable from './LedAvailable'
import memoize from 'memoize-one'
import {
  VariableSizeList as List,
  ListChildComponentProps,
  areEqual
} from 'react-window'

interface Props {
  isLoading: boolean
  itemleds: Led[]
  onDragStart: Function

  infoLuminary: Function
  getAvailableLeds: Function
  active: boolean
  type: string
}

const TableLeds: React.FC<Props> = (props) => {
  const {
    itemleds,
    onDragStart,
    infoLuminary,

    getAvailableLeds,
    type,
    active
  } = props

  const RenderRow = memo((props: ListChildComponentProps) => {
    //function RenderRow(props: ListChildComponentProps) {
    const { index, style } = props
    const { onDragStart } = props.data
    const led: Led = itemleds[index]

    return (
      <LedAvailable
        style={{
          ...style,
          top: `${parseFloat(String(style.top))}px`,
          bottom: `${parseFloat(String(style.bottom)) + 10 * index}px`,
          width: '97%'
        }}
        led={led}
        index={index}
        onDragStart={onDragStart}
        infoLuminary={infoLuminary}
        key={led.id}
      />
    )
  }, areEqual)

  const createItemData = memoize((itemleds: Led[], onDragStart: Function) => ({
    itemleds,
    onDragStart
  }))

  useEffect(() => {
    itemleds.length === 0 && getAvailableLeds(type)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])
  const itemData = createItemData(itemleds, onDragStart)
  /*itemSize={(index: number) =>
          ((itemleds[index].brand.length / 20) % 10) * 25 + 55
        } */
  return (
    <div
      className={
        type === 'catalog' && itemleds.length !== 0 ? 'tour-availableleds' : ''
      }
    >
      <LedTableTitle>
        <div> </div>
        <div>Company</div>
        <div>Brand</div>
        <div>Reference</div>
        <div></div>
      </LedTableTitle>
      <List
        height={704}
        itemData={itemData}
        itemCount={itemleds.length}
        itemSize={(index: number) => {
          const led: Led = itemleds[index]
          const longestText: number =
            led.brand.length > led.reference.length
              ? led.brand.length
              : led.reference.length
          let normalSize = 85
          if (longestText >= 22) normalSize += 20
          return normalSize
        }}
        width={520}
      >
        {RenderRow}
      </List>
    </div>
  )
}

const LedTableTitle = styled.div`
  display: flex;

  padding-bottom: 0px;
  color: ${(props: CompProps) => (props.theme as Theme).color.neutral._900};
  ${(props: CompProps) => (props.theme as Theme).font_size.xxxsmall};
  div {
    flex-grow: 1;
  }

  div:nth-of-type(1) {
    flex-grow: 0;
    padding: 15px;
    padding-bottom: 10px;
  }
  color: ${(props: CompProps) => (props.theme as Theme).color.neutral._500};
  display: grid;
  grid-template-columns: 10% 21% 32% 34%;
  margin-top: 15px;
`

export default TableLeds
