import API, { APP_PATH_PUBLIC, STATUS } from './api'
import { AxiosResponse } from 'axios'
import { Project } from './ProjectApi'

export interface TileLineFixture {
  id: string
  reference: string
}

export interface TimelineProject {
  data: Hour[]
  datetime_unix: number
  gmt: number
  end_time: number
  fixture: TileLineFixture
  id: string
  reference: number
  project_id: string
  start_time: number
  project?: Project
}

export interface Timelinefix {
  fixture_id: string
  end_time: number
  month: number
  reference: string
  start_time: number
  year: number
}

export interface Timeline {
  data?: Hour[]
  datetime_unix: number
  gmt: number
  end_time: number
  fixture: []
  id: string
  reference: string
  project_id?: string
  start_time: number
  deleteConfirm?: boolean
  report: boolean
  shared: boolean
}

export interface Hour {
  hour24: number
  params: [
    {
      name: string
      value: number
      view: number
    }
  ]
  scene_id: string
  scene_name?: string
  spd: {
    wavelength: number[]
    irradiance: number[]
  }
  data?: {
    cct: number
    ces_skin: number
    cla_100: number
    coi: number
    cri: number
    cs_100: number
    duv: number
    efficiency_lmw: number
    luminous_flux_lm: number
    mder: number
    mp: number
    pf_w: number
    r9: number
    ra: number
    rf: number
    rg: number
    ul: number
    x: number
    y: number
    eml_100: number
    medi_100: number
  }
  type: number
}

export interface TransHour {
  end_scene_id: string
  end_time_24h: number
  fixture_id: string
  month: number
  start_scene_id: string
  start_time_24h: number
  target_middle_ccts: number[]
  year: number
}

class TimeLineApi {
  getHistoric = async (projectId: string | undefined): Promise<any> => {
    try {
      const response: AxiosResponse = await API.get(
        `timeline/get_historic/${projectId}`
      )

      if (response.status === STATUS.OK) {
        return response.data.content.timelines
      }

      return []
    } catch (error) {
      console.error(error)
      return []
    }
  }

  getAvailableFixtures = async (): Promise<any> => {
    try {
      const response: AxiosResponse = await API.get(
        `timeline/get_available_fixtures`
      )

      if (response.status === STATUS.OK) {
        return response.data.content.fixtures
      }

      return []
    } catch (error) {
      console.error(error)
      return []
    }
  }

  CreateProjectTimeline = async (
    project_id: string,
    Timeline: Timelinefix
  ): Promise<any> => {
    try {
      const response: AxiosResponse = await API.put(
        `timeline/new/${project_id}`,
        {
          ...Timeline
        }
      )

      if (response.status === STATUS.OK) {
        return response.data.content
      }
    } catch (error) {
      console.error(error)
      return false
    }

    return false
  }

  copyTimeLine = async (
    month: number,
    year: number,
    source_project_id: string,
    target_project_id: string,
    timeline_id: string,
    reference: string
  ): Promise<any> => {
    try {
      const response: AxiosResponse = await API.put(
        `timeline/duplicate/${source_project_id}/${timeline_id}`,
        {
          month,
          project_id: target_project_id,
          reference,
          year
        }
      )

      if (response.status === STATUS.OK) {
        return response.data.content
      }
    } catch (error) {
      console.error(error)
      return false
    }

    return false
  }

  getTimelineProject = async (
    project_id: string | undefined,
    timeline_id: string | undefined
  ): Promise<any> => {
    try {
      const response: AxiosResponse = await API.get(
        `timeline/info/${project_id}/${timeline_id}`
      )

      if (response.status === STATUS.OK) {
        return response.data.content.timeline
      }
    } catch (error) {
      console.error(error)
      return []
    }

    return []
  }

  GetHours = async (
    fixture_id: string,
    scene_id: string,
    time_24h: number
  ): Promise<any> => {
    try {
      const response: AxiosResponse = await API.post('timeline/get_hour', {
        fixture_id,
        scene_id,
        time_24h
      })

      if (response.status === STATUS.OK) {
        return response.data.content.hour
      }
    } catch (error) {
      console.error(error)
      return false
    }

    return false
  }

  UpdateTimeline = async (
    project_id: string,
    timeline_id: string,
    hours: any
  ): Promise<any> => {
    try {
      const response: AxiosResponse = await API.post(
        `timeline/update/${project_id}/${timeline_id}`,
        {
          ...hours
        }
      )

      if (response.status === STATUS.OK) {
        return response.data
      }
    } catch (error) {
      console.error(error)
      return false
    }

    return false
  }

  getTransitionHour = async (Hours: TransHour | undefined): Promise<any> => {
    try {
      const response: AxiosResponse = await API.post(
        `timeline/get_transition_hours`,
        Hours
      )

      if (response.status === STATUS.OK) {
        return response.data.content.transitions
      }
    } catch (error) {
      console.error(error)
      return []
    }

    return []
  }

  DeleteTimelinebyID = async (
    project_id: string,
    timeline_id: string
  ): Promise<any> => {
    try {
      const response: AxiosResponse = await API.delete(
        `timeline/delete/${project_id}/${timeline_id}`
      )

      if (response.status === STATUS.OK) {
        return response.data
      }
    } catch (error) {
      console.error(error)
      return false
    }

    return false
  }

  exportTimeline = async (
    project_id: string,
    timeline_id: string,
    timeline_reference: string
  ): Promise<any> => {
    try {
      const response: AxiosResponse = await API.post(
        `timeline/download/${project_id}/${timeline_id}`,
        { responseType: 'blob' }
      )
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', timeline_reference + '.csv') //or any other extension
      document.body.appendChild(link)
      link.click()

      if (response.status === STATUS.OK) {
        return response.data
      }
    } catch (error) {
      console.error(error)
      return []
    }

    return []
  }

  sharedTimeline = async (
    projectId: string,
    publicId: string
  ): Promise<any> => {
    try {
      const response: AxiosResponse = await API.get(
        `timeline/shared/toggle/${projectId}/${publicId}`
      )

      if (response.status === STATUS.OK) {
        return response.data
      }
    } catch (error) {
      console.error(error)

      return false
    }
  }

  getQR = async (projectId: string, publicId: string): Promise<any> => {
    try {
      const response: AxiosResponse = await API.post(
        `timeline/shared/qr/${projectId}/${publicId}`,
       { app_path: APP_PATH_PUBLIC + "timeline/" }
      )

      if (response.status === STATUS.OK) {
        return response.data.content
      }
    } catch (error) {
      console.error(error)

      return false
    }
  }

  getSharedInfo = async (publicId: string): Promise<any> => {
    try {
      const response: AxiosResponse = await API.get(
        `timeline/shared/info/${publicId}`
      )

      if (response.status === STATUS.OK) {
        return response.data.content.timeline
      }
    } catch (error) {
      console.error(error)

      return false
    }
  }
}

export const timeLineApi = new TimeLineApi()
