import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { CompProps, Theme } from '../../styles/theme'
import {
  LoadingContainer,
  MainButtonAction,
  DivSearch
} from '../../styles/commons'
import Loading from '../../components/common/Loading'
import { projectApi, Project } from '../../api/ProjectApi'
import NewProject from '../NewProject'

import { Auth } from '../../App'
import Modal from './Modal'
import SearchComponent from './Search'
import ProjectList from './ProjectList'
import Tour from '../help/Tour'
interface Props {
  auth: Auth
}

const SectionProject: React.FC<Props> = (props) => {
  const [Search, setSearch] = useState('')
  const [updateProjectList, setUpdateProjectList] = useState(false)

  /*
  const CircleIcon = <FontAwesomeIcon icon={faCircle} />
  const TrashIcon = <FontAwesomeIcon icon={faTrash} />
  const EditIcon = <FontAwesomeIcon icon={faPencilAlt} />
  const SearchIcon = <FontAwesomeIcon icon={faSearch} />
  const Export = <FontAwesomeIcon icon={faPaperPlane} />
  const LocationIcon = <FontAwesomeIcon icon={faMapMarkerAlt} />
  const ChartAreaIcon = <FontAwesomeIcon icon={faChartArea} />
  */

  const [Newproject, setNewproject] = useState(false)
  const [Projects, setProjects] = useState<Project[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const [projectTourActive, setprojectTourActive] = useState(false)
  const [projectTourTimelineActive, setprojectTourTimelineActive] = useState(false)
  const [projectTourActivePopUp, setprojectTourActivePopUp] = useState(false)

  const openNewProject = () => {
    setprojectTourActivePopUp(projectTourActive)
    setNewproject(true)
  }

  const closeModalProject = () => {
    setprojectTourActivePopUp(false)
    setNewproject(false)
    RefreshProject()
  }

  const RefreshProject = () => {
    setUpdateProjectList(!updateProjectList)
  }

  useEffect(() => {
    let didCancel: boolean = false
    async function getProjects() {
      let userProjectResponse: Project[]

      !didCancel && setIsLoading(true)
      try {
        userProjectResponse = await projectApi.GetProjects()
        const userProject: Project[] = Object.assign(userProjectResponse, {})
        !didCancel && setprojectTourActive(userProject.length === 0)
        !didCancel && setprojectTourTimelineActive(userProject.length === 1)
        !didCancel && setProjects(userProject)
      } catch (error) {
        console.error(error)
      } finally {
        !didCancel && setIsLoading(false)
      }
    }

    getProjects()
    return () => {
      didCancel = false
    }
  }, [updateProjectList])

  return (
    <DivProjectContainer>
      <DivSearch>
        <SearchComponent Search={Search} GetSearch={setSearch} />

        <ButtonNewProject
          className="tour-new-project"
          onClick={() => openNewProject()}
        >
          {' '}
          New Project
        </ButtonNewProject>
        {!isLoading && projectTourActive && !Newproject && (
          <Tour section="PAGE_PROJECT" />
        )}
        {NewProject && (
          <Modal
            newproject={true}
            open={Newproject}
            closeModal={closeModalProject}
            title="New Project"
          >
            <NewProject
              projectTourActive={projectTourActivePopUp}
              closeModal={closeModalProject}
            />
          </Modal>
        )}
      </DivSearch>
      <ProjectCount>Showing {Projects.length} project(s)</ProjectCount>
      {isLoading && (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      )}
      {!isLoading && Projects.length > 0 && (
        <ProjectList
          Projects={Projects}
          Refresh={RefreshProject}
          Search={Search}
          projectTimelineActive={projectTourTimelineActive}
        />
      )}
    </DivProjectContainer>
  )
}

const DivProjectContainer = styled.div`
  min-height: 250px;
`

const ButtonNewProject = styled(MainButtonAction)`
  height: auto;
  margin-left: auto;
`
const ProjectCount = styled.p`
  margin-top: 10px;
  margin-bottom: 25px;
  ${(props: CompProps) => (props.theme as Theme).font_size.xxxsmall};
`

export default SectionProject
