import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import theme, { CompProps, Theme } from '../../styles/theme'
import { Historic, STATUS_SIMULATION } from '../../api/UserApi'
import { ledApi, FamilyLed, Led, LedCatalog } from '../../api/LedApi'

import { faTrashAlt, faChartArea } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { simulationApi } from '../../api/SimulationApi'
import Modal from '../common/Modal'
import LedInfoModal from '../LedInfoModal'

import { Accordion, Icon } from 'semantic-ui-react'
import {
  AccordionUI,
  DeleteSceneIconContainer,
  ModalConfirm
} from '../../styles/commons'

import AnalyticsListener from '../../api/AnalyticsListener'

import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import ModalConfirmText from './ModalConfirmText'
//import ModalConfirm from './ModalConfirm'

interface Props {
  LedsTourActive?: boolean
  listItems: FamilyLed
  isHistoric: boolean
  simulationPage?: boolean
  search: string
  catalog: boolean
  index: number
  DeleteLed: Function
  defaultOpenIndex?: number
}

interface HistoricSectionListItemProps {
  isHistoric: boolean
  theme: any
}

const RemoveLuminaryIcon = <FontAwesomeIcon icon={faTrashAlt} />
const OrderLedsDown = <FontAwesomeIcon icon={faCaretDown} />
let OrderLedsUp = <FontAwesomeIcon icon={faCaretUp} />
const ChartAreaIcon = <FontAwesomeIcon icon={faChartArea} />
const LedList: React.FC<Props> = (props) => {
  let [ItemsLeds, setItemsLeds] = useState<Led[]>([])
  let [ItemsLedsAux, setItemsLedsAux] = useState<Led[]>(ItemsLeds)
  const [itemsToCheck] = useState<Historic[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const [Ledinfo, setLedinfo] = useState()
  const [ModalLedShow, setModalLedShow] = useState(false)
  const { LedsTourActive } = props
  const [AuxSearch, setAuxSearch] = useState<string>()

  const startIndex =
    props.defaultOpenIndex === props.index ? props.defaultOpenIndex : -1
  const [activeIndex, SetactiveIndex] = useState<number>(startIndex)
  const { DeleteLed } = props
  // const [OrdersLedsReference, setOrderLedsReference] = useState(OrderLedsUp)
  const [OrdersLedsColumns, setOrdersLedsColumns] = useState({
    reference: { icon: OrderLedsDown, color: theme.color._900 },
    brand: { icon: OrderLedsUp, color: theme.color.neutral._700 },
    data: { icon: OrderLedsUp, color: theme.color.neutral._700 }
  })

  const [OrderbyLed, setOrderByLed] = useState({
    orderby: 'reference',
    order: true
  })
  /*
  useEffect(() => {
    const search = () => {
      if (ItemsLedsAux === undefined || ItemsLedsAux === null) {
          setItemsLedsAux(ItemsLeds)
      }

      // let ItemsLedsSearch: Led[] = ItemsLeds
      if (props !== undefined && props.search !== undefined) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ItemsLedsAux = ItemsLeds.filter(
          (item) => item.reference.startsWith(props.search)
          //String('Bl').toString()
        )
        // setItemsLeds(ItemsLedsAux)
      } 
    }
    search()
    // setItemsLeds(ItemsLeds)
  }, [props.search])*/

  useEffect(() => {
    setAuxSearch(props.search)
  }, [props.search])

  useEffect(() => {
    if (ItemsLedsAux.length <= 0) {
      setItemsLedsAux(ItemsLeds)
    }
  }, [ItemsLeds, ItemsLedsAux])
  useEffect(() => {
    if (AuxSearch !== undefined) {
      const ItemsLedsAuxHelper = ItemsLedsAux.filter((item: Led) =>
        item.reference
          .toLowerCase()
          .includes(AuxSearch.toLowerCase().toString())
      )
      setItemsLeds(ItemsLedsAuxHelper)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AuxSearch])

  useEffect(() => {
    let didCancel = false

    async function getLedsByFamily() {
      !didCancel && setIsLoading(true)

      try {
        let userLedsResponse: LedCatalog[] = []

        if (props.catalog === false) {
          userLedsResponse = await ledApi.getLedsByFamily(props.listItems.id)
        } else {
          userLedsResponse = await ledApi.getLedsbyCompanies(props.listItems.id)
        }
        /* 
      Led catalog
      */

        userLedsResponse = userLedsResponse.sort(function (a, b) {
          const led1 = String(a['reference']).toLowerCase()
          const led2 = String(b['reference']).toLowerCase()
          if (led1 < led2) {
            return -1
          }
          if (led1 > led2) {
            return 1
          }
          return 0
        })
        if (props.catalog === true) {
        } else {
          //  setItemsLeds(userLedsResponse)
        }
        !didCancel && setItemsLeds(userLedsResponse)
      } catch (error) {
        console.error(error)
      } finally {
        !didCancel && setIsLoading(false)
      }
    }

    if (AuxSearch === undefined || AuxSearch === '') {
      getLedsByFamily()
    }
    return () => {
      didCancel = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const deleteLuminary = (index: number) => {
    const itemsAux = ItemsLeds.map((item: Led, i: number) => {
      if (i === index) {
        item.deleteConfirm = true
      }

      return item
    })
    setItemsLeds(itemsAux)
  }

  const infoLuminary = async (led: Led) => {
    let infoled: any
    if (props.catalog === true) {
      infoled = await ledApi.getInfoLedCatalog(led.id)
    } else {
      infoled = await ledApi.getInfoLed(led.id)
    }

    infoled.id = led.id

    AnalyticsListener.createRouteView(`led_info/${led.id}`)

    setLedinfo(infoled)
    setModalLedShow(true)
  }

  const closeModalLed = () => {
    setModalLedShow(false)
  }

  /*
  const openModalLed = () => {
    setModalLedShow(true)
  }
  */

  const handleClickAccordion = (e: any, titleProps: any) => {
    const { index } = titleProps
    // const { handleactiveIndex } = activeIndex
    const newIndex = activeIndex === index ? -1 : index
    SetactiveIndex(newIndex)
  }

  const closeDeleteLed = () => {
    const itemsAux = ItemsLeds.map((item: Led, i: number) => {
      item.deleteConfirm = false

      return item
    })
    setItemsLeds(itemsAux)
  }

  const acceptDeleteLed = async () => {
    const LedToDelete = ItemsLeds.find(
      (item: any) => item.deleteConfirm === true
    )

    if (LedToDelete) {
      const response = await ledApi.deleteLedById(LedToDelete.id)

      if (response) {
        const itemsAux = ItemsLeds.filter(
          (item: any) => item.deleteConfirm !== true
        )

        setItemsLeds(itemsAux)
        if (DeleteLed) {
          DeleteLed()
        }
      }
    }
  }

  useEffect(() => {
    if (itemsToCheck.length > 0) {
      let indexToCheck = 0
      const checkItemStatusRequest = async () => {
        const response = await simulationApi.checkStatusSimulationById(
          itemsToCheck[indexToCheck].public_id
        )

        if (
          indexToCheck < itemsToCheck.length - 1 &&
          response.status !== STATUS_SIMULATION.error.id
        ) {
          indexToCheck++
        } else {
          indexToCheck = 0
        }
      }
      const checkItemStatusTimeout = setInterval(checkItemStatusRequest, 5000)

      return () => clearInterval(checkItemStatusTimeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsToCheck])

  //let order = true

  const orderLed = (orderby: string) => {
    const OrderLed = {
      orderby: orderby,
      order: OrderbyLed.order,
      color: theme.color._900
    }

    const columnas = OrdersLedsColumns
    const keyordernow = OrderLed.orderby.toLowerCase() as keyof typeof columnas
    const keyorderold = OrderbyLed.orderby.toLowerCase() as keyof typeof columnas

    if (
      OrderLed.orderby === OrderbyLed.orderby &&
      OrderLed.order === OrderbyLed.order
    ) {
      OrderLed.order = !OrderLed.order

      columnas[keyordernow].color = theme.color.neutral._700
      if (OrderLed.order) {
        columnas[keyordernow].icon = OrderLedsUp
        columnas[keyordernow].color = theme.color.neutral._700
        columnas[keyorderold].icon = OrderLedsDown
        columnas[keyorderold].color = theme.color._900
      } else {
        columnas[keyordernow].icon = OrderLedsDown
        columnas[keyordernow].color = theme.color._900
        columnas[keyorderold].icon = OrderLedsUp
        columnas[keyorderold].color = theme.color._900
      }
      //setOrderLeds(OrderLedsDown)
    }

    if (OrderLed.orderby !== OrderbyLed.orderby) {
      columnas[keyordernow].icon = OrderLedsDown
      columnas[keyordernow].color = theme.color._900
      columnas[keyorderold].icon = OrderLedsUp
      columnas[keyorderold].color = theme.color.neutral._700
      OrderLed.order = true

      // setOrderLeds(OrderLedsUp)
    }

    ItemsLeds.sort(function (a, b) {
      //ORDER STRING
      const key = OrderLed.orderby.toLowerCase() as keyof Led
      if (key !== 'data') {
        const led1 = String(a[key]).toLowerCase()
        const led2 = String(b[key]).toLowerCase()
        if (OrderLed.order) {
          if (led1 < led2) {
            return -1
          }
          if (led1 > led2) {
            return 1
          }
        } else {
          if (led1 > led2) {
            return -1
          }
          if (led1 < led2) {
            return 1
          }
        }
      } else {
        const led1 = Number(a[key].tj_c)
        const led2 = Number(b[key].tj_c)
        if (OrderLed.order) {
          if (led1 < led2) {
            return -1
          }
          if (led1 > led2) {
            return 1
          }
        } else {
          if (led1 > led2) {
            return -1
          }
          if (led1 < led2) {
            return 1
          }
        }
      }

      //ORDER STRING END
      //ORDER DATA

      //ORDER DATA FI

      return 0
    })

    // OrderLedsUp = OrderLedsDown
    setOrdersLedsColumns(columnas)
    setOrderByLed(OrderLed)
  }

  return (
    <AccordionUI fluid>
      <LedsSectionList>
        <Accordion.Title
          active={activeIndex === props.index}
          index={props.index}
          onClick={handleClickAccordion}
        >
          {props.listItems.reference} <Icon name="dropdown" />
        </Accordion.Title>
        <Accordion.Content active={activeIndex === props.index}>
          {!isLoading &&
            props.listItems !== undefined &&
            ItemsLeds.length !== 0 && (
              <TableLeds>
                {!isLoading && ItemsLeds.length !== 0 && (
                  <thead>
                    <tr>
                      <TableHeader onClick={() => orderLed('reference')}>
                        Reference{'  '}
                        <OrderReference
                          color={OrdersLedsColumns['reference']['color']}
                        >
                          {OrdersLedsColumns['reference']['icon']}
                        </OrderReference>
                      </TableHeader>
                      <TableHeader onClick={() => orderLed('brand')}>
                        Brand{'  '}
                        <OrderBrand color={OrdersLedsColumns['brand']['color']}>
                          {OrdersLedsColumns['brand']['icon']}
                        </OrderBrand>
                      </TableHeader>
                      <th>
                        ºC
                        <Order></Order>
                      </th>
                      <th>
                        Data
                        <Order></Order>
                      </th>
                    </tr>
                  </thead>
                )}
                {!isLoading && ItemsLeds.length !== 0 && (
                  <tbody>
                    {ItemsLeds.map((led: Led, i: number) => {
                      return (
                        <tr key={led.id}>
                          <td>{led.reference}</td>
                          <td>{led.brand}</td>
                          <td>{led.data.tj_c}</td>
                          <td>
                            if_ma: {led.data.if_ma} {''}
                            vg_v: {led.data.vf_v} {''}
                            radiant_flux_mw : {led.data.radiant_flux_mw}
                          </td>
                          <td>
                            <DeleteSceneIconContainer
                              className={
                                '' +
                                (i === 0 &&
                                  LedsTourActive &&
                                  'tour-specific-led') +
                                ''
                              }
                              onClick={() => infoLuminary(led)}
                            >
                              {ChartAreaIcon}
                            </DeleteSceneIconContainer>
                          </td>
                          <td> </td>
                          <td>
                            {!props.catalog && (
                              <DeleteSceneIconContainer
                                onClick={() => deleteLuminary(i)}
                              >
                                {RemoveLuminaryIcon}
                              </DeleteSceneIconContainer>
                            )}
                          </td>
                          {led.deleteConfirm && (
                            /*   <SimpleConfirm
                              key={led.reference}
                              open={led.deleteConfirm}
                              closeModal={closeDeleteLed}
                              acceptConfirm={acceptDeleteLed}
                              message="Are you sure you want to delete it?"
                            /> */
                            <ModalConfirm
                              size="tiny"
                              closeIcon
                              open={led.deleteConfirm}
                              onClose={() => closeDeleteLed()}
                            >
                              <ModalConfirmText
                                closeModal={closeDeleteLed}
                                object={led}
                                message="Are you sure you want to delete this led?"
                                acceptConfirm={acceptDeleteLed}
                              />
                            </ModalConfirm>
                          )}
                        </tr>
                      )
                    })}
                  </tbody>
                )}
              </TableLeds>
            )}
        </Accordion.Content>
        {ModalLedShow && (
          <Modal open={ModalLedShow} closeModal={closeModalLed}>
            <LedInfoModal info={Ledinfo} />
          </Modal>
        )}
      </LedsSectionList>

      {isLoading && (
        <LoadingContainer>
          <div className="ui tiny active inline loader"></div>
        </LoadingContainer>
      )}
    </AccordionUI>
  )
}

const LoadingContainer = styled.div`
  height: 100%;
  height: 55px;
  border-bottom: 1px solid;
  border-color: ${(props: CompProps) =>
    (props.theme as Theme).color.black.lightest};
  padding-top: 15px;
  padding-left: 15px;
`

const TableHeader = styled.th`
  cursor: pointer;
`

const TableLeds = styled.table`
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;
  th {
    text-align: left;
    padding: 10px;
  }
  td {
    padding: 10px;
    color: ${(props: CompProps) => (props.theme as Theme).color.neutral._900};
  }
  td:nth-child(1) {
    width: 20%;
  }
  td:nth-child(2) {
    width: 20%;
  }
  td:nth-child(4) {
    min-width: 48%;
  }
  td:nth-child(5) {
    max-width: 20px;
  }
  td:nth-child(6) {
    max-width: 20px;
  }
  tbody tr:nth-child(2n) {
    border-bottom: 0;
    background-color: ${(props: CompProps) =>
      (props.theme as Theme).color.neutral._300};
  }
`

const LedsSectionList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-bottom: 1px solid;
  border-color: ${(props: CompProps) =>
    (props.theme as Theme).color.black.lightest};
  div:first-child {
    text-transform: uppercase;
  }
  .icon {
    float: right !important;
  }
`
const OrderReference = styled.span`
  cursor: pointer;
  color: ${(props) => props.color};
`

const OrderBrand = styled.span`
  cursor: pointer;
  color: ${(props) => props.color};
`
const Order = styled.span`
  cursor: pointer;
`

export default LedList
