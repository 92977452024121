import React, { useEffect, useRef } from 'react'
import { Chart } from 'chart.js'

import { ChartProps } from './ChartStatic'

const DoughnutChart: React.FC<ChartProps> = (props) => {

    const chartCanvasRef = useRef<HTMLCanvasElement>(null)

    useEffect(() => {
        const canvas: HTMLCanvasElement | null = chartCanvasRef.current
        canvas && drawChart(canvas)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.chartData])

    const drawDoughnutLabel = (chart: Chart) => {
        const doughnutlabel = chart!.options!.plugins!.doughnutlabel
        const width = chart.width
        const height = chart.height
        const ctx = chart.ctx
    
        if (ctx && width && height) {
          const fontSize = (height / 114).toFixed(2)
          ctx.font = `${fontSize}em sans-serif`
          ctx.textBaseline = 'middle'
          ctx.fillStyle = doughnutlabel.color
    
          const title = doughnutlabel.title
          const titleX = Math.round((width - ctx.measureText(title).width) / 2)
          const titleY = height / 2.5
          const valueText = doughnutlabel.value.toFixed(1)
          const valueX = Math.round((width - ctx.measureText(valueText).width) / 2)
    
          ctx.fillText(title, titleX, titleY)
          ctx.fillText(valueText, valueX, titleY + 25)
        }
    }

    const drawChart = (canvasRef: HTMLCanvasElement) => {
        const ctx: CanvasRenderingContext2D | null = canvasRef.getContext("2d")
        if(ctx) {
            const chartGraph = new Chart(ctx, {
            type: 'doughnut',
            data: {
                datasets: [{
                    data: props.chartData[0],
                    backgroundColor: [
                        props.chartOptions.colors.active,
                        props.chartOptions.colors.pasive
                    ],
                }],
                labels: [
                  " Value",
                  " Value"
                ]
            },
            options: {
                legend: {
                    display: false
                },
                responsive: false,
                animation: {
                    onComplete: () => {
                        props.callBack(chartGraph.toBase64Image())
                        Object.defineProperty(chartGraph.options.animation, 'onComplete', {value: undefined})
                    }
                },
                plugins: {
                  doughnutlabel: {
                    title: props.chartOptions.label,
                    value: props.chartData[0][0],
                    color: props.chartOptions.colors.active
                  }
                }
            },
            plugins: [
              {
                beforeDatasetDraw: (chart: Chart) => {
                  drawDoughnutLabel(chart)
                }
              }
            ]
            })
        }  
    }

    return(
        <canvas ref={chartCanvasRef} width={130} height={130} />
    )
}

export default DoughnutChart
