import React, { useEffect, useRef } from 'react'
import { Chart } from 'chart.js'

import { ChartProps } from './ChartStatic'
import { nmToRGB, normValue, generateWaveLength } from './ChartHelpers'


const SpectraStatic: React.FC<ChartProps> = (props) => {

    const chartCanvasRef = useRef<HTMLCanvasElement>(null)

    useEffect(() => {
        const canvas: HTMLCanvasElement | null = chartCanvasRef.current
        canvas && drawChart(canvas)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.chartData])

    const drawChart = (canvasRef: HTMLCanvasElement) => {
        const ctx: CanvasRenderingContext2D | null = canvasRef.getContext("2d")
        if(ctx) {
            // GEnerate Wavlegth data
            const wavelengthData: number[] = generateWaveLength()
            
            // Create Spectra Gradient
            const canvasWidth: number = 740
            const canvasLeftOffset: number = 10 // The margin generated by the left axis
            const canvasBottomOffset: number = 10 // The margin generated by the bottom axis
            var gradientStroke = ctx.createLinearGradient(
                canvasLeftOffset,
                canvasBottomOffset,
                canvasWidth,
                canvasBottomOffset
            )
            for (var i = 0; i < wavelengthData.length; i++) {
                var color = nmToRGB(wavelengthData[i])
                var value = normValue(i, wavelengthData.length, 0)
                gradientStroke.addColorStop(
                  value,
                  'rgba(' + color[0] + ', ' + color[1] + ', ' + color[2] + ', 0.8)'
                )
            }
            
            // Draw Chart
            const chartGraph = new Chart(ctx, {
            type: 'line',
            data: {
                labels: wavelengthData,
                datasets: [{
                    data: props.chartData[0].filter(data => data < 0 ? 0 : data),
                    backgroundColor: gradientStroke,
                    borderColor: 'rgba(180,180,180,0.25)',
                    pointRadius: 0,
                    pointHitRadius: 20 
                }]
            },
            options: {
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: -10
                    }
                },
                legend: {
                    display: false
                },
                scales: {
                    yAxes: [{
                        scaleLabel: {
                            display: false,
                        },
                        ticks: {
                            beginAtZero: true,
                            display: false
                        },
                        gridLines: {
                            display: false
                        }
                    }],
                    xAxes: [{
                        scaleLabel: {
                            display: false,
                        },
                        ticks: {
                            display: false
                        },
                        gridLines: {
                            display: false
                        }
                    }]
                },
                responsive: false,
                animation: {
                    onComplete: () => {
                        props.callBack(chartGraph.toBase64Image())
                        Object.defineProperty(chartGraph.options.animation, 'onComplete', {value: undefined})
                    }
                }
            }
            })
        }  
    }

    return(
        <canvas ref={chartCanvasRef} width={740} height={280} />
    )
}

export default SpectraStatic
