import React from 'react'
import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View
} from '@react-pdf/renderer'
import { StaticGraphicData } from '../charts/ChartStaticContext'
import { PDFDocTexts } from './types'
import logo from '../../images/kumux-logo.png'
import hclsun from '../../images/hcl-sun.png'

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column'
  },
  containerHeader: {
    flexDirection: 'column',
    backgroundColor: '#E1E1E1, 100%',
    width: '100%',
    paddingTop: '25px',
    paddingLeft: '45px',
    paddingRight: '45px',
    alignItems: 'center'
  },
  containerHeaderPage2: {
    flexDirection: 'column',
    backgroundColor: '#FFA0A0, 100%',
    width: '100%',
    paddingTop: '25px',
    paddingLeft: '45px',
    paddingRight: '45px',
    alignItems: 'center',
    height: '175px'
  },
  containerHeaderPage3: {
    flexDirection: 'column',
    backgroundColor: '#FFD8A0 , 100%',
    width: '100%',
    paddingTop: '25px',
    paddingLeft: '45px',
    paddingRight: '45px',
    alignItems: 'center',
    height: '175px'
  },
  headerText: {
    textTransform: 'uppercase',
    alignItems: 'stretch',
    flexDirection: 'row',
    alignContent: 'space-between',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 15,
    maxHeight: 35,
    marginBottom: 25
  },
  headerTextSection2: {
    textTransform: 'uppercase',
    alignItems: 'stretch',
    flexDirection: 'row',
    alignContent: 'space-between',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 15,
    marginBottom: 85
  },
  fixture: {
    fontSize: 12,
    marginBottom: 5
  },
  textTitleColorimetric: {
    fontSize: 13,
    color: '#FF7474, 100%',
    marginTop: 13,
    textTransform: 'uppercase',
    marginBottom: 10
  },
  textTitleNV: {
    fontSize: 13,
    color: '#FFC774, 100%',
    marginTop: 15,
    textTransform: 'uppercase',
    marginBottom: 10
  },
  textTitleEN: {
    fontSize: 13,
    color: '#A5C4F7, 100%',
    marginTop: 15,
    textTransform: 'uppercase',
    marginBottom: 10
  },
  borderText: {
    borderBottom: '1px',
    borderBottomColor: ' #C4C4C4, 100%',
    borderBottomWidth: 1
  },
  textDonutCri: {
    fontSize: 7,
    color: '#FF7474, 100%',
    marginLeft: 10,
    marginTop: 25
  },
  textDonutMp: {
    fontSize: 7,
    color: '#FFC774, 100%',
    marginLeft: 10,
    marginTop: 10
  },
  textDonutEn: {
    fontSize: 7,
    color: '#A5C4F7, 100%',
    marginLeft: 10,
    marginTop: 25
  },
  textdonut: {
    color: '#C4C4C4',
    fontSize: 10,
    marginTop: 10,
    marginBottom: 15
  },
  container: {
    flexDirection: 'column',
    alignContent: 'space-between',
    justifyContent: 'space-between',
    width: '100%'
  },
  infoDonuts: {
    flexDirection: 'row',
    paddingLeft: 30,
    paddingRight: 30,
    width: '100%'
  },
  containerDonut: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 30,
    marginBottom: 15,
  },
  columnsDonut: {
    width: '150px',
    margin: 15,
  },
  donuts: {
    width: '85px'
  },
  tableDonut: {
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  tablerow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'space-between',
    marginTop: 10
  },
  tabletextTitle: {
    fontSize: 9,
    textAlign: 'left',
    fontWeight: 'bold',
    fontStyle: 'bold',
    paddingRight: 10
  },
  tabletextValue: {
    fontSize: 10,
    textAlign: 'left'
  },
  tablecolumn: {
    width: '50%'
  },
  graficsTable: {
    justifyContent: 'space-between',
    alignContent: 'space-between',
    top: -60,
    paddingLeft: 45,
    paddingRight: 45,
    width: '100%'
  },
  graficsRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'space-between',
    marginTop: 15
  },
  graficsColumn: {
    width: '45%',
    backgroundColor: 'white',
    flexDirection: 'column'
  },
  chartContainer: {
    backgroundColor: 'white',
    padding: '20px',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px',
    borderColor: '#bfbfbf, 100%',
    borderWidth: 1,
    borderStyle: 'solid'
  }
})

interface ChartProps {
  width: string
  base64SrcData?: string
}

const ChartPDF = (props: ChartProps) => {
  return (
    <>
      {props.base64SrcData && (
        <View style={styles.chartContainer}>
          <Image style={{ width: props.width }} src={props.base64SrcData} />
        </View>
      )}
    </>
  )
}

interface Props {
  texts: PDFDocTexts
  graphics: StaticGraphicData[]
}

const ScenePDFDoc: React.FC<Props> = (props) => {
  const { graphics, texts } = props

  const getGraphicB64Data = (name: string) => {
    const graphic = graphics.find((graphic) => graphic.name === name)
    return graphic === undefined ? '' : graphic.value
  }

  return (
    <Document>
      {/* PAGE 1 - SPD */}
      <Page wrap size="A4" style={styles.page}>
        <View style={styles.containerHeader}>
          <View style={styles.headerText}>
            <Image src={logo} />
            <View>
              <Text style={styles.fixture}>{props.texts.fixture}</Text>
              <Text>{props.texts.scene}</Text>
            </View>
          </View>
          <Image style={{width: '400px'}} src={getGraphicB64Data('spd')} />
        </View>
        <View style={styles.container}>
          <View style={styles.infoDonuts}>
            <View style={styles.columnsDonut}>
              <View style={styles.containerDonut}>
                <Image style={styles.donuts} src={getGraphicB64Data('info_color')} />
                <View style={{width: '60px'}}> 
                  <Text style={styles.textDonutCri}>
                    A minium CRI of 80 is recommended for indoor projects
                  </Text>
                </View>
              </View>
              <View style={styles.borderText}>
                <Text style={styles.textTitleColorimetric}>Colorimetric</Text>
              </View>
              <View>
                <Text style={styles.textdonut}>
                  The Color Rendering Index is a quantitative measure of the
                  ability of a light source to reveal the colors of various
                  objects.
                </Text>
              </View>
              <View style={styles.tabledonut}>
                <View style={styles.tablerow}>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextTitle}>CCT (K)</Text>
                  </View>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextValue}>{texts.cct}</Text>
                  </View>
                </View>
                <View style={styles.tablerow}>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextTitle}>Duv</Text>
                  </View>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextValue}>{texts.duv}</Text>
                  </View>
                </View>
                <View style={styles.tablerow}>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextTitle}>Rf</Text>
                  </View>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextValue}>{texts.rf}</Text>
                  </View>
                </View>
                <View style={styles.tablerow}>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextTitle}>Rg</Text>
                  </View>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextValue}>{texts.rg}</Text>
                  </View>
                </View>
                <View style={styles.tablerow}>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextTitle}>CRI</Text>
                  </View>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextValue}>{texts.cri}</Text>
                  </View>
                </View>
                <View style={styles.tablerow}>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextTitle}>R9</Text>
                  </View>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextValue}>{texts.r9}</Text>
                  </View>
                </View>
                <View style={styles.tablerow}>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextTitle}>COI</Text>
                  </View>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextValue}>{texts.coi}</Text>
                  </View>
                </View>
                <View style={styles.tablerow}>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextTitle}>x</Text>
                  </View>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextValue}>{texts.x}</Text>
                  </View>
                </View>
                <View style={styles.tablerow}>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextTitle}>y</Text>
                  </View>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextValue}>{texts.y}</Text>
                  </View>
                </View>
              </View>
            </View>

            <View style={styles.columnsDonut}>
              <View style={styles.containerDonut}>
                <Image style={styles.donuts} src={getGraphicB64Data('info_hcl')} />
                <View style={{ width: '60px' }}>
                  <Text style={styles.textDonutMp}>
                    MP -/= 0.4 RELAXING MP +/= 0.4 ENERGIZING
                  </Text>
                  <Text style={styles.textDonutMp}>
                    MP -/= 0.4 RELAXING MP +/= 0.4 ENERGIZING
                  </Text>
                </View>
              </View>
              <View style={styles.borderText}>
                <Text style={styles.textTitleNV}>Non-Visual</Text>
              </View>
              <View>
                <Text style={styles.textdonut}>
                  The Color Rendering Index is a quantitative measure of the
                  ability of a light source to reveal the colors of various
                  objects.
                </Text>
              </View>
              <View style={styles.tabledonut}>
                <View style={styles.tablerow}>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextTitle}>M/P ratio</Text>
                  </View>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextValue}>{texts.mp}</Text>
                  </View>
                </View>
                <View style={styles.tablerow}>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextTitle}>MDER</Text>
                  </View>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextValue}>{texts.mder}</Text>
                  </View>
                </View>
                <View style={styles.tablerow}>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextTitle}>CS @100 lux</Text>
                  </View>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextValue}>{texts.cs_100}</Text>
                  </View>
                </View>
                <View style={styles.tablerow}>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextTitle}>Cla @100 lux</Text>
                  </View>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextValue}>{texts.cla_100}</Text>
                  </View>
                </View>
                <View style={styles.tablerow}>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextTitle}>EML @100 lux</Text>
                  </View>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextValue}>{texts.eml_100}</Text>
                  </View>
                </View>
                <View style={styles.tablerow}>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextTitle}>MEDI @100 lux</Text>
                  </View>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextValue}>{texts.medi_100}</Text>
                  </View>
                </View>
                <View style={styles.tablerow}>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextTitle}>440-490nm %</Text>
                  </View>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextValue}>{texts.ul}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.columnsDonut}>
              <View style={styles.containerDonut}>
                <Image style={styles.donuts} src={getGraphicB64Data('info_energy')} />
                <View style={{ width: '60px' }}>
                <Text style={styles.textDonutEn}>
                  A minimum energy efficiency of 100 lm/W is recommended
                </Text>
                </View>
              </View>
              <View style={styles.borderText}>
                <Text style={styles.textTitleEN}>Power</Text>
              </View>
              <View>
                <Text style={styles.textdonut}>
                  The Color Rendering Index is a quantitative measure of the
                  ability of a light source to reveal the colors of various
                  objects.
                </Text>
              </View>
              <View style={styles.tabledonut}>
                <View style={styles.tablerow}>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextTitle}>Efficiency (lm/W)</Text>
                  </View>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextValue}>
                      {texts.efficiency_lmw}
                    </Text>
                  </View>
                </View>
                <View style={styles.tablerow}>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextTitle}>
                      Electrical Power (W)
                    </Text>
                  </View>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextValue}>{texts.pf_w}</Text>
                  </View>
                </View>
                <View style={styles.tablerow}>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextTitle}>
                      Luminous Flux (lm)
                    </Text>
                  </View>
                  <View style={styles.tablecolumn}>
                    <Text style={styles.tabletextValue}>
                      {texts.luminous_flux_lm}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
      {/* PAGE 2 - COLORIMETRIC */}
      <Page wrap size="A4" style={styles.page}>
        <View style={styles.containerHeaderPage2}>
          <View style={styles.headerTextSection2}>
            <Image src={logo} style={{ width: 35 }} />
            <View>
              <Text style={styles.fixture}>{props.texts.fixture}</Text>
              <Text>{props.texts.scene}</Text>
            </View>
          </View>
        </View>
        <View style={styles.container}>
          <View style={styles.graficsTable}>
            <View style={styles.graficsRow}>
              <View style={styles.graficsColumn}>
                <ChartPDF width="170px" base64SrcData={getGraphicB64Data('cri')} />
                <View style={styles.borderText}>
                  <Text style={styles.textTitleColorimetric}>CRI VALUES</Text>
                </View>
                <View>
                  <Text style={styles.textdonut}>
                    The Color Rendering Index is a quantitative measure of the
                    ability of a light source to reveal the colors of various
                    objects.
                  </Text>
                </View>
              </View>
              <View style={styles.graficsColumn}>
                <ChartPDF width="170px" base64SrcData={getGraphicB64Data('colorvector')} />
                <View style={styles.borderText}>
                  <Text style={styles.textTitleColorimetric}>
                    COLOR VECTOR GRAPHIC
                  </Text>
                </View>
                <View>
                  <Text style={styles.textdonut}>
                    The Color Rendering Index is a quantitative measure of the
                    ability of a light source to reveal the colors of various
                    objects.
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.graficsRow}>
              <View style={styles.graficsColumn}>
                <ChartPDF width="170px" base64SrcData={getGraphicB64Data('cie')} />
                <View style={styles.borderText}>
                  <Text style={styles.textTitleColorimetric}>CIE 1931</Text>
                </View>
                <View>
                  <Text style={styles.textdonut}>
                    The Color Rendering Index is a quantitative measure of the
                    ability of a light source to reveal the colors of various
                    objects.
                  </Text>
                </View>
              </View>
              <View style={styles.graficsColumn}>
                <ChartPDF width="170px" base64SrcData={getGraphicB64Data('saturation')} />
                <View style={styles.borderText}>
                  <Text style={styles.textTitleColorimetric}>
                    FIDELITY/ SATURATION CHART
                  </Text>
                </View>
                <View>
                  <Text style={styles.textdonut}>
                    The Color Rendering Index is a quantitative measure of the
                    ability of a light source to reveal the colors of various
                    objects.
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
      {/* PAGE 3 - ENERGY */}
      <Page size="A4" style={styles.page}>
        <View style={styles.containerHeaderPage3}>
          <View style={styles.headerTextSection2}>
            <Image src={logo} style={{ width: 35 }} />
            <View>
              <Text style={styles.fixture}>{props.texts.fixture}</Text>
              <Text>{props.texts.scene}</Text>
            </View>
          </View>
          <View style={styles.container}>
            <View style={
              {
                width: '280px',
                marginLeft: 110,
                marginTop: -30,
              }
            }>
            <View>
              <ChartPDF width="220px" base64SrcData={getGraphicB64Data('hcl')} />
            </View>
            <View style={styles.borderText}>
                  <Text
                    style={{
                      fontSize: 12,
                      color: '#FFC774, 100%',
                      marginTop: 30,
                      textTransform: 'uppercase',
                      marginBottom: 10
                    }}
                  >
                    HUMAN CENTRIC LIGHTING EVALUATION
                  </Text>
                </View>
                <View>
                  <Text style={styles.textdonut}>
                    The Color Rendering Index is a quantitative measure of the
                    ability of a light source to reveal the colors of various
                    objects.
                  </Text>
                </View>
              </View>
              <Image style={{ position: 'relative', top:225 }} src={hclsun} />
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default ScenePDFDoc
