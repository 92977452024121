import React from 'react'

export interface StaticGraphicData {
    name: string,
    value: string
}

export interface StacticGraphicsData {
    graphics: StaticGraphicData[]
}

export const stacticGraphicsData: StacticGraphicsData = {
    graphics: []    
}
 
const StacticGraphicsContext = React.createContext(stacticGraphicsData)
export default StacticGraphicsContext
