export interface RadarProps {
  relax: number
  focusRadar: number
  natural: number
  boost: number
  create: number
}

class ReportParameters {
  public map_value = (
    x: number,
    x1: number,
    x2: number,
    y1: number,
    y2: number
  ) => {
    // this function maps values
    // y = m*x + n
    const m = (y2 - y1) / (x2 - x1)
    let y = m * x + y1 - m * x1
    if (y < 0) {
      y = 0
    } else if (y > 100) {
      y = 100
    }
    return y
  }

  cie_to_rgb = function (x: number, y: number, brightness: number | undefined) {
    //Set to maximum brightness if no custom value was given (Not the slick ECMAScript 6 way for compatibility reasons)
    if (brightness === undefined) {
      brightness = 254
    }
    const z: number = 1.0 - x - y
    const Y: number = parseFloat((brightness / 254).toFixed(2))
    const X = (Y / y) * x
    const Z = (Y / y) * z
    //Convert to RGB using Wide RGB D65 conversion
    let red = X * 1.656492 - Y * 0.354851 - Z * 0.255038
    let green = -X * 0.707196 + Y * 1.655397 + Z * 0.036152
    let blue = X * 0.051713 - Y * 0.121364 + Z * 1.01153
    //If red, green or blue is larger than 1.0 set it back to the maximum of 1.0
    if (red > blue && red > green && red > 1.0) {
      green = green / red
      blue = blue / red
      red = 1.0
    } else if (green > blue && green > red && green > 1.0) {
      red = red / green
      blue = blue / green
      green = 1.0
    } else if (blue > red && blue > green && blue > 1.0) {
      red = red / blue
      green = green / blue
      blue = 1.0
    }
    //Reverse gamma correction
    red =
      red <= 0.0031308
        ? 12.92 * red
        : (1.0 + 0.055) * Math.pow(red, 1.0 / 2.4) - 0.055
    green =
      green <= 0.0031308
        ? 12.92 * green
        : (1.0 + 0.055) * Math.pow(green, 1.0 / 2.4) - 0.055
    blue =
      blue <= 0.0031308
        ? 12.92 * blue
        : (1.0 + 0.055) * Math.pow(blue, 1.0 / 2.4) - 0.055
    //Convert normalized decimal to decimal
    red = Math.round(red * 255)
    green = Math.round(green * 255)
    blue = Math.round(blue * 255)
    if (isNaN(red)) red = 0
    if (isNaN(green)) green = 0
    if (isNaN(blue)) blue = 0
    return [red, green, blue]
  }

  public get_focus(mp_ratio: number, rf: number, duv: number) {
    // mp_ratio 60%; rf 20%; duv 20%
    const mp_index_inc = this.map_value(mp_ratio, 0.3, 0.8, 0, 100)
    const mp_index_dec = this.map_value(mp_ratio, 0.8, 1.3, 100, 0)
    const mp_index = Math.min(mp_index_inc, mp_index_dec)
    const rf_index = this.map_value(rf, 0, 95, 0, 100)
    const duv_index = this.map_value(Math.abs(duv), 0.015, 0, 0, 100)
    const focus_index = Math.round(
      mp_index * 0.6 + rf_index * 0.2 + duv_index * 0.2
    )
    return focus_index
  }
  public get_PL_proximity = (duv: number) => {
    // duv = 0.015 -> PL_proximity = 0
    // duv = 0 -> PL_proximity = 100
    // absolute value applied to Duv
    //const duv_abs = Math.abs(duv)
    // PL_proximity calculation
    const PL_proximity = Math.round(
      this.map_value(Math.abs(duv), 0.015, 0, 0, 100)
    )
    return PL_proximity
  }

  public get_rg_index = (rg: any) => {
    // Rg can have values over 100, let's rescale it
    // avoid strange values
    if (rg < 0) {
      rg = 0
    }
    // difference between ideal (100) and real
    const rg_error = Math.abs(100 - rg)
    // apply this error
    const rg_index = Math.round(100 - rg_error)
    return rg_index
  }

  public get_energyEfficiency_index = (efficiency_lmw: number) => {
    // PL_proximity calculation
    const energyEfficiency_index = Math.round(
      this.map_value(efficiency_lmw, 60, 180, 0, 100)
    )
    return energyEfficiency_index
  }
  public get_electricalPower_index = (pf_w: number) => {
    // PL_proximity calculation
    const electricalPower_index = Math.round(
      this.map_value(pf_w, 3, 100, 0, 100)
    )
    return electricalPower_index
  }

  public get_luminousFlux_index = (luminous_flux_lm: number) => {
    const luminousFlux_index = Math.round(
      this.map_value(luminous_flux_lm, 300, 5000, 0, 100)
    )
    return luminousFlux_index
  }
  public get_radiantFlux_index = (radiant_flux_mw: number) => {
    // PL_proximity calculation
    const radiantFlux_index = Math.round(
      this.map_value(radiant_flux_mw, 300, 10000, 0, 100)
    )
    return radiantFlux_index
  }

  public get_ler_index = (ler_lmw: number) => {
    // PL_proximity calculation
    const ler_index = Math.round(this.map_value(ler_lmw, 80, 400, 0, 100))
    return ler_index
  }

  public get_relax = (mp_ratio: number, rf: number, duv: number) => {
    // mp_ratio 70%; rf 20%; duv 10%
    const mp_index = this.map_value(mp_ratio, 0.3, 1.1, 100, 0)
    const rf_index = this.map_value(rf, 0, 95, 0, 100)
    const duv_index = this.map_value(Math.abs(duv), 0.015, 0, 0, 100)
    const relax_index = Math.round(
      mp_index * 0.7 + rf_index * 0.2 + duv_index * 0.1
    )
    return relax_index
  }

  public get_boost = (mp_ratio: number, rf: number, duv: number) => {
    const mp_index = this.map_value(mp_ratio, 0.3, 1.1, 0, 100)
    const rf_index = this.map_value(rf, 0, 95, 0, 100)
    const duv_index = this.map_value(Math.abs(duv), 0.015, 0, 0, 100)
    const boost_index = Math.round(
      mp_index * 0.7 + rf_index * 0.2 + duv_index * 0.1
    )
    return boost_index
  }

  public get_create = (mp_ratio: number, rf: number, duv: number) => {
    // mp_ratio 40%; rf 40%; duv 20%
    const mp_index_inc = this.map_value(mp_ratio, 0.3, 0.55, 0, 100)
    const mp_index_dec = this.map_value(mp_ratio, 0.55, 0.8, 100, 0)
    const mp_index = Math.min(mp_index_inc, mp_index_dec)
    const rf_index = this.map_value(rf, 0, 95, 0, 100)
    const duv_index = this.map_value(Math.abs(duv), 0.015, 0, 0, 100)
    const create_index = Math.round(
      mp_index * 0.4 + rf_index * 0.4 + duv_index * 0.2
    )
    return create_index
  }

  public get_natural = (rf: number, duv: number, cct_k: number) => {
    // rf 60%; duv 20%; cct 20%
    const rf_index = this.map_value(rf, 0, 95, 0, 100)
    const duv_index = this.map_value(Math.abs(duv), 0.015, 0, 0, 100)
    const cct_index_inc = this.map_value(cct_k, 3000, 4500, 0, 100)
    const cct_index_dec = this.map_value(cct_k, 5700, 7000, 100, 0)
    const cct_index = Math.min(cct_index_inc, cct_index_dec)
    const natural_index = Math.round(
      rf_index * 0.6 + duv_index * 0.2 + cct_index * 0.2
    )
    return natural_index
  }

  public parametersDropdown = {
    incandescent: {
      wavelength: [
        380,
        381,
        382,
        383,
        384,
        385,
        386,
        387,
        388,
        389,
        390,
        391,
        392,
        393,
        394,
        395,
        396,
        397,
        398,
        399,
        400,
        401,
        402,
        403,
        404,
        405,
        406,
        407,
        408,
        409,
        410,
        411,
        412,
        413,
        414,
        415,
        416,
        417,
        418,
        419,
        420,
        421,
        422,
        423,
        424,
        425,
        426,
        427,
        428,
        429,
        430,
        431,
        432,
        433,
        434,
        435,
        436,
        437,
        438,
        439,
        440,
        441,
        442,
        443,
        444,
        445,
        446,
        447,
        448,
        449,
        450,
        451,
        452,
        453,
        454,
        455,
        456,
        457,
        458,
        459,
        460,
        461,
        462,
        463,
        464,
        465,
        466,
        467,
        468,
        469,
        470,
        471,
        472,
        473,
        474,
        475,
        476,
        477,
        478,
        479,
        480,
        481,
        482,
        483,
        484,
        485,
        486,
        487,
        488,
        489,
        490,
        491,
        492,
        493,
        494,
        495,
        496,
        497,
        498,
        499,
        500,
        501,
        502,
        503,
        504,
        505,
        506,
        507,
        508,
        509,
        510,
        511,
        512,
        513,
        514,
        515,
        516,
        517,
        518,
        519,
        520,
        521,
        522,
        523,
        524,
        525,
        526,
        527,
        528,
        529,
        530,
        531,
        532,
        533,
        534,
        535,
        536,
        537,
        538,
        539,
        540,
        541,
        542,
        543,
        544,
        545,
        546,
        547,
        548,
        549,
        550,
        551,
        552,
        553,
        554,
        555,
        556,
        557,
        558,
        559,
        560,
        561,
        562,
        563,
        564,
        565,
        566,
        567,
        568,
        569,
        570,
        571,
        572,
        573,
        574,
        575,
        576,
        577,
        578,
        579,
        580,
        581,
        582,
        583,
        584,
        585,
        586,
        587,
        588,
        589,
        590,
        591,
        592,
        593,
        594,
        595,
        596,
        597,
        598,
        599,
        600,
        601,
        602,
        603,
        604,
        605,
        606,
        607,
        608,
        609,
        610,
        611,
        612,
        613,
        614,
        615,
        616,
        617,
        618,
        619,
        620,
        621,
        622,
        623,
        624,
        625,
        626,
        627,
        628,
        629,
        630,
        631,
        632,
        633,
        634,
        635,
        636,
        637,
        638,
        639,
        640,
        641,
        642,
        643,
        644,
        645,
        646,
        647,
        648,
        649,
        650,
        651,
        652,
        653,
        654,
        655,
        656,
        657,
        658,
        659,
        660,
        661,
        662,
        663,
        664,
        665,
        666,
        667,
        668,
        669,
        670,
        671,
        672,
        673,
        674,
        675,
        676,
        677,
        678,
        679,
        680,
        681,
        682,
        683,
        684,
        685,
        686,
        687,
        688,
        689,
        690,
        691,
        692,
        693,
        694,
        695,
        696,
        697,
        698,
        699,
        700,
        701,
        702,
        703,
        704,
        705,
        706,
        707,
        708,
        709,
        710,
        711,
        712,
        713,
        714,
        715,
        716,
        717,
        718,
        719,
        720,
        721,
        722,
        723,
        724,
        725,
        726,
        727,
        728,
        729,
        730,
        731,
        732,
        733,
        734,
        735,
        736,
        737,
        738,
        739,
        740,
        741,
        742,
        743,
        744,
        745,
        746,
        747,
        748,
        749,
        750,
        751,
        752,
        753,
        754,
        755,
        756,
        757,
        758,
        759,
        760,
        761,
        762,
        763,
        764,
        765,
        766,
        767,
        768,
        769,
        770,
        771,
        772,
        773,
        774,
        775,
        776,
        777,
        778,
        779,
        780
      ],
      irradiance: [
        0.041,
        0.041,
        0.042,
        0.043,
        0.044,
        0.045,
        0.046,
        0.047,
        0.048,
        0.049,
        0.05,
        0.051,
        0.052,
        0.053,
        0.054,
        0.055,
        0.056,
        0.057,
        0.059,
        0.06,
        0.061,
        0.062,
        0.063,
        0.064,
        0.066,
        0.067,
        0.068,
        0.069,
        0.071,
        0.072,
        0.073,
        0.074,
        0.076,
        0.077,
        0.078,
        0.08,
        0.081,
        0.083,
        0.084,
        0.085,
        0.087,
        0.088,
        0.09,
        0.091,
        0.093,
        0.094,
        0.096,
        0.097,
        0.099,
        0.1,
        0.102,
        0.104,
        0.105,
        0.107,
        0.109,
        0.11,
        0.112,
        0.114,
        0.115,
        0.117,
        0.119,
        0.121,
        0.122,
        0.124,
        0.126,
        0.128,
        0.129,
        0.131,
        0.133,
        0.135,
        0.137,
        0.139,
        0.141,
        0.143,
        0.145,
        0.147,
        0.148,
        0.15,
        0.152,
        0.154,
        0.156,
        0.158,
        0.161,
        0.163,
        0.165,
        0.167,
        0.169,
        0.171,
        0.173,
        0.175,
        0.177,
        0.18,
        0.182,
        0.184,
        0.186,
        0.188,
        0.191,
        0.193,
        0.195,
        0.197,
        0.2,
        0.202,
        0.204,
        0.207,
        0.209,
        0.211,
        0.214,
        0.216,
        0.218,
        0.221,
        0.223,
        0.225,
        0.228,
        0.23,
        0.233,
        0.235,
        0.238,
        0.24,
        0.243,
        0.245,
        0.248,
        0.25,
        0.253,
        0.255,
        0.258,
        0.26,
        0.263,
        0.266,
        0.268,
        0.271,
        0.273,
        0.276,
        0.279,
        0.281,
        0.284,
        0.287,
        0.289,
        0.292,
        0.295,
        0.297,
        0.3,
        0.303,
        0.305,
        0.308,
        0.311,
        0.314,
        0.316,
        0.319,
        0.322,
        0.325,
        0.327,
        0.33,
        0.333,
        0.336,
        0.339,
        0.341,
        0.344,
        0.347,
        0.35,
        0.353,
        0.356,
        0.358,
        0.361,
        0.364,
        0.367,
        0.37,
        0.373,
        0.376,
        0.379,
        0.382,
        0.384,
        0.387,
        0.39,
        0.393,
        0.396,
        0.399,
        0.402,
        0.405,
        0.408,
        0.411,
        0.414,
        0.417,
        0.42,
        0.423,
        0.426,
        0.429,
        0.432,
        0.435,
        0.438,
        0.441,
        0.444,
        0.446,
        0.449,
        0.452,
        0.455,
        0.458,
        0.461,
        0.464,
        0.467,
        0.471,
        0.474,
        0.477,
        0.48,
        0.483,
        0.486,
        0.489,
        0.492,
        0.495,
        0.498,
        0.501,
        0.504,
        0.507,
        0.51,
        0.513,
        0.516,
        0.519,
        0.522,
        0.525,
        0.528,
        0.531,
        0.534,
        0.537,
        0.54,
        0.543,
        0.546,
        0.549,
        0.552,
        0.555,
        0.558,
        0.561,
        0.564,
        0.567,
        0.57,
        0.573,
        0.576,
        0.579,
        0.582,
        0.585,
        0.588,
        0.591,
        0.594,
        0.597,
        0.6,
        0.603,
        0.606,
        0.609,
        0.612,
        0.615,
        0.618,
        0.621,
        0.624,
        0.627,
        0.63,
        0.633,
        0.636,
        0.639,
        0.642,
        0.645,
        0.648,
        0.651,
        0.654,
        0.657,
        0.66,
        0.662,
        0.665,
        0.668,
        0.671,
        0.674,
        0.677,
        0.68,
        0.683,
        0.686,
        0.689,
        0.692,
        0.694,
        0.697,
        0.7,
        0.703,
        0.706,
        0.709,
        0.712,
        0.714,
        0.717,
        0.72,
        0.723,
        0.726,
        0.729,
        0.731,
        0.734,
        0.737,
        0.74,
        0.742,
        0.745,
        0.748,
        0.751,
        0.754,
        0.756,
        0.759,
        0.762,
        0.765,
        0.767,
        0.77,
        0.773,
        0.775,
        0.778,
        0.781,
        0.783,
        0.786,
        0.789,
        0.792,
        0.794,
        0.797,
        0.799,
        0.802,
        0.805,
        0.807,
        0.81,
        0.813,
        0.815,
        0.818,
        0.82,
        0.823,
        0.826,
        0.828,
        0.831,
        0.833,
        0.836,
        0.838,
        0.841,
        0.843,
        0.846,
        0.848,
        0.851,
        0.853,
        0.856,
        0.858,
        0.861,
        0.863,
        0.866,
        0.868,
        0.87,
        0.873,
        0.875,
        0.878,
        0.88,
        0.882,
        0.885,
        0.887,
        0.89,
        0.892,
        0.894,
        0.897,
        0.899,
        0.901,
        0.904,
        0.906,
        0.908,
        0.91,
        0.913,
        0.915,
        0.917,
        0.919,
        0.922,
        0.924,
        0.926,
        0.928,
        0.931,
        0.933,
        0.935,
        0.937,
        0.939,
        0.941,
        0.944,
        0.946,
        0.948,
        0.95,
        0.952,
        0.954,
        0.956,
        0.958,
        0.96,
        0.963,
        0.965,
        0.967,
        0.969,
        0.971,
        0.973,
        0.975,
        0.977,
        0.979,
        0.981,
        0.983,
        0.985,
        0.987,
        0.989,
        0.99,
        0.992,
        0.994,
        0.996,
        0.998,
        1.0
      ]
    },

    daylight: {
      wavelength: [
        380,
        381,
        382,
        383,
        384,
        385,
        386,
        387,
        388,
        389,
        390,
        391,
        392,
        393,
        394,
        395,
        396,
        397,
        398,
        399,
        400,
        401,
        402,
        403,
        404,
        405,
        406,
        407,
        408,
        409,
        410,
        411,
        412,
        413,
        414,
        415,
        416,
        417,
        418,
        419,
        420,
        421,
        422,
        423,
        424,
        425,
        426,
        427,
        428,
        429,
        430,
        431,
        432,
        433,
        434,
        435,
        436,
        437,
        438,
        439,
        440,
        441,
        442,
        443,
        444,
        445,
        446,
        447,
        448,
        449,
        450,
        451,
        452,
        453,
        454,
        455,
        456,
        457,
        458,
        459,
        460,
        461,
        462,
        463,
        464,
        465,
        466,
        467,
        468,
        469,
        470,
        471,
        472,
        473,
        474,
        475,
        476,
        477,
        478,
        479,
        480,
        481,
        482,
        483,
        484,
        485,
        486,
        487,
        488,
        489,
        490,
        491,
        492,
        493,
        494,
        495,
        496,
        497,
        498,
        499,
        500,
        501,
        502,
        503,
        504,
        505,
        506,
        507,
        508,
        509,
        510,
        511,
        512,
        513,
        514,
        515,
        516,
        517,
        518,
        519,
        520,
        521,
        522,
        523,
        524,
        525,
        526,
        527,
        528,
        529,
        530,
        531,
        532,
        533,
        534,
        535,
        536,
        537,
        538,
        539,
        540,
        541,
        542,
        543,
        544,
        545,
        546,
        547,
        548,
        549,
        550,
        551,
        552,
        553,
        554,
        555,
        556,
        557,
        558,
        559,
        560,
        561,
        562,
        563,
        564,
        565,
        566,
        567,
        568,
        569,
        570,
        571,
        572,
        573,
        574,
        575,
        576,
        577,
        578,
        579,
        580,
        581,
        582,
        583,
        584,
        585,
        586,
        587,
        588,
        589,
        590,
        591,
        592,
        593,
        594,
        595,
        596,
        597,
        598,
        599,
        600,
        601,
        602,
        603,
        604,
        605,
        606,
        607,
        608,
        609,
        610,
        611,
        612,
        613,
        614,
        615,
        616,
        617,
        618,
        619,
        620,
        621,
        622,
        623,
        624,
        625,
        626,
        627,
        628,
        629,
        630,
        631,
        632,
        633,
        634,
        635,
        636,
        637,
        638,
        639,
        640,
        641,
        642,
        643,
        644,
        645,
        646,
        647,
        648,
        649,
        650,
        651,
        652,
        653,
        654,
        655,
        656,
        657,
        658,
        659,
        660,
        661,
        662,
        663,
        664,
        665,
        666,
        667,
        668,
        669,
        670,
        671,
        672,
        673,
        674,
        675,
        676,
        677,
        678,
        679,
        680,
        681,
        682,
        683,
        684,
        685,
        686,
        687,
        688,
        689,
        690,
        691,
        692,
        693,
        694,
        695,
        696,
        697,
        698,
        699,
        700,
        701,
        702,
        703,
        704,
        705,
        706,
        707,
        708,
        709,
        710,
        711,
        712,
        713,
        714,
        715,
        716,
        717,
        718,
        719,
        720,
        721,
        722,
        723,
        724,
        725,
        726,
        727,
        728,
        729,
        730,
        731,
        732,
        733,
        734,
        735,
        736,
        737,
        738,
        739,
        740,
        741,
        742,
        743,
        744,
        745,
        746,
        747,
        748,
        749,
        750,
        751,
        752,
        753,
        754,
        755,
        756,
        757,
        758,
        759,
        760,
        761,
        762,
        763,
        764,
        765,
        766,
        767,
        768,
        769,
        770,
        771,
        772,
        773,
        774,
        775,
        776,
        777,
        778,
        779,
        780
      ],
      irradiance: [
        0.424,
        0.428,
        0.432,
        0.436,
        0.44,
        0.444,
        0.448,
        0.452,
        0.456,
        0.46,
        0.464,
        0.488,
        0.512,
        0.535,
        0.559,
        0.583,
        0.607,
        0.631,
        0.655,
        0.679,
        0.702,
        0.71,
        0.717,
        0.725,
        0.732,
        0.739,
        0.747,
        0.754,
        0.762,
        0.769,
        0.777,
        0.778,
        0.78,
        0.781,
        0.783,
        0.785,
        0.786,
        0.788,
        0.79,
        0.791,
        0.793,
        0.787,
        0.782,
        0.776,
        0.77,
        0.764,
        0.759,
        0.753,
        0.747,
        0.741,
        0.736,
        0.751,
        0.767,
        0.782,
        0.798,
        0.813,
        0.828,
        0.844,
        0.859,
        0.875,
        0.89,
        0.9,
        0.911,
        0.921,
        0.931,
        0.942,
        0.952,
        0.962,
        0.973,
        0.983,
        0.993,
        0.994,
        0.995,
        0.995,
        0.996,
        0.997,
        0.997,
        0.998,
        0.999,
        0.999,
        1.0,
        0.997,
        0.995,
        0.992,
        0.99,
        0.987,
        0.985,
        0.982,
        0.98,
        0.977,
        0.975,
        0.976,
        0.977,
        0.978,
        0.979,
        0.979,
        0.98,
        0.981,
        0.982,
        0.983,
        0.984,
        0.978,
        0.972,
        0.966,
        0.96,
        0.954,
        0.948,
        0.942,
        0.936,
        0.93,
        0.924,
        0.924,
        0.925,
        0.925,
        0.925,
        0.926,
        0.926,
        0.927,
        0.927,
        0.928,
        0.928,
        0.927,
        0.926,
        0.924,
        0.923,
        0.922,
        0.92,
        0.919,
        0.918,
        0.916,
        0.915,
        0.912,
        0.91,
        0.907,
        0.905,
        0.902,
        0.9,
        0.897,
        0.895,
        0.892,
        0.889,
        0.892,
        0.894,
        0.897,
        0.899,
        0.902,
        0.904,
        0.907,
        0.909,
        0.912,
        0.914,
        0.911,
        0.908,
        0.906,
        0.903,
        0.9,
        0.897,
        0.895,
        0.892,
        0.889,
        0.886,
        0.886,
        0.886,
        0.885,
        0.885,
        0.885,
        0.884,
        0.884,
        0.884,
        0.883,
        0.883,
        0.88,
        0.876,
        0.873,
        0.869,
        0.866,
        0.863,
        0.859,
        0.856,
        0.852,
        0.849,
        0.846,
        0.843,
        0.839,
        0.836,
        0.833,
        0.83,
        0.827,
        0.824,
        0.821,
        0.818,
        0.817,
        0.817,
        0.816,
        0.816,
        0.815,
        0.815,
        0.814,
        0.814,
        0.814,
        0.813,
        0.807,
        0.801,
        0.795,
        0.789,
        0.783,
        0.777,
        0.771,
        0.765,
        0.759,
        0.753,
        0.754,
        0.755,
        0.756,
        0.757,
        0.758,
        0.759,
        0.761,
        0.762,
        0.763,
        0.764,
        0.764,
        0.763,
        0.763,
        0.763,
        0.762,
        0.762,
        0.762,
        0.761,
        0.761,
        0.761,
        0.759,
        0.757,
        0.756,
        0.754,
        0.752,
        0.751,
        0.749,
        0.748,
        0.746,
        0.744,
        0.741,
        0.737,
        0.733,
        0.729,
        0.726,
        0.722,
        0.718,
        0.714,
        0.711,
        0.707,
        0.707,
        0.708,
        0.708,
        0.708,
        0.709,
        0.709,
        0.709,
        0.71,
        0.71,
        0.71,
        0.707,
        0.704,
        0.701,
        0.698,
        0.695,
        0.692,
        0.689,
        0.686,
        0.682,
        0.679,
        0.679,
        0.68,
        0.68,
        0.68,
        0.68,
        0.68,
        0.68,
        0.681,
        0.681,
        0.681,
        0.683,
        0.684,
        0.686,
        0.688,
        0.69,
        0.691,
        0.693,
        0.695,
        0.697,
        0.698,
        0.695,
        0.692,
        0.688,
        0.685,
        0.681,
        0.678,
        0.675,
        0.671,
        0.668,
        0.664,
        0.657,
        0.65,
        0.643,
        0.635,
        0.628,
        0.621,
        0.614,
        0.606,
        0.599,
        0.592,
        0.593,
        0.595,
        0.597,
        0.598,
        0.6,
        0.601,
        0.603,
        0.605,
        0.606,
        0.608,
        0.61,
        0.612,
        0.615,
        0.617,
        0.619,
        0.622,
        0.624,
        0.626,
        0.629,
        0.631,
        0.62,
        0.609,
        0.599,
        0.588,
        0.577,
        0.566,
        0.555,
        0.545,
        0.534,
        0.523,
        0.53,
        0.537,
        0.544,
        0.551,
        0.558,
        0.565,
        0.572,
        0.579,
        0.586,
        0.593,
        0.598,
        0.602,
        0.606,
        0.611,
        0.615,
        0.62,
        0.624,
        0.629,
        0.633,
        0.637,
        0.628,
        0.618,
        0.608,
        0.598,
        0.589,
        0.579,
        0.569,
        0.559,
        0.55,
        0.54,
        0.525,
        0.511,
        0.496,
        0.481,
        0.467,
        0.452,
        0.438,
        0.423,
        0.409,
        0.394,
        0.411,
        0.429,
        0.446,
        0.463,
        0.481,
        0.498,
        0.515,
        0.532,
        0.55,
        0.567,
        0.564,
        0.561,
        0.558,
        0.555,
        0.553,
        0.55,
        0.547,
        0.544,
        0.541,
        0.538
      ]
    },

    cones_response: {
      wavelength: [
        380,
        381,
        382,
        383,
        384,
        385,
        386,
        387,
        388,
        389,
        390,
        391,
        392,
        393,
        394,
        395,
        396,
        397,
        398,
        399,
        400,
        401,
        402,
        403,
        404,
        405,
        406,
        407,
        408,
        409,
        410,
        411,
        412,
        413,
        414,
        415,
        416,
        417,
        418,
        419,
        420,
        421,
        422,
        423,
        424,
        425,
        426,
        427,
        428,
        429,
        430,
        431,
        432,
        433,
        434,
        435,
        436,
        437,
        438,
        439,
        440,
        441,
        442,
        443,
        444,
        445,
        446,
        447,
        448,
        449,
        450,
        451,
        452,
        453,
        454,
        455,
        456,
        457,
        458,
        459,
        460,
        461,
        462,
        463,
        464,
        465,
        466,
        467,
        468,
        469,
        470,
        471,
        472,
        473,
        474,
        475,
        476,
        477,
        478,
        479,
        480,
        481,
        482,
        483,
        484,
        485,
        486,
        487,
        488,
        489,
        490,
        491,
        492,
        493,
        494,
        495,
        496,
        497,
        498,
        499,
        500,
        501,
        502,
        503,
        504,
        505,
        506,
        507,
        508,
        509,
        510,
        511,
        512,
        513,
        514,
        515,
        516,
        517,
        518,
        519,
        520,
        521,
        522,
        523,
        524,
        525,
        526,
        527,
        528,
        529,
        530,
        531,
        532,
        533,
        534,
        535,
        536,
        537,
        538,
        539,
        540,
        541,
        542,
        543,
        544,
        545,
        546,
        547,
        548,
        549,
        550,
        551,
        552,
        553,
        554,
        555,
        556,
        557,
        558,
        559,
        560,
        561,
        562,
        563,
        564,
        565,
        566,
        567,
        568,
        569,
        570,
        571,
        572,
        573,
        574,
        575,
        576,
        577,
        578,
        579,
        580,
        581,
        582,
        583,
        584,
        585,
        586,
        587,
        588,
        589,
        590,
        591,
        592,
        593,
        594,
        595,
        596,
        597,
        598,
        599,
        600,
        601,
        602,
        603,
        604,
        605,
        606,
        607,
        608,
        609,
        610,
        611,
        612,
        613,
        614,
        615,
        616,
        617,
        618,
        619,
        620,
        621,
        622,
        623,
        624,
        625,
        626,
        627,
        628,
        629,
        630,
        631,
        632,
        633,
        634,
        635,
        636,
        637,
        638,
        639,
        640,
        641,
        642,
        643,
        644,
        645,
        646,
        647,
        648,
        649,
        650,
        651,
        652,
        653,
        654,
        655,
        656,
        657,
        658,
        659,
        660,
        661,
        662,
        663,
        664,
        665,
        666,
        667,
        668,
        669,
        670,
        671,
        672,
        673,
        674,
        675,
        676,
        677,
        678,
        679,
        680,
        681,
        682,
        683,
        684,
        685,
        686,
        687,
        688,
        689,
        690,
        691,
        692,
        693,
        694,
        695,
        696,
        697,
        698,
        699,
        700,
        701,
        702,
        703,
        704,
        705,
        706,
        707,
        708,
        709,
        710,
        711,
        712,
        713,
        714,
        715,
        716,
        717,
        718,
        719,
        720,
        721,
        722,
        723,
        724,
        725,
        726,
        727,
        728,
        729,
        730,
        731,
        732,
        733,
        734,
        735,
        736,
        737,
        738,
        739,
        740,
        741,
        742,
        743,
        744,
        745,
        746,
        747,
        748,
        749,
        750,
        751,
        752,
        753,
        754,
        755,
        756,
        757,
        758,
        759,
        760,
        761,
        762,
        763,
        764,
        765,
        766,
        767,
        768,
        769,
        770,
        771,
        772,
        773,
        774,
        775,
        776,
        777,
        778,
        779,
        780
      ],
      irradiance: [
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.002,
        0.002,
        0.002,
        0.002,
        0.003,
        0.003,
        0.003,
        0.003,
        0.004,
        0.004,
        0.005,
        0.005,
        0.006,
        0.006,
        0.007,
        0.007,
        0.008,
        0.009,
        0.01,
        0.011,
        0.012,
        0.013,
        0.014,
        0.015,
        0.016,
        0.018,
        0.019,
        0.02,
        0.021,
        0.022,
        0.023,
        0.024,
        0.025,
        0.025,
        0.026,
        0.027,
        0.028,
        0.029,
        0.03,
        0.032,
        0.033,
        0.034,
        0.035,
        0.036,
        0.037,
        0.038,
        0.039,
        0.04,
        0.041,
        0.042,
        0.042,
        0.043,
        0.044,
        0.045,
        0.046,
        0.047,
        0.048,
        0.049,
        0.05,
        0.051,
        0.052,
        0.053,
        0.055,
        0.056,
        0.058,
        0.06,
        0.063,
        0.065,
        0.068,
        0.071,
        0.074,
        0.077,
        0.08,
        0.084,
        0.087,
        0.091,
        0.095,
        0.099,
        0.103,
        0.108,
        0.113,
        0.118,
        0.123,
        0.128,
        0.133,
        0.139,
        0.145,
        0.15,
        0.156,
        0.163,
        0.169,
        0.176,
        0.184,
        0.191,
        0.199,
        0.208,
        0.217,
        0.227,
        0.237,
        0.247,
        0.259,
        0.27,
        0.282,
        0.295,
        0.309,
        0.323,
        0.338,
        0.355,
        0.372,
        0.389,
        0.407,
        0.426,
        0.444,
        0.463,
        0.483,
        0.503,
        0.524,
        0.545,
        0.566,
        0.587,
        0.608,
        0.629,
        0.65,
        0.671,
        0.691,
        0.71,
        0.728,
        0.745,
        0.762,
        0.778,
        0.793,
        0.808,
        0.822,
        0.836,
        0.849,
        0.862,
        0.874,
        0.885,
        0.895,
        0.905,
        0.915,
        0.924,
        0.932,
        0.94,
        0.947,
        0.954,
        0.96,
        0.966,
        0.971,
        0.976,
        0.98,
        0.984,
        0.987,
        0.99,
        0.993,
        0.995,
        0.997,
        0.998,
        0.999,
        1.0,
        1.0,
        1.0,
        0.999,
        0.998,
        0.997,
        0.995,
        0.993,
        0.99,
        0.986,
        0.983,
        0.979,
        0.974,
        0.969,
        0.964,
        0.958,
        0.952,
        0.945,
        0.938,
        0.931,
        0.923,
        0.915,
        0.907,
        0.898,
        0.889,
        0.88,
        0.87,
        0.86,
        0.849,
        0.839,
        0.828,
        0.816,
        0.805,
        0.793,
        0.781,
        0.769,
        0.757,
        0.745,
        0.732,
        0.72,
        0.707,
        0.695,
        0.682,
        0.669,
        0.657,
        0.644,
        0.631,
        0.618,
        0.605,
        0.592,
        0.58,
        0.567,
        0.554,
        0.541,
        0.528,
        0.516,
        0.503,
        0.49,
        0.478,
        0.466,
        0.453,
        0.441,
        0.429,
        0.417,
        0.405,
        0.393,
        0.381,
        0.369,
        0.357,
        0.345,
        0.333,
        0.321,
        0.309,
        0.298,
        0.287,
        0.276,
        0.265,
        0.255,
        0.245,
        0.235,
        0.226,
        0.217,
        0.208,
        0.2,
        0.191,
        0.183,
        0.175,
        0.167,
        0.16,
        0.152,
        0.145,
        0.138,
        0.132,
        0.125,
        0.119,
        0.113,
        0.107,
        0.101,
        0.096,
        0.091,
        0.086,
        0.082,
        0.077,
        0.073,
        0.069,
        0.065,
        0.061,
        0.057,
        0.054,
        0.051,
        0.048,
        0.045,
        0.042,
        0.039,
        0.037,
        0.034,
        0.032,
        0.03,
        0.028,
        0.026,
        0.025,
        0.023,
        0.022,
        0.021,
        0.019,
        0.018,
        0.017,
        0.016,
        0.015,
        0.014,
        0.013,
        0.012,
        0.011,
        0.01,
        0.01,
        0.009,
        0.008,
        0.008,
        0.007,
        0.007,
        0.006,
        0.006,
        0.005,
        0.005,
        0.005,
        0.004,
        0.004,
        0.004,
        0.004,
        0.003,
        0.003,
        0.003,
        0.003,
        0.003,
        0.002,
        0.002,
        0.002,
        0.002,
        0.002,
        0.002,
        0.002,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0
      ]
    },

    blue_light: {
      wavelength: [
        380,
        381,
        382,
        383,
        384,
        385,
        386,
        387,
        388,
        389,
        390,
        391,
        392,
        393,
        394,
        395,
        396,
        397,
        398,
        399,
        400,
        401,
        402,
        403,
        404,
        405,
        406,
        407,
        408,
        409,
        410,
        411,
        412,
        413,
        414,
        415,
        416,
        417,
        418,
        419,
        420,
        421,
        422,
        423,
        424,
        425,
        426,
        427,
        428,
        429,
        430,
        431,
        432,
        433,
        434,
        435,
        436,
        437,
        438,
        439,
        440,
        441,
        442,
        443,
        444,
        445,
        446,
        447,
        448,
        449,
        450,
        451,
        452,
        453,
        454,
        455,
        456,
        457,
        458,
        459,
        460,
        461,
        462,
        463,
        464,
        465,
        466,
        467,
        468,
        469,
        470,
        471,
        472,
        473,
        474,
        475,
        476,
        477,
        478,
        479,
        480,
        481,
        482,
        483,
        484,
        485,
        486,
        487,
        488,
        489,
        490,
        491,
        492,
        493,
        494,
        495,
        496,
        497,
        498,
        499,
        500,
        501,
        502,
        503,
        504,
        505,
        506,
        507,
        508,
        509,
        510,
        511,
        512,
        513,
        514,
        515,
        516,
        517,
        518,
        519,
        520,
        521,
        522,
        523,
        524,
        525,
        526,
        527,
        528,
        529,
        530,
        531,
        532,
        533,
        534,
        535,
        536,
        537,
        538,
        539,
        540,
        541,
        542,
        543,
        544,
        545,
        546,
        547,
        548,
        549,
        550,
        551,
        552,
        553,
        554,
        555,
        556,
        557,
        558,
        559,
        560,
        561,
        562,
        563,
        564,
        565,
        566,
        567,
        568,
        569,
        570,
        571,
        572,
        573,
        574,
        575,
        576,
        577,
        578,
        579,
        580,
        581,
        582,
        583,
        584,
        585,
        586,
        587,
        588,
        589,
        590,
        591,
        592,
        593,
        594,
        595,
        596,
        597,
        598,
        599,
        600,
        601,
        602,
        603,
        604,
        605,
        606,
        607,
        608,
        609,
        610,
        611,
        612,
        613,
        614,
        615,
        616,
        617,
        618,
        619,
        620,
        621,
        622,
        623,
        624,
        625,
        626,
        627,
        628,
        629,
        630,
        631,
        632,
        633,
        634,
        635,
        636,
        637,
        638,
        639,
        640,
        641,
        642,
        643,
        644,
        645,
        646,
        647,
        648,
        649,
        650,
        651,
        652,
        653,
        654,
        655,
        656,
        657,
        658,
        659,
        660,
        661,
        662,
        663,
        664,
        665,
        666,
        667,
        668,
        669,
        670,
        671,
        672,
        673,
        674,
        675,
        676,
        677,
        678,
        679,
        680,
        681,
        682,
        683,
        684,
        685,
        686,
        687,
        688,
        689,
        690,
        691,
        692,
        693,
        694,
        695,
        696,
        697,
        698,
        699,
        700,
        701,
        702,
        703,
        704,
        705,
        706,
        707,
        708,
        709,
        710,
        711,
        712,
        713,
        714,
        715,
        716,
        717,
        718,
        719,
        720,
        721,
        722,
        723,
        724,
        725,
        726,
        727,
        728,
        729,
        730,
        731,
        732,
        733,
        734,
        735,
        736,
        737,
        738,
        739,
        740,
        741,
        742,
        743,
        744,
        745,
        746,
        747,
        748,
        749,
        750,
        751,
        752,
        753,
        754,
        755,
        756,
        757,
        758,
        759,
        760,
        761,
        762,
        763,
        764,
        765,
        766,
        767,
        768,
        769,
        770,
        771,
        772,
        773,
        774,
        775,
        776,
        777,
        778,
        779,
        780
      ],
      irradiance: [
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0
      ]
    },

    circadian_response: {
      wavelength: [
        380,
        381,
        382,
        383,
        384,
        385,
        386,
        387,
        388,
        389,
        390,
        391,
        392,
        393,
        394,
        395,
        396,
        397,
        398,
        399,
        400,
        401,
        402,
        403,
        404,
        405,
        406,
        407,
        408,
        409,
        410,
        411,
        412,
        413,
        414,
        415,
        416,
        417,
        418,
        419,
        420,
        421,
        422,
        423,
        424,
        425,
        426,
        427,
        428,
        429,
        430,
        431,
        432,
        433,
        434,
        435,
        436,
        437,
        438,
        439,
        440,
        441,
        442,
        443,
        444,
        445,
        446,
        447,
        448,
        449,
        450,
        451,
        452,
        453,
        454,
        455,
        456,
        457,
        458,
        459,
        460,
        461,
        462,
        463,
        464,
        465,
        466,
        467,
        468,
        469,
        470,
        471,
        472,
        473,
        474,
        475,
        476,
        477,
        478,
        479,
        480,
        481,
        482,
        483,
        484,
        485,
        486,
        487,
        488,
        489,
        490,
        491,
        492,
        493,
        494,
        495,
        496,
        497,
        498,
        499,
        500,
        501,
        502,
        503,
        504,
        505,
        506,
        507,
        508,
        509,
        510,
        511,
        512,
        513,
        514,
        515,
        516,
        517,
        518,
        519,
        520,
        521,
        522,
        523,
        524,
        525,
        526,
        527,
        528,
        529,
        530,
        531,
        532,
        533,
        534,
        535,
        536,
        537,
        538,
        539,
        540,
        541,
        542,
        543,
        544,
        545,
        546,
        547,
        548,
        549,
        550,
        551,
        552,
        553,
        554,
        555,
        556,
        557,
        558,
        559,
        560,
        561,
        562,
        563,
        564,
        565,
        566,
        567,
        568,
        569,
        570,
        571,
        572,
        573,
        574,
        575,
        576,
        577,
        578,
        579,
        580,
        581,
        582,
        583,
        584,
        585,
        586,
        587,
        588,
        589,
        590,
        591,
        592,
        593,
        594,
        595,
        596,
        597,
        598,
        599,
        600,
        601,
        602,
        603,
        604,
        605,
        606,
        607,
        608,
        609,
        610,
        611,
        612,
        613,
        614,
        615,
        616,
        617,
        618,
        619,
        620,
        621,
        622,
        623,
        624,
        625,
        626,
        627,
        628,
        629,
        630,
        631,
        632,
        633,
        634,
        635,
        636,
        637,
        638,
        639,
        640,
        641,
        642,
        643,
        644,
        645,
        646,
        647,
        648,
        649,
        650,
        651,
        652,
        653,
        654,
        655,
        656,
        657,
        658,
        659,
        660,
        661,
        662,
        663,
        664,
        665,
        666,
        667,
        668,
        669,
        670,
        671,
        672,
        673,
        674,
        675,
        676,
        677,
        678,
        679,
        680,
        681,
        682,
        683,
        684,
        685,
        686,
        687,
        688,
        689,
        690,
        691,
        692,
        693,
        694,
        695,
        696,
        697,
        698,
        699,
        700,
        701,
        702,
        703,
        704,
        705,
        706,
        707,
        708,
        709,
        710,
        711,
        712,
        713,
        714,
        715,
        716,
        717,
        718,
        719,
        720,
        721,
        722,
        723,
        724,
        725,
        726,
        727,
        728,
        729,
        730,
        731,
        732,
        733,
        734,
        735,
        736,
        737,
        738,
        739,
        740,
        741,
        742,
        743,
        744,
        745,
        746,
        747,
        748,
        749,
        750,
        751,
        752,
        753,
        754,
        755,
        756,
        757,
        758,
        759,
        760,
        761,
        762,
        763,
        764,
        765,
        766,
        767,
        768,
        769,
        770,
        771,
        772,
        773,
        774,
        775,
        776,
        777,
        778,
        779,
        780
      ],
      irradiance: [
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.002,
        0.002,
        0.002,
        0.002,
        0.003,
        0.003,
        0.004,
        0.004,
        0.005,
        0.005,
        0.006,
        0.007,
        0.008,
        0.009,
        0.01,
        0.011,
        0.013,
        0.015,
        0.017,
        0.02,
        0.023,
        0.026,
        0.031,
        0.036,
        0.041,
        0.046,
        0.052,
        0.058,
        0.064,
        0.072,
        0.08,
        0.089,
        0.101,
        0.113,
        0.126,
        0.137,
        0.147,
        0.157,
        0.166,
        0.176,
        0.187,
        0.199,
        0.212,
        0.226,
        0.24,
        0.254,
        0.267,
        0.28,
        0.293,
        0.307,
        0.321,
        0.336,
        0.352,
        0.369,
        0.386,
        0.402,
        0.416,
        0.431,
        0.445,
        0.459,
        0.474,
        0.49,
        0.506,
        0.522,
        0.538,
        0.554,
        0.569,
        0.584,
        0.599,
        0.614,
        0.63,
        0.645,
        0.661,
        0.677,
        0.692,
        0.708,
        0.724,
        0.739,
        0.755,
        0.77,
        0.785,
        0.801,
        0.816,
        0.832,
        0.847,
        0.86,
        0.873,
        0.885,
        0.896,
        0.907,
        0.918,
        0.928,
        0.939,
        0.949,
        0.958,
        0.966,
        0.972,
        0.978,
        0.983,
        0.987,
        0.991,
        0.993,
        0.996,
        0.998,
        0.999,
        1.0,
        1.0,
        0.998,
        0.997,
        0.994,
        0.992,
        0.989,
        0.984,
        0.979,
        0.972,
        0.966,
        0.959,
        0.951,
        0.942,
        0.932,
        0.922,
        0.912,
        0.901,
        0.889,
        0.876,
        0.863,
        0.849,
        0.834,
        0.818,
        0.802,
        0.785,
        0.769,
        0.752,
        0.735,
        0.717,
        0.7,
        0.682,
        0.664,
        0.646,
        0.628,
        0.609,
        0.591,
        0.573,
        0.555,
        0.537,
        0.519,
        0.502,
        0.484,
        0.467,
        0.449,
        0.433,
        0.416,
        0.399,
        0.383,
        0.367,
        0.352,
        0.337,
        0.322,
        0.307,
        0.293,
        0.279,
        0.266,
        0.253,
        0.24,
        0.228,
        0.216,
        0.204,
        0.193,
        0.182,
        0.172,
        0.162,
        0.153,
        0.143,
        0.135,
        0.126,
        0.119,
        0.111,
        0.104,
        0.097,
        0.09,
        0.084,
        0.079,
        0.073,
        0.068,
        0.063,
        0.059,
        0.054,
        0.05,
        0.047,
        0.043,
        0.04,
        0.037,
        0.034,
        0.032,
        0.029,
        0.027,
        0.025,
        0.023,
        0.021,
        0.019,
        0.018,
        0.016,
        0.015,
        0.014,
        0.013,
        0.012,
        0.011,
        0.01,
        0.009,
        0.008,
        0.008,
        0.007,
        0.007,
        0.006,
        0.006,
        0.005,
        0.005,
        0.004,
        0.004,
        0.004,
        0.003,
        0.003,
        0.003,
        0.003,
        0.002,
        0.002,
        0.002,
        0.002,
        0.002,
        0.002,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0,
        0.0
      ]
    },

    blue_light_hazard: {
      wavelength: [
        380,
        381,
        382,
        383,
        384,
        385,
        386,
        387,
        388,
        389,
        390,
        391,
        392,
        393,
        394,
        395,
        396,
        397,
        398,
        399,
        400,
        401,
        402,
        403,
        404,
        405,
        406,
        407,
        408,
        409,
        410,
        411,
        412,
        413,
        414,
        415,
        416,
        417,
        418,
        419,
        420,
        421,
        422,
        423,
        424,
        425,
        426,
        427,
        428,
        429,
        430,
        431,
        432,
        433,
        434,
        435,
        436,
        437,
        438,
        439,
        440,
        441,
        442,
        443,
        444,
        445,
        446,
        447,
        448,
        449,
        450,
        451,
        452,
        453,
        454,
        455,
        456,
        457,
        458,
        459,
        460,
        461,
        462,
        463,
        464,
        465,
        466,
        467,
        468,
        469,
        470,
        471,
        472,
        473,
        474,
        475,
        476,
        477,
        478,
        479,
        480,
        481,
        482,
        483,
        484,
        485,
        486,
        487,
        488,
        489,
        490,
        491,
        492,
        493,
        494,
        495,
        496,
        497,
        498,
        499,
        500,
        501,
        502,
        503,
        504,
        505,
        506,
        507,
        508,
        509,
        510,
        511,
        512,
        513,
        514,
        515,
        516,
        517,
        518,
        519,
        520,
        521,
        522,
        523,
        524,
        525,
        526,
        527,
        528,
        529,
        530,
        531,
        532,
        533,
        534,
        535,
        536,
        537,
        538,
        539,
        540,
        541,
        542,
        543,
        544,
        545,
        546,
        547,
        548,
        549,
        550,
        551,
        552,
        553,
        554,
        555,
        556,
        557,
        558,
        559,
        560,
        561,
        562,
        563,
        564,
        565,
        566,
        567,
        568,
        569,
        570,
        571,
        572,
        573,
        574,
        575,
        576,
        577,
        578,
        579,
        580,
        581,
        582,
        583,
        584,
        585,
        586,
        587,
        588,
        589,
        590,
        591,
        592,
        593,
        594,
        595,
        596,
        597,
        598,
        599,
        600,
        601,
        602,
        603,
        604,
        605,
        606,
        607,
        608,
        609,
        610,
        611,
        612,
        613,
        614,
        615,
        616,
        617,
        618,
        619,
        620,
        621,
        622,
        623,
        624,
        625,
        626,
        627,
        628,
        629,
        630,
        631,
        632,
        633,
        634,
        635,
        636,
        637,
        638,
        639,
        640,
        641,
        642,
        643,
        644,
        645,
        646,
        647,
        648,
        649,
        650,
        651,
        652,
        653,
        654,
        655,
        656,
        657,
        658,
        659,
        660,
        661,
        662,
        663,
        664,
        665,
        666,
        667,
        668,
        669,
        670,
        671,
        672,
        673,
        674,
        675,
        676,
        677,
        678,
        679,
        680,
        681,
        682,
        683,
        684,
        685,
        686,
        687,
        688,
        689,
        690,
        691,
        692,
        693,
        694,
        695,
        696,
        697,
        698,
        699,
        700,
        701,
        702,
        703,
        704,
        705,
        706,
        707,
        708,
        709,
        710,
        711,
        712,
        713,
        714,
        715,
        716,
        717,
        718,
        719,
        720,
        721,
        722,
        723,
        724,
        725,
        726,
        727,
        728,
        729,
        730,
        731,
        732,
        733,
        734,
        735,
        736,
        737,
        738,
        739,
        740,
        741,
        742,
        743,
        744,
        745,
        746,
        747,
        748,
        749,
        750,
        751,
        752,
        753,
        754,
        755,
        756,
        757,
        758,
        759,
        760,
        761,
        762,
        763,
        764,
        765,
        766,
        767,
        768,
        769,
        770,
        771,
        772,
        773,
        774,
        775,
        776,
        777,
        778,
        779,
        780
      ],
      irradiance: [
        0.01,
        0.01,
        0.011,
        0.012,
        0.012,
        0.012,
        0.015,
        0.018,
        0.02,
        0.022,
        0.025,
        0.03,
        0.035,
        0.04,
        0.045,
        0.05,
        0.06,
        0.07,
        0.08,
        0.09,
        0.1,
        0.12,
        0.14,
        0.16,
        0.18,
        0.2,
        0.24,
        0.28,
        0.32,
        0.36,
        0.4,
        0.48,
        0.56,
        0.64,
        0.72,
        0.8,
        0.82,
        0.84,
        0.86,
        0.88,
        0.9,
        0.91,
        0.92,
        0.93,
        0.94,
        0.95,
        0.956,
        0.962,
        0.968,
        0.974,
        0.98,
        0.984,
        0.988,
        0.992,
        0.996,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        1.0,
        0.994,
        0.988,
        0.982,
        0.976,
        0.97,
        0.964,
        0.958,
        0.952,
        0.946,
        0.94,
        0.932,
        0.924,
        0.916,
        0.908,
        0.9,
        0.88,
        0.86,
        0.84,
        0.82,
        0.8,
        0.78,
        0.76,
        0.74,
        0.72,
        0.7,
        0.684,
        0.668,
        0.652,
        0.636,
        0.62,
        0.606,
        0.592,
        0.578,
        0.564,
        0.55,
        0.53,
        0.51,
        0.49,
        0.47,
        0.45,
        0.44,
        0.43,
        0.42,
        0.41,
        0.4,
        0.364,
        0.328,
        0.292,
        0.256,
        0.22,
        0.208,
        0.196,
        0.184,
        0.172,
        0.16,
        0.148,
        0.136,
        0.124,
        0.112,
        0.1,
        0.096,
        0.092,
        0.087,
        0.083,
        0.079,
        0.076,
        0.073,
        0.069,
        0.066,
        0.063,
        0.06,
        0.058,
        0.055,
        0.053,
        0.05,
        0.048,
        0.046,
        0.044,
        0.042,
        0.04,
        0.038,
        0.037,
        0.035,
        0.034,
        0.032,
        0.031,
        0.029,
        0.028,
        0.026,
        0.025,
        0.024,
        0.023,
        0.022,
        0.021,
        0.02,
        0.019,
        0.018,
        0.018,
        0.017,
        0.016,
        0.015,
        0.015,
        0.014,
        0.014,
        0.013,
        0.012,
        0.012,
        0.011,
        0.011,
        0.01,
        0.01,
        0.009,
        0.009,
        0.008,
        0.008,
        0.008,
        0.007,
        0.007,
        0.006,
        0.006,
        0.006,
        0.006,
        0.005,
        0.005,
        0.005,
        0.005,
        0.005,
        0.004,
        0.004,
        0.004,
        0.004,
        0.004,
        0.003,
        0.003,
        0.003,
        0.003,
        0.003,
        0.002,
        0.002,
        0.002,
        0.002,
        0.002,
        0.002,
        0.002,
        0.002,
        0.002,
        0.002,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001
      ]
    },

    fluorescent_ww: {
      wavelength: [
        380,
        381,
        382,
        383,
        384,
        385,
        386,
        387,
        388,
        389,
        390,
        391,
        392,
        393,
        394,
        395,
        396,
        397,
        398,
        399,
        400,
        401,
        402,
        403,
        404,
        405,
        406,
        407,
        408,
        409,
        410,
        411,
        412,
        413,
        414,
        415,
        416,
        417,
        418,
        419,
        420,
        421,
        422,
        423,
        424,
        425,
        426,
        427,
        428,
        429,
        430,
        431,
        432,
        433,
        434,
        435,
        436,
        437,
        438,
        439,
        440,
        441,
        442,
        443,
        444,
        445,
        446,
        447,
        448,
        449,
        450,
        451,
        452,
        453,
        454,
        455,
        456,
        457,
        458,
        459,
        460,
        461,
        462,
        463,
        464,
        465,
        466,
        467,
        468,
        469,
        470,
        471,
        472,
        473,
        474,
        475,
        476,
        477,
        478,
        479,
        480,
        481,
        482,
        483,
        484,
        485,
        486,
        487,
        488,
        489,
        490,
        491,
        492,
        493,
        494,
        495,
        496,
        497,
        498,
        499,
        500,
        501,
        502,
        503,
        504,
        505,
        506,
        507,
        508,
        509,
        510,
        511,
        512,
        513,
        514,
        515,
        516,
        517,
        518,
        519,
        520,
        521,
        522,
        523,
        524,
        525,
        526,
        527,
        528,
        529,
        530,
        531,
        532,
        533,
        534,
        535,
        536,
        537,
        538,
        539,
        540,
        541,
        542,
        543,
        544,
        545,
        546,
        547,
        548,
        549,
        550,
        551,
        552,
        553,
        554,
        555,
        556,
        557,
        558,
        559,
        560,
        561,
        562,
        563,
        564,
        565,
        566,
        567,
        568,
        569,
        570,
        571,
        572,
        573,
        574,
        575,
        576,
        577,
        578,
        579,
        580,
        581,
        582,
        583,
        584,
        585,
        586,
        587,
        588,
        589,
        590,
        591,
        592,
        593,
        594,
        595,
        596,
        597,
        598,
        599,
        600,
        601,
        602,
        603,
        604,
        605,
        606,
        607,
        608,
        609,
        610,
        611,
        612,
        613,
        614,
        615,
        616,
        617,
        618,
        619,
        620,
        621,
        622,
        623,
        624,
        625,
        626,
        627,
        628,
        629,
        630,
        631,
        632,
        633,
        634,
        635,
        636,
        637,
        638,
        639,
        640,
        641,
        642,
        643,
        644,
        645,
        646,
        647,
        648,
        649,
        650,
        651,
        652,
        653,
        654,
        655,
        656,
        657,
        658,
        659,
        660,
        661,
        662,
        663,
        664,
        665,
        666,
        667,
        668,
        669,
        670,
        671,
        672,
        673,
        674,
        675,
        676,
        677,
        678,
        679,
        680,
        681,
        682,
        683,
        684,
        685,
        686,
        687,
        688,
        689,
        690,
        691,
        692,
        693,
        694,
        695,
        696,
        697,
        698,
        699,
        700,
        701,
        702,
        703,
        704,
        705,
        706,
        707,
        708,
        709,
        710,
        711,
        712,
        713,
        714,
        715,
        716,
        717,
        718,
        719,
        720,
        721,
        722,
        723,
        724,
        725,
        726,
        727,
        728,
        729,
        730,
        731,
        732,
        733,
        734,
        735,
        736,
        737,
        738,
        739,
        740,
        741,
        742,
        743,
        744,
        745,
        746,
        747,
        748,
        749,
        750,
        751,
        752,
        753,
        754,
        755,
        756,
        757,
        758,
        759,
        760,
        761,
        762,
        763,
        764,
        765,
        766,
        767,
        768,
        769,
        770,
        771,
        772,
        773,
        774,
        775,
        776,
        777,
        778,
        779,
        780
      ],
      irradiance: [
        0.019,
        0.02,
        0.021,
        0.021,
        0.022,
        0.023,
        0.024,
        0.025,
        0.026,
        0.028,
        0.029,
        0.029,
        0.03,
        0.031,
        0.032,
        0.032,
        0.039,
        0.046,
        0.053,
        0.06,
        0.066,
        0.144,
        0.221,
        0.299,
        0.377,
        0.454,
        0.376,
        0.298,
        0.22,
        0.142,
        0.064,
        0.062,
        0.06,
        0.057,
        0.055,
        0.053,
        0.054,
        0.055,
        0.056,
        0.057,
        0.058,
        0.059,
        0.06,
        0.061,
        0.063,
        0.064,
        0.065,
        0.066,
        0.067,
        0.068,
        0.069,
        0.255,
        0.442,
        0.628,
        0.814,
        1.0,
        0.853,
        0.706,
        0.559,
        0.412,
        0.265,
        0.229,
        0.193,
        0.157,
        0.12,
        0.084,
        0.085,
        0.086,
        0.087,
        0.088,
        0.089,
        0.09,
        0.091,
        0.092,
        0.092,
        0.093,
        0.094,
        0.094,
        0.095,
        0.096,
        0.096,
        0.097,
        0.097,
        0.098,
        0.098,
        0.099,
        0.099,
        0.099,
        0.1,
        0.1,
        0.1,
        0.101,
        0.101,
        0.101,
        0.101,
        0.102,
        0.102,
        0.102,
        0.102,
        0.102,
        0.102,
        0.102,
        0.102,
        0.102,
        0.102,
        0.102,
        0.102,
        0.103,
        0.103,
        0.103,
        0.104,
        0.103,
        0.103,
        0.102,
        0.102,
        0.101,
        0.101,
        0.1,
        0.1,
        0.099,
        0.099,
        0.099,
        0.099,
        0.099,
        0.099,
        0.098,
        0.099,
        0.099,
        0.099,
        0.099,
        0.099,
        0.1,
        0.101,
        0.102,
        0.103,
        0.104,
        0.105,
        0.107,
        0.109,
        0.111,
        0.113,
        0.116,
        0.119,
        0.122,
        0.126,
        0.129,
        0.134,
        0.139,
        0.144,
        0.15,
        0.155,
        0.162,
        0.17,
        0.177,
        0.184,
        0.192,
        0.202,
        0.212,
        0.222,
        0.232,
        0.242,
        0.343,
        0.443,
        0.544,
        0.645,
        0.746,
        0.697,
        0.647,
        0.598,
        0.548,
        0.499,
        0.491,
        0.483,
        0.475,
        0.467,
        0.458,
        0.475,
        0.491,
        0.507,
        0.523,
        0.539,
        0.555,
        0.57,
        0.586,
        0.601,
        0.617,
        0.63,
        0.643,
        0.656,
        0.669,
        0.682,
        0.706,
        0.73,
        0.754,
        0.778,
        0.802,
        0.815,
        0.828,
        0.841,
        0.854,
        0.867,
        0.848,
        0.828,
        0.808,
        0.789,
        0.769,
        0.767,
        0.764,
        0.762,
        0.76,
        0.758,
        0.752,
        0.747,
        0.742,
        0.736,
        0.731,
        0.723,
        0.715,
        0.707,
        0.699,
        0.691,
        0.681,
        0.671,
        0.661,
        0.651,
        0.642,
        0.631,
        0.619,
        0.608,
        0.597,
        0.586,
        0.574,
        0.563,
        0.551,
        0.54,
        0.528,
        0.518,
        0.508,
        0.497,
        0.487,
        0.476,
        0.464,
        0.452,
        0.439,
        0.427,
        0.415,
        0.404,
        0.393,
        0.382,
        0.372,
        0.361,
        0.352,
        0.342,
        0.333,
        0.324,
        0.314,
        0.306,
        0.297,
        0.288,
        0.279,
        0.27,
        0.262,
        0.255,
        0.247,
        0.239,
        0.232,
        0.225,
        0.218,
        0.211,
        0.205,
        0.198,
        0.192,
        0.186,
        0.181,
        0.175,
        0.169,
        0.164,
        0.159,
        0.154,
        0.149,
        0.144,
        0.14,
        0.135,
        0.131,
        0.126,
        0.122,
        0.118,
        0.114,
        0.111,
        0.107,
        0.103,
        0.1,
        0.097,
        0.094,
        0.09,
        0.087,
        0.085,
        0.082,
        0.079,
        0.077,
        0.074,
        0.072,
        0.07,
        0.067,
        0.065,
        0.063,
        0.062,
        0.06,
        0.059,
        0.058,
        0.056,
        0.054,
        0.052,
        0.05,
        0.048,
        0.046,
        0.045,
        0.043,
        0.042,
        0.04,
        0.039,
        0.038,
        0.037,
        0.036,
        0.035,
        0.034,
        0.033,
        0.032,
        0.031,
        0.03,
        0.029,
        0.028,
        0.027,
        0.026,
        0.025,
        0.024,
        0.024,
        0.023,
        0.022,
        0.022,
        0.021,
        0.02,
        0.02,
        0.019,
        0.018,
        0.018,
        0.018,
        0.017,
        0.017,
        0.017,
        0.016,
        0.016,
        0.016,
        0.016,
        0.015,
        0.015,
        0.015,
        0.015,
        0.014,
        0.014,
        0.014,
        0.014,
        0.013,
        0.013,
        0.013,
        0.012,
        0.012,
        0.012,
        0.012,
        0.012,
        0.012,
        0.012,
        0.012,
        0.011,
        0.011,
        0.011,
        0.011,
        0.011,
        0.011,
        0.011,
        0.012,
        0.012,
        0.012,
        0.012,
        0.012,
        0.012,
        0.012,
        0.011,
        0.011,
        0.011,
        0.01,
        0.01,
        0.01,
        0.009,
        0.009,
        0.009,
        0.008,
        0.008,
        0.007,
        0.007,
        0.006
      ]
    },

    fluorescent_cw: {
      wavelength: [
        380,
        381,
        382,
        383,
        384,
        385,
        386,
        387,
        388,
        389,
        390,
        391,
        392,
        393,
        394,
        395,
        396,
        397,
        398,
        399,
        400,
        401,
        402,
        403,
        404,
        405,
        406,
        407,
        408,
        409,
        410,
        411,
        412,
        413,
        414,
        415,
        416,
        417,
        418,
        419,
        420,
        421,
        422,
        423,
        424,
        425,
        426,
        427,
        428,
        429,
        430,
        431,
        432,
        433,
        434,
        435,
        436,
        437,
        438,
        439,
        440,
        441,
        442,
        443,
        444,
        445,
        446,
        447,
        448,
        449,
        450,
        451,
        452,
        453,
        454,
        455,
        456,
        457,
        458,
        459,
        460,
        461,
        462,
        463,
        464,
        465,
        466,
        467,
        468,
        469,
        470,
        471,
        472,
        473,
        474,
        475,
        476,
        477,
        478,
        479,
        480,
        481,
        482,
        483,
        484,
        485,
        486,
        487,
        488,
        489,
        490,
        491,
        492,
        493,
        494,
        495,
        496,
        497,
        498,
        499,
        500,
        501,
        502,
        503,
        504,
        505,
        506,
        507,
        508,
        509,
        510,
        511,
        512,
        513,
        514,
        515,
        516,
        517,
        518,
        519,
        520,
        521,
        522,
        523,
        524,
        525,
        526,
        527,
        528,
        529,
        530,
        531,
        532,
        533,
        534,
        535,
        536,
        537,
        538,
        539,
        540,
        541,
        542,
        543,
        544,
        545,
        546,
        547,
        548,
        549,
        550,
        551,
        552,
        553,
        554,
        555,
        556,
        557,
        558,
        559,
        560,
        561,
        562,
        563,
        564,
        565,
        566,
        567,
        568,
        569,
        570,
        571,
        572,
        573,
        574,
        575,
        576,
        577,
        578,
        579,
        580,
        581,
        582,
        583,
        584,
        585,
        586,
        587,
        588,
        589,
        590,
        591,
        592,
        593,
        594,
        595,
        596,
        597,
        598,
        599,
        600,
        601,
        602,
        603,
        604,
        605,
        606,
        607,
        608,
        609,
        610,
        611,
        612,
        613,
        614,
        615,
        616,
        617,
        618,
        619,
        620,
        621,
        622,
        623,
        624,
        625,
        626,
        627,
        628,
        629,
        630,
        631,
        632,
        633,
        634,
        635,
        636,
        637,
        638,
        639,
        640,
        641,
        642,
        643,
        644,
        645,
        646,
        647,
        648,
        649,
        650,
        651,
        652,
        653,
        654,
        655,
        656,
        657,
        658,
        659,
        660,
        661,
        662,
        663,
        664,
        665,
        666,
        667,
        668,
        669,
        670,
        671,
        672,
        673,
        674,
        675,
        676,
        677,
        678,
        679,
        680,
        681,
        682,
        683,
        684,
        685,
        686,
        687,
        688,
        689,
        690,
        691,
        692,
        693,
        694,
        695,
        696,
        697,
        698,
        699,
        700,
        701,
        702,
        703,
        704,
        705,
        706,
        707,
        708,
        709,
        710,
        711,
        712,
        713,
        714,
        715,
        716,
        717,
        718,
        719,
        720,
        721,
        722,
        723,
        724,
        725,
        726,
        727,
        728,
        729,
        730,
        731,
        732,
        733,
        734,
        735,
        736,
        737,
        738,
        739,
        740,
        741,
        742,
        743,
        744,
        745,
        746,
        747,
        748,
        749,
        750,
        751,
        752,
        753,
        754,
        755,
        756,
        757,
        758,
        759,
        760,
        761,
        762,
        763,
        764,
        765,
        766,
        767,
        768,
        769,
        770,
        771,
        772,
        773,
        774,
        775,
        776,
        777,
        778,
        779,
        780
      ],
      irradiance: [
        0.014,
        0.013,
        0.012,
        0.011,
        0.01,
        0.009,
        0.009,
        0.008,
        0.007,
        0.007,
        0.006,
        0.006,
        0.005,
        0.005,
        0.005,
        0.005,
        0.007,
        0.01,
        0.012,
        0.015,
        0.017,
        0.05,
        0.083,
        0.116,
        0.149,
        0.182,
        0.149,
        0.116,
        0.083,
        0.049,
        0.016,
        0.016,
        0.015,
        0.015,
        0.014,
        0.014,
        0.014,
        0.015,
        0.015,
        0.015,
        0.016,
        0.017,
        0.017,
        0.018,
        0.019,
        0.02,
        0.021,
        0.022,
        0.024,
        0.025,
        0.026,
        0.106,
        0.185,
        0.265,
        0.344,
        0.424,
        0.362,
        0.3,
        0.239,
        0.177,
        0.115,
        0.1,
        0.085,
        0.069,
        0.054,
        0.039,
        0.039,
        0.039,
        0.039,
        0.039,
        0.04,
        0.039,
        0.039,
        0.039,
        0.039,
        0.039,
        0.038,
        0.038,
        0.037,
        0.037,
        0.036,
        0.036,
        0.035,
        0.035,
        0.034,
        0.034,
        0.033,
        0.033,
        0.032,
        0.031,
        0.031,
        0.03,
        0.03,
        0.029,
        0.028,
        0.028,
        0.031,
        0.034,
        0.038,
        0.041,
        0.044,
        0.067,
        0.09,
        0.112,
        0.135,
        0.158,
        0.161,
        0.164,
        0.167,
        0.17,
        0.173,
        0.159,
        0.144,
        0.13,
        0.115,
        0.1,
        0.09,
        0.08,
        0.07,
        0.06,
        0.05,
        0.044,
        0.039,
        0.033,
        0.027,
        0.022,
        0.02,
        0.018,
        0.017,
        0.015,
        0.013,
        0.013,
        0.012,
        0.012,
        0.011,
        0.01,
        0.01,
        0.01,
        0.009,
        0.009,
        0.009,
        0.009,
        0.009,
        0.009,
        0.009,
        0.009,
        0.011,
        0.012,
        0.013,
        0.015,
        0.016,
        0.026,
        0.036,
        0.046,
        0.056,
        0.067,
        0.154,
        0.241,
        0.328,
        0.415,
        0.502,
        0.592,
        0.683,
        0.773,
        0.864,
        0.954,
        0.849,
        0.745,
        0.64,
        0.535,
        0.43,
        0.365,
        0.3,
        0.235,
        0.17,
        0.104,
        0.093,
        0.081,
        0.069,
        0.057,
        0.045,
        0.043,
        0.041,
        0.04,
        0.038,
        0.036,
        0.035,
        0.035,
        0.034,
        0.034,
        0.033,
        0.041,
        0.05,
        0.058,
        0.066,
        0.074,
        0.094,
        0.114,
        0.134,
        0.154,
        0.175,
        0.184,
        0.194,
        0.204,
        0.214,
        0.224,
        0.22,
        0.217,
        0.214,
        0.211,
        0.208,
        0.201,
        0.194,
        0.187,
        0.18,
        0.173,
        0.166,
        0.158,
        0.15,
        0.143,
        0.135,
        0.144,
        0.153,
        0.162,
        0.171,
        0.18,
        0.344,
        0.508,
        0.672,
        0.836,
        1.0,
        0.955,
        0.909,
        0.864,
        0.819,
        0.774,
        0.662,
        0.55,
        0.438,
        0.326,
        0.214,
        0.213,
        0.212,
        0.212,
        0.211,
        0.21,
        0.211,
        0.212,
        0.213,
        0.214,
        0.215,
        0.191,
        0.166,
        0.142,
        0.118,
        0.094,
        0.083,
        0.072,
        0.06,
        0.049,
        0.038,
        0.038,
        0.039,
        0.039,
        0.04,
        0.04,
        0.044,
        0.048,
        0.053,
        0.057,
        0.061,
        0.059,
        0.057,
        0.055,
        0.052,
        0.05,
        0.048,
        0.047,
        0.045,
        0.043,
        0.041,
        0.04,
        0.039,
        0.038,
        0.036,
        0.035,
        0.033,
        0.031,
        0.028,
        0.026,
        0.024,
        0.023,
        0.022,
        0.021,
        0.021,
        0.02,
        0.02,
        0.021,
        0.021,
        0.021,
        0.022,
        0.024,
        0.026,
        0.027,
        0.029,
        0.031,
        0.032,
        0.032,
        0.033,
        0.034,
        0.034,
        0.031,
        0.029,
        0.026,
        0.023,
        0.021,
        0.021,
        0.022,
        0.022,
        0.023,
        0.023,
        0.034,
        0.044,
        0.054,
        0.064,
        0.074,
        0.079,
        0.085,
        0.091,
        0.096,
        0.102,
        0.091,
        0.08,
        0.069,
        0.058,
        0.047,
        0.039,
        0.032,
        0.025,
        0.018,
        0.01,
        0.009,
        0.008,
        0.007,
        0.006,
        0.004,
        0.004,
        0.004,
        0.004,
        0.004,
        0.004,
        0.004,
        0.004,
        0.004,
        0.003,
        0.003,
        0.004,
        0.004,
        0.004,
        0.004,
        0.004,
        0.004,
        0.004,
        0.004,
        0.004,
        0.004,
        0.004,
        0.004,
        0.003,
        0.003,
        0.003,
        0.003,
        0.003,
        0.003,
        0.003,
        0.002,
        0.003,
        0.003,
        0.003,
        0.003,
        0.003,
        0.003,
        0.003,
        0.003,
        0.003,
        0.003,
        0.003,
        0.003,
        0.002,
        0.002,
        0.002,
        0.002,
        0.002,
        0.002,
        0.002,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001,
        0.001
      ]
    }
  }
}
export default new ReportParameters()
