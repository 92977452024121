import React, { useState, useEffect, SyntheticEvent } from 'react'
import styled from 'styled-components'
import { CompProps, Theme } from '../styles/theme'

import { LoadingContainer, StyledSlider } from '../styles/commons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import 'react-toastify/dist/ReactToastify.css'

import { Grid, Dropdown, Modal, Popup } from 'semantic-ui-react'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import Radar from '../components/charts/Radar'
import SpectraReport from '../components/charts/SpectraReport'
import ReportParameters, { RadarProps } from '../api/ReportParameters'

import { Hour, timeLineApi, TimelineProject } from '../api/TimelineApi'
import Loading from '../components/common/Loading'
import { Project } from '../api/ProjectApi'
import {
  faPauseCircle,
  faPlayCircle
} from '@fortawesome/free-regular-svg-icons'

import { useParams } from 'react-router-dom'
import logo from '../images/kumux-logo.png'

const infoIcon = <FontAwesomeIcon icon={faInfoCircle} />
interface Props {
  /* showTutorial: boolean
  publicId: string
  timelineId: string
  shared: boolean*/
}

const ReportTimelinePublic: React.FC<Props> = (props: Props) => {
  const { publicId } = useParams()
  // const { shared } = props

  const [isLoading, setIsLoading] = useState(false)

  const [rgbRadar, setrgbRadar] = useState<number[][]>()

  const [intervalRange, setintervalRange] = useState(0)

  const [selectShadow, setselectShadow] = React.useState<string>('')
  const Track = (props: any, state: any) => (
    <StyledSPDTrack {...props} index={state.index} />
  )
  const Thumb = (props: any) => <StyledSPDThumb {...props} />

  const ProjectInfoDefault: any = {
    description: '',
    location_coords: [0, 0],
    location_name: '',
    id: '',
    end_time: '',
    reference: ''
  }

  const [projectInfo, setProjectInfo] = useState<Project>(ProjectInfoDefault)
  const [timelines, setTimelines] = useState<TimelineProject>()
  const [hourNow, sethourNow] = useState<Hour>()
  const [radarParameters, setradarParameters] = useState<RadarProps[]>([])
  const [spdData, setSpdData] = useState<number[][]>()
  const [totalHour, setTotalHour] = useState<Hour[]>()
  const [hourIndex, setHourIndex] = useState<number>(-1)

  const options: Array<any> = []

  options.push({ key: 99, text: 'REFERENCE SPD', value: 99 })
  Object.keys(ReportParameters.parametersDropdown).forEach((key, index) => {
    const searchRegExp = /_/g
    const keyFormat = key.replace(searchRegExp, ' ').toUpperCase()
    options.push({ key: index, text: keyFormat, value: index })
  })

  const PauseIcon = <FontAwesomeIcon icon={faPauseCircle} />
  const PlayIcon = <FontAwesomeIcon icon={faPlayCircle} />

  useEffect(() => {
    let didCancel = false
    const getInfoTimeLineReport = async () => {
      setIsLoading(true)
      if (publicId) {
        const timelinesProject: TimelineProject = await timeLineApi.getSharedInfo(
          publicId
        )

        if (timelinesProject) {
          const totalHourFilter: Hour[] = timelinesProject.data.filter(
            (item: any) => item.type !== 0
          )

          !didCancel && setTotalHour(totalHourFilter)
          !didCancel && setTimelines(timelinesProject)
          if (timelinesProject.project) setProjectInfo(timelinesProject.project)
          !didCancel && sethourNow(timelinesProject.data[0])
          const auxDataArray = timelinesProject.data.map((o) =>
            Object.assign({}, o.spd.irradiance)
          )
          !didCancel && setSpdData(auxDataArray)
          !didCancel && setHourIndex(0)
        }
      }

      setIsLoading(false)
    }

    getInfoTimeLineReport()

    return () => {
      didCancel = true
    }
  }, [publicId])

  useEffect(() => {
    if (timelines) {
      const hourNowAux = Object.assign({}, timelines.data[hourIndex])
      sethourNow(hourNowAux)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hourIndex])

  const handleDropdown = (event: SyntheticEvent, data: any) => {
    let keyFormat: string = ''
    if (data.value !== 99) {
      const searchRegExp = / /g
      const optionSelect: any = options.filter(
        (item: any) => item.key === data.value
      )

      keyFormat = String(optionSelect[0].text)
        .replace(searchRegExp, '_')
        .toLowerCase()
    }
    setselectShadow(keyFormat)
  }

  const changeSlider = (valueSlider: number | number[] | undefined | null) => {
    if (
      timelines &&
      valueSlider !== null &&
      valueSlider !== undefined &&
      !Array.isArray(valueSlider)
    ) {
      const itemHourIndex = timelines.data.findIndex(
        (item: Hour) => item.hour24 === valueSlider
      )
      setHourIndex(itemHourIndex)
    }
  }

  const pauseSlider = () => {
    clearInterval(intervalRange)
  }

  const playSlider = () => {
    if (totalHour && timelines) {
      let index = hourIndex
      let runPlay = setInterval(function () {
        if (index === totalHour.length - 1) {
          clearInterval(runPlay)
          runPlay = 0
        } else {
          index++
          setHourIndex(index)
          setintervalRange(runPlay)
        }
      }, 1000)
    }
  }

  useEffect(() => {
    const changeParametersRadar = () => {
      let RadarPropsaux: RadarProps[]

      if (totalHour) {
        RadarPropsaux = totalHour.map((hour: Hour) => {
          let RadarPropsaux: RadarProps = {
            relax: 0,
            focusRadar: 0,
            natural: 0,
            boost: 0,
            create: 0
          }

          if (hour !== undefined && hour.data) {
            const relax = ReportParameters.get_relax(
              hour.data.mp,
              hour.data.rf,
              hour.data.duv
            )
            if (relax) {
              RadarPropsaux.relax = relax
            }
            const create = ReportParameters.get_create(
              hour.data.mp,
              hour.data.rf,
              hour.data.duv
            )
            if (create) {
              RadarPropsaux.create = create
            }
            const natural = ReportParameters.get_natural(
              hour.data.rf,
              hour.data.duv,
              hour.data.cct
            )
            if (natural) {
              RadarPropsaux.natural = natural
            }
            const boost = ReportParameters.get_boost(
              hour.data.mp,
              hour.data.rf,
              hour.data.duv
            )
            if (boost) {
              RadarPropsaux.boost = boost
            }

            const focusRadar = ReportParameters.get_focus(
              hour.data.mp,
              hour.data.rf,
              hour.data.duv
            )

            if (focusRadar) {
              RadarPropsaux.focusRadar = focusRadar
            }
          }

          return RadarPropsaux
        })
        let rgbRadarAux: number[][]
        rgbRadarAux = totalHour.map((hour: Hour) => {
          let rgb: number[] = []
          if (hour !== undefined && hour.data) {
            rgb = ReportParameters.cie_to_rgb(
              hour.data.x,
              hour.data.y,
              undefined
            )
          }
          return rgb
        })

        setrgbRadar(rgbRadarAux)
        setradarParameters(RadarPropsaux)
      }
    }

    changeParametersRadar()
  }, [totalHour])

  return (
    <>
      {isLoading && (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      )}

      {!isLoading && hourNow && (
        <MainContainer>
          <GridMargin>
            {timelines && projectInfo && (
              <TitleWrapper>
                <LogoMobile>
                  <img src={logo} alt="Kumux" />
                </LogoMobile>
                <Grid.Column width={8} className="qr" floated="left">
                  <LogoContainer>
                    <img src={logo} alt="Kumux" />
                  </LogoContainer>
                  <div>
                    <TitleMayus> {timelines.fixture.reference} </TitleMayus>
                    <ReferenceMayus> {timelines.reference} </ReferenceMayus>
                    <HourTitle>
                      {new Intl.DateTimeFormat('en-US', {
                        year: 'numeric',
                        month: 'long'
                      }).format(new Date(timelines.datetime_unix * 1000))}{' '}
                      / {timelines.start_time}:00 - {timelines.end_time}:00{' '}
                    </HourTitle>
                  </div>
                </Grid.Column>
                <ColumnTextRight width={8} floated="right">
                  <TitleMayus>{projectInfo.reference}</TitleMayus>
                  <p> {projectInfo.location_name}</p>
                </ColumnTextRight>
              </TitleWrapper>
            )}
            <Grid.Row columns={1}>
              <ColumnCenter>
                {radarParameters && rgbRadar && (
                  <Radar
                    radarParameters={radarParameters}
                    rgbRadar={rgbRadar}
                    hourIndex={hourIndex}
                  />
                )}
              </ColumnCenter>
            </Grid.Row>
            <GridCenter className="rowwidth">
              <DropDownWrapper className="columndropdown" width={8}>
                <DropdownReport
                  selection
                  defaultValue={99}
                  options={options}
                  onChange={(event: SyntheticEvent, data: object) =>
                    handleDropdown(event, data)
                  }
                />
              </DropDownWrapper>
              <Grid.Column className="columninfo" width={8}>
                {hourNow && (
                  <ContainerInfo>
                    {}{' '}
                    <Popup
                      content={
                        <div>
                          <b> CES:</b>
                          {hourNow.data && hourNow.data.ces_skin}<br />{' '}
                          <b>COI: </b>
                          {hourNow.data && hourNow.data.coi} <br />{' '}
                          <b>CLA @100&nbsp;lux: </b>
                          {hourNow.data && hourNow.data.cla_100} <br />{' '}
                          <b>CS @100&nbsp;lux: </b>
                          {hourNow.data && hourNow.data.cs_100} <br />{' '}
                          <b>EML @100&nbsp;lux: </b>
                          {hourNow.data && (hourNow.data.eml_100) ? hourNow.data.eml_100 : '-'} <br />{' '}
                          <b>MEDI @100&nbsp;lux: </b>
                          {hourNow.data && (hourNow.data.medi_100) ? hourNow.data.medi_100 : '-'} <br />{' '}
                          <b>DUV: </b>
                          {hourNow.data && hourNow.data.duv} <br />{' '}
                          <b>LUMINOUS FLUX LM: </b>
                          {hourNow.data && hourNow.data.luminous_flux_lm}
                          <br />
                          <b>MDER: </b>
                          {hourNow.data && hourNow.data.mder} <br />
                          <b>MP: </b>
                          {hourNow.data && hourNow.data.mp}
                          <br /> <b>PF W: </b>
                          {hourNow.data && hourNow.data.pf_w}
                          <br /> <b>R9: </b>
                          {hourNow.data && hourNow.data.r9}
                          <br /> <b>RF: </b>
                          {hourNow.data && hourNow.data.rf}
                          <br /> <b>RG: </b>
                          {hourNow.data && hourNow.data.rg} <br />
                          <b>UL: </b>
                          {hourNow.data && hourNow.data.ul} <br />
                          <b>X: </b>
                          {hourNow.data && hourNow.data.x} <br />
                          <b>Y: </b>
                          {hourNow.data && hourNow.data.y}{' '}
                        </div>
                      }
                      trigger={infoIcon}
                    />
                    <span>
                      CRI: <b> {hourNow.data && hourNow.data.cri}</b>
                    </span>{' '}
                    <span>
                      CCT: <b> {hourNow.data && hourNow.data.cct}</b>
                    </span>{' '}
                    <span>
                      EFF:{' '}
                      <b> {hourNow.data && hourNow.data.efficiency_lmw} </b>
                    </span>
                  </ContainerInfo>
                )}
              </Grid.Column>
              <ContainerScenes className="spectrareport" width={16}>
                <ContainerReport>
                  {spdData && (
                    <SpectraReport
                      hourIndex={hourIndex}
                      irradianceData={spdData}
                      canvas_width={'300px'}
                      compare_spd={
                        selectShadow !== '' &&
                        ReportParameters.parametersDropdown[
                          selectShadow as keyof typeof ReportParameters.parametersDropdown
                        ]
                      }
                    />
                  )}
                  {hourNow && (
                    <ContainerRange>
                      <StyledSPDSlider
                        min={totalHour && totalHour[0].hour24}
                        max={
                          totalHour && totalHour[totalHour.length - 1].hour24
                        }
                        renderTrack={Track}
                        renderThumb={Thumb}
                        value={hourNow.hour24}
                        onChange={(val) => changeSlider(val)}
                      />
                    </ContainerRange>
                  )}
                  <ContainerInfoScene>
                    <ContainerIcons>
                      <ContainerPause onClick={() => pauseSlider()}>
                        {PauseIcon}
                      </ContainerPause>{' '}
                      <ContainerPlay onClick={() => playSlider()}>
                        {PlayIcon}
                      </ContainerPlay>
                    </ContainerIcons>

                    <ContainerNameScene>
                      <h4>{hourNow.hour24}:00 </h4>
                      <p> {hourNow.scene_name} </p>
                    </ContainerNameScene>
                  </ContainerInfoScene>
                </ContainerReport>
              </ContainerScenes>
            </GridCenter>
          </GridMargin>
        </MainContainer>
      )}
    </>
  )
}

const LogoContainer = styled.div`
  margin-right: 30px;
  height: 100%;
  width: 78px;
  @media (max-width: 767px) {
    display: none;
  }
`

const TitleWrapper = styled(Grid.Row)`
  margin-left: 30px;
  margin-right: 30px;
  align-items: end;
`

const DropDownWrapper = styled(Grid.Column)`
  z-index: 102;
`

const DropdownReport = styled(Dropdown)`
  padding: 0.55714em 1.1em 0.58571429em 1em !important;
  min-height: 0px !important;
  .icon {
    top: 0.58571429em !important;
  }
  font-size: 13px;
  color: ${(props: CompProps) =>
    (props.theme as Theme).color.neutral._700}!important;
  .text {
    font-size: 13px;
  }
`

const ContainerNameScene = styled.div`
  p {
    text-transform: uppercase;
    font-weight: bolder;
  }
  h4 {
    font-size: 24px;
    margin-bottom: 5px;
  }
  p {
    font-size: 14px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const ContainerPause = styled.span`
  svg {
    color: ${(props: CompProps) => (props.theme as Theme).color.neutral._700};
  }
  svg:hover {
    color: ${(props: CompProps) => (props.theme as Theme).color._900};
    cursor: pointer;
  }
`

const ReferenceMayus = styled.h4`
  text-transform: uppercase;
  font-size: 24px;
  line-height: 0px;
  font-weight: normal;
`

const HourTitle = styled.p`
  ${(props) => (props.theme as Theme).font_size.xxsmall}
  color: ${(props: CompProps) => (props.theme as Theme).color.neutral._700};
  padding-top: 5px;
`

const TitleMayus = styled.p`
  text-transform: uppercase;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;

  color: ${(props: CompProps) => (props.theme as Theme).color.neutral._700};
`

const ContainerInfo = styled.div`
  cursor: help;
  svg {
    margin-right: 5px;
  }
  span {
    color: ${(props: CompProps) => (props.theme as Theme).color.neutral._700};
  }
  span > b {
    color: rgba(0, 0, 0, 0.87);
  }
  @media (max-width: 767px) {
    span {
      font-size: 0.85rem;
    }
  }
`

const ContainerPlay = styled.span`
  svg:hover {
    color: ${(props: CompProps) => (props.theme as Theme).color._900};
    cursor: pointer;
  }
  svg {
    color: ${(props: CompProps) => (props.theme as Theme).color.neutral._700};
  }
`

const ContainerReport = styled.div`
  width: 100%;
  height: 250px;
`

const ContainerRange = styled.div`
  position: relative;
  top: -15px;
  z-index: 1;
`

const StyledSPDSlider = styled(StyledSlider)`
  height: 5px;
  z-index: 100;
`

export const StyledSPDThumb = styled.div`
  height: 20px;
  width: 20px;
  text-align: center;
  background-color: ${(props: CompProps) =>
    (props.theme as Theme).color.white.regular};
  border: 3px solid ${(props: CompProps) => (props.theme as Theme).color._900};
  border-radius: 50%;
  cursor: grab;
  top: -7px;
  outline: 0;
  position: relative;
`
export const StyledSPDTrack = styled.div`
  top: 0;
  bottom: 0;
  background: ${(props: any) =>
    props.index === 2
      ? (props: CompProps) => (props.theme as Theme).color._500
      : props.index === 1
      ? (props: CompProps) => (props.theme as Theme).color.white.darkest
      : (props: CompProps) => (props.theme as Theme).color._500};
  border-radius: 999px;
`

const ContainerScenes = styled(Grid.Column)`
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
`

const ContainerIcons = styled.div`
  font-size: 20px;
  float: right;
`

const ColumnTextRight = styled(Grid.Column)`
  text-align: right;
  .reference {
    font-size: 18px;
    margin-bottom: 10px;
  }
  p {
    font-size: 16px;
  }
`
const LogoMobile = styled.div`
  display: none;
  @media (max-width: 767px) {
    width: 100%;

    display: flex;
    margin-bottom: 20px;
    justify-content: right;
    padding-left: 1rem;
    padding-right: 1rem;
    img {
      width: 75px;
    }
  }
`
const ColumnCenter = styled(Grid.Column)`
  display: flex !important;
  justify-content: center !important;
`

const GridMargin = styled(Grid)`
  @media (max-width: 767px) {
    margin-top: 0px !important;
  }
  margin-top: 15px !important;
  .spectrareport {
    margin-top: 40px !important;
  }
  &&& .rowwidth {
    width: 85% !important;
    align-items: center;
  }
  .qr {
    display: flex !important;
    align-items: flex-end !important;
  }
  @media (max-width: 767px) {
    .qr {
    }
    &&& .rowwidth {
      width: 100% !important;
      align-items: center;
    }
  }
`

const GridCenter = styled(Grid.Row)`
  text-align: center;

  margin: 0 auto;
  .columndropdown {
    text-align: left;
  }
  .columninfo {
    text-align: right;
  }
  @media (max-width: 767px) {
  }
`

const ContainerInfoScene = styled.div`
  width: 100%;
  line-height: 1em;
`

const MainContainer = styled(Modal.Content)`
  max-width: 760px;
  margin: 60px auto;
`

export default ReportTimelinePublic
