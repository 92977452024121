import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Chart } from 'chart.js'
import { RadarProps } from '../../api/ReportParameters'

interface Props {
  radarParameters: RadarProps[]
  hourIndex: number
  rgbRadar: number[][]
}

const Radar: React.FC<Props> = (props) => {
  const chartCanvasRef = React.useRef<HTMLCanvasElement>(null)
  const [previousIndex, setPreviousIndex] = useState<number>(0)
  const { radarParameters, hourIndex, rgbRadar } = props

  useEffect(() => {
    Object.values(props.radarParameters[props.hourIndex]) &&
      drawChart(chartCanvasRef)
    setPreviousIndex(hourIndex)
    // eslint-disable-next-line
  }, [hourIndex])

  const drawChart = (canvasRef: React.RefObject<HTMLCanvasElement>) => {
    let canvas: HTMLCanvasElement | null = canvasRef.current
      ? canvasRef.current
      : null

    let radarCanvas: CanvasRenderingContext2D | null = canvasRef.current
      ? canvasRef.current.getContext('2d')
      : null

    let gradientColor: any

    const gradientBlueAux =
      canvasRef.current && canvasRef.current
        ? canvasRef.current.getContext('2d')
        : null
    if (gradientBlueAux) {
      gradientColor = gradientBlueAux.createLinearGradient(0, 0, 0, 1)

      gradientColor.addColorStop(
        1,
        'rgba(' +
          rgbRadar[hourIndex][0] +
          ', ' +
          rgbRadar[hourIndex][1] +
          ', ' +
          rgbRadar[hourIndex][2] +
          ', 0.6)'
      )
    }

    if (radarCanvas && canvas) {
      //BLUE HOVER

      var shadowed = {
        beforeDatasetsDraw: function (chart: any, options: any) {
          chart.ctx.shadowColor =
            'rgba(' +
            rgbRadar[hourIndex][0] +
            ', ' +
            rgbRadar[hourIndex][1] +
            ', ' +
            rgbRadar[hourIndex][2] +
            ',1)'
          chart.ctx.shadowBlur = 60
        },
        afterDatasetsDraw: function (chart: any, options: any) {
          chart.ctx.shadowColor =
            'rgba(' +
            rgbRadar[hourIndex][0] +
            ', ' +
            rgbRadar[hourIndex][1] +
            ', ' +
            rgbRadar[hourIndex][2] +
            ',1)'
          chart.ctx.shadowBlur = 60
        }
      }

      const chartGraph = new Chart(radarCanvas, {
        type: 'radar',
        data: {
          labels: ['RELAX', 'FOCUS', 'NATURAL', 'BOOST', 'CREATE'],
          datasets: [
            {
              data: Object.values([
                radarParameters[previousIndex].relax,
                radarParameters[previousIndex].focusRadar,
                radarParameters[previousIndex].natural,
                radarParameters[previousIndex].boost,
                radarParameters[previousIndex].create
              ]),
              fill: true,
              backgroundColor: gradientColor,
              pointBackgroundColor: 'transparent',
              pointBorderColor: 'transparent',
              pointHoverBackgroundColor: 'transparent',
              pointHoverBorderColor: 'transparent',
              pointHitRadius: 50,
              borderColor: 'rgba(0,0,0,0.1)',
              borderJoinStyle: 'round',
              borderWidth: 1
            }
          ]
        },
        options: {
          animation: { duration: 0 },
          legend: {
            display: false
          },
          tooltips: {
            enabled: true
          },
          scale: {
            pointLabels: {
              fontSize: 14,
              fontColor: '#A0A0A0',
              fontFamily: 'roboto'
            },
            ticks: {
              maxTicksLimit: 1,
              display: false,
              suggestedMin: 0,
              suggestedMax: 100
            }
          }
        },
        plugins: [shadowed]
      })

      setTimeout(() => {
        if (
          chartGraph &&
          chartGraph.data &&
          chartGraph.data.datasets &&
          Object.values(radarParameters[hourIndex]).length > 0
        ) {
          chartGraph.options.animation &&
            (chartGraph.options.animation.duration = 1000)

          const newDataset = Object.values([
            radarParameters[hourIndex].relax,
            radarParameters[hourIndex].focusRadar,
            radarParameters[hourIndex].natural,
            radarParameters[hourIndex].boost,
            radarParameters[hourIndex].create
          ])

          newDataset.forEach((dataValue: number, index: number) => {
            chartGraph.data.datasets &&
            chartGraph.data.datasets[0] &&
            chartGraph.data.datasets[0].data &&
            chartGraph.data.datasets[0].data[index] &&
            (chartGraph.data.datasets[0].data[index] = dataValue)
          })

          chartGraph.update()
        }
      }, 100)
    }
  }

  return (
    <ContainerRadar>
      <canvas id="chart" ref={chartCanvasRef} />
    </ContainerRadar>
  )
}

const ContainerRadar = styled.div`
  width: 505px;
  margin-bottom: 30px;
  margin-top: 15px;
  #chart {
    pointer-events: none !important;
  }
  @media (max-width: 767px) {
    width: 400px;
  }
`

export default Radar
