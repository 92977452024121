import React, { useEffect, useRef } from 'react'
import { Chart } from 'chart.js'

import { ChartProps } from './ChartStatic'

const ChannelsPolar: React.FC<ChartProps> = (props) => {

    const chartCanvasRef = useRef<HTMLCanvasElement>(null)

    useEffect(() => {
        const canvas: HTMLCanvasElement | null = chartCanvasRef.current
        canvas && drawChart(canvas)
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.chartData])

    const drawChart = (canvasRef: HTMLCanvasElement) => {
        const ctx: CanvasRenderingContext2D | null = canvasRef.getContext("2d")
        if(ctx) {
            const chartGraph = new Chart(ctx, {
            type: 'polarArea',
            data: {
                labels: props.chartData[0].map((channel, index) => ('ch' + index)),
                datasets: [{
                    data: props.chartData[0].map((channel, index) => (channel*100)),
                    backgroundColor: [
                        'rgb(66, 185, 189, 0.5)',
                        'rgb(199, 141, 56, 0.5)',
                        'rgb(145, 63, 192, 0.5)',
                        'rgb(195, 60, 113, 0.5)',
                        'rgb(196, 59, 184, 0.5)',
                        'rgb(199, 72, 56, 0.5)',
                        'rgb(76, 61, 194, 0.5)',
                        'rgb(75, 199, 56, 0.5)'
                    ]
                }]
            },
            options: {
                legend: {
                    display: true,
                    position: 'right',
                    labels: {
                        padding: 15
                    }
                },
                scales: {
                    
                },
                responsive: false,
                animation: {
                    duration: 1,
                    onComplete: () => {
                        props.callBack(chartGraph.toBase64Image())
                        Object.defineProperty(chartGraph.options.animation, 'onComplete', {value: undefined})
                    }
                }
            }
            })
        }  
    }

    return(
        <canvas ref={chartCanvasRef} width={340} height={340} />
    )
}

export default ChannelsPolar
