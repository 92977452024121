import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { CompProps, Theme } from '../styles/theme'
import { SmallButtonAction } from '../styles/commons'
import { userAPI } from '../api/UserApi'
import { useHistory } from 'react-router-dom'
import { Auth } from '../App'

import TermsPolicy from './TermsPolicy'
import TermsTrialPolicy from './TermsTrialPolicy'

interface Props {
  auth: Auth
}

const TermsAndConditions: React.FC<Props> = (props) => {
  const { auth } = props
  const [acceptedPolicies, setAcceptedPolicies] = useState({
    policy: false,
    cookies: false
  })
  const [disableSend, setDisableSend] = useState(true)
  const history = useHistory()

  useEffect(() => {
    if (acceptedPolicies.policy) {
      setDisableSend(false)
    } else {
      setDisableSend(true)
    }
  }, [acceptedPolicies])

  const sendTermsConsent = async () => {
    const response = await userAPI.acceptTermsAndConditions()

    if (response) {
      props.auth.user!.confirmed_terms = true

      history.push('/')
    }
  }

  return (
    <ConditionsContainer>
      <TextContainer>
        {auth.user !== null && auth.user.is_trial === true ? (
          <TermsTrialPolicy />
        ) : (
          <TermsPolicy />
        )}
      </TextContainer>
      <CheckboxContainer
        onClick={() =>
          setAcceptedPolicies({
            ...acceptedPolicies,
            policy: !acceptedPolicies.policy
          })
        }
      >
        <input
          type="checkbox"
          name="policy"
          checked={acceptedPolicies.policy}
          onChange={(e) => {}}
        />
        <label htmlFor="policy">Accept Terms and Conditions</label>
      </CheckboxContainer>
      <SmallButtonAction
        disabled={disableSend}
        onClick={() => sendTermsConsent()}
      >
        Send
      </SmallButtonAction>
    </ConditionsContainer>
  )
}

const ConditionsContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  width: 80%;
  margin: 10px auto;
`
const TextContainer = styled.div`
  height: 666px;
`

const CheckboxContainer = styled.div`
  display: block;
  margin: 10px 0;
  position: relative;
  margin-bottom: 30px;
  label {
    padding: 12px 30px;
    width: 100%;
    display: block;
    text-align: left;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: color 200ms ease-in;
    overflow: hidden;
    &:before {
      width: 100%;
      height: 10px;
      border-radius: 50%;
      content: '';
      background-color: ${(props: CompProps) =>
        (props.theme as Theme).color._900};
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale3d(1, 1, 1);
      transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
      opacity: 0;
      z-index: -1;
    }

    &:after {
      width: 32px;
      height: 32px;
      content: '';
      border: 2px solid #d1d7dc;
      background-color: #fff;
      background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: 2px 3px;
      border-radius: 50%;
      z-index: 2;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      transition: all 200ms ease-in;
    }
  }
  input:checked ~ label {
    color: white;
    &:before {
      transform: translate(-50%, -50%) scale3d(56, 56, 1);
      opacity: 1;
    }

    &:after {
      background-color: ${(props: CompProps) =>
        (props.theme as Theme).color._500};
      border-color: ${(props: CompProps) => (props.theme as Theme).color._500};
    }
  }

  input {
    width: 32px;
    height: 32px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
  }
`
export default TermsAndConditions
