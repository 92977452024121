import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

interface Props {
  name?: string
  value: number
  prevValue?: number
  nextValue?: number
  number?: number
  scaleStart: number
  scaleEnd: number
  type: number
  updateCallBack: Function
}

const Line: React.FC<Props> = (props) => {

  const map = (value: number, x1: number, y1: number, x2: number, y2: number) =>
    ((value - x1) * (y2 - x2)) / (y1 - x1) + x2

  const isBetween = (n: number, a: number, b: number) => (n - a) * (n - b) <= 0

  const translateValueToWorldCoords = (value: number) => {
    const result: number = worldHeight - map(value, scaleStart, scaleEnd, worldMargin, worldHeight-worldMarginTop)
    return result
  }

  const translateWorldCoordsToValue = (value: number) => {
    return Math.round(map(value, worldMargin, worldHeight-worldMarginTop, scaleStart, scaleEnd))
  }

  let { number, name, updateCallBack, type, scaleStart, scaleEnd } = props

  const SvgRef = React.useRef<SVGSVGElement>(null)
  const [dragging, setDragging] = useState(false)

  const [value, setValue] = useState(props.value)

  const worldHeight: number = 150
  const worldMarginTop: number = 35
  const worldMargin: number = 15
  // const worlwidth: number = 150

  const [posY, setPosY] = useState(-1)
  const [rectStartPos, setRectStartPos] = useState(
    translateValueToWorldCoords(value)
  )
  const [rectEndPos, setRectEndPos] = useState(rectStartPos)

  const updateCirclePosition = (newPosY: number) => {
    const newPosValue: number = translateWorldCoordsToValue(worldHeight - newPosY)
    // Check limits
    if(isBetween(newPosValue, props.scaleStart, props.scaleEnd)) {
      if (props.nextValue && props.prevValue && isBetween(newPosValue, props.nextValue, props.prevValue)) {
        // Update circle information
        setPosY(newPosY)
        setValue(newPosValue)

        // Update rect information
        setRectStartPos(newPosY)

        // Update parent data
        updateCallBack(number, name, newPosValue)
      }
    }
  } 

  const upEvent = (e: any) => {
    if (dragging) {
      setDragging(false)
      // Update parent data & require api call
      updateCallBack(number, name, value, true)
    }
  }

  const downEvent = (e: any) => {
    if (type === 2 && SvgRef.current) {
      const newPosX: number = e.clientX - SvgRef.current.getBoundingClientRect().x
      const newPosY: number = e.clientY - SvgRef.current.getBoundingClientRect().y
      if(isBetween(newPosY, posY-10, posY+10) && isBetween(newPosX, 90, 110)) {
        setDragging(true)
        updateCirclePosition(newPosY)
      }
    }
  }

  const moveEvent = (e: any) => {
      if (dragging && SvgRef.current) {
        const newPosY: number = e.clientY - SvgRef.current.getBoundingClientRect().y
        updateCirclePosition(newPosY)
    }
  }

  useEffect(() => {
    props.nextValue &&
      setRectEndPos(translateValueToWorldCoords(props.nextValue))
    props.value && setPosY(translateValueToWorldCoords(props.value))
    props.value && setRectStartPos(translateValueToWorldCoords(props.value))
    props.value && setValue(props.value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  return (
    <SVGContainer
      ref={SvgRef}
      height="150"
      width="350"
      onMouseUp={upEvent}
      onMouseLeave={upEvent}
      onMouseDown={downEvent}
      onMouseMove={moveEvent}
    >
      {props.nextValue && (
        <line
          stroke="#D3AAF3"
          strokeWidth="2"
          x1="100"
          y1={rectStartPos}
          x2="350"
          y2={rectEndPos}
        />
      )}

      <CircleSVG
        fill={type && type === 2 ? '#D3AAF3' : '#FFF'}
        stroke="#D3AAF3"
        strokeWidth={type && type === 2 ? 0 : 2}
        className={type && type === 1 ? 'block' : 'noblock'}
        cx="100"
        cy={posY}
        r={type && type === 2 ? 9 : 7}
      />

      <text fontSize="smaller" x="160" y="38" fill="#BA82E7" textAnchor="end">
        {value}
      </text>
      <text fontSize="smaller" x="210" y="38" fill="#bfbfbf" textAnchor="end">
        {scaleEnd}
      </text>
      <text
        fontSize="smaller"
        x="210"
        y="140"
        fill="#bfbfbf"
        textAnchor="end"
      >
        {scaleStart}
      </text>
    </SVGContainer>
  )
}

const CircleSVG = styled.circle`
  cursor: move;
  .block {
    cursor: inherit !important;
  }
`
const SVGContainer = styled.svg`
  .block {
    cursor: inherit !important;
  }
`

export default Line
