import API, { STATUS } from './api'
import { AxiosResponse } from 'axios'

import Errors from './Errors'
import { ErrorText } from './ErrorString'

export interface Historic {
  brand: string[]
  company: string[]
  date: string
  limit: number[]
  name: string[]
  number: number[]
  reference: string
  public_id: string
  // simulation: UserSimulation
  filename?: string
  led_id?: string[]
  deleteConfirm?: boolean
}
export interface Channel {
  id: string
  type: string
  family: string
  brand: string
  reference: string
  serial: number
  parallel: number
  limit: number
  if_ma: number
  luminous_flux_lm: number
  pf_w: number
   
}

export interface FamilyLed {
  id: string
  reference: string
  total_leds: number
}
export interface Led {
  [key: string]: any
  id: string
  reference: string
  data: { radiant_flux_mw: 0; if_ma: 0; tj_c: 0; vf_v: 0 }
  brand: string
  deleteConfirm?: boolean
  new?: boolean
  type: string
  family: string
  public_id: string
  company: string
  irradiance_norm?: []
  if_ma: number
  cct_k: number
  cri: number
  rf: number
  duv:number
  rg: number
  r9: number
  coi:number
  efficiency_lmw: number
  mp_ratio: number
  mder: number
  cs_100: number
  cla_100: number
  ul: number
  pf_w: number
  luminous_flux_lm: number
  vf_v: number
  peak_wavelength: number
  eml_100: number
  ler_lmw: number
  medi_100: number
  electrical_power_w:number
}

export interface ListLeds {
  user: Led[]
  catalog: Led[]
}

export interface LedCatalog extends Led {}

class LedApi {
  createLedUser = async (LedForm: object): Promise<any> => {
    try {
      const response: AxiosResponse = await API.post('users_leds/new ', {
        ...LedForm
      })

      if (response.status === STATUS.OK) {
        return response.data
      }
    } catch (error) {
      Errors.ShowError(ErrorText.New_led.insert)
      console.error(error)

      // Errors.ShowError(error)
      return false
    }

    return false
  }
  DownloadExcelLeds = async (): Promise<any | null> => {
    try {
      const response: AxiosResponse = await API.get('users_leds/docs/xls', {
        responseType: 'blob'
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'ledlist.xlsx') //or any other extension
      document.body.appendChild(link)
      link.click()

      if (response.status === STATUS.OK) {
        return response.data.content
      }
    } catch (error) {
      console.error(error)
      return null
    }
  }

  getFamilies = async (): Promise<[]> => {
    try {
      const response: AxiosResponse = await API.get(
        'users_leds/available_families'
      )

      if (response.status === STATUS.OK) {
        return response.data.content.families
      }
    } catch (error) {
      console.error(error)
      return []
    }

    return []
  }
  /*CATALOG LED */
  getCompaniesCatalog = async (): Promise<[]> => {
    try {
      const response: AxiosResponse = await API.get('leds/available_companies')

      if (response.status === STATUS.OK) {
        return response.data.content.companies
      }
    } catch (error) {
      console.error(error)
      return []
    }

    return []
  }
  getLedsbyCompanies = async (compaines: string | undefined): Promise<any> => {
    try {
      const response: AxiosResponse = await API.get(
        `leds/available_leds/${compaines}`
      )

      if (response.status === STATUS.OK) {
        return response.data.content.leds
      }

      return []
    } catch (error) {
      console.error(error)
      return []
    }
  }

  getLedsByBrand = async (brand: string | undefined): Promise<any> => {
    try {
      const response: AxiosResponse = await API.get(
        `leds/available_leds/${brand}`
      )

      if (response.status === STATUS.OK) {
        return response.data.content.leds
      }

      return []
    } catch (error) {
      console.error(error)
      return []
    }
  }
  /* CATALOG LED*/

  getBrands = async (family_id: string | undefined): Promise<any> => {
    try {
      const response: AxiosResponse = await API.get(
        `users_leds/available_brands/${family_id}`
      )

      if (response.status === STATUS.OK) {
        return response.data.content.brands
      }
    } catch (error) {
      console.error(error)
      return []
    }

    return []
  }

  getLedsByFamily = async (family_id: string | undefined): Promise<any> => {
    try {
      const response: AxiosResponse = await API.get(
        `users_leds/available_leds/${family_id}`
      )

      if (response.status === STATUS.OK) {
        return response.data.content.leds
      }

      return []
    } catch (error) {
      console.error(error)
      return []
    }
  }

  getInfoLed = async (led_id: string | undefined): Promise<any> => {
    try {
      const response: AxiosResponse = await API.get(
        `users_leds/led_info/${led_id}`
      )
        
      if (response.status === STATUS.OK) {
        return response.data.content
      }

      return []
    } catch (error) {
      console.error(error)
      return []
    }
  }
  getInfoLedCatalog = async (led_id: string | undefined): Promise<any> => {
    try {
      const response: AxiosResponse = await API.get(`leds/led_info/${led_id}`)

      if (response.status === STATUS.OK) {
        return response.data.content
      }

      return []
    } catch (error) {
      console.error(error)
      return []
    }
  }

  deleteLedById = async (publicId: string): Promise<any> => {
    try {
      const response: AxiosResponse = await API.delete(
        `users_leds/delete_recursive/${publicId}`
      )

      if (response.status === STATUS.OK) {
        return response.data
      }
    } catch (error) {
      console.error(error)

      return false
    }

    return false
  }
  dependency_check = async (publicId: string): Promise<any> => {
    try {
      const response: AxiosResponse = await API.get(
        `users_leds/dependency_check/${publicId}`
      )

      if (response.status === STATUS.OK) {
        return response.data.content.fixtures
      }
    } catch (error) {
      console.error(error)

      return false
    }

    return false
  }
}

export const ledApi = new LedApi()
