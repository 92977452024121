import React, { useEffect, useState } from 'react'
import SpectraLineBars from '../charts/SpectraLineBars'
import Loading from '../common/Loading'

import styled from 'styled-components'
import {
  InputText,
  InputWrapper,
  SmallButtonAction
} from '../../styles/commons'
import { FilterSections, InfoScene, Spd } from './types'
import Tour from '../help/Tour'
import HelpContent from '../help/HelpContent'
import { Icon, Progress } from 'semantic-ui-react'
import { HCLRadarInfo } from '../charts/types'
import CoverFlowChartItem from '../coverflow/CoverFlowChartItem'
import { CompProps, Theme } from '../../styles/theme'
import { simulationApi } from '../../api/SimulationApi'
import ReportParameters from '../../api/ReportParameters'
import AnalyticsListener from '../../api/AnalyticsListener'
import { sceneApi } from '../../api/SceneApi'
interface Props {
  activeTour: boolean
  loading: boolean
  callbackOnChangeName: Function
  newSceneName: string
  disableNewScene: boolean

  filtersSections: FilterSections | undefined
  illuminance: number[]
  luminaryId: string
  spectrumId: string
  callbackSavedSpectra: Function
  savedSpectra: any
  callBackPopupCreate: Function
  callbackCreatingSceneLoading: Function
}
const ModalCreateScene: React.FC<Props> = (props) => {
  const {
    loading,
    callbackOnChangeName,
    newSceneName,
    disableNewScene,
    savedSpectra,
    illuminance,

    callbackSavedSpectra,
    luminaryId,
    spectrumId,
    callBackPopupCreate,
    callbackCreatingSceneLoading
  } = props
  const [tourActive, setTourActive] = useState(props.activeTour)
  const [hclInfo, setHclInfo] = useState<HCLRadarInfo>()
  const [infoScene, setInfoScene] = useState<InfoScene>()
  const [infoSpd, setinfoSpd] = useState<Spd>()
  const [channels, setchannels] = useState<number[]>([])

  useEffect(() => {
    let didCancel = false
    const getSpectra = async () => {
      const sceneResponse = await simulationApi.getOneSpectra(
        luminaryId,
        spectrumId
      )
      !didCancel && setInfoScene(sceneResponse.data)
      !didCancel && setinfoSpd(sceneResponse.spd)
      !didCancel && setchannels(sceneResponse.data.combination)
    }

    getSpectra()
    return () => {
      didCancel = true
    }
  }, [luminaryId, spectrumId])

  useEffect(() => {
    infoScene !== undefined &&
      setHclInfo({
        relax: ReportParameters.get_relax(
          infoScene.mp,
          infoScene.rf,
          infoScene.duv
        ),
        focus: ReportParameters.get_focus(
          infoScene.mp,
          infoScene.rf,
          infoScene.duv
        ),
        natural: ReportParameters.get_natural(
          infoScene.rf,
          infoScene.duv,
          infoScene.cct
        ),
        boost: ReportParameters.get_boost(
          infoScene.mp,
          infoScene.rf,
          infoScene.duv
        ),
        create: ReportParameters.get_create(
          infoScene.mp,
          infoScene.rf,
          infoScene.duv
        ),
        rgb: ReportParameters.cie_to_rgb(infoScene.x, infoScene.y, undefined)
      })
  }, [infoScene])

  useEffect(() => {

    setTourActive(props.activeTour)
  }, [props.activeTour])

  const createSpectra = async () => {
    const savedSpectraAux = savedSpectra
    callBackPopupCreate()
    callbackCreatingSceneLoading(true)
    AnalyticsListener.createRouteView(`create_scene`)
    AnalyticsListener.sendEvent('create_scene')

    const result = await sceneApi.createScene(
      luminaryId,
      newSceneName,
      spectrumId,
      illuminance
    )
  
    result.showModal = true

    savedSpectraAux.push(result)

    callbackSavedSpectra(savedSpectraAux)

    callbackCreatingSceneLoading(false)
  }

  return (
    <div>
      {hclInfo && infoSpd && !loading && infoScene ? (
        <ContainerModal>
          <TitleReference>
            Reference
            <HelpContent
              section="Simulation"
              content="reference"
              position="bottom center"
              html={<Icon name="question circle"></Icon>}
            />
          </TitleReference>

          <InputWrapper>
            <InputText
              name="reference"
              error={false}
              className="tour-name-scene"
              defaultValue={newSceneName}
              onChange={(e) => callbackOnChangeName(e.target.value)}
            ></InputText>
          </InputWrapper>

          {tourActive && <Tour section="SIMULATION_SCENE_CREATOR"></Tour>}
          <CenterSPD>
            <ContainerItemsGraphicsHCL>
              <CoverFlowChartItem
                className="previous"
                dataTitle="HCL"
                dataDesc="Human Centric Lighting values."
                chartType="hcl"
                chartData={[
                  [hclInfo.relax, hclInfo.focus, hclInfo.natural, hclInfo.boost, hclInfo.create],
                  hclInfo.rgb
                ]}
                renderType="image"
              />
            </ContainerItemsGraphicsHCL>
            <ContainerItemsGraphicsSPD>
              <SpectraLineBars
                intensity={infoSpd.irradiance}
                wavelength={infoSpd.wavelength}
                canvas_width={'580px'}
              ></SpectraLineBars>
            </ContainerItemsGraphicsSPD>
          </CenterSPD>
          <ContainerInfo>
            <Table>
              <Column className="first">
                <Row className="title">
                  <TitleColorimetric>COLORIMETRIC</TitleColorimetric>
                </Row>
                <Row>
                  <TitleBolder>CCT (K)</TitleBolder> <p>{infoScene.cct}</p>
                </Row>
                <Row>
                  <TitleBolder>Duv</TitleBolder> <p>{infoScene.duv}</p>
                </Row>
                <Row>
                  <TitleBolder>Rf</TitleBolder> <p>{infoScene.rf}</p>
                </Row>
                <Row>
                  <TitleBolder>Rg</TitleBolder> <p>{infoScene.rg}</p>
                </Row>
                <Row>
                  <TitleBolder>CRI</TitleBolder> <p>{infoScene.cri}</p>
                </Row>
                <Row>
                  <TitleBolder>R9</TitleBolder> <p>{infoScene.r9}</p>
                </Row>
                <Row>
                  <TitleBolder>COI</TitleBolder> <p>{infoScene.coi}</p>
                </Row>
                <Row>
                  <TitleBolder>X</TitleBolder> <p>{infoScene.x}</p>
                </Row>
                <Row>
                  <TitleBolder>Y</TitleBolder> <p>{infoScene.y}</p>
                </Row>
              </Column>
              <Column>
                <Row className="title">
                  <TitleNonVisual>NON-VISUAL</TitleNonVisual>
                </Row>
                <Row>
                  <TitleBolder>M/P ratio</TitleBolder> <p>{infoScene.mp}</p>
                </Row>
                <Row>
                  <TitleBolder>MDER</TitleBolder> <p>{infoScene.mder}</p>
                </Row>
                <Row>
                  <TitleBolder>CS @100 lux</TitleBolder> <p>{infoScene.cs_100}</p>
                </Row>
                <Row>
                  <TitleBolder>CLA @100 lux</TitleBolder> <p>{infoScene.cla_100}</p>
                </Row>
                <Row>
                  <TitleBolder>EML @100 lux</TitleBolder> <p>{infoScene.eml_100}</p>
                </Row>
                <Row>
                  <TitleBolder>MEDI @100 lux</TitleBolder> <p>{infoScene.medi_100}</p>
                </Row>
                <Row>
                  <TitleBolder>440-490nm %</TitleBolder> <p>{infoScene.ul}</p>
                </Row>

                <Row className="title">
                  <TitlePower>POWER</TitlePower>
                </Row>
                <Row>
                  <TitleBolder>Efficiency (lm/W)</TitleBolder>{' '}
                  <p>{infoScene.efficiency_lmw}</p>
                </Row>
                <Row>
                  <TitleBolder>Electrical Power (W)</TitleBolder>{' '}
                  <p>{infoScene.pf_w}</p>
                </Row>
                <Row>
                  <TitleBolder>Luminous Flux (lm)</TitleBolder>{' '}
                  <p>{infoScene.luminous_flux_lm}</p>
                </Row>
              </Column>
            </Table>
            <ContainerChannels>
              <Column>
                <Row className="title">
                  <TitleChannel>CHANNELS INTENSITY</TitleChannel>
                </Row>
                {channels.map((channel: number, index: number) => (
                  <WrapperChannels>
                    <p>Ch{index} </p>
                    <StyledProgressBar
                      percent={Math.round(channel * 100)}
                      progress
                    />
                  </WrapperChannels>
                ))}
              </Column>
            </ContainerChannels>
          </ContainerInfo>

          <ContainerInputCreateScene>
            <SmallButtonAction
              disabled={disableNewScene}
              onClick={() => createSpectra()}
            >
              Create Scene
            </SmallButtonAction>
          </ContainerInputCreateScene>
        </ContainerModal>
      ) : (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      )}
    </div>
  )
}
export const LoadingContainer = styled.div`
  display: block;

  ${(props: CompProps) => (props.theme as Theme).filters.drop_shadow};
`
const TitleReference = styled.h5`
    display: flex;
    align-items: flex-start;
}
`

const ContainerChannels = styled.div`
  width: 30%;
`
const WrapperChannels = styled.div`
  p {
    color: ${(props: CompProps) => (props.theme as Theme).color.neutral._500};
    ${(props: CompProps) => (props.theme as Theme).font_size.xxxsmall};
  }
  .ui.progress {
    margin: 0 !important;
  }
  .ui.progress .bar {
    height: 10px !important;
    margin-bottom: 5px;
    ${(props: CompProps) => (props.theme as Theme).font_size.xxxsmall};
  }
`
const ContainerInfo = styled.div`
  display: flex;
`
const ContainerInputCreateScene = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  input {
    margin-right: 15px;
  }
`

const CenterSPD = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
`

const ContainerItemsGraphicsHCL = styled.div`
  width: 350px;
  height: 340px;
  margin-right: 15px;
  padding: 30px;
  ${(props: CompProps) => (props.theme as Theme).filters.drop_shadow};
`

const ContainerItemsGraphicsSPD = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 700px;
  height: 340px;
  ${(props: CompProps) => (props.theme as Theme).filters.drop_shadow};
`

const Column = styled.div`
  flex-grow: 1;
  padding: 30px;
  .title {
    border-bottom: 1px solid #c4c4c4;
    margin-bottom: 15px;
    ${(props: CompProps) => (props.theme as Theme).font_size.xxsmall};
  }
  p {
    margin-bottom: 5px;
    ${(props: CompProps) => (props.theme as Theme).font_size.xxxsmall};
  }
`
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`
const Table = styled.div`
  display: flex;
  justify-content: center;
  width: 700px;
`
const ContainerModal = styled.div`
  margin: 30px;
`
const TitleNonVisual = styled.div`
  color: #ffc774;
  margin-bottom: 10px;
  width: 100%;
  font-weight: bold;
`
const TitleChannel = styled.div`
  color: black;
  margin-bottom: 10px;
  width: 100%;
  font-weight: bold;
`
const TitleColorimetric = styled.div`
  color: #ff7474;
  margin-bottom: 10px;

  width: 100%;
  font-weight: bold;
`
const TitlePower = styled.div`
  color: #a5c4f7;
  margin-bottom: 10px;
  margin-top: 25px;
  width: 100%;
  font-weight: bold;
`

const TitleBolder = styled.p`
  font-weight: bolder;
`

const StyledProgressBar = styled(Progress)`
  & > .bar {
    background: linear-gradient(45deg, #F2C85B, #D1193E) !important;
  }
`

export default ModalCreateScene
