import React, { useState, ChangeEvent, KeyboardEvent, useEffect } from 'react'

import { toast } from 'react-toastify'
import HelpContent from '../help/HelpContent'
import { Icon } from 'semantic-ui-react'
import styled from 'styled-components'
import ReactSlider from 'react-slider'
import { CompProps, Theme } from '../../styles/theme'
import { CountDecimals } from '../../utils/commons'

interface Props {
  title: string
  type: string
  values: Array<number>
  onChangeFilter: Function
  onAfterChangeSlider: Function
  iluminance?: number[]
}

const FilterSection: React.FC<Props> = (props) => {
  const [editable, setEditable] = useState<boolean>(false)
  let minValueAux, maxValueAux
  let ValuesHorinzontal: number[] = [],
    ValuesVertical: number[] = []
  const valuessection = (valueresponse: number, iluminance: number) => {
    const value: number = (valueresponse * iluminance) / 100
    const decimals: number = CountDecimals((valueresponse * iluminance) / 100)
    if (decimals >= 3) {
      return parseFloat(value.toFixed(3))
    } else {
      return parseFloat(value.toFixed(decimals))
    }
  }
  if (props.iluminance) {
    ValuesHorinzontal = [
      valuessection(props.values[0], props.iluminance[0]),
      valuessection(props.values[1], props.iluminance[0])
    ]
    /*  ValuesHorinzontal = [
       Number((props.values[0] * props.iluminance[0]) / 100).toFixed(1),
       Math.round(props.values[1] * (props.iluminance[0] / 100))
     ]*/
    ValuesVertical = [
      valuessection(props.values[0], props.iluminance[1]),
      valuessection(props.values[1], props.iluminance[1])
    ]
  } else {
    console.log(props)
    minValueAux = props.values[0]
    maxValueAux = props.values[1]
  }

  const [minValue, setMinValue] = useState<any>(props.values[0])
  const [maxValue, setMaxValue] = useState<any>(props.values[1])

  const Thumb = (props: any) => <StyledThumb {...props} />

  const Track = (props: any, state: any) => (
    <StyledTrack {...props} index={state.index} />
  )

  const onClickValues = () => {
    if (minValue !== maxValue) {
      setEditable(true)
    }
  }

  const isValidated =
    minValue >= props.values[0] &&
    minValue <= props.values[1] &&
    maxValue <= props.values[1] &&
    maxValue >= props.values[0]

  const onPressEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (isValidated) {
        props.values[0] = minValue
        props.values[1] = maxValue
        setEditable(false)
        props.onAfterChangeSlider()
      } else {
        toast.error(
          `Values have to be between ${props.values[0]} and ${props.values[1]}`
        )
      }
    }
  }

  const onPressEsc = (event: any) => {
    if (event.keyCode === 27) {
      setEditable(false)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', onPressEsc, false)

    return () => document.removeEventListener('keydown', onPressEsc, false)
  }, [])

  let titleHelp =
    'F' +
    String(props.title)
      .replace(/[^-A-Za-z0-9]+/g, '')
      .replace(/[-]+/g, '')
      .toLowerCase()

  return (
    <FiltersSection>
      <FilterSectionText>
        <FiltersSectionKey>
          <HelpContent
            section="Simulation"
            content={titleHelp}
            position="bottom center"
            html={<Icon name="question circle"></Icon>}
          ></HelpContent>
          {props.title}
        </FiltersSectionKey>
        {props.iluminance && props.values && !editable && (
          <RangeIluminance>
              <div>
                E<sub>H</sub>&nbsp;<FiltersSectionValues>{`${ValuesHorinzontal[0]} - ${ValuesHorinzontal[1]}`}</FiltersSectionValues>
              </div>
              <div>
                E<sub>V</sub>&nbsp;<FiltersSectionValues>{`${ValuesVertical[0]} - ${ValuesVertical[1]}`}</FiltersSectionValues>
              </div>
          </RangeIluminance>
        )}
        {props.iluminance === undefined && props.values && !editable && (
          <FiltersSectionValues
            onClick={() => onClickValues()}
          >{`${minValueAux} - ${maxValueAux}`}</FiltersSectionValues>
        )}
        {props.values && editable && (
          <FiltersSectionValues>
            <input
              type="number"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setMinValue(e.target.value)
              }
              onKeyDown={onPressEnter}
              value={minValue}
            />{' '}
            -
            <input
              type="number"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setMaxValue(e.target.value)
              }
              onKeyDown={onPressEnter}
              value={maxValue}
            />
          </FiltersSectionValues>
        )}
    </FilterSectionText>
    <FiltersSectionSliders>
      <StyledSlider
        defaultValue={[0, 100]}
        renderTrack={Track}
        renderThumb={Thumb}
        onChange={(data) => props.onChangeFilter(data, props.type)}
        onAfterChange={() => props.onAfterChangeSlider()}
      />
  </FiltersSectionSliders>
  </FiltersSection>
  )
}


const FiltersSection = styled.div`
  width: 260px;
`

const FilterSectionText = styled.div`
  display: flex;
`

const FiltersSectionKey = styled.div`
  display: flex;
  flex: 1;
  ${(props: CompProps) => (props.theme as Theme).font_size.xxxsmall};
  span {
    margin-right: 5px;
    margin-bottom: 8px;
  }
`
const FiltersSectionValues = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: bold;
  ${(props: CompProps) => (props.theme as Theme).font_size.xxxsmall};
  input {
    max-width: 65px;
  }
`

const RangeIluminance = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: bold;
  ${(props: CompProps) => (props.theme as Theme).font_size.xxxsmall};
  div:first-child {
    margin-right: 20px;
  }
  div {
    display: flex;
    align-items: center;
  }
`

const FiltersSectionSliders = styled.div`
  width: 100%;
  margin: 5px 5px 20px 5px;
`
const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 10px;
`
const StyledThumb = styled.div`
  height: 16px;
  width: 16px;
  text-align: center;
  background-color: ${(props: CompProps) =>
    (props.theme as Theme).color.white.regular};
  border: 3px solid ${(props: CompProps) => (props.theme as Theme).color._900};
  border-radius: 50%;
  cursor: grab;
  top: -3px;
  outline: 0;
  position: relative;
`


const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: ${(props: any) =>
    props.index === 2
      ? (props: CompProps) => (props.theme as Theme).color.white.darkest
      : props.index === 1
      ? (props: CompProps) => (props.theme as Theme).color._500
      : (props: CompProps) => (props.theme as Theme).color.white.darkest};
  border-radius: 999px;
`
export default FilterSection
