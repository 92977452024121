import React, { useEffect, useState, useCallback } from 'react'
import { Button, Popup } from 'semantic-ui-react'
import styled from 'styled-components'
import { CompProps, Theme } from '../../styles/theme'

const CoverFlow: React.FC = (props) => {

    const [childrenData, setChildrenData] = useState<any[]>([])
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')

    const [activeIndex, setActiveIndex] = useState<number>(2)
    const [targetIndex, setTargetIndex] = useState<number>(2)

    const changeItem =  useCallback((index: number) => {
        if(index === activeIndex) return
        setTargetIndex(index)

        const nextIndex: number = (index > activeIndex) ? activeIndex+1 : activeIndex-1   
        setActiveIndex(nextIndex)
        setTitle(childrenData[nextIndex].dataTitle)
        setDescription(childrenData[nextIndex].dataDesc)
    }, [activeIndex, childrenData])

    useEffect(() => {
        if(targetIndex !== activeIndex) {
            setTimeout(() => {
                changeItem(targetIndex)
            }, 250)    
        }
    }, [targetIndex, changeItem, activeIndex])

    const getClassName = (index: number) => {
        if(index === activeIndex)
            return 'selected'
        if(index === activeIndex + 1)
            return 'next'
        if(index === activeIndex - 1)
            return 'previous'
        if(index === activeIndex + 2)
            return 'last'
        if(index === activeIndex - 2)
            return 'first'
        
        return 'out'
    }

    const getRenderType = (index: number) => {
        if(index === activeIndex)
            return 'graphic'
        else 
            return 'image'
    }

    useEffect(() => {
        const childrenDataAux: any[] = React.Children.map(props.children, x => (x as any).props)
        setChildrenData(childrenDataAux)
        setTitle(childrenDataAux[activeIndex].dataTitle)
        setDescription(childrenDataAux[activeIndex].dataDesc)
    }, [props.children, activeIndex])
    
    return (
        <CoverFlowWrapper>
            <CoverFlowContainer>
                <CoverFlowItem>
                {React.Children.map(props.children, (child, index) => (
                    React.cloneElement((child as any), {
                        className: getClassName(index),
                        renderType: getRenderType(index)
                    })
                ))}
                </CoverFlowItem>
            </CoverFlowContainer>
            <CoverFlowInfo>
                <CoverFlowMenu>
                    {childrenData.map((item, index) => (
                        <Popup
                            content={item.dataTitle}
                            key={'cv_pop'+index}
                            trigger={<Button 
                                        key={'cv_but'+index} 
                                        className={(index === activeIndex) ? 'active' : ''} 
                                        onClick={() => { changeItem(index) }} />} 
                        />
                    ))}
                </CoverFlowMenu>
                <CoverFlowItemTitle>{title}</CoverFlowItemTitle>
                <CoverFlowItemDescription>{description}</CoverFlowItemDescription>
            </CoverFlowInfo>
        </CoverFlowWrapper>
    )
}

const CoverFlowWrapper = styled.div`
    width: 100%; 
`

const CoverFlowInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
`

const CoverFlowMenu = styled.div`
    margin: 5px 0px;
    button {
        border: none;
        padding: 10px !important;
        border-radius: 25px !important;
        display: inline-block;
        margin: 10px 5px !important;
        .active {
            background-color: grey !important;
        }
    }
    
`

const CoverFlowItemTitle = styled.h5`
    ${(props: CompProps) => (props.theme as Theme).font_size.regular};
    font-family: ${(props: CompProps) => (props.theme as Theme).font.bold};
`

const CoverFlowItemDescription = styled.p`
    text-align: left;
    width: 340px;
    height: 140px;
    ${(props: CompProps) => (props.theme as Theme).font_size.xxsmall};
`

const CoverFlowContainer = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    height: 400px;
    margin: 0 auto;
`

const CoverFlowItem = styled.div`
    div {
        position: absolute;
        display: flex;
        justify-content: center;
        background: transparent;
        box-shadow: ${(props: CompProps) =>
            (props.theme as Theme).filters.drop_shadow};
        transition: all 0.5s ease-out;
        img {
            padding: 15px;
        }
    }
    div > div:first-child {
        display: flex;
        align-self: center;
        box-shadow: none;
    }
    div.selected {
        height: 400px;
        width: 400px;
        margin-top: -200px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 4;
        background: white;
    }   
    div.previous {
        height: 200px;
        width: 200px;
        margin-top: -100px;
        z-index: 3;
        left: 30%;
        transform: translateX(-50%);
        background: white;
    }
    div.next {
        height: 200px;
        width: 200px;
        margin-top: -100px;
        z-index: 3;
        left: 70%;
        transform: translateX(-50%);
        background: white;
    }
    div.first {
        min-height: 150px;
        margin-top: -75px;
        min-width: 150px;
        left: 15%;
        z-index: 2;
        transform: translateX(-25%);
        background: white;
    }
    div.last {
        min-height: 150px;
        margin-top: -75px;
        min-width: 150px;
        left: 85%;
        z-index: 2;
        transform: translateX(-85%);
        background: white;
    }
    div.out {
        margin-top: -50px;
        visibility: hidden;
        z-index: 1;
    }
`

export default CoverFlow
