import React from 'react'
import styled from 'styled-components'

import { CompProps, Theme } from '../../styles/theme'

import { ParamsInfoColumn } from './types'

interface Props {
    columns: ParamsInfoColumn[]
}

const SceneParamsTable: React.FC<Props> = (props) => {

    const { columns } = props

    return (
        <InfoTableContainer>
        {columns.map((column) => (
            <TableColumContainer key={column.name}>             
                <ColumnTitle color={column.color}>{column.name}</ColumnTitle>
                {column.params.map((param) => (
                    <ColumnRowContainer key={param.name}>
                        <ParamNameCell>{param.name}</ParamNameCell>
                        <ParamValueCell>{param.value}</ParamValueCell>
                    </ColumnRowContainer>
                ))}
            </TableColumContainer>
        ))}
        </InfoTableContainer>
    )
}

export default SceneParamsTable


const InfoTableContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  gap: 60px;
`

const TableColumContainer = styled.div`
  flex-grow: 1;
  width: 33%;
`

const ColumnTitle = styled.h5`
  color: ${props => props.color};
  font-family: ${(props: CompProps) => (props.theme as Theme).font.semibold};
  padding: 15px 0px;
  border-bottom: 1px solid lightgrey;
`

const ColumnRowContainer = styled.div`
    display: flex;
    flex-diection: column;
    margin-bottom: 5px;
    width: 75%;
`

const ParamNameCell = styled.div`
    flex-grow: 1;
    ${(props: CompProps) => (props.theme as Theme).font_size.xxsmall};
    font-family: ${(props: CompProps) => (props.theme as Theme).font.bold};
    font-weight: bold;
`
const ParamValueCell = styled.div`
    font-family: ${(props: CompProps) => (props.theme as Theme).font.semibold};
    ${(props: CompProps) => (props.theme as Theme).font_size.xxsmall};
`
