import { useEffect, useState } from "react"


export const CountDecimals = (value: number): number => {
  if (Math.floor(value) === value) return 0
  return value.toString().split('.')[1].length || 0
}

export const round = (number: number, precision: number) => {
	var factor = Math.pow(10, precision);
	var tempNumber = number * factor;
	var roundedTempNumber = Math.round(tempNumber);
	return roundedTempNumber / factor;
}

export const addZero = (zeros: number) => {
  let numberIncludesZero = 1
  for (let index = 0; index < zeros; index++) {
    numberIncludesZero = numberIncludesZero / 10
  }
  return numberIncludesZero
}

// Hash any string into an integer value
// Then we'll use the int and convert to hex.
export const strToARGB = (str: string) => {
    var hash = 0
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }

    return (hash >>> 0).toString(16).slice(-6)
}

export const useDebounce = (value: any, delay: number) => {
  const [debounceValue, setDebouncedValue] = useState(value)
  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay)
    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return debounceValue
}
