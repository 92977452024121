import React, { ChangeEvent, useState, useEffect } from 'react'
import styled from 'styled-components'
import { CompProps, Theme } from '../styles/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faUpload, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import { InputText, Fieldset, InputWrapper, ButtonUI } from '../styles/commons'
import { Dropdown, Icon } from 'semantic-ui-react'
import { ledApi, FamilyLed } from '../api/LedApi'

import HelpContent from './help/HelpContent'
import Tour from './help/Tour'
import { ToastContainer } from 'react-toastify'
import { toast } from 'react-toastify'
const UploadIcon = <FontAwesomeIcon icon={faUpload} />
const PlusIcon = <FontAwesomeIcon icon={faPlusCircle} />
const MinusIcon = <FontAwesomeIcon icon={faTimesCircle} />

interface Props {
  closeModal: Function
  newled: Function
  LedsTourActive: boolean
}

const initialFormLed: any = {
  brand: '',
  family: '',
  reference: '',
  custom_data: {
    name: '',
    value: '',
    measure: ''
  },
  user_spd: {}, // ? // CSV
  power_v: '',
  power_ma: '',
  power_w: '',
  temperature: '',
  led_series: '',
  led_parallel: ''
}

const NewSimulation: React.FC<Props> = (props) => {
  const { LedsTourActive } = props
  const [FormLed, setFormLed] = useState<any>(
    JSON.parse(JSON.stringify(initialFormLed))
  )

  const [datacustom, setDatacustom] = useState<
    {
      name: String
      value: String
      measure: String
    }[]
  >([
    {
      name: '',
      value: '',
      measure: ''
    }
  ])
  let [Spectralpower, setSpectralPower] = useState<
    {
      Wavelength: String
      Irradiance: String
    }[]
  >([
    { Wavelength: '-', Irradiance: '-' },
    { Wavelength: '-', Irradiance: '-' },
    { Wavelength: '-', Irradiance: '-' },
    { Wavelength: '-', Irradiance: '-' },
    { Wavelength: '-', Irradiance: '-' }
  ])

  const [warningText, setWarningText] = useState<string>('This is a test')
  const [disabledvoltage, setdisabledvoltage] = useState<boolean>(false)
  const [disabledcurrent, setdisabledcurrent] = useState<boolean>(false)
  const [disabledpower, setdisabledpower] = useState<boolean>(false)
  const [disabledradiant, setdisabledradiant] = useState<boolean>(false)
  const [disabledluminous, setdisabledluminous] = useState<boolean>(false)
  const [Isloading, setIsloading] = useState<boolean>(false)
  const [SearchFamily, setSearchFamily] = useState<
    {
      key: string
      value: string
      text: string
    }[]
  >([])

  const [SearchBrand, setSearchBrand] = useState<
    {
      key: string
      value: string
      text: string
    }[]
  >([])
  const [ActiveFamily, setActiveFamily] = useState<boolean>(true)

  const createLed = async () => {
    setIsloading(true)

    const response = await ledApi.createLedUser(FormLed)
    if (response) {
      props.closeModal(true)
      props.newled(true)
    } else {
      setIsloading(false)
    }
  }

  const onFamilyChange = (e: any) => {
    const FormLedAux: any = Object.assign({}, FormLed)

    if (e.value !== undefined && e.value !== '') {
      setActiveFamily(false)

      FormLedAux.family = e.value
      setFormLed(FormLedAux)
    } else {
      setActiveFamily(true)
    }
  }

  const onBrandChange = (e: any) => {
    const FormLedAux: any = Object.assign({}, FormLed)

    if (e.value !== undefined && e.value !== '') {
      setActiveFamily(false)

      FormLedAux.brand = e.value
      setFormLed(FormLedAux)
    }
  }

  const onReferenceChange = (e: ChangeEvent<HTMLInputElement>) => {
    const FormLedAux: any = Object.assign({}, FormLed)
    FormLedAux.reference = e.target.value
    setFormLed(FormLedAux)
  }

  const onCurrentChange = (e: ChangeEvent<HTMLInputElement>) => {
    const FormLedAux: any = Object.assign({}, FormLed)
    FormLedAux.power_ma = parseInt(e.target.value)
      ? parseInt(e.target.value)
      : ''
    setFormLed(FormLedAux)
  }
  const onVoltageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const FormLedAux: any = Object.assign({}, FormLed)
    FormLedAux.power_v = parseFloat(e.target.value)
      ? parseFloat(e.target.value)
      : ''
    setFormLed(FormLedAux)
  }
  const onPowerChange = (e: ChangeEvent<HTMLInputElement>) => {
    const FormLedAux: any = Object.assign({}, FormLed)
    FormLedAux.power_w = parseFloat(e.target.value)
      ? parseFloat(e.target.value)
      : ''
    setFormLed(FormLedAux)
  }
  const onLuminousfluxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const FormLedAux: any = Object.assign({}, FormLed)
    FormLedAux.radiometry_lm = parseInt(e.target.value)
    setFormLed(FormLedAux)
  }
  const onRadiometryradiantChange = (e: ChangeEvent<HTMLInputElement>) => {
    const FormLedAux: any = Object.assign({}, FormLed)
    FormLedAux.radiometry_mw = parseInt(e.target.value)
    setFormLed(FormLedAux)
  }
  const onTemperatureChange = (e: ChangeEvent<HTMLInputElement>) => {
    const FormLedAux: any = Object.assign({}, FormLed)
    FormLedAux.temperature = parseInt(e.target.value)
    setFormLed(FormLedAux)
  }
  const onLedseriesChange = (e: ChangeEvent<HTMLInputElement>) => {
    const FormLedAux: any = Object.assign({}, FormLed)
    FormLedAux.led_series = parseInt(e.target.value)
    setFormLed(FormLedAux)
  }
  const onParallelledChange = (e: ChangeEvent<HTMLInputElement>) => {
    const FormLedAux: any = Object.assign({}, FormLed)
    FormLedAux.led_parallel = parseInt(e.target.value)
    setFormLed(FormLedAux)
  }

  const onCustomDataChange = (
    e: ChangeEvent<HTMLInputElement>,
    value: {
      name: String
      value: String
      measure: String
    },
    key: number
  ) => {
    if (Array.isArray(datacustom)) {
      if (e.target.name === 'name') {
        datacustom[key].name = e.target.value
      }
      if (e.target.name === 'value') {
        datacustom[key].value = e.target.value
      }
      if (e.target.name === 'measure') {
        datacustom[key].measure = e.target.value
      }
      setDatacustom(datacustom)
      const FormLedAux: any = Object.assign({}, FormLed)
      FormLedAux.custom_fields = datacustom
      setFormLed(FormLedAux)
    }
  }

  const handleFiles = (e: React.FormEvent<HTMLInputElement>) => {
    const SpectralPowerAux: Array<any> = []

    const files = e.currentTarget.files
    var reader = new FileReader()
    if (files !== null) {
      reader.readAsText(files[0])
      reader.onload = function () {
        const allLines: Array<String> = String(reader.result).split(/\r\n|\n/)

        let Wavelengthsend: Array<Number> = []
        let Irradiancesend: Array<Number> = []
        let Spectralaux = {
          irradiance: Irradiancesend,
          wavelength: Wavelengthsend
        }
        let errorLength = false

        if (allLines.length < 401) {
          errorLength = true
        }

        !errorLength &&
          allLines.forEach((line, keyLine) => {
            let Wavelength: string = ''
            let Irradiance: string = ''

            const columnas: Array<string> = line
              .split(';', 2)
              .filter((n) => !isNaN(parseFloat(n)))

            if (columnas.length === 2) {
              columnas.forEach((columna, key) => {
                if (key === 0) {
                  Wavelength = columna
                  if (
                    parseFloat(columna.toString()) !== null &&
                    !isNaN(parseFloat(columna.toString()))
                  ) {
                    Wavelengthsend.push(parseFloat(columna.toString()))
                  }
                }
                if (key === 1) {
                  Irradiance = columna.replace(',', '.')
                  if (
                    parseFloat(columna.toString()) !== null &&
                    !isNaN(parseFloat(columna.toString()))
                  ) {
                    if (Number(Irradiance) <= 0) {
                      Irradiance = '0'
                    }
                    Irradiancesend.push(Number(Irradiance))
                  }
                }
              })

              SpectralPowerAux.push({ Wavelength, Irradiance })
            }
          })

        if (SpectralPowerAux.length !== 401) {
          errorLength = true
        }

        if (errorLength) {
          toast.error(
            'Wrong file format or structure. Please check the example we provide.'
          )
        } else {
          Spectralaux = {
            wavelength: Wavelengthsend,
            irradiance: Irradiancesend
          }
          const FormLedAux: any = Object.assign({}, FormLed)
          FormLedAux.user_spd = Spectralaux

          // TODO: Necesitamos dos objetos?
          setSpectralPower(SpectralPowerAux)
          setFormLed(FormLedAux)
        }
      }
    }
  }

  const [formErrors, setFormErrors] = useState(true)
  // const [confirmForm, setConfirmForm] = useState(false)

  const DataCustomClick = () => {
    const customdata = Object.assign({}, datacustom)
    //setDatacustom([{ name: '', value: '', measure: '' }])

    if (datacustom !== undefined && datacustom.length < 1) {
      //setDatacustom(datacustom.concat(customdata))
    }
    if (datacustom !== undefined) {
      if (
        datacustom[datacustom.length - 1]['name'] !== '' &&
        datacustom[datacustom.length - 1]['name'] !== undefined
      ) {
        setDatacustom(datacustom.concat(customdata))
      }
      if (0 in datacustom) {
        //do stuff with someObject["someKey"]
      }
    } else {
    }
  }

  const DeleteDataCustomClick = (id: number) => {
    const customdata = Object.assign([], datacustom)

    if (datacustom !== undefined && datacustom.length >= 2) {
      customdata.splice(id, 1)

      setDatacustom(customdata.filter((el) => el !== el[id]))
    }
  }

  useEffect(() => {
    const validateForm = () => {
      let errors = false
      /*Angel */
      if (FormLed.brand === '') {
        errors = true
      }
      if (FormLed.family === '') {
        errors = true
      }
      if (FormLed.reference === '') {
        errors = true
      }
      if (FormLed.temperature === '') {
        errors = true
      }

      if (FormLed.temperature === '') {
        errors = true
      }
      if (FormLed.led_series === '') {
        errors = true
      }
      if (FormLed.led_parallel === '') {
        errors = true
      }
      if (!Array.isArray(FormLed.user_spd.irradiance)) {
        errors = true
      }
      let validate: number = 0
      let current_error
      let voltage_error
      let power_error

      if (FormLed.power_ma !== '') {
        validate++
      } else {
        current_error = true
      }

      let warning: boolean = false
      if (
        FormLed.power_ma !== '' &&
        FormLed.led_parallel !== '' &&
        FormLed.power_ma < FormLed.led_parallel
      ) {
        errors = true
        warning = true
        setWarningText(
          `(*) Errror: The current should be higher than  or equal to the Parallel Leds`
        )
      }
      if (FormLed.power_v !== '') {
        validate++
      } else {
        voltage_error = true
      }
      if (
        FormLed.power_v !== '' &&
        FormLed.led_series !== '' &&
        FormLed.power_v < FormLed.led_series
      ) {
        errors = true
        warning = true
        setWarningText(
          `(*) Errror: The voltage should be higher than  or equal to the Series Leds`
        )
      }

      !warning && setWarningText('')

      if (FormLed.power_w !== '') {
        validate++
      } else {
        power_error = true
      }
      if (validate >= 2) {
        if (current_error) {
          setdisabledcurrent(true)
        }
        if (power_error) {
          setdisabledpower(true)
        }
        if (voltage_error) {
          setdisabledvoltage(true)
        }
      } else {
        setdisabledcurrent(false)
        setdisabledpower(false)
        setdisabledvoltage(false)
        errors = true
      }

      let validate_flux: number = 0
      let luminous_error
      let radiant_error

      if (FormLed.radiometry_lm !== 0 && !isNaN(FormLed.radiometry_lm)) {
        validate_flux++
      } else {
        luminous_error = true
      }
      if (FormLed.radiometry_mw !== 0 && !isNaN(FormLed.radiometry_mw)) {
        validate_flux++
      } else {
        radiant_error = true
      }
      if (validate_flux >= 1) {
        if (luminous_error) {
          setdisabledluminous(true)
        }
        if (radiant_error) {
          setdisabledradiant(true)
        }
      } else {
        setdisabledradiant(false)
        setdisabledluminous(false)
        errors = true
      }

      setFormErrors(errors)
    }

    validateForm()
  }, [FormLed])

  useEffect(() => {
    let didCancel: boolean = false
    async function getUserLedFamilies() {
      let userFamiliesResponse: FamilyLed[] = []
      try {
        userFamiliesResponse = await ledApi.getFamilies()
        const DataFamilydAux = SearchFamily
        userFamiliesResponse.forEach((leds) => {
          DataFamilydAux.push({
            value: leds.reference,
            text: leds.reference,
            key: leds.id
          })
        })
        !didCancel && setSearchFamily(DataFamilydAux)
      } catch (error) {
        console.error(error)
      } finally {
      }
    }
    getUserLedFamilies()
    return () => {
      didCancel = true
    }
  }, [SearchFamily])

  useEffect(() => {
    async function getbrands() {
      try {
        const SearchFamilyAux: any = SearchFamily.filter((family) => {
          return FormLed.family === family.value
        })
        const SearchBrandAux:
          | ((
              prevState: { key: string; value: string; text: string }[]
            ) => { key: string; value: string; text: string }[])
          | { key: any; value: any; text: any }[] = []

        if (
          SearchFamilyAux.length >= 1 &&
          SearchFamilyAux[0].key !== undefined
        ) {
          let userLedsResponse: any[] = await ledApi.getBrands(
            SearchFamilyAux[0].key
          )

          if (userLedsResponse !== undefined) {
            userLedsResponse.forEach((brands) => {
              SearchBrandAux.push({
                key: brands.id,
                value: brands.reference,
                text: brands.reference
              })
            })
          }
        }

        setSearchBrand(SearchBrandAux)
      } catch (error) {
        console.error(error)
      } finally {
      }
    }
    getbrands()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [FormLed.family])

  const onFamily = (family: any) => {
    SearchFamily.push({
      value: family.value,
      text: family.value,
      key: family.value
    })
    const FormLedAux: any = Object.assign({}, FormLed)
    setSearchFamily(SearchFamily)

    if (family.value !== '') {
      setActiveFamily(false)
      FormLedAux.family = family.value
      setFormLed(FormLedAux)
    } else {
      setActiveFamily(true)
    }
  }

  const onBrand = (brand: any) => {
    SearchBrand.push({
      value: brand.value,
      text: brand.value,
      key: brand.value
    })
    const FormLedAux: any = Object.assign({}, FormLed)
    setSearchFamily(SearchFamily)

    if (brand.value !== '') {
      setActiveFamily(false)
      FormLedAux.brand = brand.value
      setFormLed(FormLedAux)
    } else {
      setActiveFamily(true)
    }
  }

  return (
    <NewSimulationWrapper>
      {LedsTourActive && <Tour section="SIMULATION_NEW_LED"></Tour>}
      <NewSimulationForm>
        <NewSimulationTitle>
          <span className="tour-general-info">General Information </span>
        </NewSimulationTitle>
        <GeneralWrapper>
          <FieldsetBrand name="brand">
            <label className="tour-company">Company</label>
            <InputWrapper>
              <DropdownFamily
                allowAdditions={true}
                options={SearchFamily}
                selection
                onAddItem={(event: any, value: any) => onFamily(value)}
                search
                onChange={(event: any, value: object) => onFamilyChange(value)}
                placeholder=""
              />
            </InputWrapper>
          </FieldsetBrand>
          <FieldsetFamliy name="family">
            <label>Brand</label>
            <InputWrapper>
              <DropdownBrand
                allowAdditions={true}
                selection
                options={SearchBrand}
                onAddItem={(event: any, value: any) => onBrand(value)}
                onChange={(event: any, value: object) => onBrandChange(value)}
                search
                disabled={ActiveFamily}
              />
            </InputWrapper>
          </FieldsetFamliy>
          <FieldsetReference name="reference">
            <label>Reference</label>
            <InputWrapper>
              <InputText
                style={{ marginTop: '2px' }}
                onChange={onReferenceChange}
                name="reference"
                error={false}
              ></InputText>
            </InputWrapper>
          </FieldsetReference>
        </GeneralWrapper>
        <LedWrap>
          <LedDataWrapper>
            <FieldsetPower name="power">
              <NewSimulationTitle className="tour-power">
                POWER
              </NewSimulationTitle>
              <LedWrapInline>
                <label>Current (mA)</label>
                <InputWrapper>
                  <InputTextinLine
                    onChange={(e) => onCurrentChange(e)}
                    name="current"
                    type="number"
                    step="any"
                    disabled={disabledcurrent}
                    error={false}
                  ></InputTextinLine>
                </InputWrapper>
                <label>
                  (mA)
                  <HelpContent
                    section="New_led"
                    content="current"
                    position="top center"
                    html={<Icon name="question circle"></Icon>}
                  ></HelpContent>
                </label>
              </LedWrapInline>
              <LedWrapInline>
                <label>Voltage</label>
                <InputWrapper>
                  <InputTextinLine
                    onChange={(e) => onVoltageChange(e)}
                    name="voltage"
                    type="number"
                    step="any"
                    disabled={disabledvoltage}
                    error={false}
                  ></InputTextinLine>
                </InputWrapper>
                <label>
                  (V)
                  <HelpContent
                    section="New_led"
                    content="voltage"
                    position="top center"
                    html={<Icon name="question circle"></Icon>}
                  ></HelpContent>
                </label>
              </LedWrapInline>
              <LedWrapInline>
                <label>Power</label>
                <InputWrapper>
                  <InputTextinLine
                    onChange={onPowerChange}
                    name="power"
                    type="number"
                    step="any"
                    disabled={disabledpower}
                    error={false}
                  ></InputTextinLine>
                </InputWrapper>
                <label>
                  (W)
                  <HelpContent
                    section="New_led"
                    content="power"
                    position="top center"
                    html={<Icon name="question circle"></Icon>}
                  ></HelpContent>
                </label>
              </LedWrapInline>
            </FieldsetPower>
            <FieldsetRadiometry name="radiometry">
              <NewSimulationTitle>RADIOMETRY</NewSimulationTitle>
              <LedWrapInline>
                <label>Luminous flux</label>
                <InputWrapper>
                  <InputTextinLine
                    type="number"
                    onChange={onLuminousfluxChange}
                    name="luminous"
                    min="0"
                    step="any"
                    disabled={disabledluminous}
                    error={false}
                  ></InputTextinLine>
                </InputWrapper>
                <label>
                  (lm)
                  <HelpContent
                    section="New_led"
                    content="luminous_flux"
                    position="top center"
                    html={<Icon name="question circle"></Icon>}
                  ></HelpContent>
                </label>
              </LedWrapInline>
              <LedWrapInline>
                <label>Radiant flux</label>
                <InputWrapper>
                  <InputTextinLine
                    type="number"
                    onChange={onRadiometryradiantChange}
                    name="luminous"
                    step="any"
                    min="0"
                    disabled={disabledradiant}
                    error={false}
                  ></InputTextinLine>
                </InputWrapper>
                <label>
                  (mW)
                  <HelpContent
                    section="New_led"
                    content="radiant_flux"
                    position="top center"
                    html={<Icon className="help" name="question circle"></Icon>}
                  ></HelpContent>
                </label>
              </LedWrapInline>
            </FieldsetRadiometry>
            <FieldsetLed>
              <NewSimulationTitle>LED SETUP</NewSimulationTitle>
              <LedWrapInline>
                <label>Series LED(s)</label>
                <InputWrapper>
                  <InputTextinLine
                    name="series_led"
                    onChange={onLedseriesChange}
                    error={false}
                    type="number"
                  ></InputTextinLine>
                </InputWrapper>
                <label>
                  (ud)
                  <HelpContent
                    section="New_led"
                    content="series_led"
                    position="top center"
                    html={<Icon name="question circle"></Icon>}
                  ></HelpContent>
                </label>
              </LedWrapInline>
              <LedWrapInline>
                <label>Parallel LEDS(s)</label>
                <InputWrapper>
                  <InputTextinLine
                    name="parallel_leds"
                    onChange={onParallelledChange}
                    error={false}
                    type="number"
                  ></InputTextinLine>
                </InputWrapper>
                <label>
                  (ud)
                  <HelpContent
                    section="New_led"
                    content="paralled_led"
                    position="top center"
                    html={<Icon name="question circle"></Icon>}
                  ></HelpContent>
                </label>
              </LedWrapInline>
            </FieldsetLed>
            <FieldsetExternal>
              <NewSimulationTitle>EXTERNAL CONDITIONS</NewSimulationTitle>

              <LedWrapInline>
                <label>Temp</label>
                <InputWrapper>
                  <InputTextinLine
                    name="external"
                    type="number"
                    step="any"
                    onChange={onTemperatureChange}
                    error={false}
                  ></InputTextinLine>
                </InputWrapper>
                <label>
                  (C)
                  <HelpContent
                    section="New_led"
                    content="temp"
                    position="top center"
                    html={<Icon name="question circle"></Icon>}
                  ></HelpContent>
                </label>
              </LedWrapInline>
            </FieldsetExternal>
            <FieldsetCustomData>
              <NewSimulationTitle>
                CUSTOM DATA <Plus onClick={DataCustomClick}>{PlusIcon} </Plus>
              </NewSimulationTitle>
              <LedWrapInline>
                <TableCustomData>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Value</th>
                      <th>Measure</th>
                      <th> &nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {datacustom &&
                      datacustom.map(
                        (
                          item: {
                            name: String
                            value: String
                            measure: String
                          },
                          key
                        ) => (
                          <tr key={key}>
                            <td>
                              <InputTextinLine
                                name="name"
                                error={false}
                                // defaultValue={item[1].name}
                                onChange={(e) => {
                                  onCustomDataChange(e, item, key)
                                }}
                              ></InputTextinLine>
                            </td>
                            <td>
                              {' '}
                              <InputTextinLine
                                name="value"
                                error={false}
                                // defaultValue={item[1].name}
                                onChange={(e) => {
                                  onCustomDataChange(e, item, key)
                                }}
                              ></InputTextinLine>
                            </td>
                            <td>
                              {' '}
                              <InputTextinLine
                                name="measure"
                                error={false}
                                // defaultValue={item[1].name}
                                onChange={(e) => {
                                  onCustomDataChange(e, item, key)
                                }}
                              ></InputTextinLine>
                            </td>
                            <td onClick={() => DeleteDataCustomClick(key)}>
                              <Plus>{MinusIcon}</Plus>
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>
                </TableCustomData>
              </LedWrapInline>
            </FieldsetCustomData>
          </LedDataWrapper>
          <FieldsetSpectralWrapper>
            <FieldsetSpectralContainer name="spectral_power_distribution">
              <NewSimulationTitle>
                SPECTRAL POWER DISTRIBUTION
              </NewSimulationTitle>
              <FieldsetSpectralText className="tour-spectral-power">
                Please upload a .csv file with yor spd data. You can{' '}
                <a href={`${process.env.PUBLIC_URL}/spd_model.csv`} download>
                  <b>download an example file</b>
                </a>{' '}
                to use as basis.
              </FieldsetSpectralText>
              <TableLedContainer>
                <TableLed>
                  <thead>
                    <tr>
                      <th>Wavelength (nm)</th>
                      <th>Irradiance (a.u.)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Spectralpower &&
                      Spectralpower.map(
                        (
                          item: {
                            Wavelength: String
                            Irradiance: String
                          },
                          key
                        ) => (
                          <tr key={key}>
                            <td>{item.Wavelength} </td>
                            <td>{item.Irradiance} </td>
                          </tr>
                        )
                      )}
                  </tbody>
                </TableLed>
              </TableLedContainer>
            </FieldsetSpectralContainer>
            <FieldsetSpectralButtonContainer>
              <InputFile
                type="file"
                name="file"
                accept=".csv"
                onChange={(e) => {
                  handleFiles(e)
                }}
              />
              <LabelInput htmlFor="file">
                {' '}
                {UploadIcon} Choose a file
              </LabelInput>
            </FieldsetSpectralButtonContainer>
          </FieldsetSpectralWrapper>
        </LedWrap>

        <CreateButtonWrapper>
          <WarningText>{warningText}</WarningText>
          <ButtonUI
            className="tour-create-led"
            btntype="primary"
            disabled={formErrors}
            loading={Isloading}
            onClick={() => createLed()}
          >
            Create
          </ButtonUI>
        </CreateButtonWrapper>
      </NewSimulationForm>
      <ToastContainer />
    </NewSimulationWrapper>
  )
}

const NewSimulationWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const NewSimulationForm = styled.div`
  padding: 0px 30px;
`

const CreateButtonWrapper = styled.div`
  display: flex;
  margin-top: 15px;
  margin-bottom: 30px;
`

const WarningText = styled.div`
  color: ${(props: CompProps) => (props.theme as Theme).color.forms.error};
  ${(props: CompProps) => (props.theme as Theme).font_size.xxsmall};
  width: 100%;
  margin-top: 12px;
`

const DropdownLed = styled(Dropdown)`
  .menu {
    border-color: transparent !important;
  }
  border: 0px solid rgba(34, 36, 38, 0.15);
  width: 100%;
  min-width: 50px;
  max-width: 250px;
  width: 100%;
  min-height: 36px;
  border: 0px solid #aba8af;
  border-bottom-color: rgb(171, 168, 175);
  border-bottom-style: solid;
  border-bottom-width: 0px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  border-bottom: 1px solid #aba8af !important;
  border-top: inherit !important;
  border-left: inherit !important;
  border-right: inherit !important;
  padding: 0 10px;
  input {
    width: 100%;
    height: 100%;
  }
  div {
    width: 100%;
    padding-top: 7px;
  }
  i {
    display: none !important;
  }
  .item {
    width: 100%;
  }
  border-radius: inherit !important;
  border: none;
  border-radius: 0.28571429rem;
`
const DropdownBrand = styled(DropdownLed)``
const DropdownFamily = styled(DropdownLed)``

const Plus = styled.span`
  cursor: pointer;
  font-size: 16px;
  color: #848a85;
`
const NewSimulationTitle = styled.h5`
  margin-top: 0;
  margin-bottom: 5px;
  text-transform: uppercase;
  color: ${(props: CompProps) => (props.theme as Theme).color.neutral._900};
  font-size: 14px;
  svg {
    margin-left: 5px;
  }
  max-width: 175px;
`
const TableCustomData = styled.table`
  border-spacing: 0px 5px;
  width: 100%;
  text-align: left;
  background: ${(props: CompProps) => (props.theme as Theme).color._100};
  padding: 15px;
  display: flex;
  flex-flow: column;
  min-height: 185px;
  input {
    border-bottom: 0px;
  }
  th {
    padding-left: 5px;
    flex-grow: 1;
    ${(props: CompProps) => (props.theme as Theme).font_size.xxxsmall};
  }
  tbody {
    max-height: 125px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  tr td:last-child {
    border-right: 1px solid black !important;
    border-bottom: 1px solid black !important;
    border-top: 1px solid black !important;
    text-align: right;
  }
  tr td:last-child span {
    position: relative;
    top: 2px;
    right: 5px;
  }

  tr td:first-child {
    border-bottom: 1px solid black !important;
    border-left: 1px solid black !important;
  }
  thead tr {
    background: ${(props: CompProps) => (props.theme as Theme).color._100};
    color: ${(props: CompProps) => (props.theme as Theme).color.neutral._900};
  }
  td {
    border-bottom: 1px solid black !important;
    border-top: 1px solid black !important;
    flex-grow: 1;
    width: 100%;

    border-bottom: 1px solid
      ${(props: CompProps) => (props.theme as Theme).color.neutral._300};
  }
  tr {
    background: white;
    display: flex;
    margin-top: 5px;
  }
`
const LabelInput = styled.label`
  margin-top: 25px;
  font-size: 10px !important;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: bold;
  background: ${(props: CompProps) => (props.theme as Theme).color._900};
  border-radius: 4px;
  color: white;
  padding: 10px !important;
  cursor: pointer;
  &:hover {
    background-color: ${(props: CompProps) =>
      (props.theme as Theme).color._500};
  }
`
const InputFile = styled.input`
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  cursor: pointer;
  &:hover {
    background-color: #25aaaa;
  }
  &:focus {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
  }
`

const InputTextinLine = styled(InputText)`
  max-width: 100px;
  min-height: 0px;
  padding: 0 0px;
  margin-right: 5px;
  margin-left: 5px;
  line-height: 0px;
  max-height: 22px;
`

const GeneralWrapper = styled.div`
  display: flex;
  flex-direction: row;
  div {
    flex: 25%;
    margin-right: 25px;
    ${(props: CompProps) => (props.theme as Theme).font_size.xxxsmall};
  }
`

const FieldsetBrand = styled(Fieldset)`
  label {
    max-width: 175px;
  }
`
const FieldsetFamliy = styled(Fieldset)``
const FieldsetReference = styled(Fieldset)``

const LedWrap = styled.div`
  display: flex;
  flex-direction: row;
`

const LedDataWrapper = styled.div`
  flex: 75%;
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  fieldset {
    width: 324px;
    margin-top: 15px;
  }
  fieldset:last-child {
    width: 100%;
    padding-right: 30px;
  }
`

const FieldsetPower = styled(Fieldset)`
  flex: 25%;
`

const FieldsetRadiometry = styled(Fieldset)`
  flex: 25%;
`

const FieldsetExternal = styled(Fieldset)`
  flex: 25%;
`

const FieldsetLed = styled(Fieldset)`
  flex: 25%;
`

const FieldsetCustomData = styled(Fieldset)`
  padding-top: 15px;
`

const LedWrapInline = styled.div`
  display: inline-flex;
  margin-top: 15px;
  width: 100%;
  label {
    width: 60px;
    margin-right: 5px;
    ${(props: CompProps) => (props.theme as Theme).font_size.xxsmall};
  }
  input {
    width: 60px;
    margin-right: 5px;
  }
`

const FieldsetSpectralWrapper = styled.div`
  flex: 25%;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`

const FieldsetSpectralContainer = styled(Fieldset)`
  height: 265px;
`

const FieldsetSpectralText = styled.p`
  ${(props: CompProps) => (props.theme as Theme).font_size.xxxsmall};
  b {
    color: ${(props: CompProps) => (props.theme as Theme).color._900};
    font-weight: bold;
  }
`

const TableLedContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  height: 139px;
`

const TableLed = styled.table`
  text-align: center;
  border-collapse: collapse;
  width: 95%;
  text-align: left;
  td {
    border: 1px solid #aba8af;
  }
  tr {
    text-align: center;
    ${(props: CompProps) => (props.theme as Theme).font_size.xxxsmall};
    border: 1px solid #aba8af;
  }
  th {
    ${(props: CompProps) => (props.theme as Theme).font_size.xxxsmall};
    border: 1px solid #aba8af;
  }
`

const FieldsetSpectralButtonContainer = styled.div`
  margin-top: 10px;
  cursor: pointer;
  position: relative;
`

export default NewSimulation
