import React from 'react'
import styled from 'styled-components'
import logo from '../../images/kumux-logo.png'
import theme from '../../styles/theme'

const Loading: React.FC = () => {
  return (
    <LoadingWrapper>
      <StyledImage src={logo} />
    </LoadingWrapper>
  )
}

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 400px;
  background:  ${theme.color._500}; /* fallback for old browsers */
  background: -webkit-radial-gradient( ${theme.color._500}, transparent, transparent); /* Chrome 10-25, Safari 5.1-6 */
  background: radial-gradient( ${theme.color._500}, transparent, transparent); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-size: 400px 400px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  }
`

const StyledImage = styled.img`
  width: 50px;
  animation: spin 4s linear infinite;
  margin: 0 auto;
  height: 50px;
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`

export default Loading
