import React from 'react'

import { InputSearch, ButtonSearch } from '../../styles/commons'

//import 'semantic-ui-less/semantic.less'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

const SearchIcon = <FontAwesomeIcon icon={faSearch} />
interface Props {
  Search: string
  GetSearch: Function
}

const SearchComponent: React.FC<Props> = (props) => {
  function handleOnChange(event: any) {
    props.GetSearch(event.target.value)
    return event.target.value
  }

  return (
    <>
      <InputSearch
        onChange={(event) => handleOnChange(event)}
        name="text"
        type="search"
        value={props.Search}
        placeholder=" Search..."
      />
      <ButtonSearch type="submit">{SearchIcon}</ButtonSearch>
    </>
  )
}

export default SearchComponent
