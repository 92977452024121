import React, { ChangeEvent } from 'react'
import styled from 'styled-components'

import { Led, ListLeds } from '../../api/LedApi'

import { Input } from 'semantic-ui-react'

interface Props {
  InitFilters: Function
  itemledsAux: ListLeds
  activeNow: string
  setitemsLeds: Function
  itemleds: ListLeds
  setitemsLedsAuxString: Function
}

const Search: React.FC<Props> = (props) => {
  const {
    itemledsAux,
    activeNow,
    setitemsLeds,
    itemleds,
    setitemsLedsAuxString
  } = props
  const HandleSearch = (data: any) => {
    //InitFilters()
    const typeKey = activeNow as keyof ListLeds
    let itemLedFilter: Led[] = []
    if (data.value === '') {
      setitemsLeds(itemledsAux)

      //  setItemsLedsAux(itemsUserLeds)
      // ItemsLedsAux = itemsUserLeds
    } else {
      itemLedFilter = itemledsAux[typeKey].filter((led: Led) => {
        if (led !== undefined) {
          return (
            String(led.family)
              .toLowerCase()
              .startsWith(String(data.value).toLowerCase()) ||
            String(led.brand)
              .toLowerCase()
              .startsWith(String(data.value).toLowerCase()) ||
            String(led.reference)
              .toLowerCase()
              .startsWith(String(data.value).toLowerCase())
          )
        } else {
          return led
        }
      })

      const itemsLedAux: ListLeds = Object.assign({}, itemleds)
      itemsLedAux[typeKey] = itemLedFilter
      // setFiltersSections(filtersSections)

      setitemsLedsAuxString(itemsLedAux)

      setitemsLeds(itemsLedAux)
    }
  }
  return (
    <InputSearch
      onChange={(event: ChangeEvent, data: object) => HandleSearch(data)}
      icon="search"
      className="search"
      iconPosition="left"
      loading={false}
      key="Search"
    />
  )
}

const InputSearch = styled(Input)`
  border-radius: 30px !important;
`
export default Search
