import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Popup } from 'semantic-ui-react'
import styled from 'styled-components'
import { sceneApi } from '../../api/SceneApi'
import ScenePrivate from '../../pages/scene/ScenePrivate'
import {
  DeleteSceneIconContainer,
  ModalConfirm,
  SmallButtonAction,
  Toogle
} from '../../styles/commons'
import { CompProps, Theme } from '../../styles/theme'
import ModalConfirmText from '../common/ModalConfirmText'
import ModalSemantic from '../common/ModalSemantic'
import Tour from '../help/Tour'

import { faTrashAlt, faLink } from '@fortawesome/free-solid-svg-icons'
import AnalyticsListener from '../../api/AnalyticsListener'
interface Props {
  savedSpectra: any
  activeTour: any
  activeTourDetails: boolean
  callbackSavedSpectra: Function
  callbacksetactiveTourDetails: Function
  simulationDetailInfo: any
}
const ListSavedScenes: React.FC<Props> = (props) => {
  const {
    savedSpectra,
    activeTour,
    activeTourDetails,
    callbackSavedSpectra,
    callbacksetactiveTourDetails,
    simulationDetailInfo
  } = props
  const RemoveSceneIcon = <FontAwesomeIcon icon={faTrashAlt} />
  const copyURL = <FontAwesomeIcon icon={faLink} />
  const copyClipBoard = async (item: any) => {
    const ResponseQR = await sceneApi.getQR(item.public_id)
    if (ResponseQR) {
      const hidden = document.createElement('input')
      document.body.appendChild(hidden)
      hidden.setAttribute('type', 'text')
      hidden.setAttribute('name', 'url')
      hidden.setAttribute(
        'style',
        'position: absolute; left: -1000px; top: -1000px'
      )
      hidden.setAttribute('value', ResponseQR.qr_code_url)
      hidden.select()
      document.execCommand('copy')
    }
  }
  const deleteScene = (index: number) => {
    const savedSpectraModified = savedSpectra.map((spectra: any, i: number) => {
      if (i === index) {
        AnalyticsListener.createRouteView(`delete_scene/${spectra.public_id}`)
        spectra.deleteConfirm = true
      }

      return spectra
    })

    callbackSavedSpectra(savedSpectraModified)
  }
  const closeDeleteScene = () => {
    const savedSpectraModified = savedSpectra.map((spectra: any, i: number) => {
      spectra.deleteConfirm = false

      return spectra
    })

    callbackSavedSpectra(savedSpectraModified)
  }
  const acceptDeleteScene = async () => {
    const sceneToDelete = savedSpectra.find(
      (spectra: any) => spectra.deleteConfirm === true
    )

    if (sceneToDelete) {
      const response = await sceneApi.deleteSceneById(sceneToDelete.public_id)

      if (response) {
        const savedSpectraAux = savedSpectra.filter(
          (spectra: any) => spectra.deleteConfirm !== true
        )

        callbackSavedSpectra(savedSpectraAux)
      }
    }
  }
  const openSavedSpectraModal = (index: number) => {
    const savedSpectraModified = savedSpectra.map((spectra: any, i: number) => {
      if (i === index) {
        AnalyticsListener.createRouteView(`view_scene/${spectra.public_id}`)
        spectra.showModal = true
      }
      return spectra
    })

    callbackSavedSpectra(savedSpectraModified)
  }
  const handleToogle = async (spectraToggle: any) => {
    await sceneApi.sharedScene(spectraToggle.public_id)

    let savedSpectraAux = Object.assign([], savedSpectra)
    //let savedSpectraAux = savedSpectra

    savedSpectraAux.forEach((spectra: any) => {
      if (spectra.public_id === spectraToggle.public_id) {
        spectra.shared = !spectra.shared
      }
    })

    callbackSavedSpectra(savedSpectraAux)
  }
  const closeSavedSpectraModal = () => {
    const savedSpectraModified = savedSpectra.map((spectra: any, i: number) => {
      spectra.showModal = false
      return spectra
    })
    if (activeTour) {
      callbacksetactiveTourDetails(true)
    }

    callbackSavedSpectra(savedSpectraModified)
  }

  const exportCSVScenes = () => {
    // Send Analitycs
    AnalyticsListener.createRouteView(`export_csv`)
    AnalyticsListener.sendEvent('export_csv')

    // Create channel column names
    let num_channels: number = savedSpectra[0].data.combination.length
    let channel_names: Array<string> = []
    for (let i = 0; i < num_channels; i++) {
      channel_names[i] = `ch${i + 1}`
    }

    // Generate CSV
    setTimeout(() => {
      const rows = [
        [
          'Scene number',
          'Scene name',
          'CCT (K)',
          'Duv',
          'Rf',
          'Rg',
          'CRI',
          'R9',
          'COI',
          'x',
          'y',
          'MP ratio',
          'MDER',
          'CS 100',
          'CLA 100',
          '440-490nm %',
          'Efficiency (lm/W)',
          'Electrical Power (W)',
          'Luminous Flux (lm)',
          ...channel_names
        ]
      ]

      savedSpectra.map((scene: any, index: number) =>
        rows.push([
          index + 1,
          String('"' + scene.reference + '"'),
          scene.data.cct,
          scene.data.duv,
          scene.data.rf,
          scene.data.rg,
          scene.data.cri,
          scene.data.r9,
          scene.data.coi,
          scene.data.x,
          scene.data.y,
          scene.data.mp,
          scene.data.mder,
          scene.data.cs_100,
          scene.data.cla_100,
          scene.data.ul,
          scene.data.efficiency_lmw,
          scene.data.pf_w,
          scene.data.luminous_flux_lm,
          ...scene.data.combination
        ])
      )

      let csvContent =
        'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n')
      let encodedUri = encodeURI(csvContent)
      let link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', `${simulationDetailInfo.reference}.csv`)
      document.body.appendChild(link)

      link.click()
    }, 1000)
  }
  return (
    <SavedSpectraDataContainer>
      {activeTour &&
        savedSpectra &&
        savedSpectra.length === 1 &&
        activeTourDetails && <Tour section="SIMULATION_SCENE_CREATOR_FINISH" />}

      {savedSpectra && savedSpectra.length > 0 ? (
        savedSpectra.map((spectra: any, index: number) => (
          <SavedSpectraRow key={index}>
            <RawTextContainer>
              <RawValue>{spectra.reference}</RawValue>
            </RawTextContainer>
            <RawTextContainer>
              <RawSubTitle>CCT (K)</RawSubTitle>
              <RawValue>{spectra.data.cct}</RawValue>
            </RawTextContainer>
            <RawTextContainer>
              <RawSubTitle>Rf</RawSubTitle>
              <RawValue>{spectra.data.rf}</RawValue>
            </RawTextContainer>
            <RawTextContainer>
              <RawSubTitle>M/P Ratio</RawSubTitle>
              <RawValue>{spectra.data.mp}</RawValue>
            </RawTextContainer>
            <RawTextContainer>
              <RawSubTitle>Efficiency (lm/W)</RawSubTitle>
              <RawValue>{spectra.data.efficiency_lmw}</RawValue>
            </RawTextContainer>
            <RawTextContainer>
              <ModalSemantic
                title="Scene Detail"
                size="large"
                isOpen={spectra.showModal}
                closeCallBack={closeSavedSpectraModal}
                trigger={
                  <SmallButtonAction
                    className="tour-view-details"
                    onClick={() => openSavedSpectraModal(index)}
                  >
                    View Detail
                  </SmallButtonAction>
                }
              >
                <ScenePrivate sceneId={spectra.public_id} />
              </ModalSemantic>
            </RawTextContainer>
            <RawTextContainer>
              Public{'  '}
              <Toogle
                checked={spectra.shared}
                onChange={() => handleToogle(spectra)}
                toggle
              />
            </RawTextContainer>
            <Copy className="copy">
              <ContainerCopy
                style={
                  spectra.shared
                    ? { visibility: 'inherit' }
                    : { visibility: 'hidden' }
                }
              >
                <Popup
                  content="Shared URL copied to the clipboard!"
                  trigger={copyURL}
                  on="click"
                  onOpen={() => copyClipBoard(spectra)}
                />
              </ContainerCopy>
            </Copy>
            <RawTextContainer>
              <DeleteSceneIconContainer onClick={() => deleteScene(index)}>
                {RemoveSceneIcon}
              </DeleteSceneIconContainer>
            </RawTextContainer>
            {spectra.deleteConfirm && (
              <ModalConfirm
                size="tiny"
                closeIcon
                open={spectra.deleteConfirm}
                onClose={() => closeDeleteScene()}
              >
                <ModalConfirmText
                  closeModal={closeDeleteScene}
                  message="Are you sure you want to delete this Scene?"
                  acceptConfirm={acceptDeleteScene}
                />
              </ModalConfirm>
            )}
          </SavedSpectraRow>
        ))
      ) : (
        <div>No Saved Scenes yet</div>
      )}
      {savedSpectra && savedSpectra.length > 0 && (
        <ExportContainer>
          <SmallButtonAction
            btnType="secondary"
            className="tour-export"
            onClick={() => exportCSVScenes()}
          >
            EXPORT
          </SmallButtonAction>
        </ExportContainer>
      )}
    </SavedSpectraDataContainer>
  )
}
const SavedSpectraDataContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-bottom: 25px;
`
const ExportContainer = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
`
const SavedSpectraRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid
    ${(props: CompProps) => (props.theme as Theme).color.black.lightest};
  align-items: center;
`
const RawTextContainer = styled.div`
  display: flex;
  flex: 0 0 12%;
  justify-content: start;
  margin: 0 5px;
  :last-child  {
    flex: 0 0 1%;
  }
  align-items: center;
  .toggle {
    padding-left: 5px !important;
  }
`
const Copy = styled(RawTextContainer)`
  flex: 0;
`
const ContainerCopy = styled.div`
  cursor: pointer;
  color: ${(props: CompProps) => (props.theme as Theme).color.neutral._900};
  :hover svg {
    color: ${(props: CompProps) => (props.theme as Theme).color._900};
  }
`
const RawSubTitle = styled.p`
  margin: 0 10px 0 0;
  font-family: ${(props: CompProps) => (props.theme as Theme).font.bold};
  ${(props: CompProps) => (props.theme as Theme).font_size.xxsmall};
`
const RawValue = styled.p`
  margin: 0;
  ${(props: CompProps) => (props.theme as Theme).font_size.xxsmall};
`
export default ListSavedScenes
