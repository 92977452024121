import React, { SyntheticEvent, useEffect, useState } from 'react'
import styled from 'styled-components'

import HelpContent from '../help/HelpContent'
import { Dropdown, Icon } from 'semantic-ui-react'
import SpectraLineBars from '../charts/SpectraLineBars'
import ReportParameters from '../../api/ReportParameters'
import { Channel } from '../../api/LedApi'

import { SquareNumber, StyledThumbFilter } from './Slider'
import { CompProps, Theme } from '../../styles/theme'
import { strToARGB } from '../../utils/commons'
import { ChannelIntensityValues, SliderSpectra } from '../../pages/Pcb'
import { StyledSlider, StyledTrack } from '../../styles/commons'

interface Props {
  ChartSPD: {
    wavelength: number[]
    intensity: number[]
    line: number[][]
    lineColors: string[]
  }
  onChangeFilter: Function
  channelIntensity?: ChannelIntensityValues
  PCBChannels: Channel[]
  setSliderSpectra: Function
  sliderSpectra?: SliderSpectra[]
}
export interface FilterColor {
  color?: number
}
const Spectral: React.FC<Props> = (props) => {
  const {
    ChartSPD,
    PCBChannels,
    channelIntensity,
    sliderSpectra,
    setSliderSpectra,
    onChangeFilter
  } = props
  const [selectShadow, setselectShadow] = useState<string>('')

  const options: Array<any> = []
  const Track = (props: any, state: any, color: string) => {
    return (
      <StyledTrackFilterReverse about={color} {...props} index={state.index} />
    )
  }
  const Thumb = (props: any, state: any, color: string) => {
    return (
      <StyledThumbFilter about={color} {...props}>
        <SquareNumber about={color}> {props['aria-valuetext']} </SquareNumber>
      </StyledThumbFilter>
    )
  }

  options.push({ key: 99, text: 'REFERENCE SPD', value: 99 })
  Object.keys(ReportParameters.parametersDropdown).forEach((key, index) => {
    const searchRegExp = /_/g
    const keyFormat = key.replace(searchRegExp, ' ').toUpperCase()
    options.push({ key: index, text: keyFormat, value: index })
  })

  const handleDropdown = (event: SyntheticEvent, data: any) => {
    let keyFormat: string = ''
    if (data.value !== 99) {
      const searchRegExp = / /g
      const optionSelect: any = options.filter(
        (item: any) => item.key === data.value
      )

      keyFormat = String(optionSelect[0].text)
        .replace(searchRegExp, '_')
        .toLowerCase()
    }
    setselectShadow(keyFormat)
  }

  const onChangeFilterNow = (dataFilter: any, index: number) => {
    if (sliderSpectra !== undefined) {
      let sliderSpectraAux: SliderSpectra[] = Object.assign([], sliderSpectra)
      sliderSpectraAux[index].now = dataFilter

      setSliderSpectra(sliderSpectraAux)
    }
  }
  useEffect(() => {
    let sliderSpectraAux: SliderSpectra[] = []
    for (let index = 0; index < PCBChannels.length; index++) {
      sliderSpectraAux.push({
        lineColors: strToARGB(PCBChannels[index].reference),
        max: 100,
        min: 1,
        name: PCBChannels[index].reference,
        now: 100,
        driver_Ma: PCBChannels[index].limit
      })
    }

    setSliderSpectra(sliderSpectraAux)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PCBChannels])

  return (
    <SPDContainer className="tour-spectral-distribution">
      {ChartSPD && (
        <div>
          <ContainerTitle>
            <Title className="">
              Spectral Sum
              <HelpContent
                section="Fixture"
                content="spectral_distribution"
                position="bottom center"
                html={<Icon name="question circle"></Icon>}
              ></HelpContent>
            </Title>
            {options && (
              <Dropdown
                selection
                defaultValue={99}
                options={options}
                onChange={(event: SyntheticEvent, data: object) =>
                  handleDropdown(event, data)
                }
              />
            )}
          </ContainerTitle>
          <SpectraLineBars
            intensity={ChartSPD.intensity}
            wavelength={ChartSPD.wavelength}
            line={ChartSPD.line}
            lineColors={ChartSPD.lineColors}
            canvas_width={'555px'}
            compare_spd={
              selectShadow !== '' &&
              ReportParameters.parametersDropdown[
                selectShadow as keyof typeof ReportParameters.parametersDropdown
              ]
            }
          ></SpectraLineBars>
          <div className="containerChannels">
            {channelIntensity && sliderSpectra && (
              <ContainerChannels>
                <div>
                  <Title>
                    Channel Intensity
                    <HelpContent
                      section="Fixture"
                      content="channel_intensity"
                      position="bottom center"
                      html={<Icon name="question circle"></Icon>}
                    />
                  </Title>
                  <div className="intesity">
                    {sliderSpectra &&
                      sliderSpectra.map(
                        (sliderSpectraElement: SliderSpectra, i: number) => {
                          return (
                            <WrapperChannels key={i}>
                              <StyledSliderSpectra
                                max={sliderSpectraElement.max}
                                min={sliderSpectraElement.min}
                                renderThumb={(props: any, state: any) =>
                                  Thumb(
                                    props,
                                    state,
                                    sliderSpectraElement.lineColors
                                  )
                                }
                                renderTrack={(props: any, state: any) =>
                                  Track(
                                    props,
                                    state,
                                    sliderSpectraElement.lineColors
                                  )
                                }
                                defaultValue={sliderSpectraElement.now}
                                value={sliderSpectraElement.now}
                                ariaLabel={sliderSpectraElement.lineColors}
                                ariaValuetext={sliderSpectraElement.name}
                                onAfterChange={(data: any) =>
                                  onChangeFilter(data, i)
                                }
                                onChange={(data: any) =>
                                  onChangeFilterNow(data, i)
                                }
                              />
                              <span>{sliderSpectraElement.now}%</span>
                            </WrapperChannels>
                          )
                        }
                      )}
                  </div>
                </div>
                <div>
                  <Title className="">
                    Values
                    <HelpContent
                      section="Fixture"
                      content="values"
                      position="bottom center"
                      html={<Icon name="question circle"></Icon>}
                    />
                  </Title>
                  <div className="wrapperspec">
                    <ContainerSpec>
                      <p> Colorimetric</p>
                      <ul>
                        <li>
                          CCT:{' '}
                          <span>
                            {channelIntensity.cct_k
                              ? channelIntensity.cct_k
                              : '-'}{' '}
                          </span>
                        </li>
                        <li>
                          CRI:{' '}
                          <span>
                            {channelIntensity.cri ? channelIntensity.cri : '-'}
                          </span>
                        </li>
                        <li>
                          Duv:{' '}
                          <span>
                            {channelIntensity.duv ? channelIntensity.duv : '-'}
                          </span>
                        </li>
                        <li>
                          R9:{' '}
                          <span>
                            {channelIntensity.r9 ? channelIntensity.r9 : '-'}
                          </span>
                        </li>
                      </ul>
                    </ContainerSpec>
                    <div>
                      <ContainerSpec>
                        <p>Non visual </p>
                        <ul>
                          <li>
                            M/P Ratio:
                            <span>
                              {channelIntensity.mp_ratio
                                ? channelIntensity.mp_ratio
                                : '-'}
                            </span>
                          </li>
                        </ul>
                      </ContainerSpec>
                      <ContainerSpec>
                        <p> Power</p>
                        <ul>
                          <li>
                            Electrical Power (W):{' '}
                            <span>
                              {channelIntensity.pf_w
                                ? channelIntensity.pf_w
                                : '-'}
                            </span>
                          </li>
                          <li>
                            Luminous Flux (lm):{' '}
                            <span>
                              {channelIntensity.luminous_flux_lm
                                ? channelIntensity.luminous_flux_lm
                                : '-'}
                            </span>
                          </li>
                        </ul>
                      </ContainerSpec>
                    </div>
                  </div>
                </div>
              </ContainerChannels>
            )}
          </div>
        </div>
      )}
    </SPDContainer>
  )
}

const ContainerSpec = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  padding-right: 20px;
  margin-top: 15px;
  p {
    color: ${(props: CompProps) => (props.theme as Theme).color.neutral._500};
    margin-bottom: 5px;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
  }
  li {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
  }
  ul {
    margin: 0px;
    padding: 0px;
  }
`
const WrapperChannels = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
  padding-top: 15px;
`

const StyledSliderSpectra = styled(StyledSlider)`
  width: 150px;
`
const StyledTrackFilterReverse = styled(StyledTrack)`
  top: 5px;
  background: ${(props: any) =>
    props.index === 2
      ? 'blue'
      : props.index === 1
      ? (props: CompProps) => (props.theme as Theme).color.white.darkest
      : '#' + props.about};
`

const SPDContainer = styled.div`
  margin-top: 15px;
  h5 span {
    display: inline-flex;
  }
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  padding: 15px;
  .wrapperspec {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .containerChannels {
    height: 160px;
  }
`
const ContainerTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  flex-direction: row;
`
const Title = styled.h5`
  margin: 0;
`
const ContainerChannels = styled.div`
  display: flex;
  justify-content: space-between;
  height: 160px;
  > div {
    width: 50%;
  }
  .intesity {
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 30px;
    padding: 15px;
    height: 130px;
  }
  .intesity .thumb div {
    position: relative;
    top: -15px;
  }
`

export default Spectral
