export const HelpText = {
  Fixture: {
    fixture:
      'Here you can find all the leds you’ve selected for your light that best match your needs.',
    new_fixture:
      'Let’s get started! Create your first fixture and discover how to take your light projects to the next level.',
    spectral_distribution:
      'Relation between radiant or luminous flux per wavelength or frequency interval and wavelength or frequency.',
    user_leds:
      'Show the leds you have uploaded to the platform.',
    led_catalog:
      'Shows Kumux catalog of comercial LEDS.',
    reference:
      'Name your new fixture',
    channel_intensity:
      'Test how the PCB respond to different channel values.',
    values:
      'Spectral Sum related Values.',
    simulation_resolution:
      'High resolution will be slower but will produce more results.',
    pcb:
      'Drag any led here to start creating your PCB.'
  },
  Led: {
    led:
      'All the LED catalogue abailable and the LEDS you’ve saved will be shown here',
    new_led:
      'Create and save any new LEDS you need for your light from any brand.',
    excel_import:
      'Or, if you already have a database, import your LEDS from an excel file',
    led_catalog:
      'Here you’ll find an extensive LED database, add to your list the ones that best match your needs',
    show_led: 'By clicking there, all the LED specifications will be shown.'
  },
  New_led: {
    general_information: 'Fill up the blank spaces with your LED information',
    current: 'Total current in miliamperes of the string of LEDs',
    voltage: 'Total voltage drop in volts of the string of LEDs',
    power: 'Total electrical power in watts of the string of LEDs',
    temp: 'Temperature of the LEDs',
    luminous_flux: 'Total luminous flux in lm of the string of LEDs',
    radiant_flux: 'Total radiant power in mW of the string of LEDs',
    series_led: 'Number of LEDs connected in series',
    paralled_led: 'Number of LEDs connected in parallel'
  },
  Simulation: {
    Fcctk: 'Correlated Color Temperature in Kelvins',
    reference: 'Name your new Scene',
    Fduv: ' Distance from the planckian (blackbody) locus',
    Frf: 'IES TM-30-18 average color fidelity',
    Frg: 'IES TM-30-18 gamut area',
    Fcri: 'Color Rendering Index (8 tests)',
    Fr9: '9th color sample of the CRI calculation (red rendering)',
    Fcoi: 'Cyanosis Observation Index',
    Fmpratio: 'Melanopic/Photopic ratio',
    Fmder: ' Melanopic Daylight Efficacy Ratio',
    Fcs: 'Circadian Stimulus (LRC) at 100 lx',
    Fcla: 'Circadian Light (LRC) at 100 lx',
    Fefficiencylmw: 'Luminous flux (lm) divided by electrical power (W)',
    Felectricalpowerw:
      'Rate per unit time at which electrical energy is transferred',
    Fluminousfluxlm:
      'Energy radiated over wavelengths sensitive to the human eye',
    F440490nm: 'Percentage of radiation between 440 and 490 nm',
    Feml: 'Equivalent Melanopic Lux at 100 lx',
    Fmedi: 'Melanopic Equivalent Daylight Illuminance at 100 lx',
    Flerlmw:'Luminous Efficacy of Radiation in lumens per optic watts',
  },
  
  Project: {
    project:
      'In this tab you can create projects based on locations, with different fixtures and scenes. It is possible to relate them to a given datetime'
  }
}
