import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { CompProps, Theme } from '../../../styles/theme'

const Footer: React.FC = () => {

  const history = useHistory()

  const openNotice = (lang: string, type: string) => {
    const legalPath: string = `/legal/${lang}/${type}`
    history.push(legalPath)
  }

  return (
    <FooterContainer>
      <ul>
        <li>Copyright 2020 • Enlighting S.L. All Rights Reserved</li>
        <li><FooterButton onClick={() => { openNotice('eng', 'legal') }}>Legal Notice</FooterButton> • <FooterButton onClick={() => { openNotice('cast', 'legal')}}>Aviso Legal</FooterButton></li>
        <li><FooterButton onClick={() => { openNotice('eng', 'privacy') }}>Privacy Policy</FooterButton> • <FooterButton onClick={() => { openNotice('cast', 'privacy')}}>Política de Privacidad</FooterButton></li>
        <li><FooterButton onClick={() => { openNotice('eng', 'cookies') }}>Cookies Policy</FooterButton> • <FooterButton onClick={() => { openNotice('cast', 'cookies')}}>Política Cookies</FooterButton></li>
      </ul>
    </FooterContainer>
  )
}

const FooterContainer = styled.div`
  position: absolute;
  ${(props: CompProps) => (props.theme as Theme).font_size.xxsmall};
  font-weight: bolder;
  margin-top: 15px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  li {
    float: left;
    margin-left: 15px;
  }
  color: ${(props: CompProps) => (props.theme as Theme).color.black.lightest};
`

const FooterButton = styled.button`
  pointer-events: auto !important;
  background-color: transparent !important;
  max-width: 180px;
  color: ${(props: CompProps) =>
    (props.theme as Theme).color.black.lightest}!important;
  &:hover {
    color: ${(props: CompProps) => (props.theme as Theme).color._500}!important;
  }
  @media (max-width: ${(props: CompProps) =>
      (props.theme as Theme).screen.md}) {
  }
  @media (max-width: ${(props: CompProps) =>
      (props.theme as Theme).screen.sm}) {
    margin-left: 0;
  }
  &:disabled {
    cursor: not-allowed !important;
    color: ${(props: CompProps) =>
      (props.theme as Theme).color.black.lightest}!important;
  }
`



export default Footer
