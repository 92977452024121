import React, { useState, useEffect } from 'react'
import { Auth } from '../App'
import styled from 'styled-components'
import HeaderContainer from '../components/common/HeaderSection'
import ModalLegal from '../components/common/ModalLegal'
import { CompProps, Theme } from '../styles/theme'
import {
  UploadImage,
  TableUI,
  Toogle,
  MainButton,
  Phrasegrey
} from '../styles/commons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserLock } from '@fortawesome/free-solid-svg-icons'
import { userAPI, Organization, Licenses } from '../api/UserApi'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {
  Grid,
  Icon,
  Input,
  Table,
  Message,
  Modal,
  Header
} from 'semantic-ui-react'

interface Props {
  auth: Auth
}

export interface PasswordForm {
  currentPassword: string
  newPassword: string
  /* confirmNewPassword: string*/
}

interface BasicInformation {
  name: string
  email: string
  org_admin: boolean
}

const initPasswordValues: PasswordForm = {
  currentPassword: '',
  newPassword: ''
  /*confirmNewPassword: ''*/
}

const initOrganization: Organization = {
  account: '',
  address: '',
  billing_address: '',
  nif: '',
  name: ''
}

/*
const ReferenceErrorIcon = (
  <FontAwesomeIcon
    title="New password and confirmation have to be the same value"
    icon={faExclamationCircle}
  />
)
*/

const Profile: React.FC<Props> = (props: Props) => {
  const [auth, setAuth] = useState(props.auth)
  const [basicData, setBasicData] = useState<BasicInformation>()
  const [OrganizationData, setOrganizationData] = useState<Organization>(
    initOrganization
  )
  const [password, setPassword] = useState<PasswordForm>(initPasswordValues)
  const [formPasswordErrors, setFormPasswordErrors] = useState(true)
  const [formorganizationErrors, setformorganizationErrors] = useState(true)
  const [ValidateUpdate, setValidateUpdate] = useState<boolean>()
  const [AutoRenew, setAutoRenew] = useState<boolean>(false)
  const [tutorialToogle, settutorialToogle] = useState<boolean>()
  const [UserLicenses, setUserLicenses] = useState<Licenses[]>()
  const UserLock = <FontAwesomeIcon icon={faUserLock} />
  const [openModal, setOpenModal] = useState(false)
  const [ImageOrganization, setImageOrganization] = useState<any>()

  const handleDragEnter = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDragLeave = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDragOver = (e: any) => {
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
    }
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = async (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const image = URL.createObjectURL(e.dataTransfer.files[0])
      setImageOrganization(image)

      let formData = new FormData()
      formData.append('file', e.dataTransfer.files[0])

      /*
      const responseUpdateImage = await userAPI.uploadimageorganization(
        formData
      )
      */

      toast.success('Your logo has been changed successfully')
      // e.dataTransfer.clearData()
      // this.dragCounter = 0
    }
  }

  const handleChange = async (e: any) => {
    if (e.target.files.length) {
      const image = URL.createObjectURL(e.target.files[0])
      setImageOrganization(image)
      let formData = new FormData()
      formData.append('file', e.target.files[0])
      const responseUpdateImage = await userAPI.uploadimageorganization(
        formData
      )
      // props.auth.changeimage(image)
      toast.success('Your logo has been changed successfully')

      if (responseUpdateImage) {
        const responseLogoOrganzation: any = await userAPI.getLogoOrganization()
        props.auth.changeimage(responseLogoOrganzation)
      }
    }
  }

  /*
  useEffect(() => {
    const updatephoto = async () => {
      const responseLogoOrganzation: any = await userAPI.getLogoOrganization()
      if (responseLogoOrganzation !== null) {
        props.auth.changeimage(responseLogoOrganzation)
      } else {
        props.auth.changeimage('')
      }
    }
    // updatephoto()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ImageOrganization])
  */

  useEffect(() => {
    setAuth(props.auth)

    if (props.auth.user) {
      setBasicData({
        name: props!.auth!.user!.name!,
        email: props!.auth!.user!.email,
        org_admin: props!.auth!.user!.org_admin
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, props.auth])

  useEffect(() => {
    let didCancel = false
    const GetLogo = async () => {
      const responseLogoOrganzation: any = await userAPI.getLogoOrganization()
      if (responseLogoOrganzation !== null) {
        !didCancel && setImageOrganization(responseLogoOrganzation)
      }
    }
    GetLogo()
    const GetAutoRenew = async () => {
      const autorenew: boolean = await userAPI.getAutoRenew()
      // refToogle.current.
      if (autorenew) {
        !didCancel && setAutoRenew(autorenew)
      } else {
        !didCancel && setAutoRenew(false)
      }
    }

    GetAutoRenew()

    return () => {
      didCancel = true
    }
  }, [])
  useEffect(() => {
    let didCancel = false
    const GetLicenses = async () => {
      const responseLicenses: Licenses[] = await userAPI.getLicenses()
      if (responseLicenses) {
        const responseLicensesSorted: Licenses[] = responseLicenses.sort(
          (a, b) => b.days_left - a.days_left
        )
        !didCancel && setUserLicenses(responseLicensesSorted)
      } else {
        !didCancel && setUserLicenses([])
      }
    }
    GetLicenses()
    return () => {
      didCancel = true
    }
  }, [])

  useEffect(() => {
    let errors = true

    if (password.currentPassword && password.newPassword) {
      errors = false
    }

    setFormPasswordErrors(errors)
  }, [password])

  useEffect(() => {
    let errors = false
    if (OrganizationData !== null) {
      if (!OrganizationData.account) {
        errors = true
      }

      if (!OrganizationData.address) {
        errors = false
      }

      if (!OrganizationData.billing_address) {
        errors = false
      }

      if (!OrganizationData.name) {
        errors = false
      }

      if (!OrganizationData.nif) {
        errors = false
      }
    }

    setformorganizationErrors(errors)
  }, [OrganizationData])

  const updatePassword = async () => {
    if (!formPasswordErrors) {
      const responseUpdatePassword = await userAPI.updatePassword(password)
      if (responseUpdatePassword) {
        toast.success('Your password has been changed successfully')

        setPassword(initPasswordValues)
      } else {
        toast.error('Your current password is wrong')
      }
    }
  }
  const downloadPDF = async (licenseid: string) => {
    await userAPI.DownloadLicense(licenseid)
  }
  const updateorganization = async () => {
    try {
      await userAPI.updateorganization(OrganizationData)
      // setValidateUpdate(true)
      toast.success('You have updated your data correctly')
    } catch (error) {
      setValidateUpdate(false)
    }
  }

  useEffect(() => {
    let didcancel = false

    const getOrganization = async () => {
      const Organization: Organization = await userAPI.getOrganization()
      !didcancel && setOrganizationData(Organization)
    }

    const getTutorial = () => {
      if (localStorage.getItem('isTutorialActive') !== null) {
        let tutorialStorage = String(localStorage.getItem('isTutorialActive'))

        let tutorialStorageBoolean: boolean =
          tutorialStorage === 'true' ? true : false
        settutorialToogle(tutorialStorageBoolean)
      }
    }

    getOrganization()
    getTutorial()

    return () => {
      didcancel = true
    }
  }, [])

  /*
  const DeleteImage = async () => {
    const ResponseDeleteImage = await userAPI.deleteLogoOrganization()
    setImageOrganization('')
    props.auth.changeimage('')
  }
  */

  const ChangeAutoRenew = async () => {
    const AutoRenewAux = !AutoRenew
    setAutoRenew(AutoRenewAux)
    await userAPI.updateAutoRenew(AutoRenewAux)
    toast.success('Your auto renew has been changed successfully')
  }
  const resetTutorial = () => {
    if (!tutorialToogle) {
      localStorage.setItem('isTutorialActive', 'true')
    } else {
      localStorage.setItem('isTutorialActive', 'false')
    }

    settutorialToogle(!tutorialToogle)
  }
  return (
    <>
      <Grid>
        <Grid.Column floated="left" width={5}>
          <Left>
            <HeaderContainer
              title="User Settings"
              user={auth.user}
            ></HeaderContainer>
          </Left>
        </Grid.Column>
        <Grid.Column floated="right" width={5}>
          <WrappePhoto>
            <label htmlFor="upload-button">
              <UploadImage
                onDrop={(e) => handleDrop(e)}
                onDragOver={(e) => handleDragOver(e)}
                onDragEnter={(e) => handleDragEnter(e)}
                onDragLeave={(e) => handleDragLeave(e)}
                title={ImageOrganization && 'upload'}
              >
                <LogoUpload
                  className="uploadimage"
                  title={ImageOrganization && 'upload'}
                >
                  <Icon name="cloud upload" size="large"></Icon>
                  <p>UPLOAD LOGO</p>
                  <input
                    type="file"
                    id="upload-button"
                    accept="image/x-png,image/gif,image/jpeg"
                    style={{ display: 'none' }}
                    onChange={handleChange}
                  />
                </LogoUpload>
                {ImageOrganization ? (
                  <>
                    <div
                      style={{
                        backgroundImage: 'url(' + ImageOrganization + ')',
                        height: '128px',
                        width: '128px',
                        backgroundSize: '100%',
                        backgroundRepeat: 'no-repeat'
                      }}
                    />
                  </>
                ) : (
                  <> </>
                )}
              </UploadImage>
            </label>
            {ImageOrganization && <> </>}
          </WrappePhoto>
        </Grid.Column>

        <Grid.Row columns={2}>
          <Grid.Column>
            {basicData && (
              <ColumnShadow>
                <H3title> My Profile </H3title>
                <Phrasegrey> Your personal information </Phrasegrey>
                <Grid>
                  <Grid.Column width={8}>
                    <span>User Name</span>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Input
                      placeholder=""
                      disabled
                      defaultValue={basicData.name}
                    />
                  </Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column width={8}>
                    <span>Email </span>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Input
                      placeholder=""
                      disabled
                      defaultValue={basicData.email}
                    />
                  </Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column width={8}>
                    <span>Show Welcome Tutorials?</span>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Toogle
                      toggle
                      checked={tutorialToogle && tutorialToogle}
                      onChange={resetTutorial}
                    />
                  </Grid.Column>
                </Grid>
                <GridPassword>
                  <Grid.Column width={8}>
                    <span>Old password </span>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Input
                      placeholder=""
                      onChange={(e) => {
                        setPassword({
                          ...password,
                          currentPassword: e.target.value
                        })
                      }}
                      type="password"
                      name="currentPassword"
                      defaultValue={password.currentPassword}
                    />
                  </Grid.Column>
                </GridPassword>
                <Grid>
                  <Grid.Column width={8}>
                    <span>New password </span>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Input
                      placeholder=""
                      onChange={(e) => {
                        setPassword({
                          ...password,
                          newPassword: e.target.value
                        })
                      }}
                      type="password"
                      name="newPassword"
                      defaultValue={password.newPassword}
                    />
                  </Grid.Column>
                </Grid>
                <Grid>
                  <GridColumnRight
                    className="paddings"
                    floated="right"
                    width={8}
                  >
                    <MainButton
                      disabled={formPasswordErrors}
                      onClick={(e: any) => {
                        e.preventDefault()
                        updatePassword()
                      }}
                    >
                      CHANGE PASSWORD
                    </MainButton>
                  </GridColumnRight>
                </Grid>
              </ColumnShadow>
            )}
          </Grid.Column>
          <Grid.Column>
            <ColumnShadowHeight>
              <Phraselock>
                <DivOrganization>
                  <H3title> Organization </H3title>
                  <Phrasegrey>
                    {' '}
                    Information reserved to Organization administrator{' '}
                  </Phrasegrey>
                </DivOrganization>
                {basicData && basicData.org_admin === true ? (
                  <Icon size="big" name="unlock" />
                ) : (
                  <Icon size="big" name="lock" />
                )}
              </Phraselock>
              {basicData && basicData.org_admin === true && OrganizationData ? (
                <>
                  <Grid>
                    <Grid.Column width={8}>
                      <span>Name </span>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Input
                        placeholder=""
                        defaultValue={OrganizationData.name}
                        onChange={(e) => {
                          setOrganizationData({
                            ...OrganizationData,
                            name: e.target.value
                          })
                        }}
                      />
                    </Grid.Column>
                  </Grid>
                  <Grid>
                    <Grid.Column width={8}>
                      <span>Address </span>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Input
                        placeholder=""
                        defaultValue={OrganizationData.address}
                        onChange={(e) => {
                          setOrganizationData({
                            ...OrganizationData,
                            address: e.target.value
                          })
                        }}
                      />
                    </Grid.Column>
                  </Grid>
                  <Grid>
                    <Grid.Column width={8}>
                      <span>CIF</span>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Input
                        placeholder=""
                        defaultValue={OrganizationData.nif}
                        onChange={(e) => {
                          setOrganizationData({
                            ...OrganizationData,
                            nif: e.target.value
                          })
                        }}
                      />
                    </Grid.Column>
                  </Grid>
                  <Grid>
                    <Grid.Column width={8}>
                      <span>Billing Address </span>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Input
                        placeholder=""
                        defaultValue={OrganizationData.billing_address}
                        onChange={(e) => {
                          setOrganizationData({
                            ...OrganizationData,
                            billing_address: e.target.value
                          })
                        }}
                      />
                    </Grid.Column>
                  </Grid>
                  <Grid>
                    <Grid.Column width={8}>
                      <span>Bank Account</span>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Input
                        placeholder=""
                        defaultValue={OrganizationData.account}
                        onChange={(e) => {
                          setOrganizationData({
                            ...OrganizationData,
                            account: e.target.value
                          })
                        }}
                      />
                    </Grid.Column>
                  </Grid>

                  <Grid>
                    <GridColumnRight floated="right" width={8}>
                      {ValidateUpdate && (
                        <Message positive size="tiny">
                          <Message.Header>
                            You have updated your data correctly
                          </Message.Header>
                        </Message>
                      )}
                    </GridColumnRight>
                    <GridColumnRight floated="right" width={8}>
                      <MainButton
                        disabled={formorganizationErrors}
                        onClick={() => updateorganization()}
                      >
                        UPDATE
                      </MainButton>
                    </GridColumnRight>
                  </Grid>
                </>
              ) : (
                <GridUserLock> {UserLock} </GridUserLock>
              )}
            </ColumnShadowHeight>
          </Grid.Column>
        </Grid.Row>

        <ToastContainer />
      </Grid>
      <GridTable>
        <Grid.Column floated="left" width={8}>
          <H3title>Licenses </H3title>
          <Phrasegrey> Your license status and information </Phrasegrey>
        </Grid.Column>
        <Grid.Column floated="right" width={8}>
          <LegalTextsContainer>
            {auth.user !== null && auth.user.is_trial === true ? (
              <ModalLegal text="Terms & conditions trial" />
            ) : (
              <ModalLegal text="Terms & conditions" />
            )}
          </LegalTextsContainer>
          {UserLicenses !== undefined &&
            UserLicenses.length > 0 &&
            !UserLicenses[0].trial && (
              <Renew>
                <div>
                  <b> AUTOMATIC RENEWAL </b>
                  <p>
                    {new Intl.DateTimeFormat('es-ES', {}).format(
                      new Date(
                        UserLicenses[0].datetime_unix * 1000 +
                          86400000 * UserLicenses[0].duration
                      ).valueOf()
                    )}
                  </p>
                </div>
                <Toogle
                  toggle
                  checked={AutoRenew && AutoRenew}
                  onChange={ChangeAutoRenew}
                />
              </Renew>
            )}
        </Grid.Column>
        <GridShadowTable width={5}>
          <Grid.Column>
            <TableUI striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell textAlign="left" width={3}>
                    Reference
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="left" width={2}>
                    Start on
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="left" width={2}>
                    Finishes on
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="left" width={2}>
                    Payment Status
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="left" width={7}>
                    PDF
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {UserLicenses &&
                  UserLicenses.map((license: Licenses, i: number) => {
                    return (
                      <Table.Row key={license.reference}>
                        <Table.Cell>{license.reference}</Table.Cell>
                        <Table.Cell textAlign="left">
                          {new Intl.DateTimeFormat('es-ES', {}).format(
                            new Date(license.datetime_unix * 1000).valueOf()
                          )}
                        </Table.Cell>
                        <Table.Cell textAlign="left">
                          {new Intl.DateTimeFormat('es-ES', {}).format(
                            new Date(
                              license.datetime_unix * 1000 +
                                86400000 * license.duration
                            ).valueOf()
                          )}
                        </Table.Cell>
                        <Table.Cell textAlign="left">
                          {' '}
                          {license.payment === 'COMPLETED' && (
                            <Completed>{license.payment}</Completed>
                          )}
                          {license.payment === 'PENDING' && (
                            <Pending>{license.payment}</Pending>
                          )}
                          {license.payment === 'FREE' && (
                            <Free>{license.payment}</Free>
                          )}
                        </Table.Cell>
                        <PDFColumn textAlign="left">
                          {' '}
                          {license.pdf_available && (
                            <Icon
                              name="download"
                              onClick={(e: any) => downloadPDF(license.id)}
                              size="large"
                            />
                          )}
                        </PDFColumn>
                      </Table.Row>
                    )
                  })}
              </Table.Body>
            </TableUI>
            <MainButton
              onClick={(e: any) => {
                setOpenModal(true)
              }}
            >
              RENEW NOW
            </MainButton>
            {openModal && (
              <Modal
                onClose={() => setOpenModal(false)}
                onOpen={() => setOpenModal(true)}
                open={openModal}
                size={'tiny'}
              >
                <Modal.Content>
                  <Modaldes>
                    <Header>RENEW YOUR LICENSE</Header>
                    <Phrasegreysize>
                      If you want to renew your license please <br />
                      contact our sales deparment{' '}
                    </Phrasegreysize>
                    <br />
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="mailto:hello@kumux.io"
                    >
                      <p style={{ color: 'rgba(0,0,0,.87)' }}>hello@kumux.io</p>
                    </a>
                    <br />
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="mailto:hello@kumux.io"
                    >
                      {' '}
                      <MainButton>TALK TO SALES</MainButton>{' '}
                    </a>
                  </Modaldes>
                </Modal.Content>
              </Modal>
            )}
          </Grid.Column>
        </GridShadowTable>
      </GridTable>
    </>
  )
}
const Modaldes = styled(Modal.Description)`
  text-align: center;
`
const WrappePhoto = styled.div`
  text-align: center;
  float: right;
  margin-top: 45px;
  margin-right: 30px;
`
const Renew = styled.div`
  display: flex;
  justify-content: flex-end;
  div {
    margin-right: 20px;
    text-align: right;
  }
  p {
    font-size: 12px;
  }

  align-items: center;
`

const GridUserLock = styled.div`
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    font-size: 125px;
  }
  color: ${(props: CompProps) => (props.theme as Theme).color.white.darkest};
`
const GridPassword = styled(Grid)`
  margin-top: 80px !important;
`
const GridColumnRight = styled(Grid.Column)`
  button {
    float: right;
  }
  padding-top: 30px !important;
`
const GridTable = styled(Grid)`
  margin: 30px 0 !important;
  padding-top: 30px !important;
  box-shadow: ${(props: CompProps) =>
    (props.theme as Theme).filters.drop_shadow};

  .left.column {
    padding-left: 30px !important;
  }
  .right.column {
    padding-right: 15px !important;
  }
`
const Phrasegreysize = styled(Phrasegrey)`
  font-size: 17px;
`

const Completed = styled.span`
  color: #5a9351;
`

const Pending = styled.span`
  color: red;
`
const Free = styled.span`
  color: #5a9351;
`

const PDFColumn = styled(Table.Cell)`
  i {
    cursor: pointer;
  }
`

const ColumnShadow = styled.div`
  box-shadow: ${(props: CompProps) =>
    (props.theme as Theme).filters.drop_shadow};
  padding: 30px;
  .grid {
    align-items: center !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    border-bottom: 1px solid #e8e8e8;
  }
  .grid:last-child {
    border-bottom: 0px solid !important;
  }
  .input {
    width: 100%;
  }
  .column {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  height: 100%;
`
const Left = styled.div`
  margin-left: 0px !important;
`
const GridShadow = styled(Grid.Row)`
  box-shadow: ${(props: CompProps) =>
    (props.theme as Theme).filters.drop_shadow};
  padding: 15px;
`
const GridShadowTable = styled(GridShadow)`
  box-shadow: none;
  margin: 0px 15px;
  margin-bottom: 15px;
  button {
    float: right;
  }
  table {
    padding-left: 0px;
    padding-right: 0px;
  }
  td {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  padding: 4px;
  th {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
`

const ColumnShadowHeight = styled(ColumnShadow)`
  height: 100%;
`

const LogoUpload = styled.div`
  ${({ title }) =>
    title === 'upload' &&
    `display:none; 
    :hover{ position:absolute; }
  `}
  width: 143px;
  height: 143px;
  background-color: rgba(0, 0, 0, 0.5);
  * {
    color: ${(props: CompProps) => (props.theme as Theme).color.white.regular};
  }
  p {
    margin-top: 10px;
  }
  align-items: middle;
  padding-top: 50px;
`

const H3title = styled.h3`
  margin-bottom: 5px;
`
const Phraselock = styled.div`
  display: flex;
  justify-content: space-between;
`
const DivOrganization = styled.div`
  margin-bottom: 12px;
`

const LegalTextsContainer = styled.div`
  margin: 15px 15px;
  position: absolute;
  top 15px;
  right: 15px;
`

export default Profile
