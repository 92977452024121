import React from 'react'
import styled from 'styled-components'
import { CompProps, Theme } from '../styles/theme'

import SpectraLineBars from '../components/charts/SpectraLineBars'

interface Props {
  info: any
}

const LedInfoModal: React.FC<Props> = (props) => {
  if (props.info) {
    return (
      <LedModalWrapper>
        <LedTittleContainer>
          <LedInfoTitle>{props.info.reference}</LedInfoTitle>
          <LedInfoSubTitle>
            {props.info.family} / {props.info.brand}
          </LedInfoSubTitle>
        </LedTittleContainer>
        <LedDataWrapper>
          <LedInfoContainer>
            {props.info.params.hasOwnProperty('cct_k') && (
              <LedInfoSection>
                <InfoTitle>CCT (K) </InfoTitle>
                <InfoText>
                  {props.info.params.cct_k === -1
                    ? '-'
                    : props.info.params.cct_k}
                </InfoText>
              </LedInfoSection>
            )}
            {props.info.params.hasOwnProperty('duv') && (
              <LedInfoSection>
                <InfoTitle>Duv </InfoTitle>
                <InfoText>
                  {props.info.params.duv === -1 ? '-' : props.info.params.duv}
                </InfoText>
              </LedInfoSection>
            )}
            {props.info.params.hasOwnProperty('cri') && (
              <LedInfoSection>
                <InfoTitle>CRI </InfoTitle>
                <InfoText>
                  {props.info.params.cri < 0 ? '-' : props.info.params.cri}
                </InfoText>
              </LedInfoSection>
            )}
            {props.info.params.hasOwnProperty('r9') && (
              <LedInfoSection>
                <InfoTitle>R9 </InfoTitle>
                <InfoText>
                  {props.info.params.r9 < 0 ? '-' : props.info.params.r9}
                </InfoText>
              </LedInfoSection>
            )}
            {props.info.params.hasOwnProperty('rf') && (
              <LedInfoSection>
                <InfoTitle>RF </InfoTitle>
                <InfoText>
                  {props.info.params.rf === -1 ? '-' : props.info.params.rf}
                </InfoText>
              </LedInfoSection>
            )}
            {props.info.params.hasOwnProperty('rg') && (
              <LedInfoSection>
                <InfoTitle>Rg </InfoTitle>
                <InfoText>
                  {props.info.params.rg === -1 ? '-' : props.info.params.rg}
                </InfoText>
              </LedInfoSection>
            )}
            {props.info.params.hasOwnProperty('coi') && (
              <LedInfoSection>
                <InfoTitle>COI</InfoTitle>
                <InfoText>
                  {props.info.params.coi === -1 ? '-' : props.info.params.coi}
                </InfoText>
              </LedInfoSection>
            )}
            {props.info.params.hasOwnProperty('peak_wavelength') && (
              <LedInfoSection>
                <InfoTitle>Peak Wavelength (nm)</InfoTitle>
                <InfoText>
                  {props.info.params.peak_wavelength === -1
                    ? '-'
                    : props.info.params.peak_wavelength}
                </InfoText>
              </LedInfoSection>
            )}
            {props.info.params.hasOwnProperty('x') && (
              <LedInfoSection>
                <InfoTitle>X</InfoTitle>
                <InfoText>
                  {props.info.params.x === -1 ? '-' : props.info.params.x}
                </InfoText>
              </LedInfoSection>
            )}
            {props.info.params.hasOwnProperty('y') && (
              <LedInfoSection>
                <InfoTitle>Y </InfoTitle>
                <InfoText>
                  {props.info.params.y === -1 ? '-' : props.info.params.y}
                </InfoText>
              </LedInfoSection>
            )}
            {props.info.params.hasOwnProperty('mp_ratio') && (
              <LedInfoSection>
                <InfoTitle>M/P ratio </InfoTitle>
                <InfoText>
                  {props.info.params.mp_ratio === -1
                    ? '-'
                    : props.info.params.mp_ratio}
                </InfoText>
              </LedInfoSection>
            )}
            {props.info.params.hasOwnProperty('mder') && (
              <LedInfoSection>
                <InfoTitle>MDER </InfoTitle>
                <InfoText>
                  {props.info.params.mder === -1 ? '-' : props.info.params.mder}
                </InfoText>
              </LedInfoSection>
            )}
            {props.info.params.hasOwnProperty('eml_100') && (
              <LedInfoSection>
                <InfoTitle>EML @ 100 lx</InfoTitle>
                <InfoText>
                  {props.info.params.eml_100 === -1
                    ? '-'
                    : props.info.params.eml_100}
                </InfoText>
              </LedInfoSection>
            )}
            {props.info.params.hasOwnProperty('medi_100') && (
              <LedInfoSection>
                <InfoTitle>MEDI @ 100 lx</InfoTitle>
                <InfoText>
                  {props.info.params.medi_100 === -1
                    ? '-'
                    : props.info.params.medi_100}
                </InfoText>
              </LedInfoSection>
            )}
            {props.info.params.hasOwnProperty('cs_100') && (
              <LedInfoSection>
                <InfoTitle>CS @ 100 lx</InfoTitle>
                <InfoText>
                  {props.info.params.cs_100 === -1
                    ? '-'
                    : props.info.params.cs_100}
                </InfoText>
              </LedInfoSection>
            )}
            {props.info.params.hasOwnProperty('cla_100') && (
              <LedInfoSection>
                <InfoTitle>CLA @ 100 lx</InfoTitle>
                <InfoText>
                  {props.info.params.cla_100 === -1
                    ? '-'
                    : props.info.params.cla_100}
                </InfoText>
              </LedInfoSection>
            )}
            {props.info.params.hasOwnProperty('vf_v') && (
              <LedInfoSection>
                <InfoTitle>Voltage forward (V)</InfoTitle>
                <InfoText>
                  {props.info.params.vf_v === -1 ? '-' : props.info.params.vf_v}
                </InfoText>
              </LedInfoSection>
            )}
            {props.info.params.hasOwnProperty('if_ma') && (
              <LedInfoSection>
                <InfoTitle>Current (mA)</InfoTitle>
                <InfoText>
                  {props.info.params.if_ma === -1
                    ? '-'
                    : props.info.params.if_ma}
                </InfoText>
              </LedInfoSection>
            )}
            {props.info.params.hasOwnProperty('electrical_power_w') && (
              <LedInfoSection>
                <InfoTitle>Electrical Power (W)</InfoTitle>
                <InfoText>
                  {props.info.params.electrical_power_w === -1
                    ? '-'
                    : props.info.params.electrical_power_w}
                </InfoText>
              </LedInfoSection>
            )}
            {props.info.params.hasOwnProperty('luminous_flux_lm') && (
              <LedInfoSection>
                <InfoTitle>Luminous Flux (lm)</InfoTitle>
                <InfoText>
                  {props.info.params.luminous_flux_lm === -1
                    ? '-'
                    : props.info.params.luminous_flux_lm}
                </InfoText>
              </LedInfoSection>
            )}
            {props.info.params.hasOwnProperty('radiant_flux_mw') && (
              <LedInfoSection>
                <InfoTitle>Radiant Flux (mW)</InfoTitle>
                <InfoText>
                  {props.info.params.radiant_flux_mw === -1
                    ? '-'
                    : props.info.params.radiant_flux_mw}
                </InfoText>
              </LedInfoSection>
            )}
            {props.info.params.hasOwnProperty('ler_lmw') && (
              <LedInfoSection>
                <InfoTitle>LER (lm/W)</InfoTitle>
                <InfoText>
                  {props.info.params.ler_lmw === -1
                    ? '-'
                    : props.info.params.ler_lmw}
                </InfoText>
              </LedInfoSection>
            )}
            {props.info.params.hasOwnProperty('efficiency_lmw') && (
              <LedInfoSection>
                <InfoTitle>Efficiency (lm/W)</InfoTitle>
                <InfoText>
                  {props.info.params.efficiency_lmw === -1
                    ? '-'
                    : props.info.params.efficiency_lmw}
                </InfoText>
              </LedInfoSection>
            )}
          </LedInfoContainer>
          <LedGraphicsContainer>
            <SpectraLineBars
              intensity={props.info.params.irradiance_norm}
              wavelength={[]}
              canvas_width={'555px'}
            />
          </LedGraphicsContainer>
        </LedDataWrapper>
      </LedModalWrapper>
    )
  }

  return <div />
}

const LedModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`

const LedTittleContainer = styled.div`
  margin-bottom: 30px;
`

const LedInfoTitle = styled.h5`
  margin-top: 0;
  margin-bottom: 5px;
  text-transform: uppercase;
  ${(props: CompProps) => (props.theme as Theme).font_size.regular};
`

const LedInfoSubTitle = styled.p`
  color: ${(props: CompProps) => (props.theme as Theme).color.black.light};
  ${(props: CompProps) => (props.theme as Theme).font_size.xsmall};
`

const LedDataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const LedInfoContainer = styled.div`
  display: flex;
  flex: 0 0 35%;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-right: 15px;
  margin-bottom: 30px;
`

const LedInfoSection = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #bfbfbf;
  padding-bottom: 5px;
  margin-bottom: 5px;
`

const InfoTitle = styled.h5`
  margin: 0;
  font-family: ${(props: CompProps) => (props.theme as Theme).font.semibold};
  ${(props: CompProps) => (props.theme as Theme).font_size.xxxsmall};
`

const InfoText = styled.div`
  ${(props: CompProps) => (props.theme as Theme).font_size.xxxsmall};
`

const LedGraphicsContainer = styled.div`
  display: flex;
  flex: 0 0 65%;
  justify-content: center;
`

export default LedInfoModal
