import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { CountDecimals } from '../../utils/commons'
import { simulationApi } from '../../api/SimulationApi'

import Loading from '../../components/common/Loading'
import SpectraLineBars from '../../components/charts/SpectraLineBars'
import CIEScatterPlot from '../../components/charts/CIEScatterPlot'
import { Theme, CompProps } from '../../styles/theme'

import AnalyticsListener from '../../api/AnalyticsListener'
import Tour from '../help/Tour'
import { Dropdown, Tab } from 'semantic-ui-react'
import { FilterDiagram, FilterSections, GraphsContainer } from './types'

import ModalSemantic from '../../components/common/ModalSemantic'

import ImageSection from './ImageSection'
import CombFilter from './CombFilter'
import DiagramChart from './DiagramChart'
import Filters from './Filters'
import ModalCreateScene from './ModalCreateScene'

interface Props {
  simulationDetailInfo: any

  activeTour: boolean
  savedSpectralength: number

  callbackSavedSpectra: Function
  savedSpectra: any
  callbackCreatingSceneLoading: Function
}

const SimulationFilters: React.FC<Props> = (props) => {
  const {
    callbackSavedSpectra,
    savedSpectra,
    callbackCreatingSceneLoading
  } = props

  const [initFilter, setInitFilter] = useState()
  const [numResults, setNumResults] = useState(0)
  const [numResultsTotal, setNumResultsTotal] = useState(0)
  const [auxnumResults, setAuxNumResults] = useState(0)
  const [newSceneName, setNewSceneName] = useState('')
  const [disableNewScene, setDisableNewScene] = useState(false)
  const [loading, setLoading] = useState(true)
  const [filter, setFilter] = useState()
  const [resultsFilters, setResultsFilters] = useState()
  const [popupCreate, setpopupCreate] = useState(false)

  const [dynamicSpdW, setDynamicSpdW] = useState<Array<number>>()
  const [dynamicSpdI, setDynamicSpdI] = useState<Array<number>>()
  const [dynamicCie, setDynamicCie] = useState()
  const [filtersDiagrams, setFiltersDiagrams] = useState<Array<FilterDiagram>>()
  const [filtersSections, setFiltersSections] = useState<FilterSections>()
  const [switchCri, setSwitchCri] = useState(false)
  const [switchEfficiency, setSwitchEfficiency] = useState(false)
  const [spectrumId, setSpectrumId] = useState('')
  const MIN_LENGTH_SCENE_NAME = 1
  const [illuminance, setilluminance] = useState<number[]>([100, 100])
  const [panes, setpanes] = useState<any[]>([])
  const [panesSPD, setpanesSPD] = useState<any[]>([])

  useEffect(() => {
    if (filtersDiagrams) {
      const panesAux: any[] = Object.assign([], panes)
      panesAux.push({
        menuItem: 'CIE 1931',
        render: () => (
          <Tab.Pane>
            {dynamicCie && !loading ? (
              <CIEScatterPlot xys={dynamicCie} />
            ) : (
              <LoadingCieContainer>
                <Loading />
              </LoadingCieContainer>
            )}
          </Tab.Pane>
        )
      })
      filtersDiagrams.map((diagram: FilterDiagram, index: number) =>
        panesAux.push({
          menuItem: diagram.title,
          render: () => (
            <Tab.Pane>
              <ImageSection
                key={index}
                title={diagram.title}
                src={diagram.src}
              />
            </Tab.Pane>
          )
        })
      )
      setpanes(panesAux)
    }
  }, [filtersDiagrams, dynamicCie])

  useEffect(() => {
    const panesAux: any[] = Object.assign([], panesSPD)
    if (dynamicSpdI && dynamicSpdW) {
      panesAux.push({
        menuItem: 'SUM SPD',
        render: () => (
          <Tab.Pane className="SPD">
            <SpdContainer>
              {dynamicSpdI && dynamicSpdW && !loading ? (
                <SpectraLineBars
                  intensity={dynamicSpdI}
                  wavelength={dynamicSpdW}
                  canvas_width={'775px'}
                />
              ) : (
                <Loading />
              )}
            </SpdContainer>
          </Tab.Pane>
        )
      })
      panesAux.push({
        menuItem: 'SPD LIST',
        render: () => <Tab.Pane className="listSPD">sdasd</Tab.Pane>
      })
      panesAux.push({
        menuItem: {
          content: (
            <div>
              SHORT BY:
              <Dropdown fluid selection />
            </div>
          ),
          key: 'Search'
        }
      })
    }

    setpanesSPD(panesAux)
  }, [dynamicSpdI, dynamicSpdW])
  useEffect(() => {
    if (newSceneName.length >= MIN_LENGTH_SCENE_NAME) {
      setDisableNewScene(false)
    } else {
      setDisableNewScene(true)
    }
  }, [newSceneName])

  useEffect(() => {
    let cleanup = false
    setLoading(true)
    const getFilters = async () => {
      !filter &&
        simulationApi
          .getFiltersData(props.simulationDetailInfo.public_id)
          .then((filtersDataResponse) => {
            if (filtersDataResponse.sliders) {
              !cleanup && setFiltersData(filtersDataResponse)
            }

            !cleanup && setLoading(false)
          })
    }

    !cleanup && getFilters()

    return () => {
      cleanup = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (resultsFilters) {
      if (resultsFilters.cri[0] >= 80 || resultsFilters.cri[1] <= 80) {
        setSwitchCri(true)
      } else {
        setSwitchCri(false)
      }

      if (
        resultsFilters.efficiency_lmw[0] >= 80 ||
        resultsFilters.efficiency_lmw[1] <= 80
      ) {
        setSwitchEfficiency(true)
      } else {
        setSwitchEfficiency(false)
      }
    }
  }, [resultsFilters])

  const setDiagrams = async (filters: any) => {
    const rfvsrg = simulationApi.getStaticRfVsRg(
      props.simulationDetailInfo.public_id,
      filters
    )

    const rfvscct = simulationApi.getStaticRfVsCct(
      props.simulationDetailInfo.public_id,
      filters
    )

    const effvscct = simulationApi.getStaticEffVsCct(
      props.simulationDetailInfo.public_id,
      filters
    )

    const mpvscct = simulationApi.getStaticMpVsCCt(
      props.simulationDetailInfo.public_id,
      filters
    )

    Promise.all([rfvsrg, rfvscct, effvscct, mpvscct]).then(function (values) {
      const diagrams: Array<FilterDiagram> = [
        {
          title: 'Rg vs Rf',
          src: values[0].img_src
        },
        {
          title: 'Rf vs CCT',
          src: values[1].img_src
        },
        {
          title: 'Eff vs CCT',
          src: values[2].img_src
        },
        {
          title: 'M/P vs CCT',
          src: values[3].img_src
        }
      ]

      setFiltersDiagrams(diagrams)
    })
  }
  const handleOnChangeCreateScene = (nameScene: string) => {
    setNewSceneName(nameScene)
  }
  const handleOnChangeIluminace = (position: number, newIluminance: number) => {
    const illuminanceAux: number[] = Object.assign([], illuminance)
    illuminanceAux[position] = Number(newIluminance)
    setilluminance(illuminanceAux)
  }
  const fillFiltersSections = () => {
    const filtersSectionsAux: FilterSections = {
      colorimetric: [
        {
          title: 'CCT (K)',
          type: 'cct'
        },
        {
          title: 'Duv',
          type: 'duv'
        },
        {
          title: 'Rf',
          type: 'rf'
        },
        {
          title: 'Rg',
          type: 'rg'
        },
        {
          title: 'CRI',
          type: 'cri'
        },
        {
          title: 'R9',
          type: 'r9'
        },
        {
          title: 'COI',
          type: 'coi'
        }
      ],
      nonVisual: [
        {
          title: 'M/P ratio',
          type: 'mp_ratio'
        },
        {
          title: 'MDER',
          type: 'mder'
        },
        {
          title: '440-490nm %',
          type: 'ul'
        }
      ],
      power: [
        {
          title: 'Efficiency (lm/W)',
          type: 'efficiency_lmw'
        },
        {
          title: 'Electrical Power (W)',
          type: 'electrical_power_w'
        },
        {
          title: 'Luminous Flux (lm)',
          type: 'luminous_flux_lm'
        },
        {
          title: 'LER (lm/W)',
          type: 'ler_lmw'
        }
      ],
      hcl: [
        {
          title: 'EML',
          type: 'eml_100'
        },
        {
          title: 'MEDI',
          type: 'medi_100'
        },
        {
          title: 'CS',
          type: 'cs_100'
        },
        {
          title: 'CLA',
          type: 'cla_100'
        }
      ]
    }

    setFiltersSections(filtersSectionsAux)
  }
  const callBackPopupCreate = () => {
    const popupCreateAux = !popupCreate
    setpopupCreate(popupCreateAux)
  }
  const setFiltersData = (filtersData: any) => {
    const slidersStringify = JSON.parse(JSON.stringify(filtersData.sliders))

    setFilter(slidersStringify)
    setResultsFilters(slidersStringify)
    setInitFilter(slidersStringify)
    setNumResults(filtersData.number_results)
    setNumResultsTotal(filtersData.number_results)
    setDynamicCharts(filtersData.sliders)
    filtersData.selected_spectrum_id &&
      setSpectrumId(filtersData.selected_spectrum_id)
    setDiagrams(filtersData.sliders)
    fillFiltersSections()
  }

  const setDynamicCharts = async (sliders: any) => {
    setDynamicSpdW(undefined)
    setDynamicSpdI(undefined)

    simulationApi
      .getDynamicSpd(props.simulationDetailInfo.public_id, sliders)
      .then((result) => {
        const intensity: Array<number> = result.data.map(
          (coord: Array<number>) => {
            return coord[1]
          }
        )
        const wavelength: Array<number> = result.data.map(
          (coord: Array<number>) => {
            return coord[0]
          }
        )
        setDynamicSpdW(wavelength)
        setDynamicSpdI(intensity)
      })

    setDynamicCie(undefined)

    simulationApi
      .getDynamicCie(props.simulationDetailInfo.public_id, sliders)
      .then((result) => {
        setDynamicCie(result.data)
      })
  }
  /*
  const createSpectra = async () => {
    setNewSceneName('')
    setpopupCreate(false)
    await props.createSpectra(
      props.simulationDetailInfo.public_id,
      newSceneName,
      spectrumId
    )
  }
*/
  const resetFilters = async () => {
    setLoading(true)
    AnalyticsListener.createRouteView('reset_filters')
    const filtersDataResponse = await simulationApi.getFiltersData(
      props.simulationDetailInfo.public_id
    )

    if (filtersDataResponse.sliders) {
      setFiltersData(filtersDataResponse)
    }

    setLoading(false)
  }

  const newRange = (value: any, slider: any, range: number) => {
    const newRangeMin = filter[slider][0]
    const newRangeMax = filter[slider][1]

    const m = (newRangeMax - newRangeMin) / (100 - 0)
    const n = newRangeMin - 0 * m
    const y = m * value + n

    return y.toFixed(CountDecimals(filter[slider][range]))
  }

  let debounce: number = 0

  const onAfterChangeSlider = async (
    slider: string,
    resultsFiltersParam: any = undefined
  ) => {
    debounce++
    if (debounce === 1) {
      const resultFilter = resultsFiltersParam || resultsFilters
      setLoading(true)

      AnalyticsListener.createRouteView(`filters_slider/${slider}`)

      setDynamicCie(undefined)
      setFiltersDiagrams(undefined)

      const responseFilters = await simulationApi.setFilters(
        resultFilter,
        props.simulationDetailInfo.public_id
      )

      const { sliders, number_results, selected_spectrum_id } = responseFilters
      const slidersStringify = JSON.parse(JSON.stringify(sliders))

      setDynamicCharts(sliders)
      setDiagrams(slidersStringify)
      setResultsFilters(slidersStringify)
      setFilter(slidersStringify)
      selected_spectrum_id && setSpectrumId(selected_spectrum_id)
      number_results && setNumResults(number_results)

      setLoading(false)
      debounce--
    }
  }

  useEffect(() => {
    if (auxnumResults === 0) {
      setAuxNumResults(numResults)
    }
    if (numResults === 1) {
      setpopupCreate(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numResults])

  const onChangeFilter = (values: any, slider: string) => {
    //AnalyticsListener.createRouteView(`filters_slider/${slider}`)

    const auxResultsFilters = JSON.parse(JSON.stringify(resultsFilters))

    const lowerValue = newRange(values[0], slider, 0)
    const higherValue = newRange(values[1], slider, 1)

    auxResultsFilters[slider][0] = lowerValue
    auxResultsFilters[slider][1] = higherValue

    setResultsFilters(auxResultsFilters)
  }

  const onChangeSwitch = (type: string) => {
    const auxResultsFilters = JSON.parse(JSON.stringify(resultsFilters))
    auxResultsFilters[type][0] = 80
    onAfterChangeSlider(type, auxResultsFilters)
  }

  return (
    <SimulationFiltersContainer>
      <SubtitleSectionRegularContainer>
        <TitleWrapper>
          <h3>Scene Creator</h3>
          <span>
            <h2>{props.simulationDetailInfo.reference}</h2>
          </span>
        </TitleWrapper>

        {filter &&
          !loading &&
          numResults !== auxnumResults &&
          numResults !== 1 &&
          props.activeTour &&
          props.savedSpectralength === 0 && (
            <Tour section="SIMULATION_SCENE_RESULTS"></Tour>
        )}
  
        {numResults === 1 && !loading && popupCreate && (
          <ModalSemantic
            title="Create Scene"
            size="large"
            isOpen={popupCreate}
            closeCallBack={callBackPopupCreate}
            trigger={<></>}
          >
            <ModalCreateScene
              callbackCreatingSceneLoading={callbackCreatingSceneLoading}
              callBackPopupCreate={callBackPopupCreate}
              savedSpectra={savedSpectra}
              callbackSavedSpectra={callbackSavedSpectra}
              luminaryId={props.simulationDetailInfo.public_id}
              spectrumId={spectrumId}
              illuminance={illuminance}
              activeTour={props.activeTour}
              filtersSections={filtersSections}
              disableNewScene={disableNewScene}
              callbackOnChangeName={handleOnChangeCreateScene}
              loading={loading}
              newSceneName={newSceneName}
            />
          </ModalSemantic>
        )}
      </SubtitleSectionRegularContainer>
      <SimulationFiltersGraphsContainer loadingComponent={loading}>
        <LeftColumns>
          <DiagramChart
            filtersDiagrams={filtersDiagrams}
            dynamicCie={dynamicCie}
            loading={loading}
          />
          <CombFilter
            filter={filter}
            numResults={numResults}
            loading={loading}
            numResultsTotal={numResultsTotal}
            callbackChangeiluminance={handleOnChangeIluminace}
            illuminance={illuminance}
          />
        </LeftColumns>
        <RightColumns>
          {/*
          {panesSPD && panesSPD.length > 0! && !loading ? (
            <TabSPD panes={panesSPD} />
          ) : (
            <LoadingCieContainer>
              <Loading />
            </LoadingCieContainer>
          )}  */}
          <SpdContainer>
            {dynamicSpdI && dynamicSpdW && !loading ? (
              <SpectraLineBars
                intensity={dynamicSpdI}
                wavelength={dynamicSpdW}
                canvas_width={'680px'}
              />
            ) : (
              <Loading />
            )}
          </SpdContainer>
          <Filters
            activeTour={props.activeTour}
            popupCreate={popupCreate}
            CallBackPopupCreate={callBackPopupCreate}
            disableNewScene={disableNewScene}
            HandleOnChangeCreateScene={handleOnChangeCreateScene}
            dynamicSpdI={dynamicSpdI}
            dynamicSpdW={dynamicSpdW}
            newSceneName={newSceneName}
            filter={filter}
            numResults={numResults}
            loading={loading}
            onChangeSwitch={onChangeSwitch}
            resetFilters={resetFilters}
            switchCri={switchCri}
            switchEfficiency={switchEfficiency}
            initFilter={initFilter}
            filtersSections={filtersSections && filtersSections}
            resultsFilters={resultsFilters}
            onChangeFilter={onChangeFilter}
            onAfterChangeSlider={onAfterChangeSlider}
            iluminance={illuminance}
          />
        </RightColumns>
      </SimulationFiltersGraphsContainer>
    </SimulationFiltersContainer>
  )
}

const SimulationFiltersContainer = styled.div`
  margin-top: 25px;
`

const SimulationFiltersGraphsContainer = styled.div<GraphsContainer>`
  display: flex;
  /* justify-content: space-between;*/
  margin-top: 15px;
 
  ${(props: GraphsContainer) =>
    props.loadingComponent && 'align-items: center;'}
`

const SpdContainer = styled.div`
  display: block;
  width: 855px;
  height: 368px;
  padding-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props: CompProps) => (props.theme as Theme).filters.drop_shadow};
`

const LoadingCieContainer = styled.div`
  display: block;
  min-width: 425px;
  min-height: 425px;
  ${(props: CompProps) => (props.theme as Theme).filters.drop_shadow};
`

const LeftColumns = styled.div`
  flex-direction: column;
  width: 400px;
  align-items: flex-start;

  .tour-scene_creator {
    margin-top: 15px;
    color: ${(props: CompProps) => (props.theme as Theme).color.neutral._700};
  }
  .tour-number-results {
    margin-top: 15px;
    margin-bottom: 20px;
  }
`

const RightColumns = styled.div`
  flex-direction: column;
  padding-left: 15px;
  /*width: 60%;*/
  width: 900px;
`

export const CreateSceneContainer = styled.div`
  display: flex;
  flex: 0 0 30%;
  justify-content: space-between;
  margin-left: 370px;
  input {
    background-color: ${(props: CompProps) =>
      (props.theme as Theme).color.white.lessdark};
    border-radius: 6px;
    -webkit-appearance: none;
    outline: none;
    padding: 6px;
    border: 0;
    &:hover {
      background-color: ${(props: CompProps) =>
        (props.theme as Theme).color.white.darker};
    }
    &:focus {
      background-color: ${(props: CompProps) =>
        (props.theme as Theme).color.white.darker};
    }
  }
`

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  border-bottom: 1px solid #e4e4e4;
  h3 {
    flex-grow: 1;
  }
`

const SubtitleSectionRegularContainer = styled.div`
  margin-top: 30px !important;
  margin-bottom: 15px !important;
`

export default SimulationFilters
