import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Table, Modal } from 'semantic-ui-react'
import { sceneApi, Scene } from '../../api/SceneApi'
import { CompProps, Theme } from '../../styles/theme'
import SpectraLineBars from '../charts/SpectraLineBars'

import { LoadingContainer } from '../../styles/commons'
import Loading from '../../components/common/Loading'

interface Props {
  sceneId: string
}

const ModalScene: React.FC<Props> = (props) => {
  const { sceneId } = props
  const [isLoading, setIsLoading] = useState(true)
  const [scene, setScene] = useState<Scene | any>()

  useEffect(() => {
    let didCancel: boolean = false
    const getScene = async () => {
      let didCancel: boolean = false
      const sceneResponse: Scene = await sceneApi.getScene(sceneId)
      if (sceneResponse) {
        !didCancel && setScene(sceneResponse)
      }
      !didCancel && setIsLoading(false)
    }

    !didCancel && setIsLoading(true)
    getScene()
    return () => {
      didCancel = true
    }
  }, [sceneId])

  return (
    <>
      {isLoading ? (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      ) : (
        <>
          <Modal.Header>{scene.reference}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <SceneDataWrapper>
                <SceneInfoContainer>
                  <Table basic="very" celled size="large">
                    <Table.Body>
                      <Table.Row textAlign="left">
                        <InfoTitle>CCT (K)</InfoTitle>
                        <InfoText>
                          {scene.data.cct !== undefined && scene.data.cct}
                        </InfoText>
                      </Table.Row>
                      <Table.Row textAlign="left">
                        <InfoTitle>DUV</InfoTitle>
                        <InfoText>{scene.data.duv}</InfoText>
                      </Table.Row>
                      <Table.Row textAlign="left">
                        <InfoTitle>CRI</InfoTitle>
                        <InfoText>{scene.data.cri}</InfoText>
                      </Table.Row>
                      <Table.Row textAlign="left">
                        <InfoTitle>R9</InfoTitle>
                        <InfoText>{scene.data.r9}</InfoText>
                      </Table.Row>
                      <Table.Row textAlign="left">
                        <InfoTitle>RF</InfoTitle>
                        <InfoText>{scene.data.rf}</InfoText>
                      </Table.Row>
                      <Table.Row textAlign="left">
                        <InfoTitle> RG</InfoTitle>
                        <InfoText>{scene.data.rg}</InfoText>
                      </Table.Row>
                      <Table.Row textAlign="left">
                        <InfoTitle>COI</InfoTitle>
                        <InfoText>{scene.data.coi}</InfoText>
                      </Table.Row>
                      <Table.Row textAlign="left">
                        <InfoTitle>MDER</InfoTitle>
                        <InfoText>{scene.data.mder}</InfoText>
                      </Table.Row>
                      <Table.Row textAlign="left">
                        <InfoTitle>M/P ratio</InfoTitle>
                        <InfoText>{scene.data.mp}</InfoText>
                      </Table.Row>
                      <Table.Row textAlign="left">
                        <InfoTitle>440-490nm %</InfoTitle>
                        <InfoText>{scene.data.ul}</InfoText>
                      </Table.Row>
                      {(scene.data.ler_lmw !== undefined) && (
                      <Table.Row textAlign="left">
                        <InfoTitle>LER</InfoTitle>
                        <InfoText>{scene.data.ler_lmw}</InfoText>
                      </Table.Row>
                      )}
                      <Table.Row textAlign="left">
                        <InfoTitle>Electrical Power</InfoTitle>
                        <InfoText>{scene.data.pf_w}</InfoText>
                      </Table.Row>
                      <Table.Row textAlign="left">
                        <InfoTitle>CS @100 lux</InfoTitle>
                        <InfoText>{scene.data.cs_100}</InfoText>
                      </Table.Row>
                      <Table.Row textAlign="left">
                        <InfoTitle>CLa @100 lux</InfoTitle>
                        <InfoText>{scene.data.cla_100}</InfoText>
                      </Table.Row>
                      {(scene.data.eml_100 !== undefined) && (
                      <Table.Row textAlign="left">
                        <InfoTitle>EML @100 lux</InfoTitle>
                        <InfoText>{scene.data.eml_100}</InfoText>
                      </Table.Row>
                      )}
                      {(scene.data.medi_100 !== undefined) && (
                      <Table.Row textAlign="left">
                        <InfoTitle>MEDI @100 lux</InfoTitle>
                        <InfoText>{scene.data.medi_100}</InfoText>
                      </Table.Row>
                      )}
                      <Table.Row textAlign="left">
                        <InfoTitle>Luminous Flux (lm)</InfoTitle>
                        <InfoText>{scene.data.luminous_flux_lm}</InfoText>
                      </Table.Row>
                      <Table.Row textAlign="left">
                        <InfoTitle>Efficiency (lm/W)</InfoTitle>
                        <InfoText>{scene.data.efficiency_lmw}</InfoText>
                      </Table.Row>
                      <Table.Row textAlign="left">
                        <InfoTitle>X</InfoTitle>
                        <InfoText>{scene.data.x}</InfoText>
                      </Table.Row>
                      <Table.Row textAlign="left">
                        <InfoTitle>Y</InfoTitle>
                        <InfoText>{scene.data.y}</InfoText>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </SceneInfoContainer>
                <SceneGraphicsContainer>
                  <SpectraLineBars
                    intensity={scene.spd.irradiance}
                    wavelength={scene.spd.wavelength}
                    canvas_width={'555px'}
                  />
                </SceneGraphicsContainer>
              </SceneDataWrapper>
            </Modal.Description>
          </Modal.Content>
        </>
      )}
    </>
  )
}

const SceneDataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const SceneInfoContainer = styled.div`
  display: flex;
  flex: 0 0 35%;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-right: 15px;
  margin-bottom: 30px;
  padding-left: 30px;
`

const SceneGraphicsContainer = styled.div`
  display: flex;
  flex: 0 0 65%;
  justify-content: center;
`

const InfoTitle = styled(Table.Cell)`
  margin: 0;
  padding-left: 30px;
  font-family: ${(props: CompProps) => (props.theme as Theme).font.semibold};
  ${(props: CompProps) => (props.theme as Theme).font_size.xxxsmall};
`

const InfoText = styled(Table.Cell)`
  ${(props: CompProps) => (props.theme as Theme).font_size.xxxsmall};
  font-weight: bolder;
`

export default ModalScene
