import React from 'react'
import styled from 'styled-components'
import { Modal, Icon } from 'semantic-ui-react'
import { MainTextButton } from '../../styles/commons'

interface Props {
  title?: string
  size?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen'
  trigger: React.ReactNode
  isOpen?: boolean
  closeCallBack?: Function
}

const ModalSemantic: React.FC<Props> = (props) => {
  const [open, setOpen] = React.useState(props.isOpen || false)

  const closeModal = () => {
    setOpen(false)
    props.closeCallBack && props.closeCallBack()
  }

  return (
    <Modal
      onClose={closeModal}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<div>{props.trigger}</div>}
      size={props.size || 'large'}
      centered={true}
    >
      <Header>
        <Title>{props.title}</Title>
        <MainTextButton onClick={closeModal}>
          <Icon name="close" size="large" />
        </MainTextButton>
      </Header>
      <ModalReactContent>
        <Modal.Description>{props.children}</Modal.Description>
      </ModalReactContent>
      <Modal.Actions></Modal.Actions>
    </Modal>
  )
}

export default ModalSemantic

const Header = styled(Modal.Header)`
  display: flex !important;
  align-items: center !important;
  padding-left: 30px !important;
  padding-right: 0px !important;
`
const Title = styled.div`
  flex-grow: 1;
`

const ModalReactContent = styled(Modal.Content)`
  padding: 0px !important;
`
