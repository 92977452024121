import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { getCookie } from '../utils/cookies'

export const STATUS = {
  OK: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  LIMIT_REACHED: 402,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  REQUEST_TIME_OUT: 408
}

export interface ApiResponse {
  success: boolean
  code: number
  message: string
}

export const GOOGLE_MAP_API_KEY = 'AIzaSyCR4_rW6l2kjXmO75TbdsbNZmlgkGYaSi0'
export const APP_PATH_PUBLIC =  process.env.REACT_APP_PUBLIC_URL || "https://app.kumux.io/public/1.0/"
const axiosInstance: AxiosInstance = axios.create()
const APIV1 =
  process.env.REACT_APP_API_URL || 'https://kumux-258410.appspot.com/api/'

axiosInstance.interceptors.request.use((req: AxiosRequestConfig) => {
  const accessToken: string = getCookie('accessToken')
  req.headers['x-access-token'] = accessToken
  req.url = `${APIV1}${req.url}`
  return req
})

axiosInstance.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response) {
      const status = error.response.status
      if (status === STATUS.UNAUTHORIZED) {
        //const path: string = window.location.pathname
        const path = window.location.pathname.split('/')
        if (path[1] !== 'login' && path[1] !== 'public') {
          // TODO: Warn the user about session expiration
          window.location.href = '/login'
        }
      } else {
        return Promise.reject(error)
      }
    } 
  }
)

export default axiosInstance
