import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import {
  InputText,
  Fieldset,
  InputWrapper,
  MessageInput,
  TYPES_MESSAGE,
  ButtonUI,
  LoaderUI
} from '../styles/commons'
import { simulationApi } from '../api/SimulationApi'
import { Theme, CompProps } from '../styles/theme'

import { Channel } from '../api/LedApi'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Tour from '../components/help/Tour'

import { Auth } from '../App'
import { useHistory, useParams } from 'react-router-dom'

import Spectral from '../components/pcb/Spectral'
import LedListPCB from '../components/pcb/LedListPCB'
import LedListAvailable from '../components/pcb/LedListAvailable'
import HelpContent from '../components/help/HelpContent'
import { Icon, Radio } from 'semantic-ui-react'
import { useDebounce } from '../utils/commons'

const ReferenceCorrectIcon = <FontAwesomeIcon icon={faCheckCircle} />
const ReferenceErrorIcon = <FontAwesomeIcon icon={faExclamationCircle} />

interface Props {
  auth: Auth
}

export interface ChannelIntensityValues {
  cct_k: number
  cri: number
  duv: number
  luminous_flux_lm: number
  mp_ratio: number
  pf_w: number
  r9: number
}

interface ChannelIntensity {
  data: []
  values: ChannelIntensityValues
}
interface SimulationForm {
  led_ids: string[]
  led_types: string[]
  serial: number[]
  parallel: number[]
  limit: number[]
  reference: string
}

const initialForm: SimulationForm = {
  led_ids: [],
  led_types: [],
  serial: [],
  parallel: [],
  limit: [],
  reference: ''
}
export interface SliderSpectra {
  lineColors: string
  max: number
  min: number
  name: string
  now: number
  driver_Ma: number
}
const MAX_CHANNELS = 8
const MIN_CHANNELS = 2
const MIN_TUTORIAL_CHANNELS = 3

const Pcb: React.FC<Props> = (props) => {

  const [auth, setAuth] = useState(props.auth)
  useEffect(() => { setAuth(props.auth) }, [auth, props.auth])

  const { showTutorial } = useParams()
  const history = useHistory()
  const [referenceIsValid, setReferenceIsValid] = useState<Boolean>()
  const [channelIntensity, setChannelIntensity] = useState<
    ChannelIntensityValues
  >()

  const [simulationForm, setSimulationForm] = useState<SimulationForm>(
    initialForm
  )
  const [referenceInput, setReferenceInput] = useState('')
  useDebounce(referenceInput, 500)
  const [formErrors, setFormErrors] = useState(true)
  const [warningText, setWarningText] = useState<string>('')
  const [isSendingSimulation, setIsSendingSimulation] = useState(false)
  const [sliderSpectra, setSliderSpectra] = useState<SliderSpectra[]>()
  const sliderSpectraBounce = useDebounce(sliderSpectra, 500)
  const [isLoadingPCB, setIsLoadingPCB] = useState(false)

  const [TotalPF_W, setTotalPF_W] = useState(0)
  const [PCBChannels, setPCBChannels] = useState<Channel[]>([])
  const debounceChannels = useDebounce(PCBChannels, 500)
  const containerPCB = useRef<HTMLDivElement>(null)
  const [resolution, setresolution] = useState('full_combinations')
  const [paneActive, setpaneActive] = useState(0)
  const [ChartSPD, setChartSPD] = useState<{
    wavelength: number[]
    intensity: number[]
    line: number[][]
    lineColors: string[]
  }>({
    wavelength: [],
    intensity: [],
    line: [[]],
    lineColors: []
  })

  const [tourActive] = useState(
    showTutorial && showTutorial === '1' ? true : false
  )

  const onDrop = (ev: any, cat: any) => {

    if (containerPCB.current !== null) {
      containerPCB.current.classList.remove('draggingNow')
    }

    if (
      PCBChannels.length !== MAX_CHANNELS &&
      ev.dataTransfer.types[0] !== 'channel'
    ) {
      let led_info = JSON.parse(ev.dataTransfer.getData('led'))

      const new_channel: Channel = {
        id: String(led_info.id),
        type: String(led_info.type),
        family: String(led_info.family),
        brand: String(led_info.brand),
        reference: String(led_info.reference),
        limit: Number(led_info.if_ma),
        parallel: 1,
        serial: 1,
        if_ma: Number(led_info.if_ma),
        luminous_flux_lm: Number(led_info.luminous_flux_lm),
        pf_w: Number(led_info.pf_w)
      }

      let pcbChannelsAux: Channel[] = [...PCBChannels]
      pcbChannelsAux.push(new_channel)
      setPCBChannels(pcbChannelsAux)
      if (PCBChannels.length === MAX_CHANNELS) {
        toast.info(`PCB capacity is limited to ${MAX_CHANNELS} channels.`)
      }
    }
  }

  /* DRAG DROP*/
  const onDragOver = (ev: any) => {
    ev.preventDefault()
  }

  // Hash any string into an integer value
  // Then we'll use the int and convert to hex.
  const strToARGB = (str: string) => {
    var hash = 0
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }

    return (hash >>> 0).toString(16).slice(-6)
  }

  const checkReference = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 0) {
      const response: Boolean = await simulationApi.checkReference(
        event.target.value
      )
      setReferenceIsValid(response)
    } else {
      setReferenceIsValid(false)
    }
  }

  /* FI DRAG DROP */

  const addToSimulationForm = (data: any) => {
    setSimulationForm({
      ...simulationForm,
      ...data
    })
  }
  
  const onChangeFilter = async (dataFilter: any, index: number) => {
    if (sliderSpectra) {
      let sliderSpectraAux: SliderSpectra[] = Object.assign([], sliderSpectra)
      sliderSpectraAux[index].now = dataFilter
      sliderSpectraAux[index].driver_Ma = PCBChannels[index].limit * (dataFilter / 100)
      setSliderSpectra(sliderSpectraAux)
      setIsLoadingPCB(false)
    }
  }

  useEffect(() => {
    
    const updateSpectra = async () => {
     
      const simulationFormAux: SimulationForm = JSON.parse(JSON.stringify(simulationForm))
      let sliderSpectraAux: SliderSpectra[] = Object.assign([], sliderSpectra)

      if (
        sliderSpectra &&
        simulationFormAux.led_ids.length !== 0 &&
        simulationFormAux.led_ids.length === sliderSpectra.length
      ) {

        setIsLoadingPCB(true)

        sliderSpectraAux.forEach((channel, index) => {
          simulationFormAux.limit[index] = channel.driver_Ma
          
        })

        const response: ChannelIntensity = await simulationApi.getDynamicSpdPCB(
          simulationFormAux
        )
        
        // TODO: Manage 500 response
        const wavelength: number[] = response.data.map((coord) => {
            return coord[0]
        })

        const intensity: number[] = response.data.map((coord) => {
            return coord[1] 
        })
      
        const chartSPDAux: {
          wavelength: number[]
          intensity: number[]
          line: number[][]
          lineColors: string[]
        } = Object.assign({}, ChartSPD)

        chartSPDAux.intensity = intensity
        chartSPDAux.wavelength = wavelength

        setChartSPD(chartSPDAux)
        setChannelIntensity(response.values)
        setIsLoadingPCB(false)
      }
    }

   
    updateSpectra()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sliderSpectraBounce, simulationForm])

  useEffect(() => {
    let didCancel = false

    async function getDynamicSpdPCB() {

      setIsLoadingPCB(true)

      const SimulationFormAux: SimulationForm = {
        led_ids: [],
        led_types: [],
        parallel: [],
        serial: [],
        limit: [],
        reference: ''
      }

      let validate = true
      const lineColors: string[] = []

      SimulationFormAux.reference = simulationForm.reference

      for (let index = 0; index < PCBChannels.length; index++) {
        const channel = PCBChannels[index]
        SimulationFormAux.led_ids.push(channel.id)
        SimulationFormAux.limit.push(isNaN(channel.limit) ? 1 : channel.limit)
        SimulationFormAux.led_types.push(channel.type)
        SimulationFormAux.serial.push(
          isNaN(channel.serial) ? 1 : channel.serial
        )
        SimulationFormAux.parallel.push(
          isNaN(channel.parallel) ? 1 : channel.parallel
        )
        lineColors.push(strToARGB(channel.reference))
        if (
          isNaN(channel.serial) ||
          isNaN(channel.limit) ||
          isNaN(channel.parallel)
        ) {
          validate = false
        }
      }

      if (validate === false) {
        toast.error('Please add a valid number to the PCB')
        return
      }

      if (PCBChannels !== undefined && PCBChannels.length > 0) {
        
        let PF_WAux: number = 0
        let DragLedsPromise = await PCBChannels.map(
          async (channel: Channel) => {
            // Calc PWF
            PF_WAux += (channel.pf_w * channel.serial * channel.limit) / channel.if_ma
            // Get Led Intensy
            const intesity = await simulationApi.getLedInfo(
              channel.type,
              channel.id
            )
            return intesity.params.irradiance_norm
          }
        )
        const line: number[][] = await Promise.all(DragLedsPromise)

        !didCancel && setTotalPF_W(Math.round(PF_WAux * 100) / 100)
        !didCancel && setChartSPD({ ...ChartSPD, line, lineColors })
      } else {
        !didCancel &&
          setChartSPD({
            wavelength: [],
            intensity: [],
            line: [],
            lineColors: []
          })
        !didCancel && setChannelIntensity(undefined)
        !didCancel && setSliderSpectra(undefined)
        !didCancel && setTotalPF_W(0)
      }

      !didCancel && setSimulationForm(SimulationFormAux)
      setIsLoadingPCB(false)
    }

    getDynamicSpdPCB()

    return () => {
      didCancel = true
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceChannels])

  
  

  useEffect(() => {
    if (referenceIsValid) {
      addToSimulationForm({ reference: referenceInput })
    } else {
      addToSimulationForm({ reference: '' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referenceIsValid, referenceInput])

  useEffect(() => {
    validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simulationForm])

  const validateForm = () => {
    let errors = false

    if (simulationForm.reference === '') {
      errors = true
    }

    if (referenceIsValid === false) {
      errors = true
    }

    if (simulationForm.led_ids.length <= 0) {
      errors = true
    }

    if (
      (PCBChannels.length < MIN_CHANNELS) ||
      (tourActive && (PCBChannels.length < MIN_TUTORIAL_CHANNELS)) ||
      (PCBChannels.length > MAX_CHANNELS)
    ) {
      errors = true
    }

    let warnings: boolean = false
    PCBChannels.forEach((channel) => {
      if (channel.limit / channel.parallel > channel.if_ma) {
        // errors = true
        warnings = true
        setWarningText(
          '(*) Warning! You are setting an electrical current higher than the nominal current of the LED. This can dramatically shorten its life or even burn it!'
        )
      }
      if (channel.limit / channel.parallel < 0.5 * channel.if_ma) {
        // errors = true
        warnings = true
        setWarningText(
          '(*) Warning! You are setting an electrical current much lower than the nominal current of the LED. Maybe you should consider reducing the number of LEDs or using others'
        )
      }
      if (
        isNaN(channel.serial) ||
        isNaN(channel.parallel) ||
        isNaN(channel.limit)
      ) {
        errors = true
      }
    })

    !warnings && setWarningText('')

    setFormErrors(errors)
  }

  const checkValidPCB = () => {
    let total_luminous_flux_lm: number = 0

    PCBChannels.forEach((channel) => {
      const luminous_flux_lm_channel: number =
        channel.luminous_flux_lm *
        channel.serial *
        (channel.limit / channel.if_ma)
      total_luminous_flux_lm += luminous_flux_lm_channel
    })

    if (total_luminous_flux_lm < 10) {
      toast.error(
        'The total luminous flux of the PCB is too low. Please, consider increasing the number of LEDs or use more power.'
      )
      return false
    } else {
      return true
    }
  }

  const createSimulation = async () => {
    if (checkValidPCB && !isSendingSimulation) {
      setIsSendingSimulation(true)
      const response = await simulationApi.createLuminary(simulationForm)
      if (response) {
        await simulationApi.startSimulationById(response.public_id, resolution)
      }
      setIsSendingSimulation(false)
      //props.closeModal(true)
      history.push('/')
    }
  }

  const handleResolutionChange = (e: any, data: any) => {
    setresolution(data.value)
  }

  useEffect(() => {
    if (tourActive === true && referenceIsValid === true) {
      setpaneActive(1)
    }
  }, [tourActive, referenceIsValid])
  
  return (
    <NewSimulationWrapper>
      <TitleWrapper>
        <h3>New Fixture</h3>
      </TitleWrapper>
      <ContainerReferenceSimulation>
        <ReferenceContainer>
          <Fieldset name="reference" className="reference">
            <h5>
              Reference
              <HelpContent
                section="Fixture"
                content="reference"
                position="bottom center"
                html={<Icon name="question circle"></Icon>}
              />
            </h5>
            <InputWrapper>
              <InputText
                className="tour-pcb"
                name="reference"
                error={!referenceIsValid}
                onBlur={(e: any) => {
                  checkReference(e)
                  return setReferenceInput(e.target.value)
                }}
                defaultValue={referenceInput}
              ></InputText>
              {referenceInput && (
                <MessageInput
                  type={
                    referenceIsValid ? TYPES_MESSAGE.OK : TYPES_MESSAGE.ERROR
                  }
                >
                  {referenceIsValid ? (
                    ReferenceCorrectIcon
                  ) : (
                    <p>{ReferenceErrorIcon} Duplicated</p>
                  )}
                </MessageInput>
              )}
            </InputWrapper>
          </Fieldset>
        </ReferenceContainer>
        <SettingsContainer>
          <ContainerSettings>
            <SettingsTitle>
              SIMULATION RESOLUTION
              <HelpContent
                section="Fixture"
                content="simulation_resolution"
                position="bottom center"
                html={<Icon name="question circle"></Icon>}
              />
            </SettingsTitle>
            <Radio
              label="Low"
              name="resolutionGroup"
              value="low_resolution"
              checked={resolution === 'low_resolution'}
              onChange={handleResolutionChange}
            />
            <Radio
              label="High"
              name="resolutionGroup"
              value="full_combinations"
              checked={resolution === 'full_combinations'}
              onChange={handleResolutionChange}
            />
          </ContainerSettings>
          <ButtonUI
            btntype="primary"
            loading={isSendingSimulation}
            disabled={formErrors}
            onClick={() => createSimulation()}
            className="tour-createfixture"
          >
            Create
          </ButtonUI>
        </SettingsContainer>
      </ContainerReferenceSimulation>
      <LeftColumnContainer
        className="droppable  "
        onDrop={(e) => onDrop(e, 'done')}
        onDragOver={(ev) => onDragOver(ev)}
        onDragEnd={(e) => {}}
      >
        <PCBWrapper ref={containerPCB}>
          {isLoadingPCB && (
            <LoadingContainer>
              <LoaderUI active inline="centered" />
            </LoadingContainer>
          )}

          <Spectral
            onChangeFilter={onChangeFilter}
            setSliderSpectra={setSliderSpectra}
            sliderSpectra={sliderSpectra && sliderSpectra}
            channelIntensity={channelIntensity}
            PCBChannels={PCBChannels}
            ChartSPD={ChartSPD}
          />
          <LedListPCB
            PCBChannels={PCBChannels}
            setPCBChannels={setPCBChannels}
            TotalPF_W={TotalPF_W}
          />
          <div className="DragYourLeds">
            <div>
              {' '}
              <i className="microchip icon"></i>
              <p>Drag your leds here.</p>{' '}
            </div>
          </div>
        </PCBWrapper>
        <WarningText>{warningText}</WarningText>
      </LeftColumnContainer>
      <RightColumnContainer>
        <LedListAvailable
          tourActive={tourActive}
          setpaneActive={setpaneActive}
          paneActive={paneActive}
          containerPCB={containerPCB}
          MAX_CHANNELS={MAX_CHANNELS}
          PCBChannels={PCBChannels}
          resolution={resolution}
          setresolution={setresolution}
        ></LedListAvailable>
      </RightColumnContainer>
      <ToastContainer />
      {tourActive && <Tour section="PCB_reference" />}
      {tourActive && referenceIsValid && paneActive === 1 && (
        <Tour section="11PCB_ledTable" />
      )}
      {isLoadingPCB === false &&
        tourActive &&
        debounceChannels.length >= MIN_TUTORIAL_CHANNELS && (
          <Tour section="PCB_DragledTable" />
        )}
    </NewSimulationWrapper>
  )
}

const PCBWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  .DragYourLeds {
    display: none;
  }
`

const LoadingContainer = styled.div`
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  padding: 50% 0px;
`

const NewSimulationWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0px 15px 15px;
`

const LeftColumnContainer = styled.div`
  width: 645px;
  .draggingNow .DragYourLeds {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 99;
    transform: translate(-50%, -50%);
    font-weight: bolder;
    font-size: 14px;
    background: ${(props: CompProps) =>
      (props.theme as Theme).color.neutral._500};
    opacity: 0.7;
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .microchip {
    float: none;
    display: block;
    width: auto;
    height: auto;
    line-height: 1;
    padding: 0;
    font-size: 3em;
    margin: 0 auto 0.5rem;
    opacity: 1;
  }
  .draggingNow {
  }
`

const ReferenceContainer = styled.div`
  h5 {
    display: inline-flex;
    margin-bottom: 0px;
  }
  .reference {
    padding-top: 0px;
  }
  input {
    min-height: 36px;
  }
`

const WarningText = styled.div`
  color: ${(props: CompProps) => (props.theme as Theme).color.forms.warning};
  ${(props: CompProps) => (props.theme as Theme).font_size.xxxsmall};
  position: absolute;
  width: 600px;
  margin-top: 12px;
`

const RightColumnContainer = styled.div`
  padding: 15px;
  padding-bottom: 0px;
  height: 100%;
`
const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 30px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e4e4e4;
`

const SettingsTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 15px;
  margin-bottom: 20px;
  color: ${(props: CompProps) => (props.theme as Theme).color.neutral._900};
  font-weight: bold;
`

const SettingsContainer = styled.div`
  display: flex;
  align-items: center;
  .radio {
    margin-right: 15px;
  }
`

const ContainerReferenceSimulation = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  align-items: flex-start;
`
const ContainerSettings = styled.div`
  margin-right: 15px;
`

export default Pcb
