import React from 'react'
import ChartStatic from '../charts/ChartStatic'

interface CoverFlowChartItemProps {
    className: string
    dataTitle: string
    dataDesc: string
    chartType: string
    renderType: string
    chartData: any
  }
  
  const CoverFlowChartItem: React.FC<CoverFlowChartItemProps> = (props) => {
      return (
        <div className={props.className}
             data-title={props.dataTitle}
             data-desc={props.dataDesc}>
             <ChartStatic chartType={props.chartType} chartData={props.chartData} renderType={props.renderType} />
        </div>
      )
  }

  export default CoverFlowChartItem
