import React, { useState } from 'react'
import styled from 'styled-components'
import { Modal as ModalReact } from 'semantic-ui-react'
import { TextButtonUI } from '../../styles/commons'
import TermsPolicy from '../../pages/TermsPolicy'
import TermsTrialPolicy from '../../pages/TermsTrialPolicy'

interface Props {
  text?: string
}

const ModalLegal: React.FC<Props> = (props) => {
  const [showTerms] = useState(props.text === 'Terms & conditions')
  const [showTermsTrial] = useState(props.text === 'Terms & conditions trial')
  return (
    <ModalReact
      size={'large'}
      trigger={<ButtonTrigger>{props.text}</ButtonTrigger>}
    >
      <ModalReact.Content>
        <ModalContainer>
          {showTerms && <TermsPolicy />}
          {showTermsTrial && <TermsTrialPolicy />}
        </ModalContainer>
      </ModalReact.Content>
    </ModalReact>
  )
}

const ModalContainer = styled(ModalReact.Description)`
  text-align: left;
  height: 40vw;
`

const ButtonTrigger = styled(TextButtonUI)`
  font-size: 12px !important;
  margin-right: 15px;
  max-width: 280px!important;
`

export default ModalLegal
