import React from 'react'

import SceneSheet from '../../components/scene/SceneSheet'

interface Props {
    sceneId: string
}

const ScenePrivate: React.FC<Props> = (props) => {
  const { sceneId } = props
  return (
    <SceneSheet sceneId={sceneId} isPublic={false} /> 
  )
}

export default ScenePrivate
