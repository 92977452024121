import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { CompProps, Theme } from '../../styles/theme'
import { Modal, Accordion, Icon, List, Loader, Dimmer } from 'semantic-ui-react'

import { ButtonUI } from '../../styles/commons'
import { Led, ledApi } from '../../api/LedApi'
import { Historic } from '../../api/UserApi'
import { simulationApi } from '../../api/SimulationApi'

interface Props {
  object?: Historic | Led
  closeModal: Function
  acceptConfirm: Function
  message: string
}
interface SceneTimline {
  timelines: [
    {
      id: string
      reference: string
    }
  ]
  scenes: [
    {
      id: string
      reference: string
    }
  ]
}

const ModalConfirmText: React.FC<Props> = (props) => {
  const { object, closeModal, acceptConfirm, message } = props
  const [activeIndex, setActiveIndex] = useState<number>(-1)
  const [FixtureList, setFixtureList] = useState<SceneTimline[] | Led[] | any>()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingButton, setIsLoadingButton] = useState(false)
  const [typeDisplay, settypeDisplay] = useState(-1)

  const handleClick = (e: any, titleProps: any) => {
    const { index } = titleProps
    // const { handleactiveIndex } = activeIndex
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex)
  }
  
  const acceptConfirmButton = () =>
    acceptConfirm && acceptConfirm() && setIsLoadingButton(true)

  useEffect(() => {
    let didCancel = false
    const dependencyCheck = async (object: Historic | Led) => {
      let list: SceneTimline | Led[] = []
      let check = false
      !didCancel && setIsLoading(true)
      if ((object as Historic).simulation) {
        //cuando clicas fixtures
        list = await simulationApi.dependency_check(
          (object as Historic).public_id
        )
        if ((list as SceneTimline).scenes.length > 0) {
          check = true
          settypeDisplay(1)
        }
      } else if ((object as Led).data.if_ma) {
        //cuando clicas Leds
        list = await ledApi.dependency_check((object as Led).id)
        if ((list as Led[]).length > 0) {
          check = true
          settypeDisplay(0)
        }
      }

      if (check && list !== []) {
        !didCancel && setFixtureList(list)
      }
      !didCancel && setIsLoading(false)
    }
    if (object) {
      dependencyCheck(object)
    }
    return () => {
      didCancel = true
    }
  }, [object])
  return (
    <>
      {isLoading ? (
        <ModalWait>
          <Dimmer active inverted>
            <Loader size="medium" />
          </Dimmer>
        </ModalWait>
      ) : (
        <>
          <Modal.Header>
          {message}
          </Modal.Header>
          <Modal.Content>
            {FixtureList ? (
              <>
                {typeDisplay === 0 && (
                <>
                  <DepenciesTitle>
                    Warning!. All of the fixtures ans it's dependencies below will also be deleted:
                  </DepenciesTitle>
                  {FixtureList.map((items: any, i: number) => {
                    return (
                      <DependenciesAccordion key={`fixture-${i}`}>
                        <Accordion.Title
                          active={activeIndex === 0}
                          index={i}
                          onClick={handleClick}
                        >
                        <Icon name="dropdown" />{' '}
                        {items.reference}
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === i}>
                            <List bulleted>
                              <List.Header>
                              Scenes ({items.scenes.length})
                              </List.Header>
                              {items.scenes.map(
                                  (scene: any, i: number) => {
                                    return (
                                      <List.Item key={`scene-${i}`}>
                                        {scene.reference}
                                      </List.Item>
                                    )
                                  }
                                )}
                            </List>
                            <List bulleted>
                              <List.Header>
                              Timelines ({items.timelines.length})
                              </List.Header>
                              {items.timelines.map(
                                  (timeline: any, i: number) => {
                                    return (
                                      <List.Item key={`timeline-${i}`}>
                                        {timeline.reference}
                                      </List.Item>
                                    )
                                  }
                                )}
                            </List>
                            </Accordion.Content>
                            </DependenciesAccordion>
                
                    )}
                  )}
                </>)}
                {typeDisplay === 1 && (
                  <>
                    <DepenciesTitle>
                      Warning!. All of the dependencies below will also be deleted:
                    </DepenciesTitle>
                    {(object as Historic) &&
                      (object as Historic).leds !== undefined &&
                      FixtureList !== undefined &&
                      FixtureList.scenes[0] !== undefined &&
                      FixtureList.scenes[0].id !== undefined && (
                        <DependenciesAccordion>
                          <Accordion.Title
                            active={activeIndex === 1}
                            index={1}
                            onClick={handleClick}
                          >
                            <Icon name="dropdown" />{' '}
                            Scenes ({FixtureList.scenes.length})
                          </Accordion.Title>
                          <Accordion.Content active={activeIndex === 1}>
                            <List bulleted>
                              {typeDisplay === 1 &&
                                FixtureList.scenes.map(
                                  (items: any, i: number) => {
                                    return (
                                      <List.Item key={`scene-${i}`}>
                                        {items.reference}
                                      </List.Item>
                                    )
                                  }
                                )}
                            </List>
                          </Accordion.Content>
                        </DependenciesAccordion>
                      )}
                    {Array.isArray(FixtureList.timelines) &&
                      FixtureList.timelines[0] !== undefined &&
                      FixtureList.timelines[0].id !== undefined && (
                        <DependenciesAccordion>
                          <Accordion.Title
                            active={activeIndex === 2}
                            index={2}
                            onClick={handleClick}
                          >
                            <Icon name="dropdown" />{' '}
                            Timelines ({FixtureList.timelines.length})
                          </Accordion.Title>
                          <Accordion.Content active={activeIndex === 2}>
                            <List bulleted>
                              {FixtureList.timelines.map(
                                  (items: any, i: number) => {
                                    return (
                                      <List.Item key={`timeline-${i}`}>
                                        {items.reference}
                                      </List.Item>
                                    )
                                  }
                              )}
                            </List>
                          </Accordion.Content>
                        </DependenciesAccordion>
                      )}
                  </>
                )}
              </>
            ) : (
              <NoDepenciesTitle>
                No dependencies found.
              </NoDepenciesTitle>
            )}
          </Modal.Content>
          <Modal.Actions>
            <ButtonUI btntype="secondary" onClick={() => closeModal()} negative>
              CANCEL
            </ButtonUI>
            <ButtonUI
              loading={isLoadingButton}
              onClick={() => acceptConfirmButton()}
              btntype="primary"
            >
              CONFIRM
            </ButtonUI>
          </Modal.Actions>
        </>
      )}
    </>
  )
}

const ModalWait = styled(Modal.Content)`
  height: 179px !important;
`

const DepenciesTitle = styled.div`
  font-family: ${(props: CompProps) => (props.theme as Theme).font.bold} !important;
  color: red;
  ${(props: CompProps) => (props.theme as Theme).font_size.xxsmall} !important;
  margin-bottom: 15px;
  width: 100%;
  text-align: center;
`

const NoDepenciesTitle = styled.div`
  font-family: ${(props: CompProps) => (props.theme as Theme).font.bold} !important;
  ${(props: CompProps) => (props.theme as Theme).color.white.darkest} !important;
  ${(props: CompProps) => (props.theme as Theme).font_size.xxsmall} !important;
  width: 100%;
  text-align: center;
`

const DependenciesAccordion = styled(Accordion) `
  margin-top: 5px;
  border-bottom: 1px solid lightgrey;
  padding-bottom 5px;
  .header {
    padding-bottom: 5px;
    font-weight: normal !important;
  }
  .title {
    font-family: ${(props: CompProps) => (props.theme as Theme).font.normal} !important;
    font-weight: normal !important;
    ${(props: CompProps) => (props.theme as Theme).color.black.lightest} !important;
    ${(props: CompProps) => (props.theme as Theme).font_size.xxsmall} !important;
    text-transform: uppercase!important;
  }
  .item {
    margin-left: 15px;
    margin-bottom: 5px;
    font-family: ${(props: CompProps) => (props.theme as Theme).font.normal} !important;
    font-weight: normal !important;
    ${(props: CompProps) => (props.theme as Theme).color.black.lightest} !important;
    ${(props: CompProps) => (props.theme as Theme).font_size.xxsmall} !important;
  }
`

export default ModalConfirmText
