import React from 'react'
import styled from 'styled-components'
import { CompProps, Theme } from '../../styles/theme'
import { ButtonUI } from '../../styles/commons'

interface Props {
  lang: string,
  openCookiesSettings: Function
}

const CookiesPolicy: React.FC<Props> = (props: Props) => {
  return (
    <ConditionsSection>
      {(props.lang === 'cast') && (
        <>
        <ConditionsTitle>Política de Cookies</ConditionsTitle>
        <ConditionsSectionTitle>INFORMACIÓN SOBRE COOKIES</ConditionsSectionTitle>
        <ConditionsSectionText>
          Debido a la entrada en vigor de la referente modificación de la «Ley de Servicios de la Sociedad de la Información»
          (LSSICE) establecida por el Real Decreto 13/2012, es de obligación obtener el consentimiento expreso del usuario de
          todas las páginas web que usan cookies prescindibles, antes de que este navegue por ellas.
        </ConditionsSectionText>
        <ConditionsSectionTitle>¿QUÉ SON LAS COOKIES?</ConditionsSectionTitle>
        <ConditionsSectionText>
          Las cookies y otras tecnologías similares tales como local shared objects, flash cookies o píxeles, son herramientas
          empleadas por los servidores Web para almacenar y recuperar información acerca de sus visitantes, así como para ofrecer
          un correcto funcionamiento del sitio.
        </ConditionsSectionText>
        <ConditionsSectionText>
          Mediante el uso de estos dispositivos se permite al servidor Web recordar algunos datos concernientes al usuario, como
          sus preferencias para la visualización de las páginas de ese servidor, nombre y contraseña, productos que más le
          interesan, etc.
        </ConditionsSectionText>
        <ConditionsSectionTitle>COOKIES AFECTADAS POR LA NORMATIVA Y COOKIES EXCEPTUADAS</ConditionsSectionTitle>
        <ConditionsSectionText>
          Según la directiva de la UE, las cookies que requieren el consentimiento informado por parte del usuario son las cookies de
          analítica y las de publicidad y afiliación, quedando exceptuadas las de carácter técnico y las necesarias para el
          funcionamiento del sitio web o la prestación de servicios expresamente solicitados por el usuario.
          </ConditionsSectionText>
        <ConditionsSectionTitle>TIPOS DE COOKIES</ConditionsSectionTitle>
        <ConditionsSectionSubTitle>SEGÚN LA FINALIDAD</ConditionsSectionSubTitle>
        <ConditionsSectionList>
          <li>
            Cookies técnicas y funcionales: son aquellas que permiten al usuario la navegación a través de una página
            web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que en ella existan.
          </li>
          <li>
            Cookies analíticas: son aquellas que permiten al responsable de las mismas el seguimiento y análisis del
            comportamiento de los usuarios de los sitios web a los que están vinculadas. La información recogida mediante
            este tipo de cookies se utiliza en la medición de la actividad de los sitios web, aplicación o plataforma y para la
            elaboración de perfiles de navegación de los usuarios de dichos sitios, aplicaciones y plataformas, con el fin de
            introducir mejoras en función del análisis de los datos de uso que hacen los usuarios del servicio.
            </li>
          <li>
            Cookies publicitarias: son aquellas que permiten la gestión, de la forma más eficaz posible, de los espacios
            publicitarios que, en su caso, el editor haya incluido en una página web, aplicación o plataforma desde la que
            presta el servicio solicitado en base a criterios como el contenido editado o la frecuencia en la que se muestran los
            anuncios.
          </li>
          <li>
            Cookies de publicidad comportamental: recogen información sobre las preferencias y elecciones personales
            del usuario (retargeting) para permitir la gestión, de la forma más eficaz posible, de los espacios publicitarios que,
            en su caso, el editor haya incluido en una página web, aplicación o plataforma desde la que presta el servicio
            solicitado.
          </li>
          <li>
            Cookies sociales: son establecidas por las plataformas de redes sociales en los servicios para permitirle
            compartir contenido con sus amigos y redes. Las plataformas de medios sociales tienen la capacidad de rastrear
            su actividad en línea fuera de los Servicios. Esto puede afectar al contenido y los mensajes que ve en otros
            servicios que visita.
          </li>
          <li>
            Cookies de afiliados: permiten hacer un seguimiento de las visitas procedentes de otras webs, con las que el
            sitio web establece un contrato de afiliación (empresas de afiliación).
          </li>
          <li>
            Cookies de seguridad: almacenan información cifrada para evitar que los datos guardados en ellas sean
            vulnerables a ataques maliciosos de terceros.
          </li>
        </ConditionsSectionList>
        <ConditionsSectionSubTitle>SEGÚN LA PROPIEDAD</ConditionsSectionSubTitle>
        <ConditionsSectionList>
        <li>
            Cookies propias: son aquellas que se envían al equipo terminal del usuario desde un equipo o dominio
            gestionado por el propio editor y desde el que se presta el servicio solicitado por el usuario.
        </li>
        <li>
            Cookies de terceros: son aquellas que se envían al equipo terminal del usuario desde un equipo o dominio que
            no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos través de las cookies.
        </li>
        </ConditionsSectionList>
        <ConditionsSectionSubTitle>SEGÚN EL PLAZO DE CONSERVACIÓN</ConditionsSectionSubTitle>
        <ConditionsSectionList>
        <li>
            Cookies de sesión: son un tipo de cookies diseñadas para recabar y almacenar datos mientras el usuario
            accede a una página web.
        </li>
        <li>
            Cookies persistentes: son un tipo de cookies en el que los datos siguen almacenados en el terminal y pueden
            ser accedidos y tratados durante un período definido por el responsable de la cookie, y que puede ir de unos
            minutos a varios años.
        </li>
        </ConditionsSectionList>
        <ConditionsSectionTitle>TRATAMIENTO DE DATOS PERSONALES</ConditionsSectionTitle>
        <ConditionsSectionText>
            ENLIGHTING TECHNOLOGIES SL es el Responsable del tratamiento de los datos personales del Interesado y le
            informa de que estos datos serán tratados de conformidad con lo dispuesto en el Reglamento (UE) 2016/679, de 27 de
            abril de 2016 (GDPR), por lo que se le facilita la siguiente información del tratamiento:
        </ConditionsSectionText>
        <ConditionsSectionText>
            Fines del tratamiento: según se especifica en el apartado de cookies que se utilizan en este sitio web.
        </ConditionsSectionText>
        <ConditionsSectionText>
            Legitimación del tratamiento: por consentimiento del interesado (art. 6.1 GDPR).
        </ConditionsSectionText>
        <ConditionsSectionText>
            Criterios de conservación de los datos: según se especifica en el apartado de cookies utilizadas en la web.
        </ConditionsSectionText>
        <ConditionsSectionText>
            Comunicación de los datos: no se comunicarán los datos a terceros, excepto en cookies propiedad de terceros o por
            obligación legal.
        </ConditionsSectionText>
        <ConditionsSectionText>
          Derechos que asisten al Interesado:
        </ConditionsSectionText>
        <ConditionsSectionList>
          <li>Derecho a retirar el consentimiento en cualquier momento.</li>
          <li>
              Derecho de acceso, rectificación, portabilidad y supresión de sus datos, y de limitación u oposición a su tratamiento.
          </li>
          <li>
              Derecho a presentar una reclamación ante la Autoridad de control (www.aepd.es) si considera que el tratamiento no se
              ajusta a la normativa vigente
          </li>
        </ConditionsSectionList>
        <ConditionsSectionText>
          Datos de contacto para ejercer sus derechos:
        </ConditionsSectionText>
        <ConditionsSectionText>
          ENLIGHTING TECHNOLOGIES SL. Carrer Baldiri Reixac, 2, edifici Torre I Planta 3 Porta A1., 3 - 08028 Barcelona
          (Barcelona). E-mail: hola@enlighting-tech.com
        </ConditionsSectionText>
        <ConditionsSectionTitle>COOKIES UTILIZADAS EN ESTE SITIO WE</ConditionsSectionTitle>
        <ConditionsTableTitle>COOKIES CONTROLADAS POR EL EDITOR</ConditionsTableTitle>
        <ConditionsTableTitle>ANALÍTICAS</ConditionsTableTitle>
        <ConditionsSectionSubTitle>Funcionales y Necesarias:</ConditionsSectionSubTitle>
        <table>
          <tr>
            <th>Propiedad</th>
            <th>Cookie</th>
            <th>Finalidad</th>
            <th>Plazo</th>
          </tr>
          <tr>
            <td>app.kumux.io</td>
            <td>__hssc</td>
            <td>Cookie necesaria para la utilización de las opciones y servicios del sitio webs</td>
            <td>Sesión</td>
          </tr>
          <tr>
            <td>app.kumux.io</td>
            <td>__hssrc</td>
            <td>Cookie necesaria para la utilización de las opciones y servicios del sitio webs</td>
            <td>30 minutos</td>
          </tr>  
        </table>
        <ConditionsSectionSubTitle>Analíticas y Optimizaciones:</ConditionsSectionSubTitle>
        <table>
          <tr>
            <th>Finalidad</th>
            <th>Cookie</th>
            <th>Finalidad</th>
            <th>Plazo</th>
          </tr>
          <tr>
            <td>app.kumux.io</td>
            <td>_ga</td>
            <td>ID utiliza para identificar a los usuarios</td>
            <td>en 2 años</td>
          </tr>
          <tr>
            <td>app.kumux.io</td>
            <td>_gat</td>
            <td>Se utiliza para monitorizar el número de peticiones al servidor de Google Analytics cuando se utiliza el Administrador de etiquetas Google</td>
            <td>Sesión</td>
          </tr>
          <tr>
            <td>app.kumux.io</td>
            <td>_gid</td>
            <td>ID utiliza para identificar a los usuarios durante 24 horas después de la última actividad</td>
            <td>en 20 horas</td>
          </tr>
          <tr>
            <td>app.kumux.io</td>
            <td>__hstc</td>
            <td>ID utiliza por Hubspot para identificar a los usuarios.</td>
            <td>en un año</td>
          </tr>
          <tr>
            <td>app.kumux.io</td>
            <td>hubspotutk</td>
            <td>ID utiliza por Hubspot para identificar a los usuarios.</td>
            <td>en un año</td>
          </tr>
        </table>
        <ConditionsTableTitle>COOKIES DE TERCEROS</ConditionsTableTitle>
        <ConditionsSectionText>
          Los servicios de terceros son ajenos al control del editor. Los proveedores pueden modificar en todo momento sus
          condiciones de servicio, finalidad y utilización de las cookies, etc.
        </ConditionsSectionText>
        <ConditionsSectionSubTitle>Proveedores externos de este sitio web:</ConditionsSectionSubTitle>
        <table>
          <tr>
            <th>Editor</th>
            <th>Política de privacidad</th>
          </tr>
          <tr>
            <td>Hupspot</td>
            <td><a href="https://legal.hubspot.com/privacy-policy" target="_blank" rel="noopener noreferrer">https://legal.hubspot.com/privacy-policy</a></td>
          </tr>
          <tr>
            <td>Google Analytics</td>
            <td><a href="https://privacy.google.com/take-control.html" target="_blank" rel="noopener noreferrer">https://privacy.google.com/take-control.html</a></td>
          </tr>
        </table>
        <ConditionsTableTitle>PANEL DE CONFIGURACIÓN DE COOKIES</ConditionsTableTitle>
        <ConditionsSectionText>
          Desde este panel podrá configurar las cookies que el sitio web puede instalar en su navegador, excepto las cookies
          técnicas o funcionales que son necesarias para la navegación y la utilización de las diferentes opciones o servicios que se
          ofrecen.
            <br/>{' '}<br/>
            <SettingsButton
                btntype="primary"
                onClick={() => {
                  props.openCookiesSettings()
                }}
              >
                Acceso al panel
            </SettingsButton>
        </ConditionsSectionText>
        <ConditionsTableTitle>CÓMO GESTIONAR LAS COOKIES DESDE EL NAVEGADOR</ConditionsTableTitle>
        <table>
          <tr>
            <td>Eliminar las cookies del dispositivo</td>
            <td>
                Las cookies que ya están en un dispositivo se pueden eliminar borrando el historial del
                navegador, con lo que se suprimen las cookies de todos los sitios web visitados.
                Sin embargo, también se puede perder parte de la información guardada (por ejemplo,
                los datos de inicio de sesión o las preferencias de sitio web)
            </td>
          </tr>
          <tr>
            <td>Gestionar las cookies específicas del sitio</td>
            <td>
                Para tener un control más preciso de las cookies específicas de cada sitio, los usuarios
                pueden ajustar su configuración de privacidad y cookies en el navegador.
            </td>
          </tr>
          <tr>
            <td>Bloquear las cookies</td>
            <td>
                Aunque la mayoría de los navegadores modernos se pueden configurar para evitar que
                se instalen cookies en los dispositivos, eso puede obligar al ajuste manual de
                determinadas preferencias cada vez que se visite un sitio o página. Además, algunos
                servicios y características pueden no funcionar correctamente (por ejemplo, los inicios de
                sesión con perfil).
            </td>
          </tr>
        </table>
        <ConditionsTableTitle>CÓMO ELIMINAR LAS COOKIES DE LOS NAVEGADORES MÁS COMUNES</ConditionsTableTitle>
        <table>
          <tr>
            <td>Chrome</td>
            <td>
              <a href="http://support.google.com/chrome/answer/95647?hl=es" target="_blank" rel="noopener noreferrer">http://support.google.com/chrome/answer/95647?hl=es</a>
            </td>
          </tr>
          <tr>
            <td>Internet Explorer. Version 11</td>
            <td>
              <a href="https://support.microsoft.com/es-es/help/278835/how-to-delete-cookie-files-in-internet-explorer" target="_blank" rel="noopener noreferrer">https://support.microsoft.com/es-es/help/278835/how-to-delete-cookie-files-in-internet-explorer</a>
            </td>
          </tr>
          <tr>
            <td>Firefox. Version 65.0.1</td>
            <td>
              <a href="https://www.mozilla.org/es-ES/privacy/websites/#cookies" target="_blank" rel="noopener noreferrer">https://www.mozilla.org/es-ES/privacy/websites/#cookies</a>
            </td>
          </tr>
          <tr>
            <td>Safari Version 5.1</td>
            <td>
              <a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac" target="_blank" rel="noopener noreferrer">https://support.apple.com/es-es/guide/safari/sfri11471/mac</a>
            </td>
          </tr>
          <tr>
            <td>Opera</td>
            <td>
              <a href="https://help.opera.com/en/latest/security-and-privacy/#clearBrowsingData" target="_blank" rel="noopener noreferrer">https://help.opera.com/en/latest/security-and-privacy/#clearBrowsingData</a>
            </td>
          </tr>
        </table>
        <br/>{' '}<br/>{' '}<br/>
        </>
        )}
        {(props.lang === 'eng') && (
        <>
        <ConditionsTitle>Cookie Policy</ConditionsTitle>
        <ConditionsSectionTitle>INFORMATION ABOUT COOKIES</ConditionsSectionTitle>
        <ConditionsSectionText>
          Due to the coming into force of the amendment to the «Law on Services of the Information Society» (LSSICE) established
          by Spanish Royal Decree 13/2012, it is mandatory to obtain the express consent of the user of all web pages that use non-
          essential cookies before browsing them.
        </ConditionsSectionText>
        <ConditionsSectionTitle>What Are Cookies?</ConditionsSectionTitle>
        <ConditionsSectionText>
          Cookies and other similar technologies such as local shared objects, flash cookies or pixels, are tools used by Web servers
          to store and retrieve information about their visitors, as well as to enable the proper functioning of the site.
        </ConditionsSectionText>
        <ConditionsSectionText>
          Through using these devices, the Web server is able to remember some data concerning the user, such as their
          preferences for viewing the server’s pages, their name and password, products that interest them most, etc.
        </ConditionsSectionText>
        <ConditionsSectionTitle>COOKIES AFFECTED BY, AND COOKIES EXEMPT FROM REGULATIONS</ConditionsSectionTitle>
        <ConditionsSectionText>
          According to the EU directive, the cookies that require the user’s informed consent are analysis, advertising and affiliation
          cookies, while technical cookies and those that are necessary for the operation of the website or the provision of services
          expressly requested by the user are exempt.
          </ConditionsSectionText>
        <ConditionsSectionTitle>TYPES OF COOKIES</ConditionsSectionTitle>
        <ConditionsSectionSubTitle>DEPENDING ON THE PURPOSE</ConditionsSectionSubTitle>
        <ConditionsSectionList>
          <li>
              Technical and functional cookies: allow the user to navigate through a website, platform or application and the
              use of different options or services available in it.
          </li>
          <li>
              Analysis cookies: allow the controller to monitor and analyse the behaviour of the users of the websites they
              are linked to. The information collected by this type of cookie is used to measure the activity of the websites,
              application or platform and to carry out browsing profiling of the users of said sites, applications and platforms, with
              the purpose of introducing improvements in the analysis of the user data carried out by service users.
          </li>
          <li>
              Advertising cookies: allow management, in the most efficient way possible, of advertising spaces which, if
              applicable, the editor has included in a website, application or platform from where requested service is provided
              based on data such as the edited content or frequency with which advertisements are made.
          </li>
          <li>
              Behavioural advertising cookies : collect information on the user’s personal preferences and choices
              (retargeting) in order to allow management, in the most efficient way possible, of the advertising spaces which, if
              applicable, the editor has included in a website, application or platform from where the requested service is
              provided.
          </li>
          <li>
              Social cookies: established by the social network platforms in the services to allow content to be shared with
              friends and networks. The social media platforms have the ability to track activity online outside the Services. This
              may affect the content and messages seen in other services used.
          </li>
          <li>
              Affiliate cookies: allow you to track visits through links from other websites, with which the website establishes
              affiliate agreements (affiliate companies).
          </li>
          <li>
              Affiliate cookies: allow you to track visits through links from other websites, with which the website establishes
              affiliate agreements (affiliate companies).
          </li>
        </ConditionsSectionList>
        <ConditionsSectionSubTitle>ACCORDING TO THE PROPERTY</ConditionsSectionSubTitle>
        <ConditionsSectionList>
        <li>
            Own cookies: are sent to the user’s terminal team from a team or power managed by the own editor and from
            where the requested service is provided by the user.
        </li>
        <li>
            Third party cookies: are sent to the user’s terminal team from a team or power which is not managed by the
            editor, but by another body which processes data obtained through the cookies.
        </li>
        </ConditionsSectionList>
        <ConditionsSectionSubTitle>DEPENDING ON THE STORAGE PERIOD</ConditionsSectionSubTitle>
        <ConditionsSectionList>
        <li>
            Session cookies: are a type of cookie designed to collect and store data as long as the user accesses a
            website.
        </li>
        <li>
            Permanent cookies: are a type of cookies where data continues to be stored in the terminal and may be
            accessed and processed for a period of time defined by the cookie controller, and that can range from a few
            minutes to several years.
        </li>
        </ConditionsSectionList>
        <ConditionsSectionTitle>PROCESSING OF PERSONAL DATA</ConditionsSectionTitle>
        <ConditionsSectionText>
          ENLIGHTING TECHNOLOGIES SL is the Controller of the personal data of the Data Subject and informs them that these
          data will be processed in accordance with the provisions of Regulation (EU) 2016/679, of 27 April 2016 (GDPR), and
          therefore the following information on the processing is provided:
        </ConditionsSectionText>
        <ConditionsSectionText>
          Purposes of the data processing: as specified in the cookies section which are used on this website.
        </ConditionsSectionText>
        <ConditionsSectionText>
          Legitimisation of the processing: by consent of the data subject (art. 6.1 GDPR).
        </ConditionsSectionText>
        <ConditionsSectionText>
          Data communication: data will not be disclosed to third parties, except in cookies owned by third parties or under legal
          obligation.
        </ConditionsSectionText>
        <ConditionsSectionText>
          Rights of the Data Subject:
        </ConditionsSectionText>
        <ConditionsSectionList>
          <li>Right to withdraw consent at any time.</li>
          <li>
          Right of access, rectification, portability and erasure of data and the limitation or objection to their processing.
          </li>
          <li>
          The right to file a claim with the Spanish Supervisory Authority (www.aepd.es) if you consider that the processing does not
          comply with current legislation.
          </li>
        </ConditionsSectionList>
        <ConditionsSectionText>
          Contact information to exercise their rights:
        </ConditionsSectionText>
        <ConditionsSectionText>
          ENLIGHTING TECHNOLOGIES SL. Carrer Baldiri Reixac, 2, edifici Torre I Planta 3 Porta A1., 3 - 08028 Barcelona
          (Barcelona). E-mail: hola@enlighting-tech.com
        </ConditionsSectionText>
        <ConditionsSectionTitle>COOKIES USED ON THIS WEBSITE</ConditionsSectionTitle>
        <ConditionsTableTitle>COOKIES CONTROLLED BY THE EDITOR</ConditionsTableTitle>
        <ConditionsTableTitle>ANALYTICS</ConditionsTableTitle>
        <ConditionsSectionSubTitle>Functional and Necessary:</ConditionsSectionSubTitle>
        <table>
          <tr>
            <th>Property</th>
            <th>Cookie</th>
            <th>Purpose</th>
            <th>Term</th>
          </tr>
          <tr>
            <td>app.kumux.io</td>
            <td>__hssc</td>
            <td>Cookie required to use Hubspot options and services</td>
            <td>Sesión</td>
          </tr>
          <tr>
            <td>app.kumux.io</td>
            <td>__hssrc</td>
            <td>Cookie required to use Hubspot options and services</td>
            <td>30 minutes</td>
          </tr>
          </table>
          <ConditionsSectionSubTitle>Analytics and Performance:</ConditionsSectionSubTitle>
          <table>
          <tr>
            <th>Property</th>
            <th>Cookie</th>
            <th>Purpose</th>
            <th>Term</th>
          </tr>
          <tr>
            <td>app.kumux.io</td>
            <td>_ga</td>
            <td>ID used to identify users</td>
            <td>in 2 years</td>
          </tr>
          <tr>
            <td>app.kumux.io</td>
            <td>_gat</td>
            <td>Used to monitor number of Google Analytics server requests when using Google Tag Manager</td>
            <td>Sesión</td>
          </tr>
          <tr>
            <td>app.kumux.io</td>
            <td>_gid</td>
            <td>ID used to identify users for 24 hours after last activity</td>
            <td>in 20 hours</td>
          </tr>
          <tr>
            <td>app.kumux.io</td>
            <td>__hstc</td>
            <td>ID used by Hubspot to identify users</td>
            <td>in a year</td>
          </tr>
          <tr>
            <td>app.kumux.io</td>
            <td>hubspotutk</td>
            <td>ID used by Hubspot to identify users</td>
            <td>in a year</td>
          </tr>
        </table>
        <ConditionsTableTitle>THIRD PARTY COOKIES</ConditionsTableTitle>
        <ConditionsSectionText>
            Third-party services are beyond the control of the editor. Suppliers may at any time modify their service conditions, the
            purpose and use of cookies, etc.
        </ConditionsSectionText>
        <ConditionsSectionSubTitle>External suppliers of this website:</ConditionsSectionSubTitle>
        <table>
          <tr>
            <th>Editor</th>
            <th>Privacy Policy</th>
          </tr>
          <tr>
            <td>Hupspot</td>
            <td><a href="https://legal.hubspot.com/privacy-policy" target="_blank" rel="noopener noreferrer">https://legal.hubspot.com/privacy-policy</a></td>
          </tr>
          <tr>
            <td>Google Analytics</td>
            <td><a href="https://privacy.google.com/take-control.html" target="_blank" rel="noopener noreferrer">https://privacy.google.com/take-control.html</a></td>
          </tr>
        </table>
        <ConditionsTableTitle>COOKIES CONFIGURATION PANEL</ConditionsTableTitle>
        <ConditionsSectionText>
            From this panel the user will be able to configure the cookies that the website can install in their browser, except for the
            technical or functional cookies that are necessary for browsing and using the different options or services that are offered.
            <br/>{' '}<br/>
            <SettingsButton
                btntype="primary"
                onClick={() => {
                  props.openCookiesSettings()
                }}
              >
                Access to the panel
            </SettingsButton>
        </ConditionsSectionText>
        <ConditionsTableTitle>HOW TO MANAGE COOKIES FROM YOUR BROWSER</ConditionsTableTitle>
        <table>
          <tr>
            <td>Delete cookies from your device</td>
            <td>
                Cookies that are already on a device can be deleted by clearing the browser history, thus
                deleting the cookies from all websites visited.
                However, some of the saved information (e.g. login data or website preferences) may also
                be lost.
            </td>
          </tr>
          <tr>
            <td>Manage site specific cookies</td>
            <td>
                For more precise control of site specific cookies, users can adjust their privacy settings
                and cookies in their browser.
            </td>
          </tr>
          <tr>
            <td>Blocking cookies</td>
            <td>
                While most modern browsers can be configured to prevent cookies from being installed
                on a device, this may require the manual adjustment of certain preferences each time a
                site or page is visited. In addition, some services and features may not work properly (for
                example, profile logins).
            </td>
          </tr>
        </table>
        <ConditionsTableTitle>HOW TO DELETE COOKIES FROM MOST COMMON BROWSERS</ConditionsTableTitle>
        <table>
          <tr>
            <td>Chrome</td>
            <td>
              <a href="http://support.google.com/chrome/answer/95647?hl=es" target="_blank" rel="noopener noreferrer">http://support.google.com/chrome/answer/95647?hl=es</a>
            </td>
          </tr>
          <tr>
            <td>Internet Explorer. Version 11</td>
            <td>
              <a href="https://support.microsoft.com/es-es/help/278835/how-to-delete-cookie-files-in-internet-explorer" target="_blank" rel="noopener noreferrer">https://support.microsoft.com/es-es/help/278835/how-to-delete-cookie-files-in-internet-explorer</a>
            </td>
          </tr>
          <tr>
            <td>Firefox. Version 65.0.1</td>
            <td>
              <a href="https://www.mozilla.org/es-ES/privacy/websites/#cookies" target="_blank" rel="noopener noreferrer">https://www.mozilla.org/es-ES/privacy/websites/#cookies</a>
            </td>
          </tr>
          <tr>
            <td>Safari Version 5.1</td>
            <td>
              <a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac" target="_blank" rel="noopener noreferrer">https://support.apple.com/es-es/guide/safari/sfri11471/mac</a>
            </td>
          </tr>
          <tr>
            <td>Opera</td>
            <td>
              <a href="https://help.opera.com/en/latest/security-and-privacy/#clearBrowsingData" target="_blank" rel="noopener noreferrer">https://help.opera.com/en/latest/security-and-privacy/#clearBrowsingData</a>
            </td>
          </tr>
        </table>
        <br/>{' '}<br/>{' '}<br/>
        </>
        )}
      </ConditionsSection>
  )
}


const SettingsButton = styled(ButtonUI)`
  max-width: 280px;
`

const ConditionsTitle = styled.h3`
  margin: 0 0 10px;
  ${(props: CompProps) => (props.theme as Theme).font_size.large};
`

const ConditionsSection = styled.div`
  background-color: ${(props: CompProps) =>
    (props.theme as Theme).color.white.regular};
  padding: 60px;
  margin: 60px 30px;
  height: calc(80vh - 90px);
  overflow-y: scroll;
  ${(props: CompProps) => (props.theme as Theme).filters.drop_shadow};
  table {
    text-align: left;
    width: 100%;
    table-layout : fixed;
    border-collapse: collapse;
    th, td {
      border: 1px solid ${(props: CompProps) => (props.theme as Theme).color.white.darker};
      vertical-align: top;
      padding: 15px;
    }
    a {
      cursor: pointer;
      color: ${(props: CompProps) => (props.theme as Theme).color.black.lightest} ! important;
      text-decoration: none;
    }
    a:hover {
      color: ${(props: CompProps) =>
        (props.theme as Theme).color._900} !important;
    }
  }
`

const ConditionsSectionTitle = styled.h4`
  ${(props: CompProps) => (props.theme as Theme).font_size.regular};
  margin: 30px 0px;
`

const ConditionsSectionSubTitle = styled.h5`
  font-family: ${(props: CompProps) => (props.theme as Theme).font.semibold};
`

const ConditionsSectionText = styled.p`
  margin-top: 15px;
  b {
    font-family: ${(props: CompProps) => (props.theme as Theme).font.bold};
  }
  a {
    cursor: pointer;
    color: ${(props: CompProps) => (props.theme as Theme).color.black.lightest} ! important;
    text-decoration: none;
  }
  a:hover {
    color: ${(props: CompProps) =>
      (props.theme as Theme).color._900} !important;
  }
`

const ConditionsSectionList = styled.ul`
  list-style: circle;
  li {
    margin-top: 10px;
  }
`

const ConditionsTableTitle = styled.div`
  ${(props: CompProps) => (props.theme as Theme).font_size.regular};
  margin: 30px 0px;
  text-align: center;
  background-color: ${(props: CompProps) => (props.theme as Theme).color.white.darker};
  padding: 5px;
`

export default CookiesPolicy
