import React, { CSSProperties } from 'react'
import styled from 'styled-components'
import { CompProps, Theme } from '../../styles/theme'

import { Led } from '../../api/LedApi'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  onDragStart: Function

  infoLuminary: Function
  style: CSSProperties
  led: Led
  index: number
}

const LedAvailable: React.FC<Props> = (props) => {
  const ChartAreaIcon = <FontAwesomeIcon icon={faInfoCircle} />
  const {
    style,
    infoLuminary,

    led,
    index,
    onDragStart
  } = props

  return (
    <LedWrapper
      style={style}
      key={led.id}
      onDragStart={(e) => {
        onDragStart(e, led, true)
      }}
      // e.dataTransfer.setData('led', JSON.stringify(led))
      //setstartDragging(true)
      // }}
      draggable={true}
      onDragEnd={(e) => {
        onDragStart(e, led, false)
      }}
      className={index === 0 ? 'first draggable ' : 'draggable'}
    >
    <ContainerLed>
      <Info className="iconinfo" onClick={() => infoLuminary(led)}>
        {ChartAreaIcon}
      </Info>

      <Family>{led.family}</Family>
      <Brand>{led.brand}</Brand>
      <Reference>{led.reference}</Reference>

      <ListInfo>
        CCT: {led.cct_k === -1 ? '-' : led.cct_k} CRI:{' '}
        {led.cri === -1 ? '-' : led.cri} RF: {led.rf === -1 ? '-' : led.rf} RG:{' '}
        {led.rg === -1 ? '-' : led.rg} Eff:{' '}
        {led.efficiency_lmw === -1 ? '-' : led.efficiency_lmw}
      </ListInfo>
    </ContainerLed>
    </LedWrapper>
  )
}

const LedWrapper = styled.div`

`

//const BorderLed = styled.div``
const ContainerLed = styled.div`
  .first {
    margin-top: 0px;
  }

  .iconinfo {
    padding-left: 10px;
    font-size: 20px;
    color: ${(props: CompProps) =>
      (props.theme as Theme).color.neutral._500}!important;
    cursor: pointer;
    grid-area: info;
  }

  display: grid;
  grid-template-columns: 10% 22% 34% 34%;
  grid-template-areas:
    'info family brand reference'
    'info ledinfo ledinfo ledinfo';

  padding-top: 15px;

  font-style: normal;
  font-weight: normal;
  ${(props: CompProps) => (props.theme as Theme).font_size.xxsmall};
  
  cursor: pointer;
  border: 1px solid #bfbfbf;

  height: 90%;

`

const ListInfo = styled.div`
  font-size: 10px;
  color: #a0a0a0;
  grid-area: ledinfo;
`

const Info = styled.div`
  grid-area: info;
  margin-top: 10px;
  svg:hover {
    color: ${(props: CompProps) => (props.theme as Theme).color._900}!important;
  }
`
const Brand = styled.div`
  grid-area: brand;
  padding-right: 10px;
`
const Reference = styled.div`
  grid-area: reference;
  padding-right: 10px;
`
const Family = styled.div`
  grid-area: family;
`

export default LedAvailable
