import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Chart } from 'chart.js'

// Esto se corresponde con la respuesta "dinamic_charts": { "spd": [[x,y]] }
// dentro del endpoint /results_simulation

interface Props {
  //spd: Array<Array<number>>
  //  spd: Array<number>
  wavelength: Array<number>
  intensity: Array<number>
  line?: number[][]
  lineColors?: string[]
  showlabels?: boolean
  background?: string
  canvas_width: string
  compare_spd?:
    | {
        wavelength: number[]
        irradiance: number[]
      }
    | Boolean
}

const SpectraLineBars: React.FC<Props> = (props) => {
  const wavefix: Array<number> = []
  let { showlabels, background, compare_spd } = props
  if (showlabels === undefined) {
    showlabels = true
  }
  if (background === undefined) {
    background = '#fff'
  }
  // for (let index = 0; index < props.spd.length; index++) {
  //  for (let w = 380; index < 780; w++) {
  // props.spd[index][0] = w
  //  }
  //  }
  for (let index = 380; index < 780; index++) {
    wavefix.push(index)
  }

  let [wavelength] = useState(props.wavelength)
  let line = props.line

  const chartCanvasRef = React.useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    function validateParams(
      intensity: Array<number>,
      wavelength: Array<number>
    ) {
      const isValid: boolean =
        intensity.length === 401 || intensity.length === 0
      return isValid
    }

    props.intensity &&
      props.wavelength &&
      validateParams(props.intensity, props.wavelength) &&
      drawChart(chartCanvasRef)

    // eslint-disable-next-line
  }, [props])

  const nmToRGB = (wavelength: number) => {
    var Gamma = 0.8,
      IntensityMax = 255,
      factor,
      red,
      green,
      blue
    if (wavelength >= 380 && wavelength < 440) {
      red = -(wavelength - 440) / (440 - 380)
      green = 0.0
      blue = 1.0
    } else if (wavelength >= 440 && wavelength < 490) {
      red = 0.0
      green = (wavelength - 440) / (490 - 440)
      blue = 1.0
    } else if (wavelength >= 490 && wavelength < 510) {
      red = 0.0
      green = 1.0
      blue = -(wavelength - 510) / (510 - 490)
    } else if (wavelength >= 510 && wavelength < 580) {
      red = (wavelength - 510) / (580 - 510)
      green = 1.0
      blue = 0.0
    } else if (wavelength >= 580 && wavelength < 645) {
      red = 1.0
      green = -(wavelength - 645) / (645 - 580)
      blue = 0.0
    } else if (wavelength >= 645 && wavelength < 781) {
      red = 1.0
      green = 0.0
      blue = 0.0
    } else {
      red = 0.0
      green = 0.0
      blue = 0.0
    }
    // Let the intensity fall off near the vision limits
    if (wavelength >= 380 && wavelength < 420) {
      factor = 0.3 + (0.7 * (wavelength - 380)) / (420 - 380)
    } else if (wavelength >= 420 && wavelength < 701) {
      factor = 1.0
    } else if (wavelength >= 701 && wavelength < 781) {
      factor = 0.3 + (0.7 * (780 - wavelength)) / (780 - 700)
    } else {
      factor = 0.0
    }
    if (red !== 0) {
      red = Math.round(IntensityMax * Math.pow(red * factor, Gamma))
    }
    if (green !== 0) {
      green = Math.round(IntensityMax * Math.pow(green * factor, Gamma))
    }
    if (blue !== 0) {
      blue = Math.round(IntensityMax * Math.pow(blue * factor, Gamma))
    }
    return [red, green, blue]
  }

  const norm = (val: number, max: number, min: number) => {
    return (val - min) / (max - min)
  }

  const drawChart = (canvasRef: React.RefObject<HTMLCanvasElement>) => {
    let canvas: HTMLCanvasElement | null = canvasRef.current
      ? canvasRef.current
      : null
    let ctx: CanvasRenderingContext2D | null = canvasRef.current
      ? canvasRef.current.getContext('2d')
      : null

    if (canvas && ctx) {
      // Fix wavelength to a value
      const wavefix: Array<number> = []
      for (let index = 380; index <= 780; index++) {
        wavefix.push(index)
      }
      wavelength = wavefix

      // Generate gradient
      const canvasWidth: number = canvas ? canvas.clientWidth : 0

      //const canvasLeftOffset: number = props.showlabels ? 70 : 10 // The margin generated by the left axis
      const canvasBottomOffset: number = props.showlabels ? 0 : 10 // The margin generated by the bottom axis
      // const canvasLeftOffset: number = props.showlabels ? 70 : 80 // The margin generated by the left axis
      let canvasLeftOffset: number = 0
      if (props.canvas_width === '555px') {
        canvasLeftOffset = 80
      }
      if (props.canvas_width === '216px') {
        canvasLeftOffset = 0
      }
      if (props.canvas_width === '800px') {
        canvasLeftOffset = 80
      }
      if (props.canvas_width === '680px') {
        canvasLeftOffset = 0
      }

      var gradientStroke = ctx.createLinearGradient(
        canvasLeftOffset,
        canvasBottomOffset,
        canvasWidth,
        canvasBottomOffset
      )

      var gradientStrokeShadow = ctx.createLinearGradient(
        canvasLeftOffset,
        canvasBottomOffset,
        canvasWidth,
        canvasBottomOffset
      )
      for (var i = 0; i < wavelength.length; i++) {
        var color = nmToRGB(wavelength[i])
        var value = norm(i, wavelength.length, 0)
        gradientStroke.addColorStop(
          value,
          'rgba(' + color[0] + ', ' + color[1] + ', ' + color[2] + ', 1.000)'
        )
      }
      gradientStrokeShadow.addColorStop(0.5, 'rgba(180,180,180,0.9)')
      // Create the chart

      let datasetsline: [
        {
          label: string
          borderColor?: CanvasGradient | string
          pointRadius?: number
          fill?: boolean
          backgroundColor?: CanvasGradient
          borderWidth?: number
          data?: number[]
          showLine?: boolean
          borderDash?: number[]
        }
      ] = [
        {
          label: 'Data',
          borderColor: gradientStroke,
          pointRadius: 0,
          fill: true,
          backgroundColor: gradientStroke,
          borderWidth: 0.5,
          data: props.intensity
        }
      ]

      if (Array.isArray(line) && line.length > 0) {
        for (let i in line) {
          datasetsline.push({
            label: 'line',
            data: line[i],
            fill: false,
            borderColor: props.lineColors ? '#' + props.lineColors[i] : 'black',
            pointRadius: 0,
            showLine: true,
            borderDash: [10, 5],
            borderWidth: 1
          })
        }
      }

      if (
        compare_spd !== undefined &&
        compare_spd !== false &&
        (compare_spd as {
          wavelength: number[]
          irradiance: number[]
        }).irradiance.length > 0
      ) {
        const shadow = compare_spd as {
          wavelength: number[]
          irradiance: number[]
        }
        datasetsline.push({
          label: 'Shadow',
          borderColor: gradientStrokeShadow,
          pointRadius: 0,
          fill: true,
          backgroundColor: gradientStrokeShadow,
          borderWidth: 0.5,
          data: shadow.irradiance
        })
      }
      new Chart(ctx, {
        type: 'line',
        data: {
          labels: wavelength,
          datasets: datasetsline
        },
        options: {
          responsive: false,
          maintainAspectRatio: true,
          devicePixelRatio: 1,
          animation: {
            duration: 1
          },
          tooltips: {
            enabled: false
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [
              {
                scaleLabel: {
                  display: showlabels,
                  labelString: 'Intensity (A.U.)',
                  padding: 10
                },
                ticks: {
                  fontColor: 'rgba(0,0,0,0.5)',
                  fontStyle: 'bold',
                  beginAtZero: true,
                  maxTicksLimit: 3,
                  padding: 10,
                  min: 0,
                  max: 1,
                  display: showlabels
                },
                gridLines: {
                  drawTicks: true,
                  display: showlabels
                }
              }
            ],
            xAxes: [
              {
                scaleLabel: {
                  display: showlabels,
                  labelString: 'Wavelength (nm)',
                  padding: 20
                },
                gridLines: {
                  zeroLineColor: 'transparent',
                  drawTicks: true,
                  display: showlabels
                },
                ticks: {
                  padding: 10,
                  fontColor: showlabels ? 'rgba(0,0,0,0.5)' : 'transparent',
                  fontStyle: 'bold',
                  display: showlabels
                }
              }
            ]
          }
        }
      })
    } else {
      console.error('Canvas element not found')
    }
  }

  return (
    <SpectraLineBarsWrapper>
      <SpectraLineBarsContainer
        background={'transparent'}
        width={props.canvas_width}
      >
        <canvas id="chart" ref={chartCanvasRef} />
      </SpectraLineBarsContainer>
    </SpectraLineBarsWrapper>
  )
}

const SpectraLineBarsWrapper = styled.div`
  position: relative;
  top: 0;
  z-index: 1;
`
const SpectraLineBarsContainer = styled.div<{
  width: string
  background: string
}>`
  display: block;
  /*background: #fff;*/
  background: transparent;
  canvas {
    pointer-events: none!important;
    background:  ${(props) => props.background} 
    width: ${(props) => props.width} !important;
    /* width: 695px !important;*/
    /* width: 555px !important;*/
  }
`

export default SpectraLineBars
