import React, { useEffect, useRef } from 'react'


import { Chart } from 'chart.js'
import { ChartProps } from './ChartStatic'

const SaturationScatter: React.FC<ChartProps> = (props) => {

    const chartCanvasRef = useRef<HTMLCanvasElement>(null)

    useEffect(() => {
        const canvas: HTMLCanvasElement | null = chartCanvasRef.current
        canvas && drawChart(canvas)
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.chartData])

    const drawChart = (canvasRef: HTMLCanvasElement) => {
        const ctx: CanvasRenderingContext2D | null = canvasRef.getContext("2d")
        if(ctx) {
            const chartGraph = new Chart(ctx, {
            type: 'scatter',
            data: {
                datasets: [
                {
                    label: 'r',
                    data: [{'x': props.chartData[0][0], 'y': props.chartData[0][1]}],
                    borderColor: 'red',
                    borderWidth: 0,
                    pointRadius: 1,
                    fill: true
                },
                {
                    label: 'k',
                    data: [{'x': 0, 'y': 200}, {'x': 100, 'y': 100}, {'x': 0, 'y': 0}],
                    borderColor: 'black',
                    borderWidth: 2,
                    pointRadius: 0,
                    fill: false,
                    showLine: true,
                    lineTension: 0
                }]
            },
            options: {
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            beginAtZero: true,
                            suggestedMin: 0,
                            suggestedMax: 100
                        },
                        scaleLabel: {
                            labelString: 'Rf',
                            padding: 10,
                            display: true
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            suggestedMin: 0,
                            suggestedMax: 200
                        },
                        scaleLabel: {
                            labelString: 'Rg',
                            padding: 10,
                            display: true
                        }
                    }]
                },
                responsive: false,
                animation: {
                    duration: 1,
                    onComplete: () => { 
                        props.callBack(chartGraph.toBase64Image())
                        Object.defineProperty(chartGraph.options.animation, 'onComplete', {value: undefined})
                    }
                }
            }
            })
        }  
    }

    return(
        <canvas ref={chartCanvasRef} width={340} height={340} />
    )
}

export default SaturationScatter
