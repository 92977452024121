import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { User } from '../../api/UserApi'
import { CompProps, Theme } from '../../styles/theme'
import { Image } from 'semantic-ui-react'

interface Props {
  user: User | null
  title: string | null
  subtitle?: string | null
}

const HeaderContainer: React.FC<Props> = (props) => {
  const [user, setUser] = useState(props.user)
  
  useEffect(() => {
    setUser(props.user)
  }, [user, props.user])

  return (
    <HeaderWrap>
      {user !== null &&
        props.title !== 'User Settings' &&
        user.photo !== 'null' && user.photo !== null && (
        <HeaderLeft>
            <Image size="tiny" src={user.photo} wrapped />
        </HeaderLeft>
      )}{' '}
      <HeaderRight>
        <h1>{props.title}</h1>
        {props.subtitle && <p>{props.subtitle}</p>}
      </HeaderRight>
    </HeaderWrap>
  )
}

const HeaderWrap = styled.div`
  display: flex;
  align-items: center;
  /*  justify-content: space-between;*/

  justify-content: start;
  .image {
    margin-right: 15px;
  }
  margin-top: 45px;
  margin-bottom: 45px;
`
const HeaderLeft = styled.div`
  margin-right: 15px;
  width: 80px;
`

const HeaderRight = styled.div`
  /* margin-left: 15px;*/
  p {
    ${(props: CompProps) => (props.theme as Theme).font_size.xxsmall};
    padding: 0;
    margin-top: -10px;
  }
`

export default HeaderContainer
