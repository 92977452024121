import React from 'react'
import { useParams } from 'react-router-dom'

import SceneSheet from '../../components/scene/SceneSheet'

const ScenePublic: React.FC = () => {
  const { publicId } = useParams<{ publicId: string }>()
  return (
    <SceneSheet sceneId={publicId} isPublic={true} /> 
  )
}

export default ScenePublic
