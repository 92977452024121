import ReactGA from 'react-ga'

interface CookiesSettings {
    analytics: boolean
    thirdParty: boolean
}

class AnalyticsListener {

    private hsq = (window as any)._hsq = (window as any)._hsq || []

    private userId = ''
    private userEmail = ''

    private initialized = false

    public activateGA = () => {
        if(!this.initialized && this.checkCookieConsent())
        {

            // Init GA
            ReactGA.initialize('UA-157540135-1', {
                debug: false
            })

            // Init Hubspot
            const script = document.createElement("script")
            script.src = "https://js.hs-scripts.com/7842869.js"
            script.id="hs-script-loader"
            script.async = true
            script.defer = true
            document.body.appendChild(script)
            
            this.initialized = true

        } else if(this.initialized && !this.checkCookieConsent()) {
            
            // Deactivate Cookies
            this.initialized = false
        }
    }

    public checkCookieConsent= () => {
        const localCookieSettings: any = localStorage.getItem('cookiesSettings')
        if(localCookieSettings) {
            const cookiesSettings: CookiesSettings = JSON.parse(localCookieSettings)
            return cookiesSettings.analytics && cookiesSettings.thirdParty
        } else {
            return false
        }
    } 

    public setId = (_id: string, _email: string) => {
        if(!this.initialized) return

        this.userId = _id
        this.userEmail = _email

        // Set user in HubSpot
        this.hsq.push(["identify",{
            email: this.userEmail,
            id: this.userId
        }])

        // Set user in GA
        ReactGA.set({ userId: this.userId })
        ReactGA.set({ dimension1: this.userId })
    }

    public pageViewListener = (location: any) => {
        if(!this.initialized) return

        // Track Hubspot router visits
        const route: string = location.pathname
        this.sendPageView(route)
        // Track GA
        ReactGA.pageview(location.pathname + location.search)
    }

    public createRouteView = (tag: string) => {
        if(!this.initialized) return

        // Track Hubspot non-router visits
        const route: string = `/${tag}`
        this.sendPageView(route)

        // Track GA non-router visits
        ReactGA.pageview(`${route}`)
    }

    private sendPageView = (tag: string) => {
        if(!this.initialized) return
        
        this.hsq.push(['setPath', `${tag}`])
        this.hsq.push(['trackPageView'])
    }   

    public sendEvent = (eventId: string) => {
        if(!this.initialized) return

        // Track Hubspot Events
        // Only Available in enterprise version.
        /*
        this.hsq.push(["trackEvent", {
            id: eventId
        }])
        */
        /*
        this._hsq.push(["trackEvent", {
            id: "Clicked Buy Now button",
            value: 20.5
        }]);
        */

       // Track GA Events
       ReactGA.event({
        category: 'User',
        action: `${eventId}`
      })
    } 

}

export default new AnalyticsListener()