import React, { useState } from 'react'
import styled from 'styled-components'
import HeaderContainer from '../components/common/HeaderSection'
import { CompProps, Theme } from '../styles/theme'

import SectionFixture from '../components/common/SectionFixture'
import SectionLed from '../components/common/SectionLed'
import SectionProject from '../components/common/SectionProject'

import { Auth } from '../App'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import '../styles/react-tabs.css'
import HelpContent from '../components/help/HelpContent'
import { Icon } from 'semantic-ui-react'

interface Props {
  auth: Auth
}

const Home: React.FC<Props> = (props) => {
  const [auth] = useState(props.auth)
  const [tabIndex, setTabIndex] = useState(auth.user?.tab ? auth.user.tab : 0)

  const updatetab = (tab: number) => {
    auth.updatetab(tab)
    setTabIndex(tab)
  }

  return (
    <>
    {auth && auth.isAuthenticated && auth.user && (
    <HomeContainer>
      <HeaderContainer
        subtitle="YOUR WORKSPACE"
        title={auth.user && 'Welcome ' + auth.user.name}
        user={auth.user}
      ></HeaderContainer>
      <HomeSection>
        <Tabs selectedIndex={tabIndex} onSelect={(index) => updatetab(index)}>
          <TabList>
            <Tab>
              <TabTitle>
                Fixtures{' '}
                <HelpContent
                  section="Fixture"
                  content="fixture"
                  position="bottom center"
                  html={<Icon name="question circle"></Icon>}
                ></HelpContent>
              </TabTitle>
            </Tab>
            <Tab>
              <TabTitle>
                Projects
                <HelpContent
                  section="Project"
                  content="project"
                  position="bottom center"
                  html={<Icon name="question circle"></Icon>}
                ></HelpContent>
              </TabTitle>
            </Tab>
            <Tab>
              <TabTitle>
                LEDs{' '}
                <HelpContent
                  section="Led"
                  content="led"
                  position="bottom center"
                  html={<Icon name="question circle"></Icon>}
                ></HelpContent>
              </TabTitle>
            </Tab>
          </TabList>
          <TabPanel>
            <SectionFixture auth={auth} />
          </TabPanel>
          <TabPanel>
            <SectionProject auth={auth} />
          </TabPanel>
          <TabPanel>
            <SectionLed auth={auth} />
          </TabPanel>
        </Tabs>
      </HomeSection>
    </HomeContainer>
    )}
    </>
  )
}

const HomeContainer = styled.div`
  margin-top: 45px;
`

const HomeSection = styled.div`
  margin: 0 0;
  .react-tabs__tab .question {
    visibility: hidden;
  }
  .react-tabs__tab--selected .question {
    visibility: initial !important;
  }
`

const TabTitle = styled.h5`
  margin: 0;
  padding: 5px;
  ${(props: CompProps) => (props.theme as Theme).color.black.lightest};
  ${(props: CompProps) => (props.theme as Theme).font_size.small};
  font-family: ${(props: CompProps) => (props.theme as Theme).font.semibold};
`

export default Home
