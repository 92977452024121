import React, { useState, useEffect } from 'react'
import styled, { Keyframes, keyframes } from 'styled-components'
import { CompProps, Theme } from '../../styles/theme'
import { ButtonUI } from '../../styles/commons'
import { Checkbox } from 'semantic-ui-react'

import AnalyticsListener from '../../api/AnalyticsListener'

interface CookiesSettings {
  analytics: boolean
  thirdParty: boolean
}

interface Props {
  opened: boolean
  isPublicUrl: boolean
}

const CookieConsent: React.FC<Props> = (props: Props) => {
  // Get cookies info from localStorage
  let cookiesLocalSettings: CookiesSettings = {
    analytics: true,
    thirdParty: true
  }
  const prevCookieSettings: any = localStorage.getItem('cookiesSettings')
  prevCookieSettings && (cookiesLocalSettings = JSON.parse(prevCookieSettings))

  const [showConsent, setShowConsent] = useState<boolean>(
    prevCookieSettings ? false : true
  )
  const [acceptedCookies, setAcceptedCookies] = useState<CookiesSettings>(
    cookiesLocalSettings
  )

  const marginTop: string = (props.isPublicUrl) ? '0px' : '60px'
  
  const slideOutUp = keyframes`
        from { opacity: 1; top: ${marginTop}; }
        to   { opacity: 0; top: -1200px; }
    `

  const slideInDown = keyframes`
        from { opacity: 0; top: -1200px; }
        to   { opacity: 1; top: ${marginTop}; }
    `

  const [animation, setAnimation] = useState<Keyframes>(slideInDown)

  const sendCookieConsent = () => {
    localStorage.setItem('cookiesSettings', JSON.stringify(acceptedCookies))
    AnalyticsListener.activateGA()
    setAnimation(slideOutUp)
    setTimeout(() => {
      setShowConsent(false)
    }, 1000)
  }

  useEffect(() => {
    if (props.opened && !showConsent) {
      setAnimation(slideInDown)
      setShowConsent(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.opened])

  return (
    <>
      {showConsent && (
        <CookieConsentContainer animation={animation} marginTop={marginTop}>
          <CookieConsentText>
            <CookieConsentTitle>Cookies</CookieConsentTitle>
            <p>
              We use our own cookies and third-party cookies to improve our
              services by analysing your browsing habits.
              <br />
              You can accept cookies by clicking on the «Accept» button or
              configure them or reject their use at any time from our Cookie
              Policy.
              <br />
            </p>
            <p>
              Utilizamos cookies propias y de terceros para mejorar nuestros
              servicios mediante el análisis de sus hábitos de navegación.Puede
              aceptar las cookies haciendo clic en el botón «Acepto» o
              configurarlas o rechazar su uso en cualquier momento desde nuestra
              Política de Cookies.
              <br />
            </p>
            <ContainerOptions>
              <CookieOptions>
                <li>
                  <Checkbox
                    checked={acceptedCookies.analytics}
                    label="Analytics / Analíticas"
                    onClick={() =>
                      setAcceptedCookies({
                        ...acceptedCookies,
                        analytics: !acceptedCookies.analytics
                      })
                    }
                  />
                </li>
                <li>
                  Improve our services and the quality of their provision.
                </li>
                <li>
                  Mejorar nuestros servicios y la calidad en la prestación de
                  los mismos.
                </li>
              </CookieOptions>
              <CookieOptions>
                <li>
                  <Checkbox
                    checked={acceptedCookies.thirdParty}
                    label="Third Party / De Terceros"
                    onClick={() =>
                      setAcceptedCookies({
                        ...acceptedCookies,
                        thirdParty: !acceptedCookies.thirdParty
                      })
                    }
                  />
                </li>
                <li>
                  Third-party services are beyond the control of the editor.
                  Suppliers may at any time modify their service conditions, the
                  purpose and use of cookies, etc.
                </li>
                <li>
                  Los servicios de terceros son ajenos al control del editor.
                  Los proveedores pueden modificar en todo momento sus
                  condiciones de servicio, finalidad y utilización de las
                  cookies, etc.
                </li>
              </CookieOptions>
            </ContainerOptions>
          </CookieConsentText>

          <CookieConsentButton>
            <ButtonUI btntype="primary" onClick={sendCookieConsent}>
              ACCEPT / ACEPTO
            </ButtonUI>
          </CookieConsentButton>
        </CookieConsentContainer>
      )}
    </>
  )
}

const CookieConsentContainer = styled.div<{ animation: Keyframes, marginTop: string }>`
  background-color: ${(props: CompProps) =>
    (props.theme as Theme).color.black.regular};
  background-color: rgba(190, 190, 190, 0.8);
  padding: 45px;
  min-height: 520px;
  width: 100%;
  position: fixed;
  top: ${(props) => props.marginTop || '60px'};
  left: 0;
  z-index: 999;
  box-shadow: ${(props: CompProps) =>
    (props.theme as Theme).filters.drop_shadow};
  animation: ${(props) => props.animation} 1.5s ease-out;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;

    padding: 15px;
  }
  z-index: 2;
`
const ContainerOptions = styled.div`
  @media (max-width: 767px) {
    display: flex;
    ul {
      padding: 0px;
      margin: 0px;
      width: 50%;
    }
  }
`
const CookieConsentTitle = styled.div`
  font-family: ${(props: CompProps) => (props.theme as Theme).font.extrabold};
  font-weight: bolder;
  ${(props: CompProps) => (props.theme as Theme).font_size.regular};
  margin-bottom: 15px;
`
const CookieConsentText = styled.div`
  @media (max-width: 767px) {
    float: auto;
    width: 100%;
  }
  @media (max-width: 992px) {
    float: auto;
    width: 100%;
  }

  float: left;
  width: 50%;
  p {
    font-family: ${(props: CompProps) => (props.theme as Theme).font.light};
    ${(props: CompProps) => (props.theme as Theme).font_size.xxsmall};
  }
`
const CookieConsentButton = styled.div`
  float: right;
  @media (max-width: 767px) {
    display: block;
    margin: 0 auto;
    margin-top: 15px;
  }
`
const CookieOptions = styled.ul`
  li {
    margin-top: 5px;

    ${(props: CompProps) => (props.theme as Theme).font_size.xxxsmall};
  }
  li:first-child {
    font-weight: bolder;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  @media (max-width: 767px) {
    width: auto;

    li:first-child {
      margin-top: 0px;
    }
  }
`

export default CookieConsent
