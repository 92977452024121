import React, { useState, useEffect, SyntheticEvent } from 'react'
import styled from 'styled-components'
import {
  Modal,
  Input,
  Form,
  Loader,
  Dimmer,
  Dropdown
} from 'semantic-ui-react'

import { MainButton } from '../../styles/commons'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Timeline, timeLineApi } from '../../api/TimelineApi'
import { projectApi, Project } from '../../api/ProjectApi'

interface Props {
  timeLineSelect: Timeline
  projectId: any
  copySuccess: Function
}

const ModalCopyTimeline: React.FC<Props> = (props) => {
  const { timeLineSelect, projectId } = props

  let date: Date = new Date(timeLineSelect.datetime_unix * 1000)

  const FormDefault: any = {
    month: date.getMonth() + 1,
    year: date.getFullYear(),
    timeline_id: timeLineSelect.id,
    project_id: projectId,
    reference: 'Copy ' + timeLineSelect.reference,
    date: timeLineSelect.datetime_unix
  }

  const [FormTimeLine, setFormTimeLine] = useState(FormDefault)
  const [projects, setprojects] = useState<any>([])
  const [isLoading, setisLoading] = useState(false)
  const HandleFormTimelineDate = (value: Date) => {
    setFormTimeLine({
      ...FormTimeLine,
      month: Number(value.getMonth() + 1),
      year: value.getFullYear(),
      date: value.getTime() / 1000
    })
  }

  const handleFormTimeline = (e: any) => {
    setFormTimeLine({
      ...FormTimeLine,
      [e.target.name]: e.target.value
    })
  }

  const handleCopyTimeline = async () => {
    setisLoading(true)
    const copyRespone = await timeLineApi.copyTimeLine(
      FormTimeLine.month,
      FormTimeLine.year,
      projectId,
      FormTimeLine.project_id,
      FormTimeLine.timeline_id,
      FormTimeLine.reference
    )

    if (copyRespone) {
      props.copySuccess(FormTimeLine.project_id)
    }

    setisLoading(false)
  }

  useEffect(() => {
    let didCancel: boolean = false
    const getProject = async () => {
      const projectResponse: Project[] = await projectApi.GetProjects()
      if (projectResponse) {
        const projectSelect = []

        for (let index = 0; index < projectResponse.length; index++) {
          projectSelect.push({
            key: projectResponse[index].id,
            value: projectResponse[index].id,
            text: projectResponse[index].reference
          })
        }

        !didCancel && setprojects(projectSelect)
      }
    }

    getProject()

    return () => {
      didCancel = true
    }
  }, [])

  const setFormDropdown = (e: any) => {
    setFormTimeLine({
      ...FormTimeLine,
      project_id: e.value
    })
  }

  return (
    <>
      <Modal.Header>{FormDefault.reference}</Modal.Header>
      <Modal.Content>
        <FormCopy>
          <Form.Field>
            <label>Copy to project</label>
            <Dropdown
              search
              fluid
              selection
              defaultValue={projectId}
              onChange={(event: SyntheticEvent, data: object) =>
                setFormDropdown(data)
              }
              options={projects}
            />
          </Form.Field>
          <Form.Field>
            <label>New name</label>
            <Input
              defaultValue={FormDefault.reference}
              placeholder="Name Timeline"
              onChange={(e: any) => handleFormTimeline(e)}
              name="reference"
            />
          </Form.Field>
          <Form.Field>
            <label>Month-Year</label>
            <DatePicker
              selected={
                FormTimeLine.date
                  ? new Date(FormTimeLine.date * 1000)
                  : new Date()
              }
              onChange={(date: Date) => HandleFormTimelineDate(date)}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              name="date"
            />
          </Form.Field>
          <FormFieldCopy>
            {isLoading ? (
              <Dimmer active inverted>
                <Loader inverted />
              </Dimmer>
            ) : (
              <MainButton onClick={handleCopyTimeline}>COPY</MainButton>
            )}
          </FormFieldCopy>
        </FormCopy>
      </Modal.Content>
    </>
  )
}

const FormCopy = styled(Form)`
  .react-datepicker-wrapper {
    width: 100%;
  }
`
const FormFieldCopy = styled(Form.Field)`
  display: flex;
  button {
    margin-left: auto !important;
  }
`

export default ModalCopyTimeline
