import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { simulationApi } from '../api/SimulationApi'
import { sceneApi } from '../api/SceneApi'
import { Auth } from '../App'
import HistoricList from '../components/common/HistoricList'

import Modal from '../components/common/Modal'

import SimulationFilters from '../components/simulation/SimulationFilters'
import Loading from '../components/common/Loading'

import Tour from '../components/help/Tour'

import styled from 'styled-components'

import { CompProps, Theme } from '../styles/theme'
import ListSavedScenes from '../components/simulation/ListSavedScenes'

interface Props {
  auth: Auth
}

interface Collapsable {
  collapsed: boolean
}
const Simulation: React.FC<Props> = (props) => {
  const { publicId, activeTour } = useParams()
  const [auth, setAuth] = useState(props.auth)
  const [simulationDetailInfo, setSimulationDetailInfo] = useState()
  const [savedSpectra, setSavedSpectra] = useState()

  let activeTourB: boolean
  activeTour ? (activeTourB = false) : (activeTourB = true)

  const [PCBCollapsed, setPCBCollapsed] = useState(activeTourB)
  const [ScenesCollapsed, setScenesCollapsed] = useState(activeTourB)
  const [creatingSceneLoading, setCreatingSceneLoading] = useState(false)
  const [activeTourDetails, setactiveTourDetails] = useState(false)
  //const [illuminance, ] = useState<number[]>([100, 100])
  //const { sceneTourActive, setsceneTourActive } = props.location

  const setLuminaryData = (dataResponse: any) => {
    const { luminaryDetailDataResponse, scenesResponse } = dataResponse

    if (luminaryDetailDataResponse) {
      setSimulationDetailInfo({
        public_id: luminaryDetailDataResponse.public_id,
        reference: luminaryDetailDataResponse.reference,
        leds: luminaryDetailDataResponse.leds
      })
    }
    scenesResponse && setSavedSpectra(scenesResponse)
  }

  useEffect(() => {
    setAuth(props.auth)
  }, [auth, props.auth])

  useEffect(() => {
    let didCancel = false
    async function getLuminaryData() {
      const luminaryDetailDataResponse = await simulationApi.getLuminaryById(
        publicId
      )
      const scenesResponse = await sceneApi.getHistoricByLuminaryId(publicId)

      !didCancel &&
        setLuminaryData({
          luminaryDetailDataResponse,
          scenesResponse
        })
    }

    !didCancel && getLuminaryData()
    return () => {
      didCancel = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const callbackSavedSpectra = (spectra: any) => {
    // setCreatingSceneLoading(true)
    setSavedSpectra(spectra)
    //   setCreatingSceneLoading(false)
  }
  const callbackCreatingSceneLoading = (creatingScene: boolean) => {
    setCreatingSceneLoading(creatingScene)
  }
  const callbacksetactiveTourDetails = (activeTourDetails: boolean) => {
    setactiveTourDetails(activeTourDetails)
  }

  /*
  const exportCSVScenesDiabled = () => {
    toast.info('This feature is not available in the demo.')
  }
  */

  return (
    <SimulationWrapper>
      <SimulationDataWrapper>
        <SimulationSectionContainer className="filters">
          {simulationDetailInfo && (
            <SimulationFilters
              savedSpectra={savedSpectra}
              callbackCreatingSceneLoading={callbackCreatingSceneLoading}
              simulationDetailInfo={simulationDetailInfo}
              activeTour={activeTour !== undefined ? true : false}
              savedSpectralength={
                savedSpectra !== undefined ? savedSpectra.length : 0
              }
              callbackSavedSpectra={callbackSavedSpectra}
            />
          )}
        </SimulationSectionContainer>
        <SimulationSectionContainer className="tour-fixtures-ledsPCB">
          <SubtitleSection
            collapsed={PCBCollapsed}
            onClick={() => setPCBCollapsed(!PCBCollapsed)}
          >
            LEDs PCB
          </SubtitleSection>
          <SimulationSectionCollapsable
            collapsed={PCBCollapsed}
            className="tour-fixtures-ledsPCB"
          >
            {simulationDetailInfo && (
              <HistoricList
                isHistoric={false}
                simulationPage={true}
                listItems={[simulationDetailInfo]}
              />
            )}
          </SimulationSectionCollapsable>
        </SimulationSectionContainer>
        <SubtitleSection
          collapsed={ScenesCollapsed}
          onClick={() => setScenesCollapsed(!ScenesCollapsed)}
        >
          Saved Scenes
        </SubtitleSection>
        <SimulationSectionCollapsable collapsed={ScenesCollapsed}>
          <ListSavedScenes
            savedSpectra={savedSpectra}
            activeTour={activeTour}
            activeTourDetails={activeTourDetails}
            callbackSavedSpectra={callbackSavedSpectra}
            callbacksetactiveTourDetails={callbacksetactiveTourDetails}
            simulationDetailInfo={simulationDetailInfo && simulationDetailInfo}
          />
        </SimulationSectionCollapsable>
      </SimulationDataWrapper>
      {activeTour && savedSpectra && savedSpectra.length === 0 && PCBCollapsed === false && (
        <Tour section="SIMULATION" />
      )}
      <Modal open={creatingSceneLoading} closeModal={() => {}}>
        <Loading />
      </Modal>
    </SimulationWrapper>
  )
}

const SimulationDataWrapper = styled.div`
  width: 100%;
  .filters {
    height: 1060px;
  }
`

const SimulationWrapper = styled.div`
  min-height: 800px;
`
const SubtitleSection = styled.h5<Collapsable>`
  margin: 0;
  ${(props: CompProps) => (props.theme as Theme).font_size.regular};
  text-transform: uppercase;
  cursor: pointer;
  border-top: 0px solid
    ${(props: CompProps) => (props.theme as Theme).color.black.lightest};
  border-bottom: 1px solid
    ${(props: CompProps) => (props.theme as Theme).color.black.lightest};
  padding: 15px;
  &:hover {
    background-color: ${(props: CompProps) =>
      (props.theme as Theme).color.white.lessdark};
  }
  &:after {
    content: '';
    margin: 0 0.5em;
    display: inline-block;
    border: 7px solid transparent;
    ${(props: Collapsable) =>
      props.collapsed
        ? 'border-top: 8px solid gray; border-bottom: 0 none;'
        : 'border-bottom: 8px solid gray; border-top: 0 none;'}
    float: right;
    margin-top: 10px;
  }
`

const SimulationSectionContainer = styled.div`
  margin-bottom: 10px;
`
const SimulationSectionCollapsable = styled.div<Collapsable>`
  ${(props: Collapsable) => (props.collapsed ? 'height: 0;' : 'height: auto;')}
  overflow: hidden;
`

export default Simulation
