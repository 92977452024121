import React from 'react'
import styled from 'styled-components'
import { CompProps, Theme } from '../styles/theme'

const TermsPolicy: React.FC = () => {
  return (
    <ConditionsSection>
      <ConditionsTitle>Terms and conditions.</ConditionsTitle>
      <ConditionsSectionSubTitle>
        Paid Version . Revision: 1.1 - October, 2020
        <br />
        <br />
      </ConditionsSectionSubTitle>
      <ConditionsSectionSubTitle>
        1. PURPOSE OF THE CONTRACT
      </ConditionsSectionSubTitle>
      <ConditionsSectionText>
        The purpose of these conditions is the overall regulation of the products, services, 
        tools and/or content that a technology-based company such as <b>ENLIGHTING TECHNOLOGIES, S.L.</b>, 
        offers its customers (hereinafter, the “<b>Services</b>”).
        <br /> <br />
        These general terms and conditions and the invoiced Services provide the regulatory framework within 
        which the contractual relationship is developed. ENLIGHTING TECHNOLOGIES, SL currently operates on 
        the internet through the website <a rel="noopener noreferrer" target="_blank" href="https://kumux.io">
        wwww.kumux.io</a> and its products and services are accessible by subscribing to this site.
        <br /> <br />
        By registering on the website www.kumux.io through a customer account and/or accessing and/or using 
        the Services, the user (hereinafter also, the “<b>Customer</b>" or the “<b>Client</b>”) accepts these conditions 
        (hereinafter also, "<b>the Contract</b>"), personally or on behalf of the natural or legal person indicated 
        in their customer account and agrees to accept our  {' '}
        <a rel="noopener noreferrer" target="_blank" href="https://app.kumux.io/legal/eng/privacy">Privacy</a> and{' '}
        <a rel="noopener noreferrer" target="_blank" href="https://app.kumux.io/legal/eng/cookies">Cookies</a> policies.
      </ConditionsSectionText>
      <ConditionsSectionSubTitle>
        2. COST AND PAYMENTS
      </ConditionsSectionSubTitle>
      <ConditionsSectionText>
        The price and payment method of the Services are those specified for the invoiced Services, according to the 
        rates applicable at the time the Services are contracted and in accordance with the Customer's profile.
        <br /> <br />
        Payments will be made by direct debit in advance of the period of use.
        <br /> <br />
        The Customer authorizes ENLIGHTING TECHNOLOGIES, SL to charge the corresponding amounts due from the current 
        account that has been registered in its Customer account and undertakes not to reverse them.
        <br /> <br />
        Said payment constitutes the right to receive the invoiced Services. Notwithstanding the foregoing, in case 
        the Customer fails to pay one (1) invoice, ENLIGHTING TECHNOLOGIES, SL may suspend said right. 
      </ConditionsSectionText>
      <ConditionsSectionSubTitle>
        3. DURATION
      </ConditionsSectionSubTitle>
      <ConditionsSectionText>
        This Contract will enter into force on the date these conditions are accepted and will have the minimum 
        duration foreseen for the invoiced Services. In case neither Party withdraws from the Contract or terminates 
        it in accordance with Condition 13 below, it will be considered automatically extended upon expiry by 
        successive periods of identical duration.
      </ConditionsSectionText>
      <ConditionsSectionSubTitle>
        4. OBLIGATIONS OF THE CONTRACTING PARTIES
      </ConditionsSectionSubTitle>
      <ConditionsSectionSubTitle>
        4.1 Obligations of ENLIGHTING TECHNOLOGIES, SL
      </ConditionsSectionSubTitle>
      <ConditionsSectionText>
        ENLIGHTING TECHNOLOGIES, SL undertakes to fulfil the following obligations derived from the relationship 
        with the Customer and consequently from the latter’s contracting of services, products, tools and/or content.
        <ConditionsSectionList>
          <li>
            Provide the user with the products, services, content, and/or tools 
            requested and invoiced with its fullest assurance in accordance with 
            the provisions of these conditions and the appendices.
          </li>
          <li>
            Expressly inform the user of the existence of these conditions prior to the signing thereof.
          </li>
          <li>
            Clearly and accurately report the specific characteristics of the products, services, content 
            and/or tools requested.
          </li>
          <li>
            Provide the user with a copy of these conditions and if the services or products require it, 
            the terms and conditions that govern the license agreement for their use.
          </li>
          <li>
            ENLIGHTING TECHNOLOGIES, SL grants the Customer a non-exclusive, limited, non-perpetual, personal, 
            non-sub licensable and non-transferable license to use the invoiced Services at all times.
          </li>
          <li>
            Provide technical support for the services provided as stipulated in the description 
            of the invoiced Services. 
          </li>
        </ConditionsSectionList>
      </ConditionsSectionText>
      <ConditionsSectionSubTitle>
        4.2 Obligations of the Customer
      </ConditionsSectionSubTitle>
      <ConditionsSectionText>
        The Customer for its part agrees to:
        <ConditionsSectionList>
          <li>
            Comply with everything established in these conditions as well as the provisions 
            of the website <a rel="noopener noreferrer" target="_blank" href="https://kumux.io">
            wwww.kumux.io</a>{' '}regarding any specific contracting conditions of the 
            products, services, content and/or tools provided by ENLIGHTING TECHNOLOGIES, SL.
          </li>
          <li>
            Complete the registration forms prior to the contracting process, as well as the 
            forms for accessing products or services that require payment, ensuing that the 
            information necessary for the subsequent issuance and payment of the invoice for 
            the contracted products or Services is accurate and up to date.
          </li>
          <li>
            Correctly provide the requested bank details and pay the price of the contracted 
            products or services in accordance with the payment method and the current price 
            list published on the website. 
          </li>
          <li>
            Use the services for the specific purposes for which they are provided; ensure the 
            proper use of the passwords for accessing the services and not transfer them to 
            unauthorized persons;
          </li>
          <li>
            Use the Services with the intended material, establish sufficient operational 
            controls and put into practice the appropriate methods of use.
          </li>
          <li>
            Take measures to prevent any damages due to the use of the Services.
          </li>
        </ConditionsSectionList>
      </ConditionsSectionText>
      <ConditionsSectionSubTitle>
        5. PROVISION OF SERVICES
      </ConditionsSectionSubTitle>
      <ConditionsSectionText>
        The terms and conditions of sale of any Services and offers provided by ENLIGHTING 
        TECHNOLOGIES, SL will appear on the website from where they may be consulted, stored 
        or printed by the user. 
      </ConditionsSectionText>
      <ConditionsSectionSubTitle>
        5.1 Availability and application of data
      </ConditionsSectionSubTitle>
      <ConditionsSectionText>
        In order to guarantee physical security and online accessibility, ENLIGHTING TECHNOLOGIES, SL 
        uses reputable and solvent providers to host its equipment and applications. By accepting these 
        general contracting terms and conditions, the Customer expressly accepts that the data that it 
        enters into the ENLIGHTING TECHNOLOGIES, SL IT system will be hosted on the remote servers of 
        the Google data center. Google is solely responsible for the technical maintenance of the servers 
        and does not have, in any case, authorization to access to them, unless this is expressly granted 
        in writing by the data controller, in which case the type of access it may have and the specific 
        purpose thereof will be specifically established.
      </ConditionsSectionText>
      <ConditionsSectionText>
        The chosen company for hosting the systems may change for technical reasons or as a result of 
        changing business needs. This will always be done with the aim of providing the best possible 
        service, as a result of which the user expressly authorizes any change in hosting company. 
        ENLIGHTING TECHNOLOGIES, SL transfers to the User the service warranty received from the hosting 
        and connectivity providers that host the services of ENLIGHTING TECHNOLOGIES, SL.
      </ConditionsSectionText>
      <ConditionsSectionText>
        This service warranty may be modified periodically, as ENLIGHTING TECHNOLOGIES, SL and its 
        providers take advantage of advances in technology and market competitiveness to improve 
        the service conditions. ENLIGHTING TECHNOLOGIES, SL works with all the technical and human 
        resources at its disposal, and in coordination with hosting and connectivity providers, to 
        ensure the applications are accessible and operational at all times. ENLIGHTING TECHNOLOGIES, SL 
        ensures that daily backups are carried out of the data stored in the systems that host the services 
        of ENLIGHTING TECHNOLOGIES, SL. 
        <br /> <br />
        The data, including the user's documents and files, are stored entirely in database systems. 
      </ConditionsSectionText>
      <ConditionsSectionSubTitle>
        5.2 Volume of data and communications
      </ConditionsSectionSubTitle>
      <ConditionsSectionText>
        ENLIGHTING TECHNOLOGIES, SL does not set limits on the volume of data that the user can store 
        in any of the ENLIGHTING TECHNOLOGIES, SL services on the network, nor on the volume of data 
        that can be transferred between its facilities and these services during a specified period. 
        ENLIGHTING TECHNOLOGIES, SL reserves the right to modify this condition at any time, depending on 
        the evolution of the service and always with the idea of improving the quality of the service for 
        its users while developing specific solutions for those users with especially intensive data 
        storage or transfer needs. 
      </ConditionsSectionText>
      <ConditionsSectionSubTitle>
        5.3 Visitor’s log
      </ConditionsSectionSubTitle>
      <ConditionsSectionText>
        ENLIGHTING TECHNOLOGIES, SL informs the user that browsing in the ENLIGHTING TECHNOLOGIES, SL 
        applications may be recorded and analyzed with the aim of improving the service and the application 
        itself. The access log created for this purpose will not contain personal or business information 
        related to the user but only statistical data regarding access to the application’s website. 
      </ConditionsSectionText>
      <ConditionsSectionSubTitle>
        5.4 Maintenance and updates
      </ConditionsSectionSubTitle>
      <ConditionsSectionText>
        The services offered by ENLIGHTING TECHNOLOGIES, SL include measures for corrective maintenance 
        (resolution of software errors and recovery of data integrity) and preventive maintenance 
        (monitoring of the evolution of the application and its data, of the stability of the system, 
        of the adequate size of the system). Additionally, these Services include updating the applications 
        of ENLIGHTING TECHNOLOGIES, SL with new and improved features when they are available, to which 
        the Customer will have access as long as the user license agreement remains in force and payments 
        are up to date. ENLIGHTING TECHNOLOGIES, SL will notify the user about these updates promptly.
      </ConditionsSectionText>
      <ConditionsSectionSubTitle>
        6. INTELLECTUAL AND INDUSTRIAL PROPERTY
      </ConditionsSectionSubTitle>
      <ConditionsSectionText>
        The Customer acknowledges that the software, know-how included therein, all computer elements, 
        programs, websites, data models, images, designs, content, logos, brands and any other product 
        that can be protected under the applicable legislation on intellectual property, patents and 
        trademarks to which they have access by subscribing to the products or services, are the property 
        of ENLIGHTING TECHNOLOGIES, SL or third party licensors.
      </ConditionsSectionText>
      <ConditionsSectionText>
        The Customer undertakes to respect these rights when using the software and Services. The contracting 
        of the Services by the Customer does not imply, in any case, the assignment of rights by 
        ENLIGHTING TECHNOLOGIES, SL in matters of intellectual property, patents or trademarks. 
        ENLIGHTING TECHNOLOGIES, SL prohibits the use of products or services in any way that directly or 
        indirectly contravenes the applicable legislation on intellectual property, patents and trademarks. 
        The Customer will be solely liable for any actions and will hold ENLIGHTING TECHNOLOGIES, SL harmless 
        in this regard.
      </ConditionsSectionText>
      <ConditionsSectionText>
        Under no circumstances may the Customer reproduce, transform, modify, translate, lease, rent, lend, 
        resell or market in any way to third parties the contracted products, services, content and/or tools 
        belonging to ENLIGHTING TECHNOLOGIES, SL, nor transfer the rights and obligations derived from this 
        Contract to third parties. The Customer may not reverse engineer, decompile or disassemble the 
        computer elements that make up the ENLIGHTING TECHNOLOGIES, SL applications.
      </ConditionsSectionText>
      <ConditionsSectionSubTitle>
        7. LIABILITY
      </ConditionsSectionSubTitle>
      <ConditionsSectionText>
        Services are provided “as is”. ENLIGHTING TECHNOLOGIES, SL will not be liable for delays 
        or faults that may occur in the access, running and operation of the website, and/or its 
        services and/or content, or for any interruptions, suspensions or malfunctions thereof, 
        when they are the result of natural disasters or force majeure, or situations of technical 
        or extreme emergency, such as strikes, computer attacks or intrusions or any other situation 
        of force majeure or unforeseen circumstances, of for errors in data transfer networks or server outages. 
      </ConditionsSectionText>
      <ConditionsSectionText>
        However, ENLIGHTING TECHNOLOGIES, SL guarantees users that as soon as it becomes aware of any error affecting 
        the Services, the technical department will proceed to remedy it.
      </ConditionsSectionText>
      <ConditionsSectionText>
        Except in cases of gross negligence or willful intent, the liability of ENLIGHTING TECHNOLOGIES, SL 
        in relation to this Contract will be subject to the following limitations:
        <ConditionsSectionList>
          <li>
            The liability of ENLIGHTING TECHNOLOGIES, SL derived from any direct damage will 
            be limited to the value of the amounts actually paid by the Customer in the last 
            12 months of the Contract.
          </li>
          <li>
            ENLIGHTING TECHNOLOGIES, SL will not be liable for any indirect or unforeseeable 
            damages that may result from the use of the Services by the Customer. 
          </li>
          <li>
            The parties expressly agree that indirect damage is considered to be any financial 
            or commercial damage, loss of earnings, data, orders or customers, reputation, as well 
            as any claim brought by a third party against the Customer. In no case will ENLIGHTING 
            TECHNOLOGIES, SL directors, employees or representatives be liable for any damage, 
            including, but not limited to, indirect, accessory, special, exemplary or criminal 
            damages or for any loss of income, loss of earnings, loss of information, loss or 
            alteration of data or computer failure or malfunction, which is a consequence or is 
            related to the use, operation or performance of the software or which arises directly 
            or indirectly from this Contract, or from the breach of any guarantee, whether imposed 
            through an action based on this Contract, negligence, tort or of any other type and even 
            if ENLIGHTING TECHNOLOGIES, SL had been warned of the possibility of said damages.  
          </li>
          <li>
            ENLIGHTING TECHNOLOGIES, SL will not be liable to the user for any breach of this Contract 
            caused by circumstances beyond the reasonable control of ENLIGHTING TECHNOLOGIES, SL.
          </li>
        </ConditionsSectionList>
      </ConditionsSectionText>
      <ConditionsSectionText>
        The Customer will hold ENLIGHTING TECHNOLOGIES, SL harmless from any third party claim. In the same way, 
        the Customer undertakes to hold ENLIGHTING TECHNOLOGIES, SL harmless for any damages that may result (i) 
        from the non-compliance, inaccuracy (including that caused by omission) or falsification of any of the statements 
        referred to in this Contract (ii) from the breach of any other obligations, commitments, provisions, terms, conditions 
        or agreements established in this Contract. For the purposes of this Contract, any loss, damage (including both consequential 
        damage and loss of earnings), injury, charge, responsibility, liability, handicap, fine, surcharge, interest or expense 
        (including costs and fees for attorneys, barristers, notaries, auditors, accountants, expert witnesses and other professionals) 
        will be considered damages. 
      </ConditionsSectionText>
      <ConditionsSectionSubTitle>
        8. CONFIDENTIALITY
      </ConditionsSectionSubTitle>
      <ConditionsSectionText>
        ENLIGHTING TECHNOLOGIES, SL undertakes to maintain absolute secrecy, security and integrity regarding the Customer’s 
        processes, programs, data and information and to not violate the confidentiality, security and ownership of the files, 
        programs and application systems, refraining from, without the corresponding written authorization of the Customer, 
        performing the slightest change, transaction, modification and addition of information to the files, programs and 
        application systems, and without providing third parties with any kind of information.
      </ConditionsSectionText>
      <ConditionsSectionText>
        ENLIGHTING TECHNOLOGIES, SL will protect the confidentiality of the information classified as confidential with the 
        same degree of care that it normally applies to its own information.
      </ConditionsSectionText>
      <ConditionsSectionText>
        The above confidentiality obligations will not be applicable to confidential information that (i) is already known 
        by ENLIGHTING TECHNOLOGIES, SL prior to the formalization of this document; (ii) is or becomes part of the public 
        domain for reasons not attributable to ENLIGHTING TECHNOLOGIES, SL; (iii) is lawfully obtained from a third party 
        not subject to any obligation of confidentiality; (iv) has been independently developed by 
        ENLIGHTING TECHNOLOGIES, SL; (v) is disclosed by operation of law; or (vi) is disclosed by 
        ENLIGHTING TECHNOLOGIES, SL with the prior written consent of the Customer.
      </ConditionsSectionText>
      <ConditionsSectionSubTitle>
        9. PERSONAL DATA PROTECTION
      </ConditionsSectionSubTitle>
      <ConditionsSectionSubTitle>
        Users’s personal data
      </ConditionsSectionSubTitle>
      <ConditionsSectionText>
        By providing Services through the website, ENLIGHTING TECHNOLOGIES, SL may process the User’s personal data. 
        More information about the processing of personal data by ENLIGHTING TECHNOLOGIES, SL can be obtained in our  {' '}
        <a rel="noopener noreferrer" target="_blank" href="https://app.kumux.io/legal/eng/privacy">Privacy Policy</a>.
      </ConditionsSectionText>
      <ConditionsSectionSubTitle>
        Third parties’ personal data
      </ConditionsSectionSubTitle>
      <ConditionsSectionText>
        ENLIGHTING TECHNOLOGIES, SL will not access or process personal data for which the Client is responsible, except 
        when this is strictly necessary to maintain or provide the Services, or to comply with a legal provision or judicial order.
      </ConditionsSectionText>
      <ConditionsSectionText>
        Notwithstanding the foregoing, in the event that ENLIGHTING TECHNOLOGIES, SL processes personal data in relation to this Contract, 
        ENLIGHTING TECHNOLOGIES, SL will comply with and be subject to the corresponding data processor agreement, which will be attached 
        to this Contract and will be incorporated into it by means of this referral. 
      </ConditionsSectionText>
      <ConditionsSectionSubTitle>
        10. SECURITY: USE OF COOKIES
      </ConditionsSectionSubTitle>
      <ConditionsSectionText>
        By providing Services through the website, ENLIGHTING TECHNOLOGIES, SL will implement and maintain administrative, 
        physical and technical security controls as established in the ENLIGHTING TECHNOLOGIES, SL service security appendix at  {' '}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://app.kumux.io/legal/eng/privacy"
        >
          {' '}
          https://app.kumux.io/legal/eng/privacy
        </a>
      </ConditionsSectionText>
      <ConditionsSectionText>
        More information about the use of cookies by ENLIGHTING TECHNOLOGIES, SL can be obtained in our  {' '}
        <a rel="noopener noreferrer" target="_blank" href="https://app.kumux.io/legal/eng/cookies">Cookies Policy</a>.
      </ConditionsSectionText>
      <ConditionsSectionSubTitle>
        11. SUPPORT AND CANCELLATIONS
      </ConditionsSectionSubTitle>
      <ConditionsSectionText>
        For any type of query regarding the services offered by ENLIGHTING TECHNOLOGIES, SL, the Customer 
        may call the ENLIGHTING TECHNOLOGIES, SL switchboard from 9 am to 6 pm on any working 
        day (in accordance with the national calendar): 
        <br /><br />
        Phone number for service queries: +34934487382.
        <br /><br />
        Additionally, the following email address is available for any questions
        regarding the provided services:{' '}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="mailto:hello@kumux.io"
        >
          hello@kumux.io
        </a>
      </ConditionsSectionText>
      <ConditionsSectionSubTitle>
        12. RIGHT OF WITHDRAWAL AND TERMINATION IN ADVANCE
      </ConditionsSectionSubTitle>
      <ConditionsSectionText>
        The user will have seven (7) business days from the day of receipt of the 
        product or from the day of execution of the Contract in the case of the 
        provision of services, to withdraw or terminate the contract signed with 
        ENLIGHTING TECHNOLOGIES, SL for the acquisition of the offered products, 
        without this implying any penalty for the User. To exercise the aforementioned 
        right, the User must send the notification in writing to ENLIGHTING TECHNOLOGIES, SL, 
        either by registered mail or by sending an email to the following address:  {' '}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="mailto:hello@kumux.io"
        >
          hello@kumux.io
        </a>
      </ConditionsSectionText>
      <ConditionsSectionText>
        In this case, ENLIGHTING TECHNOLOGIES, SL will refund the amount paid by the User 
        within a maximum of thirty (30) days from the effective receipt of the notification 
        by the user of the right of withdrawal. 
      </ConditionsSectionText>
      <ConditionsSectionText>
        ENLIGHTING TECHNOLOGIES, SL reserves the right to terminate in advance, and without 
        prior notice, the provision of the products, services, content and/or tools to those 
        users who fail to comply with the terms of these contracting conditions, or the user 
        license agreement.
      </ConditionsSectionText>
      <ConditionsSectionText>
        The user can terminate the Contract through a written notification (post or email), 
        at least fifteen (15) days before the end of the service, specifying the reason(s) 
        for which the Contract is being terminated. In certain cases, the user may not 
        exercise their right of withdrawal or termination in those contracts involving 
        the provision of goods made according to their specifications, or which due to the 
        nature of the subject of the Contract, cannot be returned or may deteriorate or expire 
        quickly, as well as those contracts for computer programs supplied electronically, which 
        can be downloaded or reproduced immediately for their use, due to their very nature.
      </ConditionsSectionText>
      <ConditionsSectionText>
        When this Contract ends, regardless of the cause, the Customer must stop using the Services 
        and immediately stop all access to the Services. Upon termination of this Agreement, 
        for whatever reason, the Customer must cease all use of the Services and immediately 
        cease all access to the Services. Notwithstanding the above, the Client will have a 
        period of fifteen (15) days from the termination of the Contract to download the data 
        and documents made available to Client on ENLIGHTING TECHNOLOGIES, SL servers. Once 
        said period has elapsed, the Client’s access to ENLIGHTING TECHNOLOGIES, SL will be 
        withdrawn and all the data and documents stored in any of the ENLIGHTING TECHNOLOGIES, 
        SL services on the network may be destroyed.
      </ConditionsSectionText>
      <ConditionsSectionText>
        Neither party will be liable for damages resulting from termination, including, among 
        other things, the resulting Customer’s inability to access the Content.
      </ConditionsSectionText>
      <ConditionsSectionSubTitle>
        13. OTHERS
      </ConditionsSectionSubTitle>
      <ConditionsSectionSubTitle>
        13.1. Asignment of the Contract:
      </ConditionsSectionSubTitle>
      <ConditionsSectionText>
        The Customer may not transfer, assign, delegate or otherwise bequeath this Contract or any 
        of its rights or obligations, without the prior written consent of ENLIGHTING TECHNOLOGIES, SL. 
        Any assignment that is attempted in breach of the foregoing will be considered void. 
      </ConditionsSectionText>
      <ConditionsSectionText>
        ENLIGHTING TECHNOLOGIES, SL may transfer, assign, charge, subcontract or otherwise bequeath this 
        Contract or any of the rights or obligations derived from it, at any time during the term of this Contract.
      </ConditionsSectionText>
      <ConditionsSectionSubTitle>
        13.2. Notifications:
      </ConditionsSectionSubTitle>
      <ConditionsSectionText>
        All legal notifications that must be made pursuant to this Contract must be in writing and sent in person or 
        by urgent registered mail to the last address entered in the Customer's account; for the contracting entity 
        ENLIGHTING TECHNOLOGIES, SL, the address specified below or at any other address that the parties may specify 
        from now on by notifying the other party in accordance with the provisions herein. A notification will be deemed 
        to have been received (i) after delivery in person; (ii) if sent by mail or email, after acknowledgment of receipt. 
        Non-legal notifications may be sent by email to the ENLIGHTING TECHNOLOGIES, SL address specified in the corresponding 
        renewal notification and will be considered effective as of the date and time of the Customer's email message. 
      </ConditionsSectionText>
      <ConditionsSectionText>
        ENLIGHTING TECHNOLOGIES, SL may send the Customer non-legal notifications at {' '}
        <a rel="noopener noreferrer" target="_blank" href="https://www.kumux.io">www.kumux.io</a>  {' '}
        through messages or control panels on the Services platform, which will also be considered effective immediately.
      </ConditionsSectionText>
      <ConditionsSectionSubTitle>
        13.3. Applicable law and competent jurisdiction:
      </ConditionsSectionSubTitle>
      <ConditionsSectionText>
        This Contract will be governed and interpreted in accordance with Spanish legislation. Any dispute arising between the 
        Customer and ENLIGHTING TECHNOLOGIES, SL with respect to this Contract will be subject to the exclusive jurisdiction of 
        the courts of the city of Barcelona. Likewise, if these general conditions have an Spanish version, in the event of any 
        discrepancy between the versions, the Spanish version will prevail. Likewise, in the event of any discrepancy between the 
        general terms and conditions and the specific conditions of any service, the latter will prevail.
      </ConditionsSectionText>
      <ConditionsSectionSubTitleCentered>
        List of the Services currently included in the subscription
      </ConditionsSectionSubTitleCentered>
      <ConditionsSectionText>
        The Platform may offer the following services:
      </ConditionsSectionText>
      <ConditionsSectionText>
      <ListNumber>
          <li>
            Access to a database of LEDS available on the market that is constantly updated and developed.
          </li>
          <li>
            Option for the customer of uploading their own LEDs to the platform and generating their own database; 
            all this information will be confidential and only the user will have access.
          </li>
          <li>
            Simulation of luminaires.
          </li>
          <li>
            Creation and design of spectrums.
          </li>
          <li>
            Generation of technical lighting reports.
          </li>
          <li>
            Download of files in CSV format.
          </li>
          <li>
            Access to online chat for support in the use of the platform.
          </li>
          <li>
            Onboarding:
            <ul>
              <li>Master class for the team of software users to get the most out of it.</li>
              <li>Upload of up to 100 LEDs in the customer database.</li>
            </ul>
          </li>
      </ListNumber>  
      </ConditionsSectionText>
      <ConditionsSectionSubTitleCentered>
        SCOPE OF THE PLATFORM SUPPORT SERVICE CONTRACT
      </ConditionsSectionSubTitleCentered>
      <ConditionsSectionText>
        Scope of the Platform Support Service Contract: The scope of this contract covers the support service for the use 
        of the platform and, therefore, remote access to a specialist. The support service is specified in the following scope:
      </ConditionsSectionText>
      <ConditionsSectionText>
        <ListLetter>
          <li>
            Remote technical support will be provided through a Help Desk service. Problems related to the operation of the software 
            and/or services will be resolved in a standard period of 8 working hours (Monday-Thursday 08:30-18:00, Friday 08:00-15:00).
          </li>
        </ListLetter>
      </ConditionsSectionText>
      <ConditionsSectionText>
        The following services are excluded from the scope of the contract:
      <ListLetter>
          <li>
            Any onsite activity.
          </li>
          <li>
            Modifications and/or functional and/or technical adjustments to the software required by the customer.
          </li>
          <li>
            Any services associated with the correction of any anomalies caused by the Customer in their use of the subscription, 
            any services and/or the software that does not comply with the conditions of use.
          </li>
          <li>
            Any services that are not directly related to the software.
          </li>
          <li>
            Recovery of any files that have been destroyed and their safeguarding through backups. The customer must undertake to carry 
            out ordinary backups.
          </li>
          <li>
            Operating problems caused by changes in the customer's computing environment.
          </li>
          <li>
            As well as any other service not specified in this service contract.
          </li>
      </ListLetter>
      <br /><br /><br /><br />
      </ConditionsSectionText>
    </ConditionsSection>
  )
}

const ListNumber = styled.ul`
  list-style-type: decimal;
  li {
    margin-top:15px;
  }
`

const ListLetter = styled.ul`
  list-style-type: lower-latin;
  li {
    margin-top:15px;
  }
`

const ConditionsTitle = styled.h3`
  margin: 0 0 10px;
  ${(props: CompProps) => (props.theme as Theme).font_size.large};
`

const ConditionsSection = styled.div`
  background-color: ${(props: CompProps) =>
    (props.theme as Theme).color.white.darker};
  padding: 60px 90px;
  height: 100%;
  overflow-y: scroll;
`

const ConditionsSectionSubTitle = styled.h5`
  font-family: ${(props: CompProps) => (props.theme as Theme).font.semibold};
  padding-top: 15px;
  padding-bottom: 15px;
`

const ConditionsSectionSubTitleCentered = styled.h5`
  font-family: ${(props: CompProps) => (props.theme as Theme).font.semibold};
  width: 100%;
  text-align: center;
  padding-top: 60px;
  padding-bottom: 30px;
`

const ConditionsSectionText = styled.p`
  b {
    font-family: ${(props: CompProps) => (props.theme as Theme).font.bold};
  }
  a {
    font-weight: bold;
  }
`

const ConditionsSectionList = styled.ul`
  list-style: circle;
`

export default TermsPolicy
