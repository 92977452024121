import React from 'react'
import styled from 'styled-components'
import { CompProps, Theme } from '../../styles/theme'

interface Props {
    lang: string
}
  
const PrivacyPolicy: React.FC<Props> = (props: Props) => {
  return (
    <ConditionsSection>
        {(props.lang === 'cast') && (
        <>
            <ConditionsTitle>Política de privacidad</ConditionsTitle>
            <ConditionsSectionTitle>1. INFORMACIÓN AL USUARIO</ConditionsSectionTitle>
            <ConditionsSectionSubTitle>
                ¿Quién es el responsable del tratamiento de tus datos personales?
            </ConditionsSectionSubTitle>
            <ConditionsSectionText>
                <b>ENLIGHTING TECHNOLOGIES SL</b> es el RESPONSABLE del tratamiento de los datos personales del USUARIO y le
                informa de que estos datos serán tratados de conformidad con lo dispuesto en el Reglamento (UE) 2016/679, de 27 de
                abril (GDPR), y la Ley Orgánica 3/2018, de 5 de diciembre (LOPDGDD).
            </ConditionsSectionText>
            <ConditionsSectionSubTitle>
                ¿Para qué tratamos tus datos personales?
            </ConditionsSectionSubTitle>
            <ConditionsSectionText>
                Para mantener una relación comercial con el usuario. Las operaciones previstas para realizar el tratamiento son:
            </ConditionsSectionText>
            <ConditionsSectionList>
                <li>
                    Remisión de comunicaciones comerciales publicitarias por e-mail, fax, SMS, MMS, redes sociales o cualquier
                    otro medio electrónico o físico, presente o futuro, que posibilite realizar comunicaciones comerciales. Estas
                    comunicaciones serán realizadas por el RESPONSABLE y estarán relacionadas con sus productos y servicios, o
                    de sus colaboradores o proveedores, con los que este haya alcanzado algún acuerdo de promoción. En este
                    caso, los terceros nunca tendrán acceso a los datos personales.
                </li>
                <li>Realizar estudios de mercado y análisis estadísticos.</li>
                <li>
                    Tramitar encargos, solicitudes, dar respuesta a las consultas o cualquier tipo de petición que sea realizada por el USUARIO a través de cualquiera de las formas de contacto que se ponen a su disposición en la página
                    web del RESPONSABLE.
                </li>
                <li>
                    Remitir el boletín informativo online, sobre novedades, ofertas y promociones en nuestra actividad.
                </li>
            </ConditionsSectionList>
            <ConditionsSectionSubTitle>
                ¿Por qué motivo podemos tratar tus datos personales?
            </ConditionsSectionSubTitle>
            <ConditionsSectionText>
                Porque el tratamiento está legitimado por el artículo 6 del GDPR de la siguiente forma:
            </ConditionsSectionText>
            <ConditionsSectionList>
                <li>
                    Con el consentimiento del USUARIO: remisión de comunicaciones comerciales y del boletín informativo.
                </li>
                <li>
                    Por interés legítimo del RESPONSABLE: realizar estudios de mercado, análisis estadísticos, etc. y tramitar
                    encargos, solicitudes, etc. a petición del USUARIO.
                </li>
            </ConditionsSectionList>
            <ConditionsSectionSubTitle>
                ¿Durante cuánto tiempo guardaremos tus datos personales?
            </ConditionsSectionSubTitle>
            <ConditionsSectionText>
                Se conservarán durante no más tiempo del necesario para mantener el fin del tratamiento o existan prescripciones
                legales que dictaminen su custodia y cuando ya no sea necesario para ello, se suprimirán con medidas de seguridad
                adecuadas para garantizar la anonimización de los datos o la destrucción total de los mismos.
            </ConditionsSectionText>
            <ConditionsSectionSubTitle>
                ¿A quién facilitamos tus datos personales?
            </ConditionsSectionSubTitle>
            <ConditionsSectionText>
                No está prevista ninguna comunicación de datos personales a terceros salvo, si fuese necesario para el desarrollo y
                ejecución de las finalidades del tratamiento, a nuestros proveedores de servicios relacionados con comunicaciones, con
                los cuales el RESPONSABLE tiene suscritos los contratos de confidencialidad y de encargado de tratamiento exigidos
                por la normativa vigente de privacidad.
            </ConditionsSectionText>
            <ConditionsSectionSubTitle>
                ¿Cuáles son tus derechos?
            </ConditionsSectionSubTitle>
            <ConditionsSectionText>
                Los derechos que asisten al USUARIO son:
            </ConditionsSectionText>
            <ConditionsSectionList>
                <li>Derecho a retirar el consentimiento en cualquier momento.</li>
                <li>
                Derecho de acceso, rectificación, portabilidad y supresión de sus datos, y de limitación u oposición a su
                tratamiento.
                </li>
                <li>
                Derecho a presentar una reclamación ante la autoridad de control (www.aepd.es) si considera que el
                tratamiento no se ajusta a la normativa vigente.
                </li>
            </ConditionsSectionList>
            <ConditionsSectionText>
                <b>Datos de contacto para ejercer sus derechos:</b>
            </ConditionsSectionText>
            <ConditionsSectionText>
                ENLIGHTING TECHNOLOGIES SL. Carrer Baldiri Reixac, 2, edifici Torre I
                Planta 3 Porta A1., 3 - 08028 Barcelona (Barcelona). Email:
                hola@enlighting-tech.com
            </ConditionsSectionText>
            <ConditionsSectionTitle>
                2. CARÁCTER OBLIGATORIO O FACULTATIVO DE LA INFORMACIÓN FACILITADA POR EL USUARIO
            </ConditionsSectionTitle>
            <ConditionsSectionText>
                Los USUARIOS, mediante la marcación de las casillas correspondientes y la entrada de datos en los campos, marcados
                con un asterisco (*) en el formulario de contacto o presentados en formularios de descarga, aceptan expresamente y de
                forma libre e inequívoca, que sus datos son necesarios para atender su petición, por parte del prestador, siendo
                voluntaria la inclusión de datos en los campos restantes. El USUARIO garantiza que los datos personales facilitados al
                RESPONSABLE son veraces y se hace responsable de comunicar cualquier modificación de los mismos.<br/>
                El RESPONSABLE informa de que todos los datos solicitados a través del sitio web son obligatorios, ya que son
                necesarios para la prestación de un servicio óptimo al USUARIO. En caso de que no se faciliten todos los datos, no se
                garantiza que la información y servicios facilitados sean completamente ajustados a sus necesidades.
            </ConditionsSectionText>
            <ConditionsSectionTitle>3. MEDIDAS DE SEGURIDAD</ConditionsSectionTitle>
            <ConditionsSectionText>
                Que de conformidad con lo dispuesto en las normativas vigentes en protección de datos personales, el RESPONSABLE
                está cumpliendo con todas las disposiciones de las normativas GDPR y LOPDGDD para el tratamiento de los datos
                personales de su responsabilidad, y manifiestamente con los principios descritos en el artículo 5 del GDPR, por los
                cuales son tratados de manera lícita, leal y transparente en relación con el interesado y adecuados, pertinentes y
                limitados a lo necesario en relación con los fines para los que son tratados.
            </ConditionsSectionText>
            <ConditionsSectionText>
                El RESPONSABLE garantiza que ha implementado políticas técnicas y organizativas apropiadas para aplicar las
                medidas de seguridad que establecen el GDPR y la LOPDGDD con el fin de proteger los derechos y libertades de los
                USUARIOS y les ha comunicado la información adecuada para que puedan ejercerlos.
            </ConditionsSectionText>
            <ConditionsSectionText>
                Para más información sobre las garantías de privacidad, puedes dirigirte{' '}
                <b>al</b> RESPONSABLE a través de ENLIGHTING TECHNOLOGIES SL. Carrer
                Baldiri Reixac, 2, edifici Torre I Planta 3 Porta A1., 3 - 08028
                Barcelona (Barcelona). Email: hola@enlighting-tech.com
                <br/>{' '}<br/>{' '}<br/>
            </ConditionsSectionText>
        </>
        )}
        {(props.lang === 'eng') && (
        <>
            <ConditionsTitle>Privacy Policy</ConditionsTitle>
            <ConditionsSectionTitle>1. USER INFORMATION</ConditionsSectionTitle>
            <ConditionsSectionSubTitle>
                Who is the controller of your personal data?
            </ConditionsSectionSubTitle>
            <ConditionsSectionText>
                <b>ENLIGHTING TECHNOLOGIES SL</b> is the CONTROLLER of the USER’s
                personal data and informs him/her that these data shall be processed
                in accordance with the provisions of Regulation (EU) 2016/679 of 27
                April (GDPR) and the Organic Law 3/2018 of 5 December (LOPDGDD).
            </ConditionsSectionText>
            <ConditionsSectionSubTitle>
                Why do process your personal data?
            </ConditionsSectionSubTitle>
            <ConditionsSectionText>
                To maintain a commercial relationship with the user. The planned
                processing operations are:
            </ConditionsSectionText>
            <ConditionsSectionList>
                <li>
                Sending commercial advertising communications by email, fax, SMS,
                MMS, social networks or by any other electronic or physical means,
                present or future, that make it possible to carry out commercial
                communications. These communications will be made by the CONTROLLER
                and will be related to their products and services, or those of
                their partners or suppliers with whom they have reached a promotion
                agreement. In this case, the third parties will never have access to
                personal data.
                </li>
                <li>Conduct market research and statistical analysis.</li>
                <li>
                Processing orders, requests, respond to queries or any type of
                request made by the USER through any of the contact methods
                available at the CONTROLLER’s website.
                </li>
                <li>
                Send the online newsletter on news, offers and promotions in our
                activity.
                </li>
            </ConditionsSectionList>
            <ConditionsSectionSubTitle>
                Why can we process your personal data?
            </ConditionsSectionSubTitle>
            <ConditionsSectionText>
                Because the processing is legitimised by article 6 of the GDPR as
                follows:
            </ConditionsSectionText>
            <ConditionsSectionList>
                <li>
                With the USER’s consent: sending commercial communications and the
                newsletter.
                </li>
                <li>
                In the legitimate interest of the CONTROLLER: conduct market
                research, statistical analysis, etc. and process orders, requests,
                etc. at the request of the USER.
                </li>
            </ConditionsSectionList>
            <ConditionsSectionSubTitle>
                For how long will we keep your personal data?
            </ConditionsSectionSubTitle>
            <ConditionsSectionText>
                Data shall be stored for no longer than is necessary to maintain the
                purpose of the processing or for as long as there are legal
                prescriptions dictating their custody, and when such purpose is no
                longer necessary the data shall be erased with appropriate security
                measures to ensure the anonymization of the data or their complete
                destruction.
            </ConditionsSectionText>
            <ConditionsSectionSubTitle>
                To whom do we disclose your personal data?
            </ConditionsSectionSubTitle>
            <ConditionsSectionText>
                No communication of personal data to third parties is foreseen except,
                if necessary for the development and execution of the purposes of the
                processing, to our suppliers of services related to communications,
                with which theCONTROLLER has signed the confidentiality and data
                processor contracts required by current privacy regulations.
            </ConditionsSectionText>
            <ConditionsSectionSubTitle>
                What are your rights?
            </ConditionsSectionSubTitle>
            <ConditionsSectionText>
                The rights of the USER are:
            </ConditionsSectionText>
            <ConditionsSectionList>
                <li>Right to withdraw consent at any time.</li>
                <li>
                Right of access, rectification, portability and erasure of your data
                and the limitation or objection to their processing.
                </li>
                <li>
                The right to file a claim with the Spanish Supervisory Authority
                (www.aepd.es) if you consider that the processing does not comply
                with the current legislation.
                </li>
            </ConditionsSectionList>
            <ConditionsSectionText>
                <b>Contact information for exercising rights:</b>
            </ConditionsSectionText>
            <ConditionsSectionText>
                ENLIGHTING TECHNOLOGIES SL. Carrer Baldiri Reixac, 2, edifici Torre I
                Planta 3 Porta A1., 3 - 08028 Barcelona (Barcelona). Email:
                hola@enlighting-tech.com
            </ConditionsSectionText>
            <ConditionsSectionTitle>
                2. COMPULSORY OR OPTIONAL NATURE OF THE INFORMATION PROVIDED BY THE
                USER
            </ConditionsSectionTitle>
            <ConditionsSectionText>
                The USERS, by marking the corresponding boxes and entering data in the
                fields, marked with an asterisk (*) in the contact form or download
                forms, accept expressly and in a free and unequivocal way that their
                data are necessary for the supplier to meet their request, voluntarily
                providing their data in the remaining fields. The USER ensures that
                the personal data provided to the CONTROLLER are true and is
                responsible for communicating any changes to them. The CONTROLLER
                informs that all data requested through the website are mandatory, as
                they are necessary for the provision of an optimal service to the
                USER. In the event that not all of the data is provided, there is no
                guarantee that the information and services provided will be
                completely adapted to the User’s needs.
            </ConditionsSectionText>
            <ConditionsSectionTitle>3. SECURITY MEASURES</ConditionsSectionTitle>
            <ConditionsSectionText>
                That in accordance with the provisions of the current regulations on
                the protection of personal data, the CONTROLLER is complying with all
                the provisions of the GDPR and LOPDGDD regulations for processing the
                personal data for which they are responsible, and is manifestly
                complying with the principles described in Article 5 of the GDPR, by
                which they are processed in a lawful, fair and transparent manner in
                relation to the data subject and appropriate, relevant and limited to
                what is necessary in relation to the purposes for which they are
                processed.
            </ConditionsSectionText>
            <ConditionsSectionText>
                The CONTROLLER guarantees that all appropriate technical and
                organisational policies have been implemented to apply the security
                measures established by the GDPR and LOPDGDD in order to protect the
                rights and freedoms of USERS and has communicated the appropriate
                information for them to be able to exercise them.
            </ConditionsSectionText>
            <ConditionsSectionText>
                For more information about privacy guarantees, you can contact{' '}
                <b>the</b> CONTROLLER through ENLIGHTING TECHNOLOGIES SL. Carrer
                Baldiri Reixac, 2, edifici Torre I Planta 3 Porta A1., 3 - 08028
                Barcelona (Barcelona). Email: hola@enlighting-tech.com
                <br/>{' '}<br/>{' '}<br/>
            </ConditionsSectionText>
        </>
        )}
    </ConditionsSection>
  )
}

const ConditionsTitle = styled.h3`
  margin: 0 0 10px;
  ${(props: CompProps) => (props.theme as Theme).font_size.large};
`

const ConditionsSection = styled.div`
  background-color: ${(props: CompProps) =>
    (props.theme as Theme).color.white.regular};
  padding: 60px;
  margin: 60px 30px;
  height: calc(80vh - 90px);
  overflow-y: scroll;
  ${(props: CompProps) => (props.theme as Theme).filters.drop_shadow};
`

const ConditionsSectionTitle = styled.h4`
  ${(props: CompProps) => (props.theme as Theme).font_size.regular};
  margin: 30px 0px;
`

const ConditionsSectionSubTitle = styled.h5`
  font-family: ${(props: CompProps) => (props.theme as Theme).font.semibold};
`

const ConditionsSectionText = styled.p`
  b {
    font-family: ${(props: CompProps) => (props.theme as Theme).font.bold};
  }
`

const ConditionsSectionList = styled.ul`
  list-style: circle;
`

export default PrivacyPolicy
