import React, { useState, useEffect } from 'react'

import styled from 'styled-components'
import { Chart } from 'chart.js'
import { CompProps, Theme } from '../../styles/theme'

interface Props {
  hourIndex: number
  irradianceData: Array<number[]>
  line?: number[][]
  lineColors?: string[]
  compare_spd?:
    | {
        wavelength: number[]
        irradiance: number[]
      }
    | Boolean
  background?: string
  canvas_width: string
}

const SpectraReport: React.FC<Props> = (props) => {
  const { compare_spd } = props

  let { background } = props
  const showlabels = false
  if (background === undefined) {
    background = '#fff'
  }

  const wavelength: Array<number> = []
  for (let index = 380; index < 780; index++) {
    wavelength.push(index)
  }

  const chartCanvasRef = React.useRef<HTMLCanvasElement>(null)

  const [previousIndex, setPreviousIndex] = useState<number>(0)

  const validateIrradiance = (intensity: Array<number>) => {
    return intensity.length === 401
  }

  useEffect(() => {
    setPreviousIndex(props.hourIndex)
    if (props.irradianceData[props.hourIndex] !== undefined) {
      validateIrradiance(
        Object.values(props.irradianceData[props.hourIndex])
      ) && drawChart(chartCanvasRef)
    }

    // eslint-disable-next-line
  }, [props.hourIndex, props.compare_spd])

  const nmToRGB = (wavelength: number) => {
    var Gamma = 0.8,
      IntensityMax = 255,
      factor,
      red,
      green,
      blue
    if (wavelength >= 380 && wavelength < 440) {
      red = -(wavelength - 440) / (440 - 380)
      green = 0.0
      blue = 1.0
    } else if (wavelength >= 440 && wavelength < 490) {
      red = 0.0
      green = (wavelength - 440) / (490 - 440)
      blue = 1.0
    } else if (wavelength >= 490 && wavelength < 510) {
      red = 0.0
      green = 1.0
      blue = -(wavelength - 510) / (510 - 490)
    } else if (wavelength >= 510 && wavelength < 580) {
      red = (wavelength - 510) / (580 - 510)
      green = 1.0
      blue = 0.0
    } else if (wavelength >= 580 && wavelength < 645) {
      red = 1.0
      green = -(wavelength - 645) / (645 - 580)
      blue = 0.0
    } else if (wavelength >= 645 && wavelength < 781) {
      red = 1.0
      green = 0.0
      blue = 0.0
    } else {
      red = 0.0
      green = 0.0
      blue = 0.0
    }
    // Let the intensity fall off near the vision limits
    if (wavelength >= 380 && wavelength < 420) {
      factor = 0.3 + (0.7 * (wavelength - 380)) / (420 - 380)
    } else if (wavelength >= 420 && wavelength < 701) {
      factor = 1.0
    } else if (wavelength >= 701 && wavelength < 781) {
      factor = 0.3 + (0.7 * (780 - wavelength)) / (780 - 700)
    } else {
      factor = 0.0
    }
    if (red !== 0) {
      red = Math.round(IntensityMax * Math.pow(red * factor, Gamma))
    }
    if (green !== 0) {
      green = Math.round(IntensityMax * Math.pow(green * factor, Gamma))
    }
    if (blue !== 0) {
      blue = Math.round(IntensityMax * Math.pow(blue * factor, Gamma))
    }
    return [red, green, blue]
  }

  const norm = (val: number, max: number, min: number) => {
    return (val - min) / (max - min)
  }

  const drawChart = (canvasRef: React.RefObject<HTMLCanvasElement>) => {
    let canvas: HTMLCanvasElement | null = canvasRef.current
      ? canvasRef.current
      : null
    let ctx: CanvasRenderingContext2D | null = canvasRef.current
      ? canvasRef.current.getContext('2d')
      : null

    if (canvas && ctx) {
      // Generate gradient
      const canvasWidth: number = canvas ? canvas.clientWidth : 0
      // const canvasLeftOffset: number = showlabels ? 70 : 10 // The margin generated by the left axis
      const canvasLeftOffset: number = showlabels ? 70 : 0 // The margin generated by the left axis
      const canvasBottomOffset: number = showlabels ? 0 : 10 // The margin generated by the bottom axis

      var gradientStroke = ctx.createLinearGradient(
        canvasLeftOffset,
        canvasBottomOffset,
        canvasWidth,
        canvasBottomOffset
      )
      var gradientStrokeShadow = ctx.createLinearGradient(
        canvasLeftOffset,
        canvasBottomOffset,
        canvasWidth,
        canvasBottomOffset
      )

      for (var i = 0; i < wavelength.length; i++) {
        var color = nmToRGB(wavelength[i])
        var value = norm(i, wavelength.length, 0)
        gradientStroke.addColorStop(
          value,
          'rgba(' + color[0] + ', ' + color[1] + ', ' + color[2] + ', 0.8)'
        )
      }
      gradientStrokeShadow.addColorStop(0.5, 'rgba(180,180,180,0.9)')
      // Create the chart

      let datasetsline: [
        {
          label: string
          borderColor?: CanvasGradient | string
          pointRadius?: number
          fill?: boolean
          backgroundColor?: CanvasGradient
          borderWidth?: number
          data?: number[]
          showLine?: boolean
          borderDash?: number[]
        }
      ] = [
        {
          label: 'Data',
          borderColor: gradientStroke,
          pointRadius: 0,
          fill: true,
          backgroundColor: gradientStroke,
          borderWidth: 0.5,
          data: Object.values(props.irradianceData[previousIndex])
        }
      ]

      if (
        compare_spd !== false &&
        (compare_spd as {
          wavelength: number[]
          irradiance: number[]
        }).irradiance.length > 0
      ) {
        const shadow = compare_spd as {
          wavelength: number[]
          irradiance: number[]
        }
        datasetsline.push({
          label: 'Shadow',
          borderColor: gradientStrokeShadow,
          pointRadius: 0,
          fill: true,
          backgroundColor: gradientStrokeShadow,
          borderWidth: 0.5,
          data: shadow.irradiance
        })
      }

      const chartGraph = new Chart(ctx, {
        type: 'line',
        data: {
          labels: wavelength,
          datasets: datasetsline
        },
        options: {
          responsive: false,
          maintainAspectRatio: true,
          devicePixelRatio: 1,
          animation: {
            duration: 0
          },
          tooltips: {
            enabled: false
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [
              {
                scaleLabel: {
                  display: showlabels,
                  labelString: 'Intensity (A.U.)',
                  padding: 10
                },
                ticks: {
                  fontColor: 'rgba(0,0,0,0.5)',
                  fontStyle: 'bold',
                  beginAtZero: true,
                  maxTicksLimit: 3,
                  padding: 10,
                  min: 0,
                  max: 1,
                  display: showlabels
                },
                gridLines: {
                  drawTicks: true,
                  display: showlabels
                }
              }
            ],
            xAxes: [
              {
                scaleLabel: {
                  display: showlabels,
                  labelString: 'Wavelength (nm)',
                  padding: 20
                },
                gridLines: {
                  zeroLineColor: 'transparent',
                  drawTicks: true,
                  display: showlabels
                },
                ticks: {
                  padding: 10,
                  fontColor: showlabels ? 'rgba(0,0,0,0.5)' : 'transparent',
                  fontStyle: 'bold',
                  display: showlabels
                }
              }
            ]
          }
        }
      })

      setTimeout(() => {
        if (
          chartGraph &&
          chartGraph.data &&
          chartGraph.data.datasets &&
          Object.values(props.irradianceData[props.hourIndex]).length > 0
        ) {
          chartGraph.options.animation &&
            (chartGraph.options.animation.duration = 1000)

          const dataset = chartGraph.data.datasets[0].data
          dataset &&
            Object.values(props.irradianceData[props.hourIndex]).forEach(
              (dataValue: number, index: number) => {
                chartGraph.data.datasets &&
                  chartGraph.data.datasets[0] &&
                  chartGraph.data.datasets[0].data &&
                  chartGraph.data.datasets[0].data[index] &&
                  (chartGraph.data.datasets[0].data[index] = dataValue)
              }
            )

          chartGraph.update()
        }
      }, 100)
    } else {
      console.error('Canvas element not found')
    }
  }

  return (
    <SpectraLineBarsWrapper>
      <SpectraLineBarsContainer
        background={'transparent'}
        width={props.canvas_width}
      >
        <canvas id="chart" ref={chartCanvasRef} />
      </SpectraLineBarsContainer>

      <ContainerInfoScene></ContainerInfoScene>
    </SpectraLineBarsWrapper>
  )
}
const ContainerInfoScene = styled.div`
  display: flex;
  justify-content: space-between;
`

const SpectraLineBarsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0;
  z-index: 1;
  #range::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #4caf50 !important;
    cursor: pointer;
  }
  #range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #4caf50;
    cursor: pointer;
  }
  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 12.8px;
    cursor: pointer;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    background: ${(props: CompProps) => (props.theme as Theme).color._900};
    border-radius: 25px;
    border: 0px solid #000101;
  }
`
const SpectraLineBarsContainer = styled.div<{
  width: string
  background: string
}>`
  display: block;
  /*background: #fff;*/
  background: transparent;
  canvas {
    pointer-events: none;
    background:  ${(props) => props.background} 
    width: ${(props) => props.width} !important;
    /* width: 695px !important;*/
    /* width: 555px !important;*/
  }
`

export default SpectraReport
