import React, { useState } from 'react'
import styled from 'styled-components'
import { Theme, CompProps } from '../../styles/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
// import { faLightbulb } from '@fortawesome/free-regular-svg-icons'
import { ButtonUI } from '../../styles/commons'

interface Props {
  open?: boolean
  isConfirm?: boolean
  closeModal?: Function
  acceptConfirm?: Function
  title?: string
  newproject?: boolean
}

const closeModalIcon = <FontAwesomeIcon icon={faTimes} />
// const NewSimulationIcon = <FontAwesomeIcon icon={faLightbulb} />
const Modal: React.FC<Props> = (props) => {
  const closeModal = () => props.closeModal && props.closeModal()
  const acceptConfirm = () =>
    props.acceptConfirm && props.acceptConfirm() && setIsLoading(true)
  const [isLoading, setIsLoading] = useState(false)

  return (
    <ModalWrapper open={props.open}>
      <ModalContainer isConfirm={props.isConfirm} newproject={props.newproject}>
        <ModalHeader>
          <Title>{props.title}</Title>
          <CloseModalIconWrapper onClick={() => closeModal()}>
            {closeModalIcon}
          </CloseModalIconWrapper>
        </ModalHeader>
        <ModalBody>{props.children}</ModalBody>
        {props.isConfirm && (
          <ModalFooter>
            <ButtonUI btntype="secondary" onClick={() => closeModal()}>
              CANCEL
            </ButtonUI>

            <ButtonUI
              onClick={() => acceptConfirm()}
              btntype="primary"
              loading={isLoading}
            >
              CONFIRM
            </ButtonUI>
          </ModalFooter>
        )}
      </ModalContainer>
    </ModalWrapper>
  )
}

const ModalWrapper = styled.div<Props>`
  z-index: 95;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: ${(props: Props) => (props.open ? 'flex' : 'none')};
  /* display: flex; */
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`
const ModalContainer = styled.div<Props>`
  background-color: ${(props: CompProps) =>
    (props.theme as Theme).color.white.regular};
  border-radius: 2px;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 48px;
  width: ${(props: Props) => (props.isConfirm ? '500px' : '980px')};
  ${(props: Props) =>
    props.isConfirm ? 'flex: 0 0 500px' : 'flex: 0 0 980px'};

  ${(props: Props) =>
    props.newproject !== undefined &&
    props.newproject === true &&
    ' flex: 0 0 600px!important'};
  max-height: calc(100vh - 46px);
  position: relative;
`
const ModalHeader = styled.div`
  ${(props: CompProps) => (props.theme as Theme).font_size.regular};
  display: flex;
  margin: 15px;
  align-items: center;
  justify-content: space-between;

  padding-right: 20px;
  height: 45px;

  border-bottom: 1px solid
    ${(props: CompProps) => (props.theme as Theme).color.black.lightest};
`
const CloseModalIconWrapper = styled.div`
  cursor: pointer;
`
const Title = styled.h5`
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 20px;
  text-transform: uppercase;
`

const ModalBody = styled.div`
  padding: 15px;
  max-height: calc(100vh - 150px);
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 170px;
  padding-top: 25px;
`
const ModalFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 65px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-top: 1px solid
    ${(props: CompProps) => (props.theme as Theme).color.black.lightest};
`

export default Modal
