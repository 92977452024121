import React, { useEffect, useRef } from 'react'
import { Chart } from 'chart.js'

import { ChartProps } from './ChartStatic'

const HCLRadar: React.FC<ChartProps> = (props) => {

    const chartCanvasRef = useRef<HTMLCanvasElement>(null)

    useEffect(() => {
        const canvas: HTMLCanvasElement | null = chartCanvasRef.current
        canvas && drawChart(canvas)
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.chartData])

    const drawChart = (canvasRef: HTMLCanvasElement) => {
        const ctx: CanvasRenderingContext2D | null = canvasRef.getContext("2d")
        if(ctx) {
            // Create fill color
            const gradientColor = ctx.createLinearGradient(0, 0, 0, 1)
            gradientColor.addColorStop(
                1,
                'rgba(' +
                    props.chartData[1][0] +
                  ', ' +
                    props.chartData[1][1] +
                  ', ' +
                    props.chartData[1][2] +
                  ', 0.6)'
            )

            // Draw Graph
            const chartGraph = new Chart(ctx, {
            type: 'radar',
            data: {
                labels: ['RELAX', 'FOCUS', 'NATURAL', 'BOOST', 'CREATE'],
                datasets: [{
                    data: props.chartData[0],
                    fill: true,
                    backgroundColor: gradientColor,
                    pointBackgroundColor: 'transparent',
                    pointBorderColor: 'transparent',
                    pointHoverBackgroundColor: 'transparent',
                    pointHoverBorderColor: 'transparent',
                    pointHitRadius: 50,
                    borderColor: 'rgba(0,0,0,0.1)',
                    borderJoinStyle: 'round',
                    borderWidth: 1
                }]
            },
            options: {
                legend: {
                    display: false
                },
                scale: {
                    pointLabels: {
                      fontSize: 12,
                      fontColor: '#A0A0A0',
                      fontFamily: 'roboto'
                    },
                    ticks: {
                      maxTicksLimit: 1,
                      display: false,
                      suggestedMin: 0,
                      suggestedMax: 100
                    }
                },
                responsive: false,
                animation: {
                    duration: 1,
                    onComplete: () => {
                        props.callBack(chartGraph.toBase64Image())
                        Object.defineProperty(chartGraph.options.animation, 'onComplete', {value: undefined})
                    }
                }
            }
            })
        }  
    }

    return(
        <canvas ref={chartCanvasRef} width={340} height={340} />
    )
}

export default HCLRadar
