import API, { STATUS } from './api'
import { AxiosResponse } from 'axios'
import Errors from './Errors'

class SimulationApi {
  checkReference = async (reference: string): Promise<boolean> => {
    try {
      const response: AxiosResponse = await API.get(
        `fixture/check_reference/${reference}`
      )

      if (response.status === STATUS.OK) {
        return response.data
      }
    } catch (error) {
      Errors.ShowError(error)
      return false
    }

    return false
  }

  getCompanies = async (): Promise<[]> => {
    try {
      const response: AxiosResponse = await API.get('pcb/available_companies')

      if (response.status === STATUS.OK) {
        return response.data.content
      }
    } catch (error) {
      Errors.ShowError(error)
      return []
    }

    return []
  }

  getBrandsByCompany = async (
    company_type: string,
    company_id: string
  ): Promise<[]> => {
    try {
      const response: AxiosResponse = await API.get(
        `pcb/available_brands/${company_type}/${company_id}`
      )

      if (response.status === STATUS.OK) {
        return response.data.content
      }
    } catch (error) {
      Errors.ShowError(error)
      return []
    }

    return []
  }

  getLedsByBrand = async (
    brand_type: string,
    brand_id: string
  ): Promise<any> => {
    try {
      const response: AxiosResponse = await API.get(
        `pcb/available_leds/${brand_type}/${brand_id}`
      )

      if (response.status === STATUS.OK) {
        return response.data.content
      }
    } catch (error) {
      Errors.ShowError(error)
      return []
    }

    return []
  }

  getLedInfo = async (led_type: string, led_id: string): Promise<any> => {
    try {
      const response: AxiosResponse = await API.get(
        `pcb/led_info/${led_type}/${led_id}`
      )

      if (response.status === STATUS.OK) {
        return response.data.content
      }
    } catch (error) {
      Errors.ShowError(error)
      return []
    }

    return []
  }

  getInfoIndividualImageByLedId = async (
    led_type: string,
    led_id: string
  ): Promise<{}> => {
    try {
      const response: AxiosResponse = await API.get(
        `pcb/get_individual_png/${led_type}/${led_id}`
      )

      if (response.status === STATUS.OK) {
        return response.data.content
      }
    } catch (error) {
      Errors.ShowError(error)
      return {}
    }

    return {}
  }

  getCombinedImageBySimulationForm = async (
    simulationForm: object
  ): Promise<any> => {
    try {
      const response: AxiosResponse = await API.post('pcb/get_spd_comb_png', {
        ...simulationForm
      })

      if (response.status === STATUS.OK) {
        return response.data.content
      }
    } catch (error) {
      Errors.ShowError(error)
      return {}
    }

    return {}
  }

  getLuminaryById = async (publicId: string | undefined): Promise<any> => {
    try {
      const response: AxiosResponse = await API.get(`fixture/get/${publicId}`)

      if (response.status === STATUS.OK) {
        return response.data.content
      }

      return {}
    } catch (error) {
      Errors.ShowError(error)

      return {}
    }
  }

  getFiltersData = async (luminary_id: string): Promise<any> => {
    try {
      const response: AxiosResponse = await API.get(
        `spectra/get_raw/${luminary_id}`
      )

      if (response.status === STATUS.OK) {
        return response.data.content
      }
    } catch (error) {
      Errors.ShowError(error)

      return false
    }

    return false
  }

    getOneSpectra = async (luminary_id: string,spectrum_id: string): Promise<any> => {
    try {
      const response: AxiosResponse = await API.get(
        `spectra/get_one/${luminary_id}/${spectrum_id}`
      )

      if (response.status === STATUS.OK) {
        return response.data.content
      }
    } catch (error) {
      Errors.ShowError(error)

      return false
    }

    return false
  }

  getDynamicChartsData = async (
    luminary_id: string,
    sliders: any
  ): Promise<any> => {
    try {
      const response: AxiosResponse = await API.post(
        `spectra/filter/dymamic_charts/${luminary_id}`,
        {
          ...sliders
        }
      )

      if (response.status === STATUS.OK) {
        return response.data.content
      }
    } catch (error) {
      Errors.ShowError(error)

      return false
    }

    return false
  }

  getDynamicSpdPCB = async (data: any): Promise<any> => {
    try {
      const response: AxiosResponse = await API.post(
        `pcb/charts/dymamic/combined_spd`,
        {
          ...data
        }
      )
     
      if (response.status === STATUS.OK) {
        return response.data.content
      }
    } catch (error) {
      console.error(error)
      // Errors.ShowError(error)

      return false
    }

    return false
  }

  getDynamicSpd = async (luminary_id: string, sliders: any): Promise<any> => {
    try {
      const response: AxiosResponse = await API.post(
        `spectra/charts/dymamic/spd/${luminary_id}`,
        {
          ...sliders
        }
      )

      if (response.status === STATUS.OK) {
        return response.data.content
      }
    } catch (error) {
      Errors.ShowError(error)

      return false
    }

    return false
  }

  getDynamicCie = async (luminary_id: string, sliders: any): Promise<any> => {
    try {
      const response: AxiosResponse = await API.post(
        `spectra/charts/dymamic/cie/${luminary_id}`,
        {
          ...sliders
        }
      )

      if (response.status === STATUS.OK) {
        return response.data.content
      }
    } catch (error) {
      Errors.ShowError(error)

      return false
    }

    return false
  }

  getStaticRfVsRg = async (luminary_id: string, sliders: any): Promise<any> => {
    try {
      const response: AxiosResponse = await API.post(
        `spectra/charts/static/rf_vs_rg/${luminary_id}`,
        {
          ...sliders
        }
      )

      if (response.status === STATUS.OK) {
        return response.data.content
      }
    } catch (error) {
      Errors.ShowError(error)

      return false
    }

    return false
  }

  getStaticRfVsCct = async (
    luminary_id: string,
    sliders: any
  ): Promise<any> => {
    try {
      const response: AxiosResponse = await API.post(
        `spectra/charts/static/rf_vs_cct/${luminary_id}`,
        {
          ...sliders
        }
      )

      if (response.status === STATUS.OK) {
        return response.data.content
      }
    } catch (error) {
      Errors.ShowError(error)

      return false
    }

    return false
  }

  getStaticEffVsCct = async (
    luminary_id: string,
    sliders: any
  ): Promise<any> => {
    try {
      const response: AxiosResponse = await API.post(
        `spectra/charts/static/eff_vs_cct/${luminary_id}`,
        {
          ...sliders
        }
      )

      if (response.status === STATUS.OK) {
        return response.data.content
      }
    } catch (error) {
      Errors.ShowError(error)

      return false
    }

    return false
  }

  getStaticMpVsCCt = async (
    luminary_id: string,
    sliders: any
  ): Promise<any> => {
    try {
      const response: AxiosResponse = await API.post(
        `spectra/charts/static/mp_vs_cct/${luminary_id}`,
        {
          ...sliders
        }
      )

      if (response.status === STATUS.OK) {
        return response.data.content
      }
    } catch (error) {
      Errors.ShowError(error)

      return false
    }

    return false
  }

  setFilters = async (
    filters: any,
    reference: string | undefined
  ): Promise<any> => {
    try {
      const response: AxiosResponse = await API.post(
        `spectra/filter/${reference}`,
        {
          ...filters
        }
      )

      if (response.status === STATUS.OK) {
        return response.data.content
      }
    } catch (error) {
      Errors.ShowError(error)
      return {}
    }

    return {}
  }

  createLuminary = async (simulationForm: object): Promise<any> => {
    try {
      const response: AxiosResponse = await API.post('fixture/new', {
        ...simulationForm
      })

      if (response.status === STATUS.OK) {
        return response.data
      }
    } catch (error) {
      Errors.ShowError(error)
      return false
    }

    return false
  }

  dependency_check = async (publicId: string): Promise<any> => {
    try {
      const response: AxiosResponse = await API.get(
        `fixture/dependency_check/${publicId}`
      )
      if (response.status === STATUS.OK) {
        return response.data.content
      }
    } catch (error) {
      Errors.ShowError(error)
    }
  }

  deleteLuminaryById = async (publicId: string): Promise<any> => {
    try {
      const response: AxiosResponse = await API.delete(
        `fixture/delete/${publicId}`
      )

      if (response.status === STATUS.OK) {
        return response.data
      }
    } catch (error) {
      Errors.ShowError(error)

      return false
    }

    return false
  }

  startSimulationById = async (
    publicId: string,
    algorithm: string = 'full_combinations'
  ): Promise<any> => {
    try {
      const response: AxiosResponse = await API.post(
        `simulation/start/${publicId}`,
        {
          algorithm
        }
      )

      if (response.status === STATUS.OK) {
        return response.data
      }
    } catch (error) {
      Errors.ShowError(error)

      return -1
    }

    return -1
  }

  checkStatusSimulationById = async (publicId: string): Promise<any> => {
    try {
      const response: AxiosResponse = await API.get(
        `simulation/status/${publicId}`
      )

      if (response.status === STATUS.OK) {
        return {
          public_id: publicId,
          status: response.data.content.status,
          unix_start: response.data.content.unix_start,
          unix_end: response.data.content.unix_end
        }
      }
    } catch (error) {
      Errors.ShowError(error)

      return { status: -1, public_id: publicId }
    }

    return { status: -1, public_id: publicId }
  }

  getAvailableLeds = async (type: string): Promise<any> => {
    try {
      const response: AxiosResponse = await API.get(`pcb/available_leds/`+type)
     
      if (response.status === STATUS.OK) {
        return response.data.content.leds
      }
    } catch (error) {
      console.error(error)
      // Errors.ShowError(error)

      return false
    }

    return false
  }
}

export const simulationApi = new SimulationApi()
