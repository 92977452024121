import React, { useEffect, useReducer } from 'react'
import JoyRide, { ACTIONS, EVENTS, STATUS } from 'react-joyride'

import tourScript, { TourSteps } from './TourSteps'
import theme from '../../styles/theme'

interface Props {
  section: string
}

// Tour component
const Tour: React.FC<Props> = (props) => {
  let section: TourSteps | undefined =
    tourScript.sections.find((el) => el.section === props.section) || undefined
  const TOUR_STEPS = (section && (section.steps as TourSteps)) || []

  // Initial state for the tour component
  const INITIAL_STATE = {
    key: new Date(),
    run: false,
    continuous: true,
    loading: false,
    stepIndex: 0,
    steps: TOUR_STEPS
  }

  // Reducer will manage updating the local state
  const reducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
      // start the tour:
      case 'START':
        return { ...state, run: true }
      // Reset to 0th step
      case 'RESET':
        return { ...state, stepIndex: 0 }
      // Stop the tour
      case 'STOP':
        return { ...state, run: false }
      // Update the steps for next / back button click
      case 'NEXT_OR_PREV':
        return { ...state, ...action.payload }
      // Restart the tour - reset go to 1st step, restart create new tour
      case 'RESTART':
        return {
          ...state,
          stepIndex: 0,
          run: true,
          loading: false,
          key: new Date()
        }
      default:
        return state
    }
  }

  const [tourState, setTourState] = useReducer(reducer, INITIAL_STATE)

  const callback = (data: any) => {
    const { action, index, type, status } = data
    if (
      // If close button clicked then close the tour
      action === ACTIONS.CLOSE ||
      // If skipped or end tour, then close the tour
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      setTourState({ type: 'STOP' })
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      // Check whether next or back button click and update the step
      const stepIndex: number = index + (action === ACTIONS.PREV ? -1 : 1)
      setTourState({
        type: 'NEXT_OR_PREV',
        payload: { stepIndex }
      })
    }
  }

  const startTour = () => {
    setTourState({ type: 'RESTART' })
  }

  /*const endTour = () => {
      setTourState({ type: "STOP" })
  }*/

  useEffect(() => {
    if (localStorage.getItem('isTutorialActive') === String(true)) {
      window.scrollTo(0, 0)
      startTour()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.section, localStorage.getItem('isTutorialActive')])

  return (
    <>
      <JoyRide
        {...tourState}
        callback={callback}
        scrollOffset={50}
        disableCloseOnEsc={true}
        disableOverlay={false}
        disableOverlayClose={true}
        showSkipButton={false}
        styles={{
          options: {
            backgroundColor: theme.color._100,
            arrowColor: theme.color._100,
            width: '100%',
            height: '100%',
            zIndex: 9999
          },
          tooltipContainer: {
            textAlign: 'left'
          },
          buttonNext: {
            backgroundColor: 'white',
            color: 'black'
          },
          buttonSkip: {
            backgroundColor: 'white',
            color: 'black'
          },
          buttonBack: {
            backgroundColor: 'white',
            color: 'black'
          }
        }}
        locale={{
          last: 'Ok',
          skip: 'Skip'
        }}
      />
    </>
  )
}

export default Tour
