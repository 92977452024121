import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { CompProps, Theme } from '../../styles/theme'
import {
  NoResultsIconWrapper,
  NoResultsContainer,
  NoResultsTextWrapper,
  MainButtonAction,
  InputSearch,
  Phrasegrey,
  ButtonSearch
} from '../../styles/commons'
import { Header, Image, Modal as ModalReact } from 'semantic-ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import { Auth } from './../../App'
import Modal from '../common/Modal'
import NewSimulationLed from '../../components/NewSimulationLed'
import { faDiagnoses } from '@fortawesome/free-solid-svg-icons'
import { ledApi, FamilyLed } from '../../api/LedApi'
import LedList from '../../components/common/LedList'
import SimpleConfirm from '../../components/common/SimpleConfirm'
import excelpng from '../../images/EXCEL-ICON.png'
import AnalyticsListener from './../../api/AnalyticsListener'

import Tour from '../help/Tour'
interface Props {
  auth: Auth
}

const SectionLed: React.FC<Props> = (props) => {
  const SearchIcon = <FontAwesomeIcon icon={faSearch} />
  const [Search, setSearch] = useState('')
  const [newLedClose, setNewLedClose] = useState(false)
  const [CreateLed, setCreateLed] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [LedsFamilies, setLedsFamilies] = useState<FamilyLed[]>([])
  const [LedsCatalogFamilies, setLedsCatalogFamilies] = useState<FamilyLed[]>(
    []
  )
  const NoResultsIcon = <FontAwesomeIcon icon={faDiagnoses} />

  const [newSimulationLed, setNewSimulationLed] = useState(false)
  const [LedsTourActive, setLedsTourActive] = useState(false)

  function handleOnChange(event: any) {
    setSearch(event.target.value)
    return event.target.value
  }

  const closeModalConfirm = () => {
    setNewLedClose(true)
  }

  const closeModalCancel = () => {
    setNewLedClose(false)
  }

  const openNewLed = () => {
    AnalyticsListener.createRouteView('new_led')
    setNewSimulationLed(true)
  }

  const closeModalLed = (isANewLed: boolean = false) => {
    setNewLedClose(false)
    setNewSimulationLed(false)
  }

  const CreateNewLed = () => {
    AnalyticsListener.createRouteView('create_led')
    AnalyticsListener.sendEvent('create_led')
    setCreateLed(true)
  }

  const DeleteLed = () => {
    //  AnalyticsListener.createRouteView('create_led')
    //  AnalyticsListener.sendEvent('create_led')
    setCreateLed(true)
  }

  useEffect(() => {
    let didCancel = false
    async function getUserLedFamilies() {
      !didCancel && setIsLoading(true)
      let userFamiliesResponse: FamilyLed[] = []
      let userComapinesResponse: [] = []
      try {
        userFamiliesResponse = await ledApi.getFamilies()
        userComapinesResponse = await ledApi.getCompaniesCatalog()
        const needLedsTour: boolean = userFamiliesResponse.length === 0
        // const needLedsTour: boolean = true
        setLedsTourActive(needLedsTour)

        !didCancel && setLedsFamilies(userFamiliesResponse)
        !didCancel && setLedsCatalogFamilies(userComapinesResponse)
      } catch (error) {
        console.error(error)
      } finally {
        !didCancel && setIsLoading(false)
      }
    }

    if (!didCancel || CreateLed === true) {
      getUserLedFamilies()

      setCreateLed(false)
    }

    return () => {
      didCancel = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CreateLed])

  const downloadExcel = async () => {
    AnalyticsListener.createRouteView('download_excel')
    AnalyticsListener.sendEvent('download_excel')
    await ledApi.DownloadExcelLeds()
  }

  return (
    <DivLedsContainer>
      <DivSearch>
        <WrapperSearch>
          <InputSearch
            onChange={(event) => handleOnChange(event)}
            name="text"
            type="search"
            value={Search}
            placeholder=" Search..."
          />

          <ButtonSearch type="submit">{SearchIcon}</ButtonSearch>
        </WrapperSearch>
        <WrapperButtonsLedImport>
          <ButtonNewLed
            className="tour-new_led"
            onClick={() => openNewLed()}
            disabled={!isLoading ? false : true}
          >
            New Led
          </ButtonNewLed>
          {!isLoading && LedsTourActive && <Tour section="PAGE_LED" />}
          <ModalReact
            size={'tiny'}
            trigger={
              <ButtonExcel className="tour-excel">EXCEL IMPORT</ButtonExcel>
            }
          >
            <ModalReact.Content>
              <Modaldes>
                <Header>EXCEL IMPORT</Header>

                <PhrasegreyPadding>
                  If you want us to import your led data you can download <br />
                  the excel file below, fill it with your information and
                  contact us for further instructions at{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="mailto:hello@kumux.io"
                  >
                    hello@kumux.io
                  </a>
                  .{' '}
                </PhrasegreyPadding>

                <br />

                <ImageExcel
                  src={excelpng}
                  onClick={() => downloadExcel()}
                  centered
                  size="small"
                ></ImageExcel>

                <br />
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="mailto:hello@kumux.io"
                >
                  {' '}
                </a>
              </Modaldes>
            </ModalReact.Content>
          </ModalReact>
        </WrapperButtonsLedImport>
        {newSimulationLed && (
          <Modal
            open={newSimulationLed}
            closeModal={closeModalConfirm}
            title="New Led"
          >
            <NewSimulationLed
              LedsTourActive={LedsTourActive}
              closeModal={closeModalLed}
              newled={CreateNewLed}
            ></NewSimulationLed>
          </Modal>
        )}
      </DivSearch>
      <HistoricCountSimulations>
        Showing {LedsFamilies.length + LedsCatalogFamilies.length} company(es)
      </HistoricCountSimulations>
      <DivLedSectionTitle>YOUR LEDS</DivLedSectionTitle>
      {(!isLoading && LedsFamilies.length === 0 && (
        <NoResultsContainer>
          <NoResultsIconWrapper>{NoResultsIcon}</NoResultsIconWrapper>
          <NoResultsTextWrapper>
            You have not added any led yet.
          </NoResultsTextWrapper>
        </NoResultsContainer>
      )) ||
        (!isLoading && LedsFamilies.length > 0 && (
          <DivLedListContainer>
            {LedsFamilies &&
              LedsFamilies.map((item, i) => {
                return (
                  <LedList
                    search={Search}
                    index={i}
                    DeleteLed={DeleteLed}
                    key={item.reference}
                    listItems={item}
                    isHistoric={true}
                    catalog={false}
                  />
                )
              })}
          </DivLedListContainer>
        ))}

      <DivLedSectionTitle className="tour-led-catalog">
        LED CATALOG
      </DivLedSectionTitle>

      {(!isLoading && LedsCatalogFamilies.length === 0 && (
        <NoResultsContainer>
          <NoResultsIconWrapper>{NoResultsIcon}</NoResultsIconWrapper>
          <NoResultsTextWrapper>No Leds found.</NoResultsTextWrapper>
        </NoResultsContainer>
      )) ||
        (!isLoading && LedsCatalogFamilies.length > 0 && (
          <DivLedListContainer>
            {LedsCatalogFamilies.map((item, i) => {
              return (
                <LedList
                  search={Search}
                  index={i}
                  defaultOpenIndex={LedsTourActive ? 0 : -1}
                  key={item.reference}
                  listItems={item}
                  DeleteLed={DeleteLed}
                  isHistoric={true}
                  catalog={true}
                  LedsTourActive={LedsTourActive}
                />
              )
            })}
          </DivLedListContainer>
        ))}
      {newLedClose && (
        <SimpleConfirm
          open={newLedClose}
          closeModal={closeModalCancel}
          acceptConfirm={closeModalLed}
          message="Discard all changes?"
        />
      )}
    </DivLedsContainer>
  )
}

const DivLedSectionTitle = styled.h5`
  margin-top: 30px;
`

const DivLedsContainer = styled.div`
  min-height: 250px;
`
const Modaldes = styled(ModalReact.Description)`
  text-align: center;
`
const HistoricCountSimulations = styled.p`
  margin-top: 10px;
  margin-bottom: 25px;
  ${(props: CompProps) => (props.theme as Theme).font_size.xxxsmall};
`

const DivSearch = styled.div`
  width: 100%;
  position: relative;
  display: flex;

  justify-content: space-between;
`

const ImageExcel = styled(Image)`
  cursor: pointer;
`
const ButtonNewLed = styled(MainButtonAction)`
  height: auto;
  margin-left: auto;
  :disabled {
    background: ${(props: CompProps) =>
      (props.theme as Theme).color.white.darker}!important;
  }
`
const PhrasegreyPadding = styled(Phrasegrey)`
  padding-top: 15px;
  font-size: 17px;
`

const ButtonExcel = styled(MainButtonAction)`
  height: auto;

  margin-left: 30px;
`
const WrapperButtonsLedImport = styled.div`
  display: flex;
`

const WrapperSearch = styled.div`
  display: flex;
`

const DivLedListContainer = styled.div`
  padding: 25px;
  ${(props: CompProps) => (props.theme as Theme).filters.drop_shadow};
`

export default SectionLed
