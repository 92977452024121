import React, { useEffect, useRef } from 'react'

declare var google: any

interface Props {
  locationCallback: Function
  EditLocation: string
}

interface Place {
  address: string,
  lat: number,
  lng: number
}

const GPlace: React.FC<Props> = (props) => {

  const placeInputRef = useRef(null)
  const { EditLocation, locationCallback } = props
  
  useEffect(() => {
    const initPlaceAPI = (EditLocation: string) => {
      let locationInput: any = placeInputRef.current
      locationInput.value = ''
      if (EditLocation && EditLocation.length > 0) {
        locationInput.value = EditLocation
      }
      
      let autocomplete = new google.maps.places.Autocomplete(locationInput)
      
      const maps = () => {
        let place = autocomplete.getPlace()
        if (place.geometry) {
          const result: Place = {
            address: place.formatted_address,
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
          }
          locationCallback(result)
        }
      }
  
      google.maps.event.addListener(autocomplete, 'place_changed', maps)
    }

    initPlaceAPI(EditLocation)
  }, [EditLocation, locationCallback])

  return (
    <>
      <div className="ui left icon input">
        <input ref={placeInputRef} type="text" placeholder="Location" />
        <i aria-hidden="true" className="map marker alternate icon"></i>
      </div>
    </>
  )
}

export default GPlace
