import React, { useState, useEffect, SyntheticEvent } from 'react'
import styled from 'styled-components'

import { CompProps, Theme } from '../styles/theme'
import { Grid, Input, Form, Dropdown, Icon, Popup } from 'semantic-ui-react'
import SpectraLineBars from '../components/charts/SpectraLineBars'
import { Auth } from '../App'
import {
  MainButton,
  DropdownProject,
  LoadingContainer,
  ModalPopup
} from '../styles/commons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Line from '../components/charts/Line'
import {
  faMapMarkerAlt,
  faSun,
  faMoon
} from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom'
import {
  timeLineApi,
  TimelineProject,
  TransHour,
  Hour
} from '../api/TimelineApi'
import { projectApi, Project } from '../api/ProjectApi'
import { sceneApi, Scene } from '../api/SceneApi'
import Loading from '../components/common/Loading'
import { getSunrise, getSunset } from 'sunrise-sunset-js'
import { ToastContainer, toast } from 'react-toastify'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Tour from '../components/help/Tour'
import ModalScene from '../components/common/ModalScene'

interface Props {
  auth: Auth
}

const LocationIcon = <FontAwesomeIcon icon={faMapMarkerAlt} />
const LocationSun = <FontAwesomeIcon icon={faSun} />
const LocationMoon = <FontAwesomeIcon icon={faMoon} />

const FormDefault: any = {
  fixture_id: '',
  month: '',
  reference: '',
  start_time: 0,
  end_time: 23,
  year: '',
  date: ''
}

const ProjectInfoDefault: any = {
  description: '',
  location_coords: [0, 0],
  location_name: '',
  id: '',
  end_time: '',
  reference: ''
}

const HourDefault: Hour = {
  hour24: -1,
  params: [
    {
      name: '',
      value: 0,
      view: 0
    }
  ],
  scene_id: '',
  scene_name: '',
  spd: {
    wavelength: [],
    irradiance: []
  },
  type: 0
}

const Timeline: React.FC<Props> = (props) => {
  const { showTutorial, publicId, timelineId } = useParams()
  const [isNewTimeline, setIsNewTimeline] = useState(false)

  const [Timelines, setTimelines] = useState<TimelineProject>()
  const [FormTimeLine, setFormTimeLine] = useState(FormDefault)
  const [ListScene, setListScene] = useState<Scene[]>([])
  // TODO: define timeline tutorial activation

  const [tourTimeLine] = useState(!!(showTutorial === '1'))
  const [tourScenes, setTourScenes] = useState(false)
  const [tourTimeLineAdjust, setTourTimeLineAdjust] = useState(false)

  const [ErrorForm, setErrorForm] = useState(false)

  const [openPopUpScene, setOpenPopUpScene] = useState(false)
  const [popupSceneId, setPopupSceneId] = useState<string>()

  const [ErrorTimeForm, setErrorTimeForm] = useState(false)
  const [ProjectInfo, setProjectInfo] = useState<Project>(ProjectInfoDefault)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingFixtures, setIsLoadingFixtures] = useState(false)
  const [isLoadingTimeline, setIsLoadingTimeline] = useState(false)
  const [Blockform, setBlockform] = useState(false)
  const [Blocksave, setBlocksave] = useState(true)
  const [Fixtures, setFixtures] = useState<any>(Array)

  useEffect(() => {
    let didCancel = false
    const GetinfoProject = async () => {
      !didCancel && setIsLoading(true)
      try {
        const ProjectInfoResponse: Project = await projectApi.GetProject(
          publicId
        )
        
        if (ProjectInfoResponse) {
          !didCancel && setProjectInfo(ProjectInfoResponse)
          if (timelineId) {
            // TIMELINE YA CREADO
            !didCancel && setBlockform(true)
            const TimelinesProject: TimelineProject = await timeLineApi.getTimelineProject(
              publicId,
              timelineId
            )
            !didCancel && setTimelines(TimelinesProject)
            // Set Form info
            !didCancel && updateFormData(TimelinesProject)
            // Get Scenes
            const ListScenes: Scene[] = await sceneApi.getHistoricByLuminaryId(
              TimelinesProject.fixture.id
            )
            !didCancel && setIsNewTimeline(false)
            !didCancel && setListScene(ListScenes)
            // Activate tutorial if needed
            const numDynamicScenes: number = TimelinesProject.data.filter(
              (x) => x.type === 1
            ).length
            !didCancel &&
              !!(showTutorial === '1') &&
              setTourScenes(numDynamicScenes < 2)
          } else {
            // CREANDO TIMELINE
            !didCancel && setIsLoading(false)
            !didCancel && setIsLoadingFixtures(true)
            !didCancel && setIsNewTimeline(true)
            let optionsFixture: Array<any> = []
            const TimelineFixTures: any = await timeLineApi.getAvailableFixtures()
            for (let index = 0; index < TimelineFixTures.length; index++) {
              const dropDownElement = {
                key: TimelineFixTures[index].public_id,
                value: TimelineFixTures[index].public_id,
                text: TimelineFixTures[index].reference
              }
              optionsFixture.push(dropDownElement)
            }
            !didCancel && setFixtures(optionsFixture)
            !didCancel && setIsLoadingFixtures(false)
          }
        }
        !didCancel && setIsLoading(false)
      } catch (error) {
        console.error(error)
      }
    }

    GetinfoProject()
    return () => {
      didCancel = true
    }
  }, [publicId, timelineId, showTutorial])

  const CreateTimeline = async () => {
    setIsLoading(true)

    const TimelineResponse: any =
      publicId &&
      (await timeLineApi.CreateProjectTimeline(publicId, FormTimeLine))

    if (TimelineResponse) {
      const TimelinesProject: TimelineProject = await timeLineApi.getTimelineProject(
        publicId,
        TimelineResponse.id
      )
      TimelinesProject && setTimelines(TimelinesProject)

      updateFormData(TimelinesProject)

      const ListScenes: Scene[] = await sceneApi.getHistoricByLuminaryId(
        TimelinesProject.fixture.id
      )
      ListScenes && setListScene(ListScenes)

      setIsNewTimeline(false)
      setBlockform(true)
      setErrorForm(true)

      setTimeout(() => {
        showTutorial === '1' && setTourScenes(true)
      }, 0)
    }

    setIsLoading(false)
  }

  const SaveTimeline = async () => {
    setIsLoadingTimeline(true)
    if (publicId !== undefined && Timelines) {
      const TimelinesAux: any = Object.assign([], Timelines)

      let date: Date = FormTimeLine.date
      TimelinesAux.month = date.getMonth() + 1
      TimelinesAux.year = date.getFullYear()

      const TimelinesUpdate = await timeLineApi.UpdateTimeline(
        publicId,
        Timelines.id,
        TimelinesAux
      )
      if (TimelinesUpdate.content) {
        const response: string = TimelinesUpdate.content.info
        toast.success(response)
      } else {
        toast.error('Error saving timeline')
      }
    }
    setIsLoadingTimeline(false)
  }

  const HandleFormTimelineFixture = (event: SyntheticEvent, data: any) => {
    setFormTimeLine({ ...FormTimeLine, fixture_id: data.value })
  }

  const HandleFormTimelineDate = (value: Date) => {
    setFormTimeLine({
      ...FormTimeLine,
      month: Number(value.getMonth() + 1),
      year: value.getFullYear(),
      date: value
    })
  }

  const HandleFormTimeline = (e: any) => {
    if (e.target.name === 'date') {
      let date: any = String(e.target.value).split('-')
      setFormTimeLine({
        ...FormTimeLine,
        month: Number(date[0]),
        year: Number(date[1]),
        date: e.target.value
      })
    } else {
      if (e.target.name === 'start_time' || e.target.name === 'end_time') {
        setFormTimeLine({
          ...FormTimeLine,
          [e.target.name]: parseInt(e.target.value)
        })
      } else {
        setFormTimeLine({
          ...FormTimeLine,
          [e.target.name]: e.target.value
        })
      }
    }
  }

  useEffect(() => {
    const validateForm = () => {
      let error: boolean = false
      let errorTimeForm: boolean = false

      if (FormTimeLine.reference === '') {
        error = true
      }

      if (FormTimeLine.month === '') {
        error = true
      }
      if (FormTimeLine.year === '') {
        error = true
      }
      if (FormTimeLine.fixture_id === '') {
        error = true
      }

      if (FormTimeLine.start_time >= FormTimeLine.end_time) {
        errorTimeForm = true
        error = true
      }

      if (parseInt(FormTimeLine.start_time) > 23) {
        errorTimeForm = true
        error = true
      }

      if (parseInt(FormTimeLine.end_time) > 23) {
        errorTimeForm = true
        error = true
      }

      setErrorTimeForm(errorTimeForm)
      setErrorForm(error)
    }

    validateForm()
  }, [FormTimeLine])

  const updateFormData = (ProjectInfo: TimelineProject) => {
    // Set Fixture Info
    const dropDownElement = {
      key: ProjectInfo.fixture.id,
      value: ProjectInfo.fixture.reference,
      text: ProjectInfo.fixture.reference
    }
    setFixtures([dropDownElement])

    // Set Time info
    const FormTimelineAux: any = Object.assign({}, FormDefault)
    const d: Date = new Date(ProjectInfo.datetime_unix * 1000)
    FormTimelineAux.date =
      (d.getMonth() < 10 ? '0' + d.getMonth() : d.getMonth()) +
      '-' +
      d.getFullYear()
    FormTimelineAux.year = d.getFullYear()
    FormTimelineAux.month = d.getMonth()
    FormTimelineAux.fixture_id = ProjectInfo.fixture.id
    FormTimelineAux.start_time = ProjectInfo.start_time
    FormTimelineAux.reference = ProjectInfo.reference
    FormTimelineAux.end_time = ProjectInfo.end_time
    FormTimelineAux.date = d
    setFormTimeLine(FormTimelineAux)
  }

  const getHour = async (
    fixture_id: string,
    scene_id: string,
    time_24h: number
  ) => {
    setIsLoadingTimeline(true)

    const HournewScene: Hour = await timeLineApi.GetHours(
      fixture_id,
      scene_id,
      time_24h
    )

    if (HournewScene) {
      const TimelinesAux: TimelineProject = Object.assign([], Timelines)
      const timelineHourIndex: number = TimelinesAux.data.findIndex(
        (el: any) => {
          return time_24h === el.hour24
        }
      )

      const TimelinesClean: TimelineProject = deleteHour(TimelinesAux, time_24h)
      TimelinesClean.data[timelineHourIndex] = HournewScene

      const TimelinesAuxWithTransitions: TimelineProject = await updateHours(
        TimelinesClean
      )
      setTimelines(TimelinesAuxWithTransitions)

      setBlocksave(false)
    }

    setIsLoadingTimeline(false)
  }

  const removeHour = async (time_24h: number) => {
    setIsLoadingTimeline(true)

    const TimelinesAux: TimelineProject = Object.assign([], Timelines)
    const timelineHourIndex: number = TimelinesAux.data.findIndex((el: any) => {
      return time_24h === el.hour24
    })

    const TimelinesClean: TimelineProject = deleteHour(TimelinesAux, time_24h)
    TimelinesClean.data[timelineHourIndex] = Object.assign(
      {},
      { ...HourDefault, hour24: time_24h, type: 0 }
    )

    const TimelinesAuxWithTransitions: TimelineProject = await updateHours(
      TimelinesClean
    )
    setTimelines(TimelinesAuxWithTransitions)
    setBlocksave(false)

    setIsLoadingTimeline(false)
  }

  const deleteHour = (
    Timelines: TimelineProject,
    time_24h: number
  ): TimelineProject => {
    const hourIndex: number = Timelines.data.findIndex((el: any) => {
      return time_24h === el.hour24
    })

    for (let i: number = hourIndex - 1; i >= 0; i--) {
      if (Timelines.data[i].type === 2) {
        const h: number = Timelines.data[i].hour24
        Timelines.data[i] = Object.assign(
          {},
          { ...HourDefault, hour24: h, type: 0 }
        )
      } else {
        break
      }
    }

    for (
      let i: number = hourIndex + 1;
      i < Timelines.data.length;
      Timelines.data.length, i++
    ) {
      if (Timelines.data[i].type === 2) {
        const h: number = Timelines.data[i].hour24
        Timelines.data[i] = Object.assign(
          {},
          { ...HourDefault, hour24: h, type: 0 }
        )
      } else {
        break
      }
    }

    return Timelines
  }

  const updateCircleInfo = async (
    hour24: number,
    paramName: string,
    newValue: number,
    apiUpdate: boolean = false
  ) => {
    setIsLoadingTimeline(true)

    const TimelinesAux: TimelineProject = Object.assign([], Timelines)
    const timelineHourIndex: any = TimelinesAux.data.findIndex((el: any) => {
      return el.hour24 === hour24
    })

    if (TimelinesAux.data[timelineHourIndex]) {
      TimelinesAux.data[timelineHourIndex].params[0].value = newValue
    }

    if (apiUpdate) {
      // Buscamos las horas intermedias:
      const HourSend: Hour[] = []
      let find: boolean = false
      for (let index = timelineHourIndex - 1; index >= 0; index--) {
        const element = TimelinesAux.data[index]
        if (find === false && element.type === 1) {
          HourSend.push(TimelinesAux.data[index])
          find = true
        }
      }

      find = false
      for (
        let index = timelineHourIndex + 1;
        index <= TimelinesAux.data.length;
        index++
      ) {
        const element: Hour = TimelinesAux.data[index]
        if (find === false && element.type === 1) {
          HourSend.push(TimelinesAux.data[index])
          find = true
        }
      }

      let middleHours = TimelinesAux.data.filter(
        (timeline) =>
          timeline.hour24 > HourSend[0].hour24 &&
          timeline.hour24 < HourSend[1].hour24
      )

      // Contruimos el request:
      let date: Date = FormTimeLine.date

      let SendTransHour: TransHour = {
        end_time_24h: HourSend[1].hour24,
        end_scene_id: HourSend[1].scene_id,
        fixture_id: Fixtures[0].key,
        month: date.getMonth() + 1,
        year: date.getFullYear(),
        start_scene_id: HourSend[0].scene_id,
        start_time_24h: HourSend[0].hour24,
        target_middle_ccts: []
      }

      for (let indexS = 0; indexS < middleHours.length; indexS++) {
        SendTransHour.target_middle_ccts.push(
          middleHours[indexS].params[0].value
        )
      }

      // Get the intermidate hours
      // TODO: This code is repeated in updateHours
      const TransHours: Hour[] = await timeLineApi.getTransitionHour(
        SendTransHour
      )

      if (TransHours.length > 0) {
        TimelinesAux.data = TimelinesAux.data.map(
          (hour) =>
            TransHours.find((transHour) => transHour.hour24 === hour.hour24) ||
            hour
        )
      } else {
        toast.error('Error calculating transhours')
      }
    }

    setBlocksave(false)
    setIsLoadingTimeline(false)
    setTimelines(TimelinesAux)
  }

  const updateHours = async (
    Timelines: TimelineProject
  ): Promise<TimelineProject> => {
    const SendTransHours: Array<TransHour> = []

    let startIndex: number = -1
    Timelines.data.forEach((hour: Hour, hourIndex: number) => {
      if (hour.type === 1 && startIndex === -1) {
        startIndex = hourIndex
      } else if (hour.type === 2) {
        startIndex = -1
      } else if (
        hour.type === 1 &&
        startIndex !== -1 &&
        hourIndex - startIndex === 1
      ) {
        startIndex = hourIndex
      } else if (
        hour.type === 1 &&
        startIndex !== -1 &&
        hourIndex - startIndex > 1
      ) {
        const endIndex: number = hourIndex

        let date: any = FormTimeLine.date

        let SendTransHour: TransHour = {
          end_time_24h: Timelines.data[endIndex].hour24,
          end_scene_id: Timelines.data[endIndex].scene_id,
          fixture_id: Fixtures[0].key,
          month: date.getMonth() + 1,
          year: date.getFullYear(),
          start_scene_id: Timelines.data[startIndex].scene_id,
          start_time_24h: Timelines.data[startIndex].hour24,
          target_middle_ccts: []
        }

        const middleHours: number = endIndex - (startIndex + 1)
        const startCCT: number = Timelines.data[startIndex].params[0].value
        const endCCT: number = Timelines.data[endIndex].params[0].value
        const diffCCT: number = Math.abs(endCCT - startCCT)
        const cctScale: number = diffCCT / (middleHours + 1)

        for (let i: number = 0; i < middleHours; i++) {
          if (endCCT >= startCCT) {
            SendTransHour.target_middle_ccts.push(startCCT + cctScale * (i + 1))
          } else {
            SendTransHour.target_middle_ccts.push(startCCT - cctScale * (i + 1))
          }
        }

        SendTransHours.push(SendTransHour)
        startIndex = endIndex
      }
    })

    // TODO: This code is repeated in updateCircle

    for (const sendTransHour of SendTransHours) {
      const TransHours: Hour[] = await timeLineApi.getTransitionHour(
        sendTransHour
      )
      if (TransHours.length > 0) {
        // Activate tutorial if needed
        if (tourScenes) {
          setTourScenes(false)
          setTourTimeLineAdjust(true)
        } else if (tourTimeLineAdjust) {
          setTourTimeLineAdjust(false)
        }
        // Parse data
        Timelines.data = Timelines.data.map(
          (hour) =>
            TransHours.find((transHour) => transHour.hour24 === hour.hour24) ||
            hour
        )
      } else {
        toast.error('Error calculating transhours')
      }
    }

    return Timelines
  }

  const getParamMinValue = (paramIndex: number): number => {
    if (Timelines) {
      const valueArray: Array<number> = Timelines.data.map((timeline: Hour) => {
        return timeline.params[paramIndex] &&
          timeline.params[paramIndex].value > 0 &&
          timeline.type !== 0
          ? timeline.params[paramIndex].value
          : 20000
      })
      const result: number = Math.floor(Math.min(...valueArray) / 200) * 200
      return result
    } else {
      return 0
    }
  }

  const getParamMaxValue = (paramIndex: number): number => {
    if (Timelines) {
      const valueArray: Array<number> = Timelines.data.map((timeline: Hour) => {
        return timeline.params[paramIndex] && timeline.type !== 0
          ? timeline.params[paramIndex].value
          : 0
      })
      const result: number = Math.ceil(Math.max(...valueArray) / 200) * 200
      return result
    } else {
      return 20000
    }
  }

  const get24hourSunState = (
    hour24: number,
    lat: number,
    long: number,
    date: Date,
    timelineGMT: number
  ): boolean => {
    // Date is generated from UTC but with  browser local GMT
    const hour_diff = timelineGMT + date.getTimezoneOffset() / 60
    const sunrise_date: Date = getSunrise(lat, long, date)
    sunrise_date.setHours(sunrise_date.getHours() + hour_diff)
    const sunset_date: Date = getSunset(lat, long, date)
    sunset_date.setHours(sunset_date.getHours() + hour_diff)
    const sunrise: number = sunrise_date.getHours()
    const sunset: number = sunset_date.getHours()

    const isBetween = (n: number, a: number, b: number) =>
      (n - a) * (n - b) <= 0
    const isDay: boolean = isBetween(hour24, sunrise, sunset)
    return isDay
  }

  const get24hourSunPercent = (
    hour24: number,
    lat: number,
    long: number,
    date: Date,
    timelineGMT: number
  ): string => {
    // Date is generated from UTC but with  browser local GMT
    const hour_diff = timelineGMT + date.getTimezoneOffset() / 60
    const sunrise_date: Date = getSunrise(lat, long, date)
    sunrise_date.setHours(sunrise_date.getHours() + hour_diff)
    const sunset_date: Date = getSunset(lat, long, date)
    sunset_date.setHours(sunset_date.getHours() + hour_diff)

    const sunrise: number = sunrise_date.getHours()
    const sunset: number = sunset_date.getHours()

    const isBetween = (n: number, a: number, b: number) =>
      (n - a) * (n - b) <= 0
    const isDay: boolean = isBetween(hour24, sunrise, sunset)

    if (isDay) {
      const rangeMiddle: number = sunrise + (sunset - sunrise) * 0.5
      const range: number =
        hour24 <= rangeMiddle - 1
          ? rangeMiddle - sunrise
          : sunset - sunset * 0.5
      const correctedStartValue: number =
        hour24 <= rangeMiddle - 1 ? hour24 - sunrise : hour24 - sunset * 0.5
      const percentage: number = (correctedStartValue * 100) / range

      return hour24 <= rangeMiddle - 1
        ? `${Math.round(percentage)} %`
        : `${Math.round(100 - percentage)} %`
    } else {
      return 'Night Time'
    }
  }

  const HandleOpenPopup = async (sceneId: string) => {
    setPopupSceneId(sceneId)
    setOpenPopUpScene(true)
  }

  return (
    <HomeContainer>
      {isLoading ? (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      ) : (
        <>
          <Grid>
            <Grid.Row>
              <Grid.Column width={13}>
                {!isNewTimeline ? (
                  <h1>{FormTimeLine.reference}</h1>
                ) : (
                  <h1>New Timeline</h1>
                )}
              </Grid.Column>
              <Grid.Column width={3} textAlign="right">
                {isNewTimeline ? (
                  <MainButton
                    className="tour-timeline-create"
                    disabled={ErrorForm}
                    content="CREATE TIMELINE"
                    onClick={CreateTimeline}
                  />
                ) : (
                  <MainButton
                    className="tour-timeline-save"
                    disabled={Blocksave}
                    content="SAVE TIMELINE"
                    onClick={SaveTimeline}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <CotaninerTimeLine>
            <Grid>
              <Grid.Row>
                <Grid.Column width={16} floated="right" textAlign="right">
                  {!isLoading && ProjectInfo && Timelines && (
                    <LocationProject className="Location">
                      <LocationSection> {LocationIcon}</LocationSection>
                      <CityLocation>
                        <div> {ProjectInfo.location_name}</div>
                        <GMT>
                          {Timelines &&
                            `${
                              Timelines.gmt >= 0 ? 'GMT +' : 'GMT -'
                            }${Math.round(Timelines.gmt)}`}
                        </GMT>
                      </CityLocation>
                    </LocationProject>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <FormCalculate>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Form.Field className="tour-timeline-fixture">
                      <label> Fixture </label>
                      <Dropdown
                        onChange={(event: SyntheticEvent, data: any) =>
                          HandleFormTimelineFixture(event, data)
                        }
                        name="fixture_id"
                        clearable
                        options={Fixtures}
                        selection
                        loading={isLoadingFixtures}
                        disabled={Blockform || isLoadingFixtures}
                        placeholder={
                          Fixtures[0] ? Fixtures[0].text : 'Choose a Fixture'
                        }
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <FormField className="tour-timeline-time-month-year">
                      <label>Month-Year </label>
                      <DatePicker
                        selected={
                          FormTimeLine.date ? FormTimeLine.date : new Date()
                        }
                        onChange={(date: Date) => HandleFormTimelineDate(date)}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        disabled={Blockform}
                        value={FormTimeLine.date}
                        readOnly={Blockform}
                      />
                    </FormField>
                  </Grid.Column>
                  <Grid.Column className="tour-timeline-time" width={4}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={8}>
                          <FormField>
                            <label>Start Time</label>
                            <Input
                              icon="clock"
                              type="number"
                              min={0}
                              max={FormTimeLine.end_time}
                              iconPosition="left"
                              name="start_time"
                              error={ErrorTimeForm}
                              step="1"
                              disabled={Blockform}
                              onChange={(e: any) => HandleFormTimeline(e)}
                              value={FormTimeLine.start_time}
                            />
                          </FormField>
                        </Grid.Column>
                        <Grid.Column width={8}>
                          <FormField>
                            <label>End Time</label>
                            <Input
                              icon="clock"
                              type="number"
                              iconPosition="left"
                              min={1}
                              max={23}
                              disabled={Blockform}
                              step="1"
                              error={ErrorTimeForm}
                              name="end_time"
                              value={FormTimeLine.end_time}
                              onChange={(e: any) => HandleFormTimeline(e)}
                            />
                          </FormField>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    {isNewTimeline && (
                      <FormField className="tour-timeline-name">
                        <label>Reference</label>
                        <Input
                          onChange={(e: any) => HandleFormTimeline(e)}
                          placeholder="Name Timeline"
                          name="reference"
                          disabled={Blockform}
                          value={FormTimeLine.reference}
                        />
                      </FormField>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </FormCalculate>
            <TimelineEditorContainer>
              {isLoadingTimeline && (
                <TimelineLoadingContainer>
                  <div className="ui active centered inline loader"></div>
                </TimelineLoadingContainer>
              )}
              <GridContainer columns="16" divided>
                <Gridrow className={Timelines && 'row'}>
                  {Timelines &&
                    Timelines.data.map((item: any, ix: number) => {
                      let test: number = Number(ix) + Number(1)

                      if (
                        Timelines.data[test] !== undefined &&
                        Array.isArray(Timelines.data[test].params) &&
                        Timelines.data[test].params[0] !== undefined
                      ) {
                      }

                      return (
                        <Grid.Column
                          key={ix}
                          className={` tableEscens 
                          
                          param-container-middle-${item.type}`}
                        >
                          <LocationProjectLine className="Location">
                            <LocationSection>
                              {get24hourSunState(
                                item.hour24,
                                ProjectInfo.location_coords[0],
                                ProjectInfo.location_coords[1],
                                new Date(Timelines.datetime_unix * 1000),
                                Timelines.gmt
                              )
                                ? LocationSun
                                : LocationMoon}
                            </LocationSection>
                            <CityLocation>
                              <div>{item.hour24}:00</div>
                              <GMT>
                                {get24hourSunPercent(
                                  item.hour24,
                                  ProjectInfo.location_coords[0],
                                  ProjectInfo.location_coords[1],
                                  new Date(Timelines.datetime_unix * 1000),
                                  Timelines.gmt
                                )}
                              </GMT>
                            </CityLocation>
                            <DropdownProject
                              className={`icon tour-timeline-scene${ix}`}
                              icon="ellipsis vertical"
                            >
                              <Dropdown.Menu>
                                {ListScene &&
                                  ListScene.map((itemS: Scene, ix: number) => {
                                    return (
                                      <Dropdown.Item
                                        key={itemS.reference}
                                        text={itemS.reference}
                                        content={
                                          <PopupScene
                                            position="left center"
                                            wide="very"
                                            content={
                                              <ContainerPopup>
                                                <b>CCT:</b>
                                                {itemS.data.cct} <b>CRI:</b>
                                                {itemS.data.cri}{' '}
                                                <b>M/P ratio:</b>
                                                {itemS.data.mp}{' '}
                                                <b>Energy efficiency:</b>
                                                {itemS.data.efficiency_lmw}
                                              </ContainerPopup>
                                            }
                                            trigger={<p>{itemS.reference}</p>}
                                          />
                                        }
                                        onClick={(event: SyntheticEvent) =>
                                          getHour(
                                            Fixtures[0].key
                                              ? Fixtures[0].key
                                              : FormTimeLine.fixture_id,
                                            itemS.public_id,
                                            item.hour24
                                          )
                                        }
                                      />
                                    )
                                  })}
                                {item.type === 1 && (
                                  <>
                                    <Dropdown.Divider />
                                    <Dropdown.Item
                                      icon="trash"
                                      text="Remove"
                                      onClick={(event: SyntheticEvent) => {
                                        removeHour(item.hour24)
                                      }}
                                    />
                                    <br />
                                  </>
                                )}
                              </Dropdown.Menu>
                            </DropdownProject>
                          </LocationProjectLine>
                          {item.type !== 0 && (
                            <ContainerSpectra>
                              <SpectraLineBars
                                intensity={item.spd.irradiance}
                                wavelength={item.spd.wavelength}
                                canvas_width={'216px'}
                                showlabels={false}
                              />
                              {item.type === 1 ? (
                                <Labeltitle
                                  onClick={() => HandleOpenPopup(item.scene_id)}
                                >
                                  {`${item.scene_name}  `}
                                  <Icon name="info circle" size="small" />
                                </Labeltitle>
                              ) : (
                                <Labeltitle />
                              )}
                            </ContainerSpectra>
                          )}
                          {item.type !== 0 && (
                            <ParamContainer
                              className={`param-container-${item.params[0].name}-${ix}`}
                            >
                              <ParamBlock>
                                <Line
                                  type={item.type ? item.type : 0}
                                  name={item.params[0].name}
                                  number={item.hour24}
                                  value={item.params[0].value}
                                  scaleStart={getParamMinValue(0)}
                                  scaleEnd={getParamMaxValue(0)}
                                  prevValue={
                                    Timelines.data[ix - 1] &&
                                    Timelines.data[ix - 1].params[0] &&
                                    Timelines.data[ix - 1].params[0].value
                                  }
                                  nextValue={
                                    Timelines.data[ix + 1] &&
                                    Timelines.data[ix + 1].params[0] &&
                                    Timelines.data[ix + 1].params[0].value
                                  }
                                  updateCallBack={updateCircleInfo}
                                />
                              </ParamBlock>
                              <ParamName>
                                {ix === 0 && item.params[0].name.toUpperCase()}
                              </ParamName>
                              <ParamDivider />
                            </ParamContainer>
                          )}
                        </Grid.Column>
                      )
                    })}
                </Gridrow>
              </GridContainer>
            </TimelineEditorContainer>
          </CotaninerTimeLine>
        </>
      )}
      {popupSceneId && (
        <ModalPopup
          closeIcon
          size="large"
          onClose={() => setOpenPopUpScene(false)}
          onOpen={() => setOpenPopUpScene(true)}
          open={openPopUpScene}
        >
          <ModalScene sceneId={popupSceneId} />
        </ModalPopup>
      )}
      {tourTimeLine && isNewTimeline && <Tour section="PAGE_NEW_TIMELINE" />}
      {tourScenes && !isNewTimeline && <Tour section="PAGE_CREATE_TIMELINE" />}
      {/* TODO: Implement PAGE_CREATE_TIMELINE_ADJUST_PARAMETERS */}
      {tourTimeLineAdjust && !isNewTimeline && (
        <Tour section="PAGE_CREATE_TIMELINE_ADJUST_PARAMETERS" />
      )}
      <ToastContainer />
    </HomeContainer>
  )
}

const ParamContainer = styled.div`
  position: relative;
  hr:not(first-child) {
    margin-left: -1em;
  }
`
const ParamName = styled.div`
  position: absolute;
  bottom: 20px;
  margin-left: 5px;
  ${(props: CompProps) => (props.theme as Theme).color.black.lightest};
  ${(props: CompProps) => (props.theme as Theme).font_size.xxxsmall};
  font-family: ${(props: CompProps) => (props.theme as Theme).font.semibold};
  font-weight: bolder;
  color: #ba82e7;
`

const ParamDivider = styled.hr`
  border: 1px;
  background: lightgrey;
  width: calc(100% + 2em);
  height: 1px;
`

const ParamBlock = styled.div`
  min-height: 150px;
`

const Labeltitle = styled.div`
  cursor: pointer;
  ${(props: CompProps) => (props.theme as Theme).color.black.lightest};
  ${(props: CompProps) => (props.theme as Theme).font_size.xsmall};
  font-family: ${(props: CompProps) => (props.theme as Theme).font.semibold};
  margin-left: 10px;
  height: 30px;
  i {
    width: auto;
  }
  :hover {
    color: ${(props: CompProps) => (props.theme as Theme).color._900};
  }
`
const LocationProject = styled.div`
  display: flex;
  align-items: center;
  > div {
    display: inline-block;
    padding-left: 10px;
    padding-right: 4px;
  }
  float: right;
`
const ContainerSpectra = styled.div`
  margin-top: 20px;
  padding-top: 10px;
  box-shadow: ${(props: CompProps) =>
    (props.theme as Theme).filters.drop_shadow};
`
const LocationProjectLine = styled(LocationProject)`
  float: inherit;
`
const LocationSection = styled.div`
  font-size: 35px;
  color: #dadada;
`
const FormCalculate = styled(Form)`
  margin-bottom: 50px;
`

const GMT = styled.div`
  color: rgba(0, 0, 0, 0.46);
`
const CotaninerTimeLine = styled.div`
  box-shadow: ${(props: CompProps) =>
    (props.theme as Theme).filters.drop_shadow};
  padding: 30px;
  margin-top: 30px;
`

const HomeContainer = styled.div`
  margin-top: 45px;
`
const CityLocation = styled.div`
  div {
  }
`

const Gridrow = styled.div`
  .tableEscens:nth-child(2n) {
    /*  background: #efefef;*/
  }
`

const TimelineEditorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const TimelineLoadingContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  z-index: 100;
  padding-top: 300px;
`

const GridContainer = styled(Grid)`
  overflow-x: auto !important;
  display: block !important;
  min-height: 460px;
  width: 100%;
  position: relative;
  &&& > .row {
    display: grid;
    grid-auto-flow: column;
  }
  .dropdown {
    margin-left: auto;
    font-size: 35px;
    color: #dadada;
  }
  i.icon {
    height: initial;
  }
  .column {
    /*  width: 4.16% !important; */
    width: 250px !important;
  }
`

const FormField = styled(Form.Field)`
  .ui.input.error > input {
    background-color: #fff6f6;
    border-color: #e0b4b4;
    color: #9f3a38;
    box-shadow: none;
  }
`

const ContainerPopup = styled.div`
  /*
display:flex;
flex-direction: row;
img {
  width: auto!important
}*/
`
const PopupScene = styled(Popup)`
  /*
  max-width: 940px!important;
  width: 940px!important;*/
`

export default Timeline
