import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { CompProps, Theme } from '../../styles/theme'
import { MainButton, IconUI } from '../../styles/commons'
import logo from '../../images/kumux-logo.png'
import { sceneApi } from '../../api/SceneApi'

import ModalSemantic from '../common/ModalSemantic'
import { LoadingContainer } from '../../styles/commons'
import Loading from '../../components/common/Loading'

import { default as CoverFlow } from '../coverflow/CoverFlow'
import CoverFlowChartItem from '../coverflow/CoverFlowChartItem'
import { DoughnutChart, HCLRadarInfo } from '../charts/types'
import { ParamsInfoColumn, PDFDocTexts, defaultPDFDocTexts } from './types'
import SceneParamsTable from './SceneParamsTable'
import ScenePDFView from './ScenePDFView'
import ChartStatic from '../charts/ChartStatic'
import ReportParameters from '../../api/ReportParameters'

interface Props {
  sceneId: string
  isPublic: boolean
}

const SceneSheet: React.FC<Props> = (props) => {
  const { sceneId, isPublic } = props

  const [spectra, setspectra] = useState()
  const [spdSpectra, setspdSpectra] = useState()
  const [reference, setreference] = useState()
  const [referenceFixture, setReferenceFixture] = useState()

  const [criValues, setCriValues] = useState([])
  const [channelsValues, setChannelsValues] = useState([])
  const [saturationValuesR, setSaturationValuesR] = useState([])
  const [saturationValuesK, setSaturationValuesK] = useState([])
  const [doughnutCharts, setDoughnutCharts] = useState<DoughnutChart>()
  const [hclInfo, setHclInfo] = useState<HCLRadarInfo>()
  const [pdfTexts, setPDFTexts] = useState<PDFDocTexts>(defaultPDFDocTexts)
  const [qrImage, setQrImage] = useState<string>()

  const [infoTableColums, setInfoTableColumns] = useState<ParamsInfoColumn[]>(
    []
  )

  useEffect(() => {
    let didCancel = false
    const getSharedInfo = async () => {
      if (sceneId) {
        // TODO: Upgrade API requests to parent component
        let spectraResponse: any = {}
        let scenesQRResponse: any = {}
        if (isPublic) {
          spectraResponse = await sceneApi.getSharedInfo(sceneId)
        } else {
          spectraResponse = await sceneApi.getInfo(sceneId)
          scenesQRResponse = await sceneApi.getQR(sceneId)

          !didCancel && setQrImage(scenesQRResponse.qr_code_base64)
        }

        if (spectraResponse) {
          !didCancel && setspectra(spectraResponse.scene.data)
          !didCancel && setspdSpectra(spectraResponse.scene.spd)
          !didCancel && setCriValues(spectraResponse.scene.cri_values)
          !didCancel &&
            setChannelsValues(spectraResponse.scene.data.combination)
          !didCancel &&
            setSaturationValuesR(spectraResponse.scene.saturation_values.sat_r)
          !didCancel &&
            setSaturationValuesK(spectraResponse.scene.saturation_values.sat_k)
          !didCancel &&
            setPDFTexts({
              scene: spectraResponse.scene.reference,
              fixture: spectraResponse.scene.fixture.reference,
              cct: spectraResponse.scene.data.cct,
              duv: spectraResponse.scene.data.duv,
              rf: spectraResponse.scene.data.rf,
              rg: spectraResponse.scene.data.rg,
              cri: spectraResponse.scene.data.cri,
              r9: spectraResponse.scene.data.r9,
              coi: spectraResponse.scene.data.coi,
              x: spectraResponse.scene.data.x,
              y: spectraResponse.scene.data.y,
              mp: spectraResponse.scene.data.mp,
              mder: spectraResponse.scene.data.mder,
              cs_100: spectraResponse.scene.data.cs_100,
              cla_100: spectraResponse.scene.data.cla_100,
              eml_100: spectraResponse.scene.data.eml_100,
              medi_100: spectraResponse.scene.data.medi_100,
              ul: spectraResponse.scene.data.ul,
              efficiency_lmw: spectraResponse.scene.data.efficiency_lmw,
              pf_w: spectraResponse.scene.data.pf_w,
              luminous_flux_lm: spectraResponse.scene.data.luminous_flux_lm
            })
          !didCancel &&
            setHclInfo({
              relax: ReportParameters.get_relax(
                spectraResponse.scene.data.mp,
                spectraResponse.scene.data.rf,
                spectraResponse.scene.data.duv
              ),
              focus: ReportParameters.get_focus(
                spectraResponse.scene.data.mp,
                spectraResponse.scene.data.rf,
                spectraResponse.scene.data.duv
              ),
              natural: ReportParameters.get_natural(
                spectraResponse.scene.data.rf,
                spectraResponse.scene.data.duv,
                spectraResponse.scene.data.cct
              ),
              boost: ReportParameters.get_boost(
                spectraResponse.scene.data.mp,
                spectraResponse.scene.data.rf,
                spectraResponse.scene.data.duv
              ),
              create: ReportParameters.get_create(
                spectraResponse.scene.data.mp,
                spectraResponse.scene.data.rf,
                spectraResponse.scene.data.duv
              ),
              rgb: ReportParameters.cie_to_rgb(
                spectraResponse.scene.data.x,
                spectraResponse.scene.data.y,
                undefined
              )
            })
          !didCancel &&
            setDoughnutCharts({
              cri: {
                label: 'CRI',
                value: spectraResponse.scene.data.cri,
                max: 100,
                colors: {
                  active: '#FA7571',
                  pasive: '#FBA19F'
                }
              },
              efficiency: {
                label: 'EN',
                value: spectraResponse.scene.data.efficiency_lmw,
                max: 200,
                colors: {
                  active: '#A6C3FB',
                  pasive: '#C3D6FC'
                }
              },
              hcl: {
                label: 'MP',
                value: spectraResponse.scene.data.mp,
                max: 1,
                colors: {
                  active: '#FFC774',
                  pasive: '#FFD8A0'
                }
              }
            })
          !didCancel &&
            setInfoTableColumns([
              {
                name: 'COLORIMETRIC',
                color: '#FF7474',
                params: [
                  { name: 'CCT (K)', value: spectraResponse.scene.data.cct },
                  { name: 'Duv', value: spectraResponse.scene.data.duv },
                  { name: 'Rf', value: spectraResponse.scene.data.rf },
                  { name: 'Rg', value: spectraResponse.scene.data.rg },
                  { name: 'CRI', value: spectraResponse.scene.data.cri },
                  { name: 'R9', value: spectraResponse.scene.data.r9 },
                  { name: 'COI', value: spectraResponse.scene.data.coi },
                  { name: 'x', value: spectraResponse.scene.data.x },
                  { name: 'y', value: spectraResponse.scene.data.y }
                ]
              },
              {
                name: 'NON-VISUAL',
                color: '#FFC774',
                params: [
                  { name: 'M/P ratio', value: spectraResponse.scene.data.mp },
                  { name: 'MDER', value: spectraResponse.scene.data.mder },
                  { name: '440-490nm %', value: spectraResponse.scene.data.ul },
                  { name: 'CS @100 lx', value: spectraResponse.scene.data.cs_100 },
                  {
                    name: 'CLa @100 lx',
                    value: spectraResponse.scene.data.cla_100
                  },
                  { name: 'EML @100 lx',
                    value: (spectraResponse.scene.data.eml_100) ? spectraResponse.scene.data.eml_100 : '-' },
                  {
                    name: 'MEDI @100 lx',
                    value: (spectraResponse.scene.data.medi_100) ? spectraResponse.scene.data.medi_100 : '-'
                  }
                ]
              },
              {
                name: 'POWER',
                color: '#A5C4F7',
                params: [
                  {
                    name: 'Efficiency (lm/W)',
                    value: spectraResponse.scene.data.efficiency_lmw
                  },
                  {
                    name: 'Electrical Power (W)',
                    value: spectraResponse.scene.data.pf_w
                  },
                  {
                    name: 'Luminous Flux (lm)',
                    value: spectraResponse.scene.data.luminous_flux_lm
                  }
                ]
              }
            ])
          !didCancel && setreference(spectraResponse.scene.reference)
          !didCancel &&
            setReferenceFixture(spectraResponse.scene.fixture.reference)
        }
      }
    }
    getSharedInfo()
    return () => {
      didCancel = true
    }
  }, [isPublic, sceneId])

  if (sceneId && spectra && doughnutCharts && spdSpectra) {
    return (
      <SpectraPublicWrapper>
        <SpectraPublicHeader>
          <HeaderTitleContainer>
            <div className="title">
              {qrImage && <img alt={'QR code'} src={qrImage} />}
              <div>
                <HeaderTitle>
                  {referenceFixture && referenceFixture}
                </HeaderTitle>
                <HeaderSubTitle>{reference && reference}</HeaderSubTitle>
              </div>
            </div>
            <div>
              <img src={logo} alt="Kumux" />
            </div>
          </HeaderTitleContainer>
          <HeaderGraphicContainer>
            <ChartStatic
              chartType="spd"
              chartData={[spdSpectra.irradiance]}
              renderType="graphic"
            />
          </HeaderGraphicContainer>
        </SpectraPublicHeader>

        <SpectraPublicDoughnutSection>
          <DoughnutChartContainer>
            <ChartStatic
              chartType="info_color"
              chartOptions={doughnutCharts.cri}
              chartData={[
                [doughnutCharts.cri.value, 100 - doughnutCharts.cri.value]
              ]}
            />
            <DoughnutInfo color={doughnutCharts.cri.colors.active}>
              A minium CRI of <b>80</b> is recommended for indoor projects
            </DoughnutInfo>
          </DoughnutChartContainer>
          <DoughnutChartContainer>
            <ChartStatic
              chartType="info_hcl"
              chartOptions={doughnutCharts.hcl}
              chartData={[
                [doughnutCharts.hcl.value, 1 - doughnutCharts.hcl.value]
              ]}
            />
            <DoughnutInfo color={doughnutCharts.hcl.colors.active}>
              MP -/= 0.4
              <br />
              <b>RELAXING</b>
              <br />
              <br />
              MP +/= 0.4
              <br />
              <b>ENERGIZING</b>
            </DoughnutInfo>
          </DoughnutChartContainer>
          <DoughnutChartContainer>
            <ChartStatic
              chartType="info_energy"
              chartOptions={doughnutCharts.efficiency}
              chartData={[
                [
                  doughnutCharts.efficiency.value,
                  200 - doughnutCharts.efficiency.value
                ]
              ]}
            />
            <DoughnutInfo color={doughnutCharts.efficiency.colors.active}>
              A minimum energy efficiency of <b>100</b> lm/W is recommended
            </DoughnutInfo>
          </DoughnutChartContainer>
        </SpectraPublicDoughnutSection>

        <ParamsInfoContainer>
          <SceneParamsTable columns={infoTableColums} />
        </ParamsInfoContainer>

        <CoverflowContainer>
          <CoverFlow>
            <CoverFlowChartItem
              className="first"
              dataTitle="Color Vector Graphic"
              dataDesc="The chart illustrates the average hue and saturation changes
              in each of 16 hue bins. The reference source is normalized
              to a black circle, whereas the test source is represented by
              the red line."
              chartType="colorvector"
              chartData={[saturationValuesR, saturationValuesK]}
              renderType="image"
            />
            <CoverFlowChartItem
              className="previous"
              dataTitle="HCL"
              dataDesc="Human Centric Lighting values."
              chartType="hcl"
              chartData={[
                [
                  hclInfo?.relax,
                  hclInfo?.focus,
                  hclInfo?.natural,
                  hclInfo?.boost,
                  hclInfo?.create
                ],
                hclInfo?.rgb
              ]}
              renderType="image"
            />
            <CoverFlowChartItem
              className="selected"
              dataTitle="CIE 1931"
              dataDesc="CIE 1931 color spaces were the first defined quantitative
              links between distributions of wavelengths in the
              electromagnetic visible spectrum, and physiologically
              perceived colors in human color vision."
              chartType="cie"
              chartData={[[spectra.x, spectra.y]]}
              renderType="graphic"
            />
            <CoverFlowChartItem
              className="next"
              dataTitle="Saturation"
              dataDesc="Rf and Rg form a complimentary two-measure system, which can
              be plotted to visually illustrate the tradeoff between
              fidelity and saturation"
              chartType="cri"
              chartData={[criValues]}
              renderType="image"
            />
            <CoverFlowChartItem
              className="last"
              dataTitle="Channels Intensity"
              dataDesc="The chart illustrates yhe intensity distribution of the channels"
              chartType="channels"
              chartData={[channelsValues]}
              renderType="image"
            />
            <CoverFlowChartItem
              className="out"
              dataTitle="CRI Values"
              dataDesc="The Color Rendering Index is a quantitative measure of the ability 
              of a light source to reveal the colors of various objects."
              chartType="saturation"
              chartData={[[spectra.rf, spectra.rg]]}
              renderType="image"
            />
          </CoverFlow>
        </CoverflowContainer>

        <ButtonContainer>
          <ModalSemantic
            title="Scene PDF"
            size={!isPublic ? 'mini' : 'large'}
            trigger={
              <MainButton ref={React.createRef()}>
                <IconUI name="print" />
                PDF
              </MainButton>
            }
          >
            <ScenePDFView isDownload={!isPublic} textData={pdfTexts} />
          </ModalSemantic>
        </ButtonContainer>
      </SpectraPublicWrapper>
    )
  } else {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    )
  }
}

const SpectraPublicWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0px;
  margin: 0 auto;
`

const SpectraPublicHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%),
    #e1e1e1;
`

const HeaderTitleContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  .title {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-left: 30px;
  }
  img {
    width: 64px;
    margin-right: 30px;
  }
`

const HeaderTitle = styled.h4`
  text-transform: uppercase;
  margin: 0;
  ${(props: CompProps) => (props.theme as Theme).font_size.regular};
  color: ${(props: CompProps) => (props.theme as Theme).color.white.darkest};
  display: inline-block;
  width: 100%;
`

const HeaderSubTitle = styled.h5`
  margin-top: 0;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-size: 48px;
  line-height: 42px;
  font-family: ${(props: CompProps) => (props.theme as Theme).font.bold};
`

const HeaderGraphicContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
`

const SpectraPublicDoughnutSection = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin-top: 45px;
  padding: 0px 45px;
`

const DoughnutChartContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33%;
`

const DoughnutInfo = styled.div`
  margin: 0px 15px;
  width: 80px;
  color: ${(props: { color: string }) => props.color};
  ${(props: CompProps) => (props.theme as Theme).font_size.xxsmall};
  b {
    font-family: ${(props: CompProps) => (props.theme as Theme).font.bold};
  }
`

const ParamsInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  padding: 0px 45px;
`

const CoverflowContainer = styled.div`
  width: 100%;
  margin: 45px 0px;
`

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 90px;
  right: 60px;
  z-index: 100;
`

export default SceneSheet
