import API, { STATUS,APP_PATH_PUBLIC }   from './api'
import { AxiosResponse } from 'axios'
import Errors from './Errors'

export interface Scene {
  data: any
  public_id: string
  reference: string
  spd: {
    irradiance: []
    wavelength: []
  }
  showModal?: boolean
  deleteConfirm?: boolean
}

class SceneApi {
  getHistoricByLuminaryId = async (
    luminaryId: string | undefined
  ): Promise<any> => {
    try {
      const response: AxiosResponse = await API.get(
        `scene_get_historic/${luminaryId}`
      )

      if (response.status === STATUS.OK) {
        return response.data
      }

      return []
    } catch (error) {
      Errors.ShowError(error)

      return []
    }
  }

  createScene = async (
    luminary_id: string,
    scene_name: string,
    spectrum_id: string,
    illuminance:number[]
  ): Promise<any> => {
    try {
      const response: AxiosResponse = await API.put('spectra/create_scene', {
        luminary_id: luminary_id,
        scene_name: scene_name,
        spectrum_id: spectrum_id,
        illuminance: {
          horizontal: illuminance[0],
        vertical: illuminance[1]
        }
      })

      if (response.status === STATUS.OK) {
        return response.data.content
      }
    } catch (error) {
      Errors.ShowError(error)

      return false
    }
  }

  deleteSceneById = async (publicId: string): Promise<any> => {
    try {
      const response: AxiosResponse = await API.delete(
        `scene_delete/${publicId}`
      )

      if (response.status === STATUS.OK) {
        return response.data
      }
    } catch (error) {
      Errors.ShowError(error)

      return false
    }
  }

  getScene = async (publicId: string): Promise<any> => {
    try {
      const response: AxiosResponse = await API.get(`scene_get/${publicId}`)

      if (response.status === STATUS.OK) {
        return response.data.content
      }
    } catch (error) {
      Errors.ShowError(error)

      return false
    }
  }

  sharedScene = async (publicId: string): Promise<any> => {
    try {
      const response: AxiosResponse = await API.get(
        `scene/shared/toggle/${publicId}`
      )

      if (response.status === STATUS.OK) {
        return response.data
      }
    } catch (error) {
      Errors.ShowError(error)

      return false
    }
  }

  getQR = async (publicId: string): Promise<any> => {
    try {
      const response: AxiosResponse = await API.post(
        `scene/shared/qr/${publicId}`,  
        { app_path: APP_PATH_PUBLIC + "scene/" }
        
      )

      if (response.status === STATUS.OK) {
        return response.data.content
      }
    } catch (error) {
      Errors.ShowError(error)

      return false
    }
  }

  getSharedInfo = async (publicId: string): Promise<any> => {
    try {
      const response: AxiosResponse = await API.get(
        `scene/shared/info/${publicId}`
      )

      if (response.status === STATUS.OK) {
        return response.data.content
      }
    } catch (error) {
      Errors.ShowError(error)

      return false
    }
  }

  getInfo = async (publicId: string): Promise<any> => {
    try {
      const response: AxiosResponse = await API.get(
        `scene/info/${publicId}`
      )

      if (response.status === STATUS.OK) {
        return response.data.content
      }
    } catch (error) {
      Errors.ShowError(error)

      return false
    }
  }
}

export const sceneApi = new SceneApi()
