import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { CompProps, Theme } from '../../../styles/theme'
import logo from '../../../images/kumux-logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons'
import { Auth } from '../../../App'
import { useHistory, Link } from 'react-router-dom'

const signOutIcon = <FontAwesomeIcon icon={faSignOutAlt} />
const userIcon = <FontAwesomeIcon icon={faUser} />

interface Props {
  auth: Auth
}

const Header: React.FC<Props> = (props) => {
  const [auth, setAuth] = useState(props.auth)
  const history = useHistory()

  useEffect(() => {
    setAuth(props.auth)
  }, [auth, props.auth])

  const logOut = () => auth.signout(() => history.push('/login'))

  return (
    <HeaderWrapper>
      <HeaderContainer>
        <HeaderFlex>
          <NavLeft>
            <Link to={(auth && auth.isAuthenticated && auth.user) ? "/" : "/login"} title="Kumux.io Home">
              <ul>
                <li>
                  <AppLogo src={logo}></AppLogo>
                </li>
                <li>
                  <p>Kumux</p>
                  Version 2.9.5
                </li>
              </ul>
            </Link>
          </NavLeft>
          <NavRight>
            <ul>
              <li>
                {auth.user && (
                  <NavBarButton>
                    <Link to="/profile">
                      {userIcon}
                      <UserName>{auth.user.name}</UserName>
                    </Link>
                  </NavBarButton>
                )}
              </li>
              <li>
                {auth.user && (
                  <NavBarButton onClick={() => logOut()}>
                    {signOutIcon}
                  </NavBarButton>
                )}
              </li>
            </ul>
          </NavRight>
        </HeaderFlex>
      </HeaderContainer>
    </HeaderWrapper>
  )
}

const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 3;
`
const HeaderContainer = styled.div`
  background: ${(props: CompProps) =>
    (props.theme as Theme).color.white.regular};
  box-shadow: 0px 0px 8px 2px #bfbfbf;
  position: relative;
`
const HeaderFlex = styled.div`
  display: flex;
  height: 65px;
  align-items: center;
  justify-content: space-between;
`
const NavLeft = styled.nav`
  color: ${(props: CompProps) => (props.theme as Theme).color.black.light};
  font-size: 10px;
  text-decoration: none;
  color: black;
  font-family: 'HK Grotesk Normal';
  cursor: pointer;
  font-weight: bolder;
  ul {
    display: flex;
    li {
      margin-right: 10px;
      line-height: 10px;
      color: ${(props: CompProps) =>
        (props.theme as Theme).color.black.lightest};
    }
    p {
      margin-top: 5px;
      color: ${(props: CompProps) => (props.theme as Theme).color.black.light};
    }
    img {
      width: 45px;
    }
    a {
      text-decoration: none;
    }
  }
`

const NavRight = styled.nav`
  ul {
    display: flex;
    align-items: center;
    li {
      a {
        text-decoration: none;
        color: ${(props: CompProps) =>
          (props.theme as Theme).color.black.regular};
        &:hover {
          color: ${(props: CompProps) =>
            (props.theme as Theme).color.secondaryHover};
        }
      }
    }
  }
`

const AppLogo = styled.img`
  width: 80%;
`

const NavBarButton = styled.button`
  cursor: pointer;
  font-size: 14px;
  padding: 15px;
  height: 60px;
  min-width: 60px;
  pointer-events: auto;
  :hover * {
    color: ${(props: CompProps) =>
      (props.theme as Theme).color._900} !important;
  }
`

const UserName = styled.div`
  float: right;
  margin-left: 10px;
  text-transform: capitalize;
`

export default Header
