import React, { useEffect, useRef } from 'react'

import { Chart } from 'chart.js'
import { ChartProps } from './ChartStatic'
import backgroundSource from '../../images/cie.png'

const CIEStatic: React.FC<ChartProps> = (props) => {

    const chartCanvasRef = useRef<HTMLCanvasElement>(null)

    useEffect(() => {
        const canvas: HTMLCanvasElement | null = chartCanvasRef.current
        const loadGraphicWithBackground = () => {
            const img: HTMLImageElement = new Image()
            img.src = backgroundSource
            img.onload = () => {
                canvas && drawChart(canvas, img)
            }
        }
       
        loadGraphicWithBackground()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.chartData])

    
    const drawChart = (canvasRef: HTMLCanvasElement, backgroundImage: HTMLImageElement) => {
        const ctx: CanvasRenderingContext2D | null = canvasRef.getContext("2d")
        if(ctx) {
            const chartGraph = new Chart(ctx, {
            type: 'scatter',
            data: {
                datasets: [
                {
                    label: 'points',
                    data: [{'x': props.chartData[0][0], 'y': props.chartData[0][1]}],
                    borderWidth: 0,
                    pointBackgroundColor: 'black',
                    pointRadius: 2,
                    fill: true
                },
                {
                    label: 'plankiankLocus',
                    data: plankiankLocus,
                    borderColor: 'white',
                    borderWidth: 1,
                    pointRadius: 0,
                    fill: false,
                    showLine: true,
                    lineTension: 0
                }]
            },
            options: {
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            beginAtZero: true,
                            suggestedMin: 0,
                            suggestedMax: 0.8,
                            stepSize: 0.1,
                        },
                        scaleLabel: {
                            labelString: 'x',
                            padding: 10,
                            display: true
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            suggestedMin: 0,
                            suggestedMax: 0.9,
                            stepSize: 0.1,
                        },
                        scaleLabel: {
                            labelString: 'y',
                            padding: 10,
                            display: true
                        }
                    }]
                },
                responsive: false,
                animation: {
                    duration: 1,
                    onComplete: () => { 
                        props.callBack(chartGraph.toBase64Image())
                        Object.defineProperty(chartGraph.options.animation, 'onComplete', {value: undefined})
                    }
                },
                tooltips: {
                    filter: function(tooltipItem) {
                        const indx: number | null = tooltipItem.datasetIndex
                          ? tooltipItem.datasetIndex
                          : -1
                        if (indx > 0) {
                          return false
                        } else {
                          return true
                        }
                    }
                }
            },
            plugins: [
              {
                beforeDraw: (chart: Chart) => {
                    var ctx = chart.ctx
                    if(ctx && backgroundImage) {
                        ctx.drawImage(backgroundImage, 52, 18, 300, 272)
                    }
                }
              }
            ]
            })
        }  
    }

    const plankiankLocus: any[] = [
        [1000, 0.652750055750174, 0.34446222719737],
        [1100, 0.638755562092687, 0.356498233240403],
        [1200, 0.625043976741368, 0.367454828640607],
        [1300, 0.611630593056105, 0.377232925955066],
        [1400, 0.598520140114478, 0.385788815057729],
        [1500, 0.585716902268969, 0.393121905838739],
        [1600, 0.573228507819347, 0.39926473363742],
        [1700, 0.561066273656675, 0.40427441599657],
        [1800, 0.54924396755459, 0.408225276139745],
        [1900, 0.537776092484472, 0.411202565887823],
        [2000, 0.526676280311873, 0.41329727450763],
        [2100, 0.515956053999373, 0.414601990958848],
        [2200, 0.505624025055233, 0.415207746862562],
        [2300, 0.495685491796189, 0.415201731840687],
        [2400, 0.486142360147258, 0.414665751277374],
        [2500, 0.476993298233858, 0.413675289942435],
        [2600, 0.468234043017033, 0.41229905027829],
        [2700, 0.459857791746717, 0.410598847355503],
        [2800, 0.451855627306685, 0.408629759737106],
        [2900, 0.444216941599399, 0.406440453838584],
        [3000, 0.436929833678155, 0.404073616886221],
        [3100, 0.429981469069442, 0.401566449156763],
        [3200, 0.423358393840696, 0.398951179344833],
        [3300, 0.417046801877253, 0.396255577584578],
        [3400, 0.411032757009395, 0.393503449026874],
        [3500, 0.405302373516452, 0.390715097242847],
        [3600, 0.399841959501467, 0.387907751428262],
        [3700, 0.394638127971748, 0.38509595475246],
        [3800, 0.389677880399752, 0.382291913526683],
        [3900, 0.384948667236696, 0.37950580841094],
        [4000, 0.380438429420364, 0.376746069841299],
        [4200, 0.372029239990332, 0.371332087117875],
        [4400, 0.364364350545175, 0.366090886546814],
        [4600, 0.357366240041146, 0.361048030584121],
        [4800, 0.350965477408777, 0.356217933228418],
        [5000, 0.345100160725069, 0.35160700531884],
        [5200, 0.339715260382228, 0.347215989076895],
        [5400, 0.334761937596386, 0.343041681898789],
        [5600, 0.330196880654761, 0.339078203229392],
        [5800, 0.325981682339978, 0.335317921146619],
        [6000, 0.322082269887888, 0.331752126277376],
        [6500, 0.313524949311538, 0.323626953980771],
        [7000, 0.306372718718652, 0.316511125739794],
        [7500, 0.300333620610026, 0.310264864929518],
        [8000, 0.295186142428596, 0.304763622626521],
        [9000, 0.286924765725065, 0.295581717193809],
        [10000, 0.280632719756407, 0.288286029784579],
        [15000, 0.263705272809263, 0.267277169489418],
        [20000, 0.256456439408808, 0.257628552153879]
      ].map(point => { return {'x': point[1], 'y': point[2]}; })

    return(
        <canvas ref={chartCanvasRef} width={340} height={340} />
    )
}

export default CIEStatic
