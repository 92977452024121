// Theme

export interface Font {
  primary: string
  secondary: string
  light: string
  normal: string
  medium: string
  semibold: string
  bold: string
  extrabold: string
}

export interface FontSize {
  xxxsmall: string
  xxsmall: string
  xsmall: string
  small: string
  regular: string
  large: string
  larger: string
  xlarge: string
}

export interface Color {
  primary: string
  secondary: string
  third: string
  quarter: string
  _900: string
  _700: string
  _500: string
  _300: string
  _100: string
  fourty: string
  secondaryHover: string
  accent: string
  background: {
    white: string
    light: string
  }
  neutral: {
    _900: string
    _700: string
    _500: string
    _300: string
    _200: string
  }
  white: {
    regular: string
    lessdark: string
    dark: string
    darker: string
    darkest: string
  }
  black: {
    lightest: string
    light: string
    regular: string
  }
  forms: {
    correct: string
    error: string
    warning: string
  }
  secondaryRGBA: string
}

export interface Screen {
  xs: string
  sm: string
  md: string
  lg: string
}

export interface Filters {
  drop_shadow: string
}

export interface Theme {
  font: Font
  font_size: FontSize
  color: Color
  screen: Screen
  filters: Filters
}

export interface CompProps {
  status?: string
  theme?: Theme
}

const theme: Theme = {
  font: {
    primary: `'Roboto'`,
    secondary: `'Roboto'`,
    light: `'Roboto'`,
    normal: `'Roboto'`,
    medium: `'Roboto'`,
    semibold: `'Roboto'`,
    bold: `'Roboto'`,
    extrabold: `'Roboto'`
  },
  font_size: {
    xxxsmall: 'font-size: 12px;',
    xxsmall: 'font-size: 14px;',
    xsmall: 'font-size: 16px;',
    small: 'font-size: 17px;',
    regular: 'font-size: 18px; line-height: 24px',
    large: 'font-size: 28px; line-height: 30px',
    larger: 'font-size: 40px; line-height: 50px',
    xlarge: 'font-size: 48px; line-height: 48px'
  },

  color: {
    primary: '#071435',
    secondary: '#098c8c',
    third: '#848a85',
    quarter: '#DADADA',
    fourty: '#767676',
    _900: '#DC765F',
    _700: '#E98F7B',
    _500: '#FFA48F',
    _300: '#FFCBBF',
    _100: '#FFEAE6',
    secondaryHover: '#25aaaa',
    accent: '#cca86e',
    background: {
      white: '#ffffff',
      light: '#f8f8f8'
    },
    neutral: {
      _900: '#000000',
      _700: '#606060',
      _300: '#E4E4E4',
      _500: '#A0A0A0',
      _200: '#F8F8F8'
    },
    white: {
      regular: '#ffffff',
      lessdark: '#faf9f8',
      dark: '#f6f6f6',
      darker: '#eeeeee',
      darkest: '#cccccc'
    },
    black: {
      lightest: '#ABA8AF',
      light: '#241c15',
      regular: '#000'
    },
    forms: {
      correct: '#3ac97a',
      error: '#c93a3a',
      warning: '#cc3300'
    },
    secondaryRGBA: 'rgb(75, 163, 163, 0.5)'
  },
  screen: {
    xs: '575px',
    sm: '767px',
    md: '991px',
    lg: '1199px'
  },
  filters: {
    drop_shadow:
      '-webkit-box-shadow: 3px 10px 48px -25px rgba(0, 0, 0, 0.5);-moz-box-shadow: 3px 10px 48px -25px rgba(0, 0, 0, 0.5);box-shadow: 3px 10px 48px -25px rgba(0, 0, 0, 0.5);'
  }
}

export default theme
