import { toast } from 'react-toastify'

const ShowError = (error: string) => {
  //const { message } = error.response.data

  toast.error(error)
}

const Errors = {
  ShowError
}

export default Errors
