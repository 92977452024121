import React, { useEffect, useState } from 'react'
import Layout from './components/common/layout/layout'
import { userAPI, User } from './api/UserApi'
import { Switch, Route } from 'react-router'
import Home from './pages/Home'
import Simulation from './pages/Simulation'
import Login from './pages/Login'
import Loading from './components/common/Loading'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import Profile from './pages/Profile'
import Timeline from './pages/TimeLine'
import AnalyticsListener from './api/AnalyticsListener'
import TermsAndConditions from './pages/TermsAndConditions'
import ScenePublic from './pages/scene/ScenePublic'
import ReportTimelinePublic from './pages/ReportTimelinePublic'
import CookieConsent from './components/legal/CookieConsent'
import CookiesPolicy from './pages/legal/CookiesPolicy'
import LegalPolicy from './pages/legal/LegalPolicy'
import PrivacyPolicy from './pages/legal/PrivacyPolicy'
import Pcb from './pages/Pcb'

export interface Auth {
  isAuthenticated: boolean
  user: User | null
  checkAuthenticate: Function
  signout: Function
  changeimage: Function
  updatetab: Function
}

const authState: Auth = {
  isAuthenticated: false,
  user: null,
  async checkAuthenticate(cb: Function) {
    authState.user = await userAPI.getUser()
    if (authState.user) {
      if (authState.user !== null && authState.user.org_admin !== null) {
        if (localStorage.getItem('photo') !== null) {
          authState.user.photo = String(localStorage.getItem('photo'))
        }
      }
      if (authState.user !== null && localStorage.getItem('tab') !== null) {
        authState.user.tab = Number(localStorage.getItem('tab'))
      }

      authState.isAuthenticated = true
    } else {
      cb()
    }
  },
  signout(cb: Function) {
    authState.isAuthenticated = false
    authState.user = null
    AnalyticsListener.setId('', '')
    //localStorage.clear()
    localStorage.removeItem('photo')
    localStorage.removeItem('tab')
    userAPI.signout()
    cb()
  },
  async changeimage(image: string) {
    authState.user = await userAPI.getUser()
    if (authState.user !== null && authState.user.org_admin !== null) {
      authState.user.photo = image
      localStorage.setItem('photo', image)
    }
  },
  updatetab(tab: number) {
    if (authState.user !== null) {
      authState.user.tab = tab
      localStorage.setItem('tab', String(tab))
    }
  }
}

const App: React.FC = () => {
  const [auth, setAuth] = useState(authState)
  const [isLoading, setIsLoading] = useState(false)
  const [publicUrl, setPublicUrl] = useState(false)
  const [openCookieCosent, setOpenCookieConsent] = useState(false)
  const history = useHistory()

  useEffect(() => {
    // Activate analitycs
    AnalyticsListener.activateGA()
    // Analitycs IDs
    if (auth && auth.isAuthenticated && auth.user) {
      AnalyticsListener.setId(auth.user.public_id, auth.user.email)
    }

    //Check terms
    const hasNotTermsAndConditions = (auth: Auth): boolean | null => {
      return (
        auth.user &&
        !auth.user.confirmed_terms &&
        window.location.pathname !== '/terms-and-conditions'
      )
    }
    if (hasNotTermsAndConditions(auth)) {
      history.push('/terms-and-conditions')
    }
  })

  useEffect(() => {
    let didCancel = false

    const url = window.location.pathname.split('/')

    async function getAuth() {
      !didCancel && setIsLoading(true)
      try {
        if (url[1] !== 'public') {
          await auth.checkAuthenticate(() => history.push('/login'))
          setPublicUrl(false)
        } else {
          setPublicUrl(true)
        }
        !didCancel && setAuth(auth)
      } catch (error) {
        console.error(error)
      } finally {
        !didCancel && setIsLoading(false)
      }
    }

    getAuth()
    return () => {
      didCancel = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth])

  const toogleCookieConsent = () => {
    setOpenCookieConsent(false)
    setTimeout(() => {
      setOpenCookieConsent(true)
    }, 0)
  }

  return !isLoading ? (
    <Layout auth={auth} publicUrl={publicUrl}>
      <Switch>
        <Route exact path="/">
          <Home auth={auth}></Home>
        </Route>
        <Route exact path="/legal/cast/cookies">
          <CookiesPolicy
            lang={'cast'}
            openCookiesSettings={toogleCookieConsent}
          />
        </Route>
        <Route exact path="/legal/eng/cookies">
          <CookiesPolicy
            lang={'eng'}
            openCookiesSettings={toogleCookieConsent}
          />
        </Route>
        <Route exact path="/legal/cast/legal">
          <LegalPolicy lang={'cast'} />
        </Route>
        <Route exact path="/legal/eng/legal">
          <LegalPolicy lang={'eng'} />
        </Route>
        <Route exact path="/legal/cast/privacy">
          <PrivacyPolicy lang={'cast'} />
        </Route>
        <Route exact path="/legal/eng/privacy">
          <PrivacyPolicy lang={'eng'} />
        </Route>
        <Route exact path="/login">
          <Login auth={auth}></Login>
        </Route>
        <Route exact path="/profile">
          <Profile auth={auth}></Profile>
        </Route>
        <Route exact path="/pcb/:showTutorial">
          <Pcb auth={auth} />
        </Route>
        <Route exact path="/terms-and-conditions">
          <TermsAndConditions auth={auth}></TermsAndConditions>
        </Route>
        <Route path="/timeline/:showTutorial/:publicId/:timelineId?">
          <Timeline auth={auth}></Timeline>
        </Route>
        <Route path="/luminary/:publicId/:activeTour?">
          <Simulation auth={auth}></Simulation>
        </Route>
        <Route path="/public/1.0/scene/:publicId/">
          <ScenePublic />
        </Route>
        <Route path="/public/1.0/timeline/:publicId/">
          <ReportTimelinePublic />
        </Route>
      </Switch>
      <CookieConsent opened={openCookieCosent} isPublicUrl={publicUrl} />
    </Layout>
  ) : (
    <LoadingContainer>
      <Loading />
    </LoadingContainer>
  )
}

const LoadingContainer = styled.div`
  margin: 20% auto;
  width: 100%;
  display: flex;
  justify-content: center;
`

export default App
