import React, {
  useState,
  useEffect,
  useImperativeHandle,
  Fragment,
  forwardRef,
  Ref
} from 'react'
import styled from 'styled-components'

import {
  faChartArea,
  faPaperPlane,
  faTrash,
  faPencilAlt,
  faCopy,
  faLink
} from '@fortawesome/free-solid-svg-icons'
import {
  NoResultsContainer,
  LoaderUI,
  NoResultsTextWrapper,
  ModalPopup,
  ModalConfirm,
  Toogle
} from '../../styles/commons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CompProps, Theme } from '../../styles/theme'
import { Link } from 'react-router-dom'
import { timeLineApi, Timeline } from '../../api/TimelineApi'
import ModalCopyTimeline from './ModalCopyTimeline'
import { ToastContainer, toast } from 'react-toastify'
import ModalConfirmText from './ModalConfirmText'
import ReportTimeline from '../../pages/ReportTimeline'
import { Popup } from 'semantic-ui-react'
const TrashIcon = <FontAwesomeIcon icon={faTrash} title={'Delete timeline.'}/>
const EditIcon = <FontAwesomeIcon icon={faPencilAlt} title={'Edit timeline.'}/>
const ChartAreaIcon = <FontAwesomeIcon icon={faChartArea} title={'View timeline presentation.'}/>
const Export = <FontAwesomeIcon icon={faPaperPlane} title={'Export timeline to csv.'}/>
const copyIcon = <FontAwesomeIcon icon={faCopy} title={'Duplicate timeline.'}/>
const copyClipboard = <FontAwesomeIcon icon={faLink} title={'Copy URL.'}/>

interface Props {
  projectId: string
  refreshCallBack: Function
  ref: Ref<RefObject>
  setTourTimelineCallback: Function
  projectTimelineActive: boolean
}

interface RefObject {
  refreshList: () => void
}

const TimelineList: React.FC<Props> = forwardRef(
  (props: Props, ref: Ref<RefObject>) => {
    useImperativeHandle(ref, () => ({ refreshList }))

    const { projectId, projectTimelineActive, setTourTimelineCallback } = props

    const [tourActive, setTourActive] = useState(projectTimelineActive)

    const [TimeLines, setTimeLines] = useState<Timeline[]>([])
    const [timeLinesSelect, setTimeLinesSelect] = useState<Timeline | any>()
    const [Isloading, setIsloading] = useState<boolean>()
    const [IsCopy, setIsCopy] = useState<boolean>()
    const [showReport, setshowReport] = useState<boolean>(false)
    const [updateTimeline, setupdateTimeline] = useState<boolean>()

    useEffect(() => {
      let didCancel = false
      const GetTimeLines = async () => {
        !didCancel && setIsloading(true)
        if (projectId) {
          const TimeLines: Array<Timeline> = await timeLineApi.getHistoric(
            projectId
          )
          !didCancel && setTimeLines(TimeLines)
          !didCancel && setIsloading(false)
          if (TimeLines.length === 0) {
            !didCancel && setTourTimelineCallback(true)
          }
          if (TimeLines.length !== 1) {
            !didCancel && setTourActive(false)
          }
        }
      }
      GetTimeLines()
      return () => {
        didCancel = true
      }
    }, [projectId, updateTimeline, setTourTimelineCallback])

    const deleteTimeline = (index: number) => {
      const itemsAux = TimeLines.map((item: Timeline, i: number) => {
        if (i === index) {
          item.deleteConfirm = true
        }

        return item
      })
      setTimeLines(itemsAux)
    }
    const copyClipBoard = async (item: Timeline) => {
      const ResponseQR = await timeLineApi.getQR(projectId, item.id)
      if (ResponseQR) {
        const hidden = document.createElement('input')
        document.body.appendChild(hidden)
        hidden.setAttribute('type', 'text')
        hidden.setAttribute('name', 'url')
        hidden.setAttribute(
          'style',
          'position: absolute; left: -1000px; top: -1000px'
        )
        hidden.setAttribute('value', ResponseQR.qr_code_url)
        hidden.select()
        document.execCommand('copy')
      }
    }

    const closeDeleteTimeline = () => {
      const itemsAux = TimeLines.map((item: Timeline, i: number) => {
        item.deleteConfirm = false

        return item
      })
      setTimeLines(itemsAux)
    }

    const acceptDeleteTimeline = async () => {
      const TimelinetoDelete = TimeLines.find(
        (item: any) => item.deleteConfirm === true
      )

      if (TimelinetoDelete) {
        const response = await timeLineApi.DeleteTimelinebyID(
          projectId,
          TimelinetoDelete.id
        )

        if (response) {
          const itemsAux = TimeLines.filter(
            (item: any) => item.deleteConfirm !== true
          )

          setTimeLines(itemsAux)
        }
      }
    }

    const handleCopyTimeline = (timelistSelect: Timeline) => {
      setIsCopy(true)

      setTimeLinesSelect(timelistSelect)
    }

    const handleReportTimeline = (timelistSelect: Timeline) => {
      setshowReport(true)

      setTimeLinesSelect(timelistSelect)
    }

    const closeModal = () => {
      // setupdateTimeline(!updateTimeline)
      setIsCopy(false)
    }
    const closeModalReport = () => {
      // setupdateTimeline(!updateTimeline)
      setshowReport(false)
    }

    const refreshList = () => {
      setupdateTimeline(!updateTimeline)
    }

    const copySuccess = (projectId: string) => {
      toast.success('Your Timeline could be successfully copied!')
      props.refreshCallBack(projectId)
      setIsCopy(false)
    }

    const exportTimeline = (
      projectId: string,
      timelineId: string,
      timelineReference: string
    ) => {
      timeLineApi.exportTimeline(projectId, timelineId, timelineReference)
    }

    const HandleToogle = async (timelineToggle: Timeline) => {
      const response = await timeLineApi.sharedTimeline(
        projectId,
        timelineToggle.id
      )
      if(response) {
        let timelinesAux = Object.assign([], TimeLines)
        timelinesAux.forEach((timeline: Timeline) => {
          if (timeline.id === timelineToggle.id) {
            timeline.shared = !timeline.shared
          }
        })
        setTimeLines(timelinesAux)
      }
    }

    return (
      <>
        {!Isloading && TimeLines.length !== 0 ? (
          <TableProject>
            <thead>
              <tr>
                <th>Reference</th>
                <th>Fixture</th>
                <th>Date</th>
                <th>Time</th>
                <th>Public</th>

                {/* <th>Data</th> */}
              </tr>
            </thead>
            <tbody>
              {TimeLines &&
                TimeLines.length !== 0 &&
                TimeLines.map((item: Timeline, i: number) => {
                  return (
                    <Fragment key={i}>
                      <tr>
                        <td>{item.reference}</td>
                        <td>{item.fixture}</td>
                        <td>
                          {new Intl.DateTimeFormat('es-ES', {
                            year: 'numeric',
                            month: '2-digit'
                          }).format(new Date(item.datetime_unix * 1000))}
                        </td>
                        <td>
                          {item.start_time}:00 - {item.end_time}:00{' '}
                          {`${item.gmt >= 0 ? ' (+' : ' ('}${item.gmt})`}
                        </td>
                        <td>
                          {' '}
                          <Toogle
                            toggle
                            checked={item.shared && item.shared}
                            onChange={() => HandleToogle(item)}
                            disabled={!item.report}
                          />
                        </td>

                        <ColumnIcon>
                          <Popup
                            content="Shared URL copied to the clipboard!"
                            trigger={
                              <div
                                style={
                                  item.shared
                                    ? { visibility: 'inherit' }
                                    : { visibility: 'hidden' }
                                }
                              >
                                {copyClipboard}
                              </div>
                            }
                            on="click"
                            onOpen={() => copyClipBoard(item)}
                          />
                        </ColumnIcon>

                        {/*   
                        {' '}
                        <div>Well {CircleIcon} </div>
                      </ColumnData>
                      <ColumnData>
                        {' '}
                        <div> Color {CircleIcon} </div>
                      </ColumnData>
                      <ColumnData>
                        {' '}
                        <div>Efficieny {CircleIcon} </div>
                      </ColumnData> */}
                        {item.report ? (
                          <ColumnIcon
                            onClick={() => handleReportTimeline(item)}
                            className="active"
                          >
                            {ChartAreaIcon}
                          </ColumnIcon>
                        ) : (
                          <ColumnIconDisabled>
                            {ChartAreaIcon}
                          </ColumnIconDisabled>
                        )}

                        <ColumnIcon
                          className="active"
                          onClick={() =>
                            exportTimeline(projectId, item.id, item.reference)
                          }
                        >
                          {' '}
                          {Export}
                        </ColumnIcon>
                        <ColumnIcon>
                          <Link
                            className="active"
                            to={`timeline/${tourActive ? 1 : 0}/${projectId}/${
                              item.id
                            }`}
                          >
                            {EditIcon}
                          </Link>
                        </ColumnIcon>
                        <ColumnIcon
                          onClick={() => handleCopyTimeline(item)}
                          className="active"
                        >
                          {copyIcon}
                        </ColumnIcon>
                        <ColumnIcon onClick={() => deleteTimeline(i)}>
                          <Link to={'#'} className="active">
                            {TrashIcon}{' '}
                          </Link>
                        </ColumnIcon>
                      </tr>
                      {item.deleteConfirm && (
                        <ModalConfirm
                          size="tiny"
                          closeIcon
                          open={item.deleteConfirm}
                          onClose={() => closeDeleteTimeline()}
                        >
                          <ModalConfirmText
                            closeModal={closeDeleteTimeline}
                            message="Are you sure you want to delete this Timeline?"
                            acceptConfirm={acceptDeleteTimeline}
                          />
                        </ModalConfirm>
                      )}
                    </Fragment>
                  )
                })}
            </tbody>
          </TableProject>
        ) : (
          <></>
        )}
        {Isloading && <LoaderUI active inline="centered" />}
        {!Isloading && TimeLines.length === 0 && (
          <NoResultsContainer>
            <NoResultsTextWrapper className="n1ew-timeline-list">
              You have not added any Timeline yet.
            </NoResultsTextWrapper>
          </NoResultsContainer>
        )}
        <ModalPopup
          size="mini"
          closeIcon
          onClose={() => closeModal()}
          onOpen={() => setIsCopy(true)}
          open={IsCopy}
        >
          <ModalCopyTimeline
            copySuccess={(projectId: string) => {
              copySuccess(projectId)
            }}
            projectId={projectId}
            timeLineSelect={timeLinesSelect}
          />
        </ModalPopup>
        <ModalPopup
          size="small"
          closeIcon
          onClose={() => closeModalReport()}
          onOpen={() => setshowReport(true)}
          open={showReport}
        >
          <ReportTimeline
            showTutorial={false}
            publicId={projectId}
            shared={timeLinesSelect && timeLinesSelect.shared}
            timelineId={timeLinesSelect && timeLinesSelect.id}
          />
        </ModalPopup>
        <ToastContainer />
      </>
    )
  }
)

const TableProject = styled.table`
  thead {
    border-bottom: 1px solid #e8e8e8;
  }
  th {
    font-family: ${(props: CompProps) => (props.theme as Theme).font.light};
    ${(props: CompProps) => (props.theme as Theme).font_size.xxsmall};
    padding-bottom: 2px;
    font-weight: bold;
  }
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;
  th {
    text-align: left;
    padding: 10px;
  }
  td {
    padding-bottom: 15px;
    /* padding: 10px; */
    color: #071435;
    margin-bottom: 10px;
    padding-top: 15px;
    padding-left: 10px;
    color: #071435;
  }
  td:nth-child(1) {
    width: 5%;
  }
  td:nth-child(2) {
    width: 5%;
  }
  td:nth-child(3) {
    width: 5%;
  }
  td:nth-child(4) {
    width: 5%;
  }
  td:nth-child(5) {
    width: 2%;
  }
  td:nth-child(6) {
    width: 2%;
  }
  td:nth-child(7) {
    width: 2%;
  }
  td:nth-child(8) {
    width: 2%;
  }
  td:nth-child(9) {
    width: 1%;
  }
  td:nth-child(10) {
    width: 1%;
  }
  td:nth-child(11) {
    width: 1%;
  }
  td:nth-child(12) {
    width: 1%;
  }
  tbody tr:nth-child(2n) {
    border-bottom: 0;
    /*  background-color: #f1f1f1; */
    background-color: #e4e4e4;
  }
  margin-top: 25px;
`

const ColumnIcon = styled.td`
  svg {
   /* color: #dadada; */
   color: #000000

    font-size: 20px;
  }
   
svg:hover {
    color: ${(props: CompProps) => (props.theme as Theme).color._900};
    cursor: pointer;
  }
 
   .noactive svg:hover {
     color: initial!important;
    cursor: initial!important;
  }


}
`

const ColumnIconDisabled = styled.td`
  svg {
    color: #dadada;
    font-size: 20px;
  }
`

export default TimelineList
