import React, { useState, useEffect } from 'react'
import { Tab } from 'semantic-ui-react'
import styled from 'styled-components'
import CIEScatterPlot from '../charts/CIEScatterPlot'
import Loading from '../common/Loading'

import { CompProps, Theme } from '../../styles/theme'

import { FilterDiagram } from './types'
import ImageSection from './ImageSection'
import { TabUI } from '../../styles/commons'

interface Props {
  filtersDiagrams?: Array<FilterDiagram>
  dynamicCie: any
  loading: boolean
}

const DiagramChart: React.FC<Props> = (props) => {
  const { filtersDiagrams, dynamicCie, loading } = props

  const [panes, setpanes] = useState<any[]>([])
  useEffect(() => {
    if (filtersDiagrams) {
      const panesAux: any[] = Object.assign([], [])
      panesAux.push({
        menuItem: 'CIE 1931',
        render: () => (
          <Tab.Pane loading={loading}>
            {dynamicCie && <CIEScatterPlot xys={dynamicCie} />}
          </Tab.Pane>
        )
      })
      filtersDiagrams.map((diagram: FilterDiagram, index: number) =>
        panesAux.push({
          menuItem: diagram.title,
          render: () => (
            <Tab.Pane>
              <ImageSection
                key={index}
                title={diagram.title}
                src={diagram.src}
              />
            </Tab.Pane>
          )
        })
      )
      setpanes(panesAux)
    }
  }, [filtersDiagrams, dynamicCie, loading])

  return (
    <DiagramChartsContainer>
      {' '}
      {panes && panes.length > 0! && !loading ? (
        <TabUIDiagrams panes={panes} />
      ) : (
        <LoadingCieContainer>
          <Loading />
        </LoadingCieContainer>
      )}{' '}
    </DiagramChartsContainer>
  )
}

const DiagramChartsContainer = styled.div`
  ${(props: CompProps) => (props.theme as Theme).filters.drop_shadow};
`

const TabUIDiagrams = styled(TabUI)`
  height: 368px;
  width: 320px;
  .ui.tabular.menu .active.item {
    margin-bottom: inherit;
  }
  &&&&& .item {
    padding: 3px !important;
    ${(props: CompProps) => (props.theme as Theme).font_size.xxxsmall};
  }
  .segment.active {
    margin-left: 25px !important;
  }
`

const LoadingCieContainer = styled.div`
  height: 368px;
`
export default DiagramChart
