import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { CompProps, Theme } from '../../styles/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import { Chart } from 'chart.js'

// Esto se corresponde con la respuesta "dinamic_charts": { "xys": [[x,y]] }
// dentro del endpoint /results_simulation

interface Props {
  xys: Array<Array<number>>
}

const CIEScatterPlot: React.FC<Props> = props => {
  const [xys] = useState(props.xys)
  const [canvasNeedsUpdate, setCanvasNeedsUpdate] = useState(false)

  const chartCanvasContainerRef = useRef<HTMLDivElement>(null)
  const backgroundCanvasRef = useRef<HTMLCanvasElement>(null)

  // Lifecycle hook

  useEffect(() => {
    drawChart(1, backgroundCanvasRef)
    // eslint-disable-next-line
  }, [canvasNeedsUpdate])

  // Zoom controls

  const zoomLevels = [
    { basesize: 345, minX: 0.0, maxX: 0.9, minY: 0.0, maxY: 0.9 },
    { basesize: 345, minX: 0.1, maxX: 0.8, minY: 0.1, maxY: 0.8 },
    { basesize: 345, minX: 0.2, maxX: 0.7, minY: 0.2, maxY: 0.7 },
    { basesize: 345, minX: 0.3, maxX: 0.6, minY: 0.3, maxY: 0.6 },
    { basesize: 345, minX: 0.3, maxX: 0.5, minY: 0.3, maxY: 0.5 }
  ]

  let zoomLevel: number = 1
  let maxZoomLevel: number = zoomLevels.length - 1

  const zoomInIcon = <FontAwesomeIcon icon={faPlusCircle} />
  const zoomOutIcon = <FontAwesomeIcon icon={faMinusCircle} />

  const zoomIn = () => {
    if (zoomLevel === maxZoomLevel) {
      return
    }

    zoomLevel += 1
    drawChart(zoomLevel, backgroundCanvasRef)
  }

  const zoomOut = () => {
    if (zoomLevel === 0) {
      return
    }

    zoomLevel -= 1
    drawChart(zoomLevel, backgroundCanvasRef)
  }

  const drawChart = (
    zoomLevel: number,
    bckCanvasRef: React.RefObject<HTMLCanvasElement>
  ) => {
    // Plankian locus
    const locus: Array<Array<number>> = [
      [1000, 0.652750055750174, 0.34446222719737],
      [1100, 0.638755562092687, 0.356498233240403],
      [1200, 0.625043976741368, 0.367454828640607],
      [1300, 0.611630593056105, 0.377232925955066],
      [1400, 0.598520140114478, 0.385788815057729],
      [1500, 0.585716902268969, 0.393121905838739],
      [1600, 0.573228507819347, 0.39926473363742],
      [1700, 0.561066273656675, 0.40427441599657],
      [1800, 0.54924396755459, 0.408225276139745],
      [1900, 0.537776092484472, 0.411202565887823],
      [2000, 0.526676280311873, 0.41329727450763],
      [2100, 0.515956053999373, 0.414601990958848],
      [2200, 0.505624025055233, 0.415207746862562],
      [2300, 0.495685491796189, 0.415201731840687],
      [2400, 0.486142360147258, 0.414665751277374],
      [2500, 0.476993298233858, 0.413675289942435],
      [2600, 0.468234043017033, 0.41229905027829],
      [2700, 0.459857791746717, 0.410598847355503],
      [2800, 0.451855627306685, 0.408629759737106],
      [2900, 0.444216941599399, 0.406440453838584],
      [3000, 0.436929833678155, 0.404073616886221],
      [3100, 0.429981469069442, 0.401566449156763],
      [3200, 0.423358393840696, 0.398951179344833],
      [3300, 0.417046801877253, 0.396255577584578],
      [3400, 0.411032757009395, 0.393503449026874],
      [3500, 0.405302373516452, 0.390715097242847],
      [3600, 0.399841959501467, 0.387907751428262],
      [3700, 0.394638127971748, 0.38509595475246],
      [3800, 0.389677880399752, 0.382291913526683],
      [3900, 0.384948667236696, 0.37950580841094],
      [4000, 0.380438429420364, 0.376746069841299],
      [4200, 0.372029239990332, 0.371332087117875],
      [4400, 0.364364350545175, 0.366090886546814],
      [4600, 0.357366240041146, 0.361048030584121],
      [4800, 0.350965477408777, 0.356217933228418],
      [5000, 0.345100160725069, 0.35160700531884],
      [5200, 0.339715260382228, 0.347215989076895],
      [5400, 0.334761937596386, 0.343041681898789],
      [5600, 0.330196880654761, 0.339078203229392],
      [5800, 0.325981682339978, 0.335317921146619],
      [6000, 0.322082269887888, 0.331752126277376],
      [6500, 0.313524949311538, 0.323626953980771],
      [7000, 0.306372718718652, 0.316511125739794],
      [7500, 0.300333620610026, 0.310264864929518],
      [8000, 0.295186142428596, 0.304763622626521],
      [9000, 0.286924765725065, 0.295581717193809],
      [10000, 0.280632719756407, 0.288286029784579],
      [15000, 0.263705272809263, 0.267277169489418],
      [20000, 0.256456439408808, 0.257628552153879]
    ]

    const config = zoomLevels[zoomLevel]

    // Draw the CIE image in the background

    const bckCanvas: HTMLCanvasElement | null = bckCanvasRef.current
      ? bckCanvasRef.current
      : null
    const bckCtx: CanvasRenderingContext2D | null = bckCanvasRef.current
      ? bckCanvasRef.current.getContext('2d')
      : null

    if (bckCanvas && bckCtx) {
      bckCtx.clearRect(0, 0, 400, 400)
      draw_CIE1931(
        bckCtx,
        config.minX,
        config.maxY,
        config.basesize,
        config.minY,
        config.maxY,
        config.basesize
      )

      bckCtx.clearRect(337, 0, 345, 400)

      // x_min: number, x_max: number, x_res: number, y_min: number, y_max: number, y_res: number
      /*
            let img = new Image()
            img.onload = function() {
                const imgSize = config.basesize * config.scale;
                bckCtx.drawImage(img, config.x_pos, config.y_pos, imgSize, imgSize * (img.height / img.width));
              
            }
    
            img.src = 'cie.png'
            */
    } else {
      console.error('Canvas element not found')
    }

    // We need to completly remove previous chart

    const canvas: HTMLElement | null = document.getElementById(
      'cie_scatter_plot'
    )
    if (canvas) {
      canvas.remove()
    }

    const canvas_new: HTMLCanvasElement = document.createElement('canvas')
    const canvasContainer: HTMLDivElement | null = chartCanvasContainerRef.current
      ? chartCanvasContainerRef.current
      : null

    if (canvas_new && canvasContainer) {
      canvas_new.id = 'cie_scatter_plot'
      canvasContainer.append(canvas_new)
    }

    if (canvas && canvas_new && canvasNeedsUpdate) {
      // Structure Spectra
      const spectra: Array<object> = xys.map(coord => {
        return { x: coord[0], y: coord[1] }
      })

      // Structure Locus
      // TODO: Obtain it structured front-end
      const locus_clean = []
      for (var i = 0; i < locus.length; i++) {
        let p = { x: locus[i][1], y: locus[i][2] }
        locus_clean.push(p)
      }

      // Render the graphic
      const ctx: CanvasRenderingContext2D | null = canvas_new
        ? canvas_new.getContext('2d')
        : null
      if (!ctx) return

      new Chart(ctx, {
        type: 'scatter',
        data: {
          datasets: [
            {
              label: 'Scatter Dataset',
              data: spectra,
              fill: false,
              pointRadius: 1,
              borderWidth: 0,
              borderColor: 'transparent',
              backgroundColor: 'rgba(0, 0, 0, 1)'
            },
            {
              label: 'Scatter Dataset',
              data: locus_clean,
              pointRadius: 0,
              borderWidth: 1.5,
              backgroundColor: 'rgb(0, 0, 0, 0.25)',
              borderColor: 'rgb(0, 0, 0, 0.25)',
              fill: false,
              showLine: true
            }
          ]
        },
        options: {
          animation: {
            duration: 0
          },
          responsive: false,
          maintainAspectRatio: true,
          aspectRatio: 1,
          legend: {
            display: false
          },
          scales: {
            xAxes: [
              {
                type: 'linear',
                position: 'bottom',
                gridLines: {
                  drawBorder: false
                },
                ticks: {
                  min: config.minX,
                  max: config.maxX,
                  stepSize: 0.1,
                  padding: 10
                }
              }
            ],
            yAxes: [
              {
                type: 'linear',
                position: 'bottom',
                gridLines: {
                  drawBorder: false
                },
                ticks: {
                  min: config.minY,
                  max: config.maxY,
                  stepSize: 0.1,
                  padding: 10
                }
              }
            ]
          },
          tooltips: {
            filter: function(tooltipItem) {
              const indx: number | null = tooltipItem.datasetIndex
                ? tooltipItem.datasetIndex
                : -1
              if (indx > 0) {
                return false
              } else {
                return true
              }
            }
          }
        }
      })
    } else {
      console.warn('Inefficient canvas update')
    }

    // TODO: Without this useEffect gets callled 3 times ¿?
    setCanvasNeedsUpdate(true)
  }

  const draw_CIE1931 = function(
    ctx: CanvasRenderingContext2D,
    x_min: number,
    x_max: number,
    x_res: number,
    y_min: number,
    y_max: number,
    y_res: number
  ) {
    for (let y: number = 0; y <= y_res; y++) {
      for (let x: number = 0; x <= x_res; x++) {
        // mapping pixelX and pixelY with coordX and coordY (y = m*x + n)
        var coordX = ((x_max - x_min) / x_res) * x
        var coordY = 1 - ((y_max - y_min) / y_res) * y
        coordX = coordX + x_min
        coordY = coordY - (1 - y_max)
        if (check_zone(coordX, coordY)) {
          var rgb = cie_to_rgb(coordX, coordY, (1 - coordX - coordY) * 255)
          rgb = normalize_values(rgb)
          ctx.fillStyle = 'rgb(' + rgb[0] + ',' + rgb[1] + ',' + rgb[2] + ')'
          ctx.fillRect(x, y, 1, 1)
        }
      }
    }
  }

  const cie_to_rgb = function(x: number, y: number, brightness: number) {
    //Set to maximum brightness if no custom value was given (Not the slick ECMAScript 6 way for compatibility reasons)
    if (brightness === undefined) {
      brightness = 254
    }

    const z: number = 1.0 - x - y
    const Y: number = parseFloat((brightness / 254).toFixed(2))
    const X = (Y / y) * x
    const Z = (Y / y) * z

    //Convert to RGB using Wide RGB D65 conversion
    let red = X * 1.656492 - Y * 0.354851 - Z * 0.255038
    let green = -X * 0.707196 + Y * 1.655397 + Z * 0.036152
    let blue = X * 0.051713 - Y * 0.121364 + Z * 1.01153

    //If red, green or blue is larger than 1.0 set it back to the maximum of 1.0
    if (red > blue && red > green && red > 1.0) {
      green = green / red
      blue = blue / red
      red = 1.0
    } else if (green > blue && green > red && green > 1.0) {
      red = red / green
      blue = blue / green
      green = 1.0
    } else if (blue > red && blue > green && blue > 1.0) {
      red = red / blue
      green = green / blue
      blue = 1.0
    }

    //Reverse gamma correction
    red =
      red <= 0.0031308
        ? 12.92 * red
        : (1.0 + 0.055) * Math.pow(red, 1.0 / 2.4) - 0.055
    green =
      green <= 0.0031308
        ? 12.92 * green
        : (1.0 + 0.055) * Math.pow(green, 1.0 / 2.4) - 0.055
    blue =
      blue <= 0.0031308
        ? 12.92 * blue
        : (1.0 + 0.055) * Math.pow(blue, 1.0 / 2.4) - 0.055

    //Convert normalized decimal to decimal
    red = Math.round(red * 255)
    green = Math.round(green * 255)
    blue = Math.round(blue * 255)

    if (isNaN(red)) red = 0

    if (isNaN(green)) green = 0

    if (isNaN(blue)) blue = 0

    return [red, green, blue]
  }

  const normalize_values = function(rgb: Array<number>) {
    rgb = filter_values(rgb)
    var maximum = Math.max(rgb[0], rgb[1], rgb[2])
    for (let i: number = 0; i < 3; i++) {
      rgb[i] = (rgb[i] / maximum) * 255
    }
    return rgb
  }

  const filter_values = function(rgb: Array<number>) {
    if (rgb[0] < 0) {
      rgb[0] = 0
    }
    if (rgb[1] < 0) {
      rgb[1] = 0
    }
    if (rgb[2] < 0) {
      rgb[2] = 0
    }
    if (rgb[0] > 255) {
      rgb[0] = 255
    }
    if (rgb[1] > 255) {
      rgb[1] = 255
    }
    if (rgb[2] > 255) {
      rgb[2] = 255
    }
    return rgb
  }

  const check_zone = function(x_: number, y_: number) {
    // zone 1
    if (x_ >= 0.1732 && y_ >= 0.6268) {
      let y_lim = 0.89141419 - 0.41066632 * x_ - 0.82737505 * x_ ** 2
      if (y_ < y_lim) {
        return true
      }
      return false
    }

    // zone 2
    if (x_ <= 0.1732 && x_ >= 0.0716 && y_ >= 0.6268) {
      let y_lim =
        0.75391568 +
        2.46122058 * x_ -
        24.78817264 * x_ ** 2 +
        91.93435911 * x_ ** 3 -
        133.06163745 * x_ ** 4
      if (y_ < y_lim) {
        return true
      }
      return false
    }

    // zone 3
    if (x_ <= 0.0716 && y_ >= 0.7608) {
      let y_lim =
        6.78064408e-1 +
        6.91390679 * x_ -
        1.30905802e2 * x_ ** 2 +
        1.2829134e3 * x_ ** 3 -
        5.32578018e3 * x_ ** 4
      if (y_ < y_lim) {
        return true
      }
      return false
    }

    // zone 4
    if (x_ <= 0.0716 && y_ <= 0.7608 && y_ >= 0.6268) {
      let y_lim =
        5.68670806e-1 +
        2.98824938e1 * x_ -
        1.7313844e3 * x_ ** 2 +
        3.75489941e4 * x_ ** 3
      if (y_ < y_lim) {
        return true
      }
      return false
    }

    // zone 5
    if (x_ <= 0.1732 && y_ <= 0.6268 && y_ >= 0.056) {
      let y_lim =
        6.80842794e-1 -
        2.36574943e1 * x_ +
        9.7862847e2 * x_ ** 2 -
        2.88097758e4 * x_ ** 3 +
        4.91379747e5 * x_ ** 4 -
        4.71917698e6 * x_ ** 5 +
        2.37211779e7 * x_ ** 6 -
        4.85047954e7 * x_ ** 7
      if (y_ > y_lim) {
        return true
      }
      return false
    }

    // zone 6
    if (x_ >= 0.1732 && y_ <= 0.2656) {
      let y_lim = -0.07458698 + 0.45766823 * x_
      if (y_ > y_lim) {
        return true
      }
      return false
    }

    // zone 7
    if (x_ >= 0.1732 && y_ >= 0.2656 && y_ <= 0.6268) {
      let y_lim = 0.99159183 - 0.97852372 * x_ - 0.01488392 * x_ ** 2
      if (y_ < y_lim) {
        return true
      }
      return false
    }

    // zone 8
    if (x_ <= 0.1732 && y_ <= 0.056) {
      let y_lim =
        1.00314815 -
        16.44535988 * x_ +
        95.04728081 * x_ ** 2 -
        192.91600091 * x_ ** 3
      if (y_ > y_lim) {
        return true
      }
      return false
    }
  }

  return (
    <CIEScatterPlotWrapper>
      <CIEScatterPlotContainer>
        <CIEScatterPlotCanvas ref={chartCanvasContainerRef}>
          <canvas id='cie_scatter_plot' />
          <canvas
            id='cie_background'
            width='400'
            height='400'
            ref={backgroundCanvasRef}
          />
        </CIEScatterPlotCanvas>
        <CIEScatterPlotIcons>
          <ZoomInIconWrapper onClick={() => zoomIn()}>
            {zoomInIcon}
          </ZoomInIconWrapper>
          <ZoomOutIconWrapper onClick={() => zoomOut()}>
            {zoomOutIcon}
          </ZoomOutIconWrapper>
        </CIEScatterPlotIcons>
      </CIEScatterPlotContainer>
    </CIEScatterPlotWrapper>
  )
}

const CIEScatterPlotWrapper = styled.div`
  position: relative;
  top: 0;
  z-index: 2;
`
const CIEScatterPlotContainer = styled.div`
  display: block;
  overflow: hidden;
`

const CIEScatterPlotCanvas = styled.div`
  cursor: pointer;
  canvas#cie_scatter_plot {
    width: 272px !important;
    height: 272px !important;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 4;
  }
  canvas#cie_background {
    width: 271px !important;
    height: 271px !important;
    margin-left: 43px;
    margin-top: 7px;
  }
`

const CIEScatterPlotIcons = styled.div`
  position: absolute;
  left: 285px;
  top: 10px;
  z-index: 4;
`

const ZoomInIconWrapper = styled.div`
  cursor: pointer;
  margin-bottom: 10px;
  color: gray;
  :hover svg {
    color: ${(props: CompProps) => (props.theme as Theme).color.secondary};
  }
`
const ZoomOutIconWrapper = styled.div`
  cursor: pointer;
  color: gray;
  :hover svg {
    color: ${(props: CompProps) => (props.theme as Theme).color.secondary};
  }
`

export default CIEScatterPlot
