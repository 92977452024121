import React from 'react'
import styled from 'styled-components'
import { CompProps, Theme } from '../../styles/theme'

interface Props {
    lang: string
}
  
const LegalPolicy: React.FC<Props> = (props: Props) => {
return (
    <ConditionsSection>
        {(props.lang === 'cast') && (
        <>
            <ConditionsTitle>Aviso legal</ConditionsTitle>
            <ConditionsSectionTitle>LEY DE LOS SERVICIOS DE LA SOCIEDAD DE LA INFORMACIÓN (LSSI)</ConditionsSectionTitle>
            <ConditionsSectionText>
                ENLIGHTING TECHNOLOGIES SL, responsable del sitio web, en adelante RESPONSABLE, pone a
                disposición de los usuarios el presente documento, con el que pretende dar cumplimiento a las
                obligaciones dispuestas en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la
                Información y de Comercio Electrónico (LSSICE), BOE N.o 166, así como informar a todos los usuarios
                del sitio web respecto a cuáles son las condiciones de uso.
            </ConditionsSectionText>
            <ConditionsSectionText>
                Toda persona que acceda a este sitio web asume el papel de usuario, comprometiéndose a la
                observancia y cumplimiento riguroso de las disposiciones aquí dispuestas, así como a cualquier otra
                disposición legal que fuera de aplicación.
            </ConditionsSectionText>
            <ConditionsSectionText>
                ENLIGHTING TECHNOLOGIES SL se reserva el derecho de modificar cualquier tipo de información
                que pudiera aparecer en el sitio web, sin que exista obligación de preavisar o poner en conocimiento
                de los usuarios dichas obligaciones, entendiéndose como suficiente la publicación en el sitio web de
                ENLIGHTING TECHNOLOGIES SL.
            </ConditionsSectionText>
            <ConditionsSectionTitle>
                1. DATOS IDENTIFICATIVOS
            </ConditionsSectionTitle>
            <ConditionsSectionText>
                Nombre de dominio: https://kumux.io/<br/>
                Nombre comercial: KUMUX<br/>
                Denominación social: ENLIGHTING TECHNOLOGIES SL<br/>
                NIF: B67033357<br/>
                Domicilio social: Departament d'Enginyeries: Electrònica, Universitat de Barcelona carrer Martí
                Franquès, 1 08028 Barcelona<br/>
                Teléfono: 934039147<br/>
                e-mail: adria@enlighting-tech.com<br/>
                Inscrita en el Registro (Mercantil / Público): Registro Mercantil de Barcelona, Boletín 164, Referencia
                350678, Tomo 46069, Folio 214, Sección 8, Hoja 508497, Inscripción 1.
            </ConditionsSectionText>
            <ConditionsSectionTitle>
                2. DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL
            </ConditionsSectionTitle>
            <ConditionsSectionText>
                El sitio web, incluyendo a título enunciativo pero no limitativo su programación, edición, compilación y
                demás elementos necesarios para su funcionamiento, los diseños, logotipos, texto y/o gráficos, son
                propiedad del RESPONSABLE o, si es el caso, dispone de licencia o autorización expresa por parte de
                los autores. Todos los contenidos del sitio web se encuentran debidamente protegidos por la normativa
                de propiedad intelectual e industrial, así como inscritos en los registros públicos correspondientes.
                Independientemente de la finalidad para la que fueran destinados, la reproducción total o parcial, uso,
                explotación, distribución y comercialización, requiere en todo caso la autorización escrita previa por
                parte del RESPONSABLE. Cualquier uso no autorizado previamente se considera un incumplimiento
                grave de los derechos de propiedad intelectual o industrial del autor.
            </ConditionsSectionText>
            <ConditionsSectionText>
                Los diseños, logotipos, texto y/o gráficos ajenos al RESPONSABLE y que pudieran aparecer en el sitio
                web, pertenecen a sus respectivos propietarios, siendo ellos mismos responsables de cualquier posible 
                controversia que pudiera suscitarse respecto a los mismos. El RESPONSABLE autoriza expresamente
                a que terceros puedan redirigir directamente a los contenidos concretos del sitio web, y en todo caso
                redirigir al sitio web principal de https://kumux.io/.
             </ConditionsSectionText>
             <ConditionsSectionText>
                El RESPONSABLE reconoce a favor de sus titulares los correspondientes derechos de propiedad
                intelectual e industrial, no implicando su sola mención o aparición en el sitio web la existencia de
                derechos o responsabilidad alguna sobre los mismos, como tampoco respaldo, patrocinio o
                recomendación por parte del mismo.
            </ConditionsSectionText>
            <ConditionsSectionText>
                Para realizar cualquier tipo de observación respecto a posibles incumplimientos de los derechos de
                propiedad intelectual o industrial, así como sobre cualquiera de los contenidos del sitio web, puede
                hacerlo a través del correo electrónico hola@enlighting-tech.com.
            </ConditionsSectionText>
            <ConditionsSectionTitle>
                3. DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL
            </ConditionsSectionTitle>
            <ConditionsSectionText>
                El RESPONSABLE se exime de cualquier tipo de responsabilidad derivada de la información publicada
                en su sitio web siempre que esta información haya sido manipulada o introducida por un tercero ajeno
                al mismo.
            </ConditionsSectionText>
            <ConditionsSectionSubTitle>
                Uso de Cookies
            </ConditionsSectionSubTitle>
            <ConditionsSectionText>
                Este sitio web puede utilizar cookies técnicas (pequeños archivos de información que el servidor envía
                al ordenador de quien accede a la página) para llevar a cabo determinadas funciones que son
                consideradas imprescindibles para el correcto funcionamiento y visualización del sitio. Las cookies
                utilizadas tienen, en todo caso, carácter temporal, con la única finalidad de hacer más eficaz la
                navegación, y desaparecen al terminar la sesión del usuario. En ningún caso, estas cookies
                proporcionan por sí mismas datos de carácter personal y no se utilizarán para la recogida de los
                mismos.
            </ConditionsSectionText>
            <ConditionsSectionText>
                Mediante el uso de cookies también es posible que el servidor donde se encuentra la web reconozca el
                navegador utilizado por el usuario con la finalidad de que la navegación sea más sencilla, permitiendo,
                por ejemplo, el acceso de los usuarios que se hayan registrado previamente a las áreas, servicios,
                promociones o concursos reservados exclusivamente a ellos sin tener que registrarse en cada visita.
                También se pueden utilizar para medir la audiencia, parámetros de tráfico, controlar el progreso y
                número de entradas, etc., siendo en estos casos cookies prescindibles técnicamente, pero
                beneficiosas para el usuario. Este sitio web no instalará cookies prescindibles sin el consentimiento
                previo del usuario.
            </ConditionsSectionText>
            <ConditionsSectionText>
                El usuario tiene la posibilidad de configurar su navegador para ser alertado de la recepción de cookies
                y para impedir su instalación en su equipo. Por favor, consulte las instrucciones de su navegador para
                ampliar esta información.
            </ConditionsSectionText>
            <ConditionsSectionSubTitle>
                Política de enlaces
            </ConditionsSectionSubTitle>
            <ConditionsSectionText>
                Desde el sitio web, es posible que se redirija a contenidos de terceros sitios web. Dado que el
                RESPONSABLE no puede controlar siempre los contenidos introducidos por terceros en sus
                respectivos sitios web, no asume ningún tipo de responsabilidad respecto a dichos contenidos. En todo
                caso, procederá a la retirada inmediata de cualquier contenido que pudiera contravenir la legislación
                nacional o internacional, la moral o el orden público, procediendo a la retirada inmediata de la
                redirección a dicho sitio web, poniendo en conocimiento de las autoridades competentes el contenido
                en cuestión.
            </ConditionsSectionText>
            <ConditionsSectionText>
                El RESPONSABLE no se hace responsable de la información y contenidos almacenados, a título
                enunciativo pero no limitativo, en foros, chats, generadores de blogs, comentarios, redes sociales o
                cualquier otro medio que permita a terceros publicar contenidos de forma independiente en la página
                web del RESPONSABLE. Sin embargo, y en cumplimiento de lo dispuesto en los artículos 11 y 16 de
                la LSSICE, se pone a disposición de todos los usuarios, autoridades y fuerzas de seguridad,
                colaborando de forma activa en la retirada o, en su caso, bloqueo de todos aquellos contenidos que
                puedan afectar o contravenir la legislación nacional o internacional, los derechos de terceros o la moral
                y el orden público. En caso de que el usuario considere que existe en el sitio web algún contenido que
                pudiera ser susceptible de esta clasificación, se ruega lo notifique de forma inmediata al administrador
                del sitio web.
            </ConditionsSectionText>
            <ConditionsSectionText>
                Este sitio web se ha revisado y probado para que funcione correctamente. En principio, puede
                garantizarse el correcto funcionamiento los 365 días del año, 24 horas al día. Sin embargo, el
                RESPONSABLE no descarta la posibilidad de que existan ciertos errores de programación, o que
                acontezcan causas de fuerza mayor, catástrofes naturales, huelgas o circunstancias semejantes que
                hagan imposible el acceso a la página web.
            </ConditionsSectionText>
            <ConditionsSectionSubTitle>
                Direcciones IP
            </ConditionsSectionSubTitle>
            <ConditionsSectionText>
                Los servidores del sitio web podrán detectar de manera automática la dirección IP y el nombre de
                dominio utilizados por el usuario. Una dirección IP es un número asignado automáticamente a un
                ordenador cuando este se conecta a Internet. Toda esta información se registra en un fichero de
                actividad del servidor que permite el posterior procesamiento de los datos con el fin de obtener
                mediciones únicamente estadísticas que permitan conocer el número de impresiones de páginas, el
                número de visitas realizadas a los servidores web, el orden de visitas, el punto de acceso, etc.
            </ConditionsSectionText>
            <ConditionsSectionTitle>
                4. LEY APLICABLE Y JURISDICCIÓN
            </ConditionsSectionTitle>
            <ConditionsSectionText>
                Para la resolución de todas las controversias o cuestiones relacionadas con el presente sitio web o de
                las actividades en él desarrolladas, será de aplicación la legislación española, a la que se someten
                expresamente las partes, siendo competentes para la resolución de todos los conflictos derivados o
                relacionados con su uso los Juzgados y Tribunales más cercanos a Barcelona.
                <br/>{' '}<br/>{' '}<br/>
            </ConditionsSectionText>
        </>
        )}
        {(props.lang === 'eng') && (
        <>
            <ConditionsTitle>Legal Notice</ConditionsTitle>
            <ConditionsSectionTitle>INFORMATION SOCIETY SERVICES ACT (LSSI)</ConditionsSectionTitle>
            <ConditionsSectionText>
                ENLIGHTING TECHNOLOGIES SL, the person responsible for the website, hereinafter the
                CONTROLLER, makes this document available to users, which is intended to comply with the
                obligations provided in Act 34/2002 of 11 July, on Information Society and Electronic Commerce
                Services (LSSICE), Spanish Official State Journal No. o 166, as well as informing all website users of
                the conditions of use.
            </ConditionsSectionText>
            <ConditionsSectionText>
                Any person who accesses this website acquires the status of user, and as such undertakes to strictly
                observe and comply with the provisions provided herein, as well as any other applicable legal clause.
            </ConditionsSectionText>
            <ConditionsSectionText>
                ENLIGHTING TECHNOLOGIES SL reserves the right to modify any type of information that may
                appear on the website, and is not obliged to give prior notice or inform users of such obligations, with
                publication on the website of ENLIGHTING TECHNOLOGIES SL being understood as sufficient.
            </ConditionsSectionText>
            <ConditionsSectionTitle>
            1. IDENTIFICATION DATA
            </ConditionsSectionTitle>
            <ConditionsSectionText>
                Domain name: https://kumux.io/<br/>
                Commercial name: KUMUX<br/>
                Company name: ENLIGHTING TECHNOLOGIES SL<br/>
                TIN: B67033357<br/>
                Registered address: Departament d'Enginyeries: Electrònica, Universitat de Barcelona carrer Martí
                Franquès, 1 08028 Barcelona<br/>
                Telephone: 934039147<br/>
                email: adria@enlighting-tech.com<br/>
                Registered in the Registry (Commercial/Public): Registro Mercantil de Barcelona, Boletín 164,
                Referencia 350678, Tomo 46069, Folio 214, Sección 8, Hoja 508497, Inscripción 1.
            </ConditionsSectionText>
            <ConditionsSectionTitle>
                2. INTELLECTUAL AND INDUSTRIAL PROPERTY RIGHTS
            </ConditionsSectionTitle>
            <ConditionsSectionText>
                The website, including but not limited to its programming, editing, compilation and other elements
                necessary for its operation, the designs, logos, text and/or graphics, are owned by the CONTROLLER
                or, if applicable, they expressly holds a license or an express authorisation by the authors. All website
                contents are duly protected by intellectual and industrial property laws, and are registered in the
                corresponding public registries.
            </ConditionsSectionText>
            <ConditionsSectionText>
                Regardless of their intended purpose, the total or partial reproduction, use, exploitation, distribution and
                commercialisation, requires in any case the prior written authorisation from the CONTROLLER. Any
                unauthorised use is considered a serious breach of the author’s intellectual or industrial property rights.
                The designs, logos, text and/or graphics not belonging to the CONTROLLER and which may appear on
                the website, belong to their respective owners who are liable for any possible dispute that may arise
                regarding them. The CONTROLLER expressly authorises third parties to redirect to the specific content
                of the website, and in any case to redirect to the main website of https://kumux.io/.
             </ConditionsSectionText>
             <ConditionsSectionText>
                The CONTROLLER acknowledges the corresponding intellectual and industrial property rights in favour
                of their owners, and any mention or appearance on the website does not imply the existence of any
                rights or responsibility whatsoever over them, nor does it imply any endorsement, sponsorship or
                recommendation by the website.
            </ConditionsSectionText>
            <ConditionsSectionText>
                Comments regarding any possible breach of intellectual or industrial property rights, as well as
                regarding the contents of the website, can be made by contacting hola@enlighting-tech.com.
            </ConditionsSectionText>
            <ConditionsSectionTitle>
                3. EXEMPTION FROM LIABILITY
            </ConditionsSectionTitle>
            <ConditionsSectionText>
                The CONTROLLER is exempt from any type of liability derived from the information published on their
                website whenever this information has been manipulated or introduced by a third party not related to
                the CONTROLLER.
            </ConditionsSectionText>
            <ConditionsSectionSubTitle>
                Use of Cookies
            </ConditionsSectionSubTitle>
            <ConditionsSectionText>
                This website uses technical cookies (small files with information that the server sends to the computer
                of the website user) in order to carry out certain functions considered necessary for the correct
                functioning and preview of the website. In any case, the cookies used are temporary, with the sole
                purpose of making navigation of the site more efficient, and disappear at the end of the user’s session.
                Under no circumstances do these cookies themselves provide personal data and will not be used for
                the collection of such data.
            </ConditionsSectionText>
            <ConditionsSectionText>
                Through using cookies, it is also possible for the server where the website is located to recognise the
                browser used by the user in order to make navigation easier, allowing, for example, users who have
                previously registered to access the areas, services, promotions or contests reserved exclusively for
                them without having to register on each visit. They may also be used to measure the audience or traffic
                parameters, monitor the progress and number of entries, etc. In these cases, the cookies used are
                technically non-essential but beneficial to the user. This website will not install non-essential cookies
                without previous user consent.
            </ConditionsSectionText>
            <ConditionsSectionText>
                The browser may be configured by the user to alert them of the reception of cookies and to prevent
                their installation on their computer. For further information, please consult the instructions of your
                browser.
            </ConditionsSectionText>
            <ConditionsSectionSubTitle>
                Link policy
            </ConditionsSectionSubTitle>
            <ConditionsSectionText>
                The user of this website may be redirected to content from third party websites. Since the
                CONTROLLER cannot always control the contents of third party websites, they do not assume any type
                of responsibility with respect to said contents. In any case, the CONTROLLER will immediately remove
                any content that may be in breach of national or international laws, morality or public order, and will
                immediately remove the redirection to this website, informing the competent authorities of the content in
                question.
            </ConditionsSectionText>
            <ConditionsSectionText>
                The CONTROLLER will not be responsible for the information and content found, including but not
                limited to, in forums, chats, blog generators, comments, social networks or any other means that allows
                third parties to publish content independently on the website of the CONTROLLER. However, and in
                accordance with Acts 11 and 16 of the LSSICE, third party content is made available to all users,
                authorities, and law enforcement bodies collaborating directly on the withdrawal or blocking of all
                content that may affect or violate national or international law, third party rights or public morals and
                public order. In the event that the user considers there to be any content on the website that could be
                considered as such, please notify the website administrator immediately.
            </ConditionsSectionText>
            <ConditionsSectionText>
                This website was revised and tested to enable its correct functioning. In principle, proper functioning
                can be guaranteed 365 days a year, 24 hours a day. However, the CONTROLLER does not rule out the
                possibility of there being certain programming errors, or that force majeure, natural disasters, strikes or
                similar circumstances may occur that make accessing the website impossible.
            </ConditionsSectionText>
            <ConditionsSectionSubTitle>
                IP Addresses
            </ConditionsSectionSubTitle>
            <ConditionsSectionText>
                The website servers can detect automatically the IP address and the domain name used by the user.
                An IP address is a number assigned automatically to a computer when connected to the Internet. All of
                this information is recorded in a system log on the server that allows the subsequent processing of the
                data in order to obtain only statistical measurements that show us the number of page hits, the number
                of visits made to the web servers, the order of visits, the access point, etc.
            </ConditionsSectionText>
            <ConditionsSectionTitle>
                4. APPLICABLE LAW AND JURISDICTION
            </ConditionsSectionTitle>
            <ConditionsSectionText>
                Spanish law, to which the parties expressly submit themselves, will apply to all disputes or questions
                related to this website or the activities carried out on it, and the Courts and Tribunals closest to
                Barcelona shall be responsible for the resolution of all conflicts arising from or related to its use.
                <br/>{' '}<br/>{' '}<br/>
            </ConditionsSectionText>
        </>
        )}
    </ConditionsSection>
    )
}

const ConditionsTitle = styled.h3`
  margin: 0 0 10px;
  ${(props: CompProps) => (props.theme as Theme).font_size.large};
`

const ConditionsSection = styled.div`
  background-color: ${(props: CompProps) =>
    (props.theme as Theme).color.white.regular};
  padding: 60px;
  margin: 60px 30px;
  height: calc(80vh - 90px);
  overflow-y: scroll;
  ${(props: CompProps) => (props.theme as Theme).filters.drop_shadow};
`

const ConditionsSectionTitle = styled.h4`
  ${(props: CompProps) => (props.theme as Theme).font_size.regular};
  margin: 30px 0px;
`

const ConditionsSectionSubTitle = styled.h5`
  font-family: ${(props: CompProps) => (props.theme as Theme).font.semibold};
`

const ConditionsSectionText = styled.p`
  b {
    font-family: ${(props: CompProps) => (props.theme as Theme).font.bold};
  }
`

export default LegalPolicy
