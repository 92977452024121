import React from 'react'
import styled from 'styled-components'

import { Popup } from 'semantic-ui-react'
import { CompProps, Theme } from '../../styles/theme'
import { HelpText } from './HelpContentStrings'
//import HelpersText from './HelpContentStrings'

interface Props {
  section: string
  content: string
  html: any
  position: any
}

const HelpContent: React.FC<Props> = (props) => {
  const { section, content, html, position } = props

  const sectionH = section as keyof typeof HelpText

  const sectionHs = content as keyof typeof HelpText[typeof sectionH]

  //return <> </>
  return (
    <WrapperPopup>
      <PopupHelp
        position={position}
        content={HelpText[sectionH][sectionHs]}
        trigger={html}
      />
    </WrapperPopup>
  )
}

export default HelpContent

export const WrapperPopup = styled.span`
  i {
    color: ${(props: CompProps) => (props.theme as Theme).color.black.lightest};
    padding-left: 7px;
    float: right;
  }
  i:hover {
    color: ${(props: CompProps) => (props.theme as Theme).color._900};
    cursor: help;
  }
`
export const PopupHelp = styled(Popup)`
  background-color: ${(props: CompProps) =>
    (props.theme as Theme).color.neutral._200}!important;
  color: black;
`
