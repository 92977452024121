import React, { useState, useEffect, SyntheticEvent } from 'react'
import styled from 'styled-components'
import { CompProps, Theme } from '../../styles/theme'
import {
  NoResultsIconWrapper,
  NoResultsContainer,
  LoadingContainer,
  NoResultsTextWrapper,
  MainButtonAction,
  DivSearch,
  InputSearch,
  ButtonSearch,
  PaginationUI
} from '../../styles/commons'

import { Historic, userAPI } from '../../api/UserApi'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Loading from '../../components/common/Loading'
import Tour from '../../components/help/Tour'

import { Auth } from './../../App'

import NewSimulation from '../../components/NewSimulation'
import HistoricList from '../../components/common/HistoricList'
import SimpleConfirm from '../../components/common/SimpleConfirm'
import { faDiagnoses, faSearch } from '@fortawesome/free-solid-svg-icons'
import AnalyticsListener from './../../api/AnalyticsListener'

import { Button, Modal as ModalReact } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
interface Props {
  auth: Auth
}

const SectionFixture: React.FC<Props> = (props) => {
  const history = useHistory()
  const [userHistoric, setUserHistoric] = useState<Historic[]>([])
  const [newSimulation, setNewSimulation] = useState(false)
  const [newSimulationClose, setNewSimulationClose] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [ListNeedsUpdate, setListNeedsUpdate] = useState(false)
  const [PagesInfo, setPagesInfo] = useState<{
    start: number
    end: number
  }>()
  const [TotalPages, setTotalPages] = useState<number>()
  const [TotalFixtures, setTotalFixtures] = useState<number>()
  const [CurrentPage, setCurrentPage] = useState<number>(1)
  const [PageLimit] = useState<number>(5)
  const NoResultsIcon = <FontAwesomeIcon icon={faDiagnoses} />
  const SearchIcon = <FontAwesomeIcon icon={faSearch} />
  const [Search, setSearch] = useState('')

  const [fixtureTourActive, setFixtureTourActive] = useState(false)
  const [sceneTourActive, setSceneTourActive] = useState(false)
  // No ha pasado por los settings de tour
  const [PopUpTour, setPopUpTour] = useState(
    localStorage.getItem('isTutorialActive') === null
  )

  const closeModalCancel = () => {
    setNewSimulationClose(false)
  }

  function handleOnChange(event: any) {
    setSearch(event.target.value)
    setCurrentPage(1)
    return event.target.value
  }

  const closeModalFixture = (isANewLuminary: boolean = false) => {
    setNewSimulationClose(false)
    setNewSimulation(false)
    if (isANewLuminary) {
      AnalyticsListener.createRouteView('create_fixture')
      AnalyticsListener.sendEvent('create_fixture')
      if (CurrentPage > 1) {
        setCurrentPage(1)
      } else {
        refreshUserHistoric && refreshUserHistoric()
      }
    }
  }

  const closePopupTour = () => {
    setPopUpTour(!PopUpTour)
    localStorage.setItem('isTutorialActive', 'false')
  }

  const closePopupTourandStartTour = () => {
    setPopUpTour(!PopUpTour)
    localStorage.setItem('isTutorialActive', 'true')
  }

  const refreshUserHistoric = () => {
    // Force fixture historic reload:
    setListNeedsUpdate(!ListNeedsUpdate)
  }

  const handlePaginationChange = (event: SyntheticEvent, data: any) => {
    setCurrentPage(data.activePage)
  }

  useEffect(() => {
    let didCancel = false

    const getUserHistoric = async () => {
      setIsLoading(true)
      try {
        let auxCurrentPage = CurrentPage

        const userHistoricResponse: any = await userAPI.getHistoric(
          auxCurrentPage,
          Search
        )

        const totalFixtures: number = userHistoricResponse.total
        const totalPages: number = Math.ceil(totalFixtures / PageLimit)

        if (auxCurrentPage > totalPages) {
          !didCancel && setCurrentPage(1)
        }

        const userHistoricResponseList: Historic[] = userHistoricResponse.content.sort(
          (a: Historic, b: Historic) =>
            a.simulation.unix_created < b.simulation.unix_created ? 1 : -1
        )

        !didCancel &&
          setPagesInfo({
            start: (auxCurrentPage - 1) * PageLimit + 1,
            end:
              PageLimit * auxCurrentPage < totalFixtures
                ? PageLimit * auxCurrentPage
                : totalFixtures
          })
        !didCancel && setTotalFixtures(totalFixtures)
        !didCancel && setTotalPages(totalPages)
        !didCancel && setUserHistoric(userHistoricResponseList)
      } catch (error) {
        console.error(error)
      } finally {
        !didCancel && setIsLoading(false)
      }
    }

    getUserHistoric()
    return () => {
      didCancel = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CurrentPage, Search, ListNeedsUpdate])

  // Activate tour if user has no fixtures
  useEffect(() => {
    const needFixtureTour: boolean = (userHistoric.length === 0) && (Search.length === 0)
    setFixtureTourActive(needFixtureTour)
    const userHasScenes: boolean = userHistoric.some((e) => e.scene_count > 0)
    const needSceneTour: boolean = userHistoric.length < 5 && !userHasScenes
    setSceneTourActive(needSceneTour)
  }, [userHistoric, Search.length])

  return (
    <DivFixturesContainer>
      <ModalReactTour closeIcon open={PopUpTour} onClose={closePopupTour}>
        <ModalReact.Header>WELCOME TO KUMUX!</ModalReact.Header>
        <ModalReact.Content>
          <p>Would you like to activate the tour?</p>
        </ModalReact.Content>
        <ModalReact.Actions>
          <Button onClick={closePopupTourandStartTour}>GO ON!</Button>
        </ModalReact.Actions>
      </ModalReactTour>
      <DivSearch>
        <InputSearch
          onChange={(event) => handleOnChange(event)}
          name="text"
          type="search"
          value={Search}
          placeholder=" Search..."
        />
        <ButtonSearch type="submit">{SearchIcon}</ButtonSearch>

        <ButtonNewFix
          onClick={() => history.push(`/pcb/${fixtureTourActive ? 1 : 0}`)}
          className="tour-newfixture"
          disabled={!isLoading ? false : true}
        >
          New fixture
        </ButtonNewFix>

        {newSimulation && (
          <NewSimulation
            closeModal={closeModalFixture}
            showTour={fixtureTourActive}
          ></NewSimulation>
        )}
      </DivSearch>
      <DivPagination>
        <PaginationContainer>
          {(TotalPages && !isLoading && (
            <PaginationUI
              ellipsisItem={null}
              onPageChange={(event: SyntheticEvent, data: any) =>
                handlePaginationChange(event, data)
              }
              totalPages={TotalPages}
              activePage={CurrentPage}
            />
          )) || <></>}
        </PaginationContainer>
        <HistoricCountContainer>
          {(TotalPages && !isLoading && (
            <HistoricCountSimulations>
              Showing {PagesInfo?.start}-{PagesInfo?.end} of {TotalFixtures}{' '}
              fixture(s)
            </HistoricCountSimulations>
          )) || <></>}
        </HistoricCountContainer>
      </DivPagination>
      {isLoading && (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      )}
      {!isLoading && userHistoric.length === 0 && (
        <NoResultsContainer>
          <NoResultsIconWrapper>{NoResultsIcon}</NoResultsIconWrapper>
          <NoResultsTextWrapper>
            You have not created any fixture yet.
          </NoResultsTextWrapper>
        </NoResultsContainer>
      )}
      {!isLoading && userHistoric.length > 0 && (
        <HistoricList
          search={Search}
          listItems={userHistoric}
          isHistoric={true}
          deleteCallBack={refreshUserHistoric}
          showSceneTour={sceneTourActive}
        />
      )}
      <DivPagination>
        <PaginationContainer>
          {(TotalPages && TotalPages > 0 && !isLoading && (
            <PaginationUI
              ellipsisItem={null}
              onPageChange={(event: SyntheticEvent, data: any) =>
                handlePaginationChange(event, data)
              }
              totalPages={TotalPages}
              activePage={CurrentPage}
            />
          )) || <></>}
        </PaginationContainer>
        {(TotalPages && TotalPages > 0 && !isLoading && (
          <HistoricCountContainer>
            <HistoricCountSimulations>
              Showing {PagesInfo?.start}-{PagesInfo?.end} of {TotalFixtures}{' '}
              fixture(s)
            </HistoricCountSimulations>
          </HistoricCountContainer>
        )) || <></>}
      </DivPagination>
      {!isLoading && !newSimulation && fixtureTourActive && (
        <Tour section="NEW_FIXTURE" />
      )}
      {newSimulationClose && (
        <SimpleConfirm
          open={newSimulationClose}
          closeModal={closeModalCancel}
          acceptConfirm={closeModalFixture}
          message="Discard all changes?"
        />
      )}
    </DivFixturesContainer>
  )
}

const ButtonNewFix = styled(MainButtonAction)`
  height: auto;
  margin-left: auto;
  :disabled {
    background: ${(props: CompProps) =>
      (props.theme as Theme).color.white.darker}!important;
  }
  &&& a {
    color: white !important;
  }
`

const DivPagination = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const PaginationContainer = styled.div`
  postition: relative;
  margin-right: 0px;
`

const HistoricCountContainer = styled.div`
  float: right;
  margin-top: 25px;
`

const HistoricCountSimulations = styled.p`
  ${(props: CompProps) => (props.theme as Theme).font_size.xxxsmall};
`

const ModalReactTour = styled(ModalReact)`
  padding: 30px;
  display: block;
  background: ${(props: CompProps) =>
    (props.theme as Theme).color._100}!important;
  width: 630px !important;
  &&&&& .header {
    border-bottom: inherit !important;
    background: ${(props: CompProps) => (props.theme as Theme).color._100};
    text-align: center;
    font-size: 36px;
  }
  &&& .content {
    background: ${(props: CompProps) => (props.theme as Theme).color._100};
    text-align: center;
    font-size: 18px;
  }
  &&& .actions {
    border-top: inherit !important;
    background: ${(props: CompProps) => (props.theme as Theme).color._100};
    display: flex;
    justify-content: center;
  }
  &&& button {
    background: ${(props: CompProps) => (props.theme as Theme).color._900};
    color: white;
    width: 68%;
    border-radius: 15px;
  }
`

const DivFixturesContainer = styled.div``

export default SectionFixture
