import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ToastContainer, toast } from 'react-toastify'
import { CompProps, Theme } from '../../styles/theme'
import { Historic, LedInfo, STATUS_SIMULATION } from '../../api/UserApi'
import { SmallButtonAction, ModalConfirm } from '../../styles/commons'
import { useHistory } from 'react-router-dom'

import {
  faTrashAlt,
  faPencilAlt,
  faChartArea,
  faCheckCircle,
  faExclamationTriangle,
  faPauseCircle,
  faClock
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { simulationApi } from '../../api/SimulationApi'
import Tour from '../../components/help/Tour'
import { Progress } from 'semantic-ui-react'
import ModalConfirmText from './ModalConfirmText'

interface Props {
  listItems: Historic[]
  isHistoric: boolean
  simulationPage?: boolean
  search?: string
  showSceneTour?: boolean
  deleteCallBack?: Function
}

interface HistoricSectionListItemProps {
  isHistoric: boolean
  theme: any
}

const RemoveLuminaryIcon = <FontAwesomeIcon icon={faTrashAlt} />
const UploadFixtureIcon = <FontAwesomeIcon icon={faPencilAlt} />
const GraficIcon = <FontAwesomeIcon icon={faChartArea} />
const GraficCheck = <FontAwesomeIcon icon={faCheckCircle} />
const GraficClock = <FontAwesomeIcon spin icon={faClock} />
const GraficError = <FontAwesomeIcon icon={faExclamationTriangle} />
const GraficPause = <FontAwesomeIcon icon={faPauseCircle} />

const HistoricList: React.FC<Props> = (props) => {
  const history = useHistory()

  const [items, setItems] = useState<Historic[]>(props.listItems)
  const [itemsToCheck, setItemsToCheck] = useState<Historic[]>([])
  const [sceneTourActive] = useState(props.showSceneTour || false)

  const deleteLuminary = (index: number) => {
    const itemsAux: Historic[] = items.map((item: any, i: number) => {
      item.deleteConfirm = i === index ? true : false

      return item
    })

    setItems(itemsAux)
  }

  const closeDeleteLuminary = () => {
    const itemsAux = items.map((item: any, i: number) => {
      item.deleteConfirm = false
      return item
    })

    setItems(itemsAux)
  }

  const acceptDeleteLuminary = async () => {
    const luminaryToDelete = items.find(
      (item: any) => item.deleteConfirm === true
    )

    if (luminaryToDelete) {
      const response = await simulationApi.deleteLuminaryById(
        luminaryToDelete.public_id
      )

      if (response) {
        const itemsAux = items.filter(
          (item: any) => item.deleteConfirm !== true
        )
        setItems(itemsAux)
        props.deleteCallBack && props.deleteCallBack()
      }
    }
  }

  const simulationIsCompleteNotification = () => {
    // Notification
    toast.success('Simulation ready.')

    // Change fav icon
    const favIcon: any = document && document.getElementById('favicon')
    const iconNew = `${process.env.PUBLIC_URL}favicon-alert.ico`
    favIcon && (favIcon.href = iconNew)

    // Play Audio
    let src = `${process.env.PUBLIC_URL}pomodoro-times-up.mp3`
    let audio = new Audio(src)
    audio.play()
  }

  useEffect(() => {
    if (props.isHistoric) {
      const itemsWithInProgressStatus = items.filter(
        (item: Historic) =>
          item.simulation.status === STATUS_SIMULATION.in_progress.id ||
          item.simulation.status === STATUS_SIMULATION.no_init.id
      )

      setItemsToCheck(itemsWithInProgressStatus)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])

  useEffect(() => {
    let didCancel: boolean = false

    const checkItemStatusRequest = async () => {
      for (const itemToCheck of itemsToCheck) {
        const response = await simulationApi.checkStatusSimulationById(
          itemToCheck.public_id
        )

        if (response.status === STATUS_SIMULATION.in_progress.id) {
          const itemsChanged = items.map((item: Historic) => {
            if (item.public_id === itemToCheck.public_id) {
              const maxTime = 420
              var timeDiff =
                Math.floor(new Date().getTime() / 1000) - response.unix_start

              var perc: number = Math.round((timeDiff / maxTime) * 100)
              item.progress = perc
            }

            return item
          })

          !didCancel && setItems(itemsChanged)
        }

        if (response.status !== itemToCheck.simulation.status) {
          if (response.status === STATUS_SIMULATION.created.id) {
            // Simulation Completed
            simulationIsCompleteNotification()
          }

          const itemsChanged = items.map((item: Historic) => {
            if (item.public_id === itemToCheck.public_id) {
              item.simulation.status = response.status
              item.simulation.unix_end = response.unix_end
              item.progress =
                response.status === STATUS_SIMULATION.created.id ? 100 : 0
            }

            return item
          })

          !didCancel && setItems(itemsChanged)

          if (response.status === STATUS_SIMULATION.error.id) {
            const itemsToCheckAux = itemsToCheck.filter(
              (item: Historic) => item.public_id !== itemToCheck.public_id
            )

            !didCancel && setItemsToCheck(itemsToCheckAux)
          }
        }
      }
    }

    if (itemsToCheck.length > 0) setTimeout(checkItemStatusRequest, 5000)

    return () => {
      didCancel = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsToCheck])

  const checkStatus = (status: number, type: string) =>
    status === STATUS_SIMULATION[type].id

  const startSimulation = (public_id: string) => {
    simulationApi.startSimulationById(public_id)

    const auxItems = items.map((item: Historic) => {
      if (item.public_id === public_id) {
        item.simulation.status = STATUS_SIMULATION.no_init.id
      }

      return item
    })

    setItems(auxItems)
  }

  return (
    <HistoricSectionList>
      {sceneTourActive && <Tour section="SIM_CREATED" />}
      {items.map((historic: Historic, i: number) => {
        return (
          <HistoricSectonListItem key={i} isHistoric={props.isHistoric}>
            {!props.simulationPage && props.isHistoric && (
              <FixtureHeaderContainer>
                <ItemReference>{historic.reference}</ItemReference>
                <ItemIcons>
                  <ContainersSvgHidden>{GraficIcon}</ContainersSvgHidden>
                  <ContainersSvgHidden>{UploadFixtureIcon}</ContainersSvgHidden>
                  {props.isHistoric &&
                    !checkStatus(historic.simulation.status, 'no_init') &&
                    !checkStatus(historic.simulation.status, 'in_progress') && (
                      <Containerssvg onClick={() => deleteLuminary(i)}>
                        {RemoveLuminaryIcon}
                      </Containerssvg>
                    )}
                </ItemIcons>
              </FixtureHeaderContainer>
            )}
            <ItemsContainer>
              <li>
                <ItemSubTitle>Company</ItemSubTitle>
                <ItemSubList>
                  {historic.leds &&
                    historic.leds.map((led: LedInfo, i: number) => {
                      return <li key={i}>{led.company}</li>
                    })}
                </ItemSubList>
              </li>
              <li>
                <ItemSubTitle>Brand</ItemSubTitle>
                <ItemSubList>
                  {historic.leds &&
                    historic.leds.map((led: LedInfo, i: number) => {
                      return <li key={i}>{led.brand}</li>
                    })}
                </ItemSubList>
              </li>
              <li>
                <ItemSubTitle>Reference</ItemSubTitle>
                <ItemSubList>
                  {historic.leds &&
                    historic.leds.map((led: LedInfo, i: number) => {
                      return <li key={i}>{led.reference}</li>
                    })}
                </ItemSubList>
              </li>
              <li className="shortColumn">
                <ItemSubTitle>LEDs Serial</ItemSubTitle>
                <ItemSubList>
                  {historic.leds &&
                    historic.leds.map((led: LedInfo, i: number) => {
                      return <li key={i}>{led.serial || 1}</li>
                    })}
                </ItemSubList>
              </li>
              <li className="shortColumn">
                <ItemSubTitle>LEDs Parallel</ItemSubTitle>
                <ItemSubList>
                  {historic.leds &&
                    historic.leds.map((led: LedInfo, i: number) => {
                      return <li key={i}>{led.parallel || led.number}</li>
                    })}
                </ItemSubList>
              </li>
              <li className="shortColumn">
                <ItemSubTitle>Current (mA)</ItemSubTitle>
                <ItemSubList>
                  {historic.leds &&
                    historic.leds.map((led: LedInfo, i: number) => {
                      return <li key={i}>{led.limit}</li>
                    })}
                </ItemSubList>
              </li>
              {props.isHistoric && (
                <li className="last">
                  {props.isHistoric &&
                    checkStatus(historic.simulation.status, 'no_init') && (
                      <SimulationDivContainer>
                        <SimulationDiv>
                          <IconDivWait style={{ color: '#F25B25' }}>
                            {' '}
                            {GraficPause}
                          </IconDivWait>
                          <div className="containersimulation">
                            <h5>WAITING...</h5>
                          </div>
                        </SimulationDiv>
                      </SimulationDivContainer>
                    )}
                  {props.isHistoric &&
                    checkStatus(historic.simulation.status, 'error') && (
                      <SimulationDivContainer>
                        <SimulationDiv>
                          <IconDivError> {GraficError} </IconDivError>

                          <p>{STATUS_SIMULATION.error.msg}</p>
                        </SimulationDiv>
                        <SimulationDiv>
                          <ButtonSimulation
                            onClick={() => startSimulation(historic.public_id)}
                          >
                            {STATUS_SIMULATION.error.label}
                          </ButtonSimulation>
                        </SimulationDiv>
                      </SimulationDivContainer>
                    )}
                  {props.isHistoric &&
                    checkStatus(historic.simulation.status, 'in_progress') && (
                      <>
                        <SimulationDivContainer>
                          <SimulationDiv className="tour-simprogress">
                            <IconDivWarning> {GraficClock} </IconDivWarning>
                            <div className="containersimulation">
                              <h5 className="warning">
                                GENERATING SPECTRA COMBINATIONS                
                              </h5>
                              <p>
                                Date:{' '}
                                {new Intl.DateTimeFormat('es-ES', {
                                  year: 'numeric',
                                  month: '2-digit',
                                  day: '2-digit'
                                }).format(
                                  new Date(
                                    historic.simulation.unix_created * 1000
                                  )
                                )}{' '}
                              </p>
                            </div>
                          </SimulationDiv>
                        </SimulationDivContainer>
                      </>
                    )}
                  {props.isHistoric &&
                    checkStatus(historic.simulation.status, 'in_progress') &&
                    sceneTourActive && <Tour section="SIM_PROGRESS" />}
                  {props.isHistoric &&
                    checkStatus(historic.simulation.status, 'created') && (
                      <>
                        <SimulationDivContainer>
                          <SimulationDiv>
                            <IconDiv className="warning">
                              {' '}
                              {GraficCheck}{' '}
                            </IconDiv>
                            <div className="containersimulation">
                              <h5>SIMULATION READY</h5>
                              <p>
                                Date:{' '}
                                {new Intl.DateTimeFormat('es-ES', {
                                  year: 'numeric',
                                  month: '2-digit',
                                  day: '2-digit'
                                }).format(
                                  new Date(
                                    historic.simulation.unix_created * 1000
                                  )
                                )}{' '}
                                | Elapsed time:{' '}
                                {new Intl.DateTimeFormat('es-ES', {
                                  minute: 'numeric',
                                  second: 'numeric'
                                }).format(
                                  new Date(
                                    historic.simulation.unix_end * 1000
                                  ).valueOf() -
                                    new Date(
                                      historic.simulation.unix_start * 1000
                                    ).valueOf()
                                )}
                              </p>
                            </div>
                          </SimulationDiv>
                          <SimulationDiv>
                            <ButtonSimulation
                              onClick={() =>
                                historic.scene_count === 0 && sceneTourActive
                                  ? history.push(
                                      `/luminary/${historic.public_id}/${sceneTourActive}`
                                    )
                                  : history.push(
                                      `/luminary/${historic.public_id}/`
                                    )
                              }
                              className={
                                '' +
                                (historic.scene_count === 0 &&
                                  sceneTourActive &&
                                  'tour-simcreated') +
                                ''
                              }
                            >
                              VIEW SIMULATION
                            </ButtonSimulation>
                          </SimulationDiv>
                        </SimulationDivContainer>
                      </>
                    )}
                  {sceneTourActive &&
                    props.isHistoric &&
                    checkStatus(historic.simulation.status, 'created') && (
                      <Tour section="S2IM_CREATED" />
                    )}
                </li>
              )}
            </ItemsContainer>
            {historic.deleteConfirm && (
              <ModalConfirm
                size="tiny"
                closeIcon
                open={historic.deleteConfirm}
                onClose={() => closeDeleteLuminary()}
              >
                <ModalConfirmText
                  closeModal={closeDeleteLuminary}
                  object={historic}
                  message="Are you sure you want to delete this Fixture?"
                  acceptConfirm={acceptDeleteLuminary}
                />
              </ModalConfirm>
            )}
            {historic.simulation && historic.simulation.status && (
              <ProgressBarContainer>
                {checkStatus(historic.simulation.status, 'in_progress') &&
                  (historic.progress ? (
                    <ProgressBar
                      percent={historic.progress}
                      attached="bottom"
                      indicating
                      warning
                    />
                  ) : (
                    <ProgressBar percent={0} attached="bottom" disabled />
                  ))}
                {checkStatus(historic.simulation.status, 'created') &&
                  historic.progress && (
                    <ProgressBar percent={100} attached="bottom" success />
                  )}
                {checkStatus(historic.simulation.status, 'error') && (
                  <ProgressBar percent={100} attached="bottom" error />
                )}
              </ProgressBarContainer>
            )}
          </HistoricSectonListItem>
        )
      })}
      <ToastContainer />
    </HistoricSectionList>
  )
}

const FixtureHeaderContainer = styled.div`
  display: flex;
  direction: row;
  margin-bottom: 30px;
  align-items: flex-start;
`
const DeleteSceneIconContainer = styled.div`
  cursor: pointer;
  color: ${(props: CompProps) => (props.theme as Theme).color.neutral._900};
  :hover svg {
    color: ${(props: CompProps) => (props.theme as Theme).color._900};
  }
`
const ItemReference = styled.div`
  flex: 1 0;
  margin-right: 15px;
  ${(props: CompProps) => (props.theme as Theme).font_size.large};
  font-family: ${(props: CompProps) => (props.theme as Theme).font.semibold};
  color: ${(props: CompProps) => (props.theme as Theme).color.neutral._900};
  font-weight: bold;
`

const ItemIcons = styled.div`
  cursor: pointer;
  svg {
    margin-top: 7px;
  }
  ${(props: CompProps) => (props.theme as Theme).font_size.xsmall};
  color: ${(props: CompProps) => (props.theme as Theme).color.secondary};
  :hover {
    color: ${(props: CompProps) => (props.theme as Theme).color.secondaryHover};
  }
`

const ItemsContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  .shortColumn {
    max-width: 100px;
  }
  .last {
    border-top: 1px solid
      ${(props: CompProps) => (props.theme as Theme).color.neutral._500};
    margin-top: 30px !important;
  }
`

const ProgressBarContainer = styled.div`
  background: ${(props: CompProps) =>
    (props.theme as Theme).color.white.darker};
`

const ProgressBar = styled(Progress)`
  width: 100%;
`

const ButtonSimulation = styled(SmallButtonAction)`
  border: 1px solid ${(props: CompProps) => (props.theme as Theme).color._900};
  color: ${(props: CompProps) => (props.theme as Theme).color._900};
  background: white;

  :hover {
    color: white;
  }
`

const HistoricSectionList = styled.div`
  display: flex;
  flex-direction: column;
`
const HistoricSectonListItem = styled.div<HistoricSectionListItemProps>`
  margin: 15px 0;
  padding: 30px;
  ${(props: HistoricSectionListItemProps) => {
  if (props.isHistoric) {
    return '-webkit-box-shadow: 3px 10px 48px -25px rgba(0, 0, 0, 0.5);-moz-box-shadow: 3px 10px 48px -25px rgba(0, 0, 0, 0.5);box-shadow: 3px 10px 48px -25px rgba(0, 0, 0, 0.5);'
  }
}}
  ${(props: HistoricSectionListItemProps) => {
  if (props.isHistoric) {
    return ` min-height: 150px;`
  }
}}

  ul {
    display: flex;
    padding: 0;
    justify-content: space-between;
    li {
      display: flex;
      flex-direction: column;
      flex: 0 0 19%;
      p {
        margin: 0;
        ${(props: CompProps) => (props.theme as Theme).font_size.xxsmall};
      }
      button {
        margin-top: 10px;
      } 
  }

    ${(props: HistoricSectionListItemProps) => {
  if (!props.isHistoric) {
    return '& li:last-child, li:nth-last-child(2) { flex: 0 0 9%;}'
  } else {
    return '& li:last-child {flex: 0 0 100%;} li:nth-last-child(2), li:nth-last-child(3) { flex: 0 0 9%;}'
  }
}}
  }
`

const Containerssvg = styled(DeleteSceneIconContainer)`
  display: inline-flex;
  color: ${(props: CompProps) => (props.theme as Theme).color.black.light};
  ${(props: CompProps) => (props.theme as Theme).font_size.xxsmall};
`

const ContainersSvgHidden = styled(DeleteSceneIconContainer)`
  display: inline-flex;
  color: gray;
  visibility: hidden;
`

const SimulationDivContainer = styled.div`
  display: flex;
  padding-top: 10px;
  justify-content: space-between;

  padding-top: 25px;
  div {
    display: inline-flex;
  }
  svg {
    color: #a1c581;
    font-size: 25px;
    margin-right: 15px;
  }
`
const IconDiv = styled.div`
  .warning {
  }
`
const IconDivWait = styled.div`
  svg {
    color: #071435;
  }
`

const IconDivWarning = styled.div`
  svg {
    color: #f25b25 !important;
  }
`
const IconDivError = styled.div`
  svg {
    color: red !important;
  }
`
const SimulationDiv = styled.div`
  .warning {
    color: #f25b25 !important;
  }
  .containersimulation {
    display: block;
    margin-top: 10px;
    margin-left: 10px;
    line-height: 6px;
  }
  .containersimulation h5 {
    margin: 0px;
  }
  .containersimulation p {
    color: gray;
    margin-top: 3px;
    ${(props: CompProps) => (props.theme as Theme).font_size.xxxsmall};
  }

  h5 {
    ${(props: CompProps) => (props.theme as Theme).font_size.xxsmall};

    color: ${(props: CompProps) => (props.theme as Theme).color._900};
  }

  align-items: center;
`

const ItemSubTitle = styled.div`
  font-family: ${(props: CompProps) => (props.theme as Theme).font.light};
  ${(props: CompProps) => (props.theme as Theme).font_size.xxsmall};
  padding-bottom: 2px;
  font-weight: bold;
`
const ItemSubList = styled.ul`
  display: flex;
  flex-direction: column;
  li {
    ${(props: CompProps) => (props.theme as Theme).font_size.xxsmall};
    padding: 3px 0;
  }
`

export default HistoricList
