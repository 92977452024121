import React, { useState, useCallback, useContext } from 'react'
import styled from 'styled-components'

import ScenePDFContext, { StacticGraphicsData, StaticGraphicData } from './ChartStaticContext'

import SpectraStatic from './SpectraStatic'
import CRIiBarChart from './CRIiBarChart'
import CIEStatic from './CIEStatic'
import ColorVectorScatter from './ColorVectorScatter'
import DoughnutChart from './DoughnutChart'
import SaturationScatter from './SaturationScatter'
import ChannelsPolar from './ChannelsPolar'
import HCLRadar from './HCLRadar'


export interface ChartProps {
    chartData: number[][]
    chartOptions?: any
    callBack: Function
}

interface Props {
    chartType: string
    chartData: number[][]
    chartOptions?: any
    renderType?: string
}

interface GraphicComponents {
    [key: string]: any
    cri: React.FC<ChartProps>
    colorvector: React.FC<ChartProps>
}

const graphicComponents: GraphicComponents = {
    spd: SpectraStatic,
    cri: CRIiBarChart,
    colorvector: ColorVectorScatter,
    cie: CIEStatic,
    saturation: SaturationScatter,
    info_color: DoughnutChart,
    info_hcl: DoughnutChart,
    info_energy: DoughnutChart,
    channels: ChannelsPolar,
    hcl: HCLRadar
}

const ChartStatic: React.FC<Props> = React.memo((props) => {

    const [isRendered, setIsRendered] = useState(false)
    const [canvasImageData, setCanvasImageData] = useState('')
    
    const pdfData: StacticGraphicsData = useContext(ScenePDFContext)

    console.info('TODO: OPTIMIZE RENDER')
    
    // useCallback is shadowed by React.Memo / Needs parent optimization to remove memoization in component
    const onCanvasRenderComplete = useCallback((b64CanvasImageData: string) => {
        // Update Image data.
        setCanvasImageData(b64CanvasImageData)
        // Update Global PDF Data Context.
        const gData: StaticGraphicData = {name: props.chartType, value: b64CanvasImageData}
        pdfData.graphics.push(gData)
        
        setIsRendered(true)
    }, [props.chartType, pdfData.graphics])

    const Graphic: React.FC<ChartProps> = graphicComponents[props.chartType]

    return(
        <GraphicContainer className={(isRendered) ? 'hidden' : ''}>
            {((canvasImageData === '') || (props.renderType === 'graphic')) ? (  
                <Graphic callBack={onCanvasRenderComplete} {...props} />
            ) : (
                <img alt={props.chartType} src={canvasImageData} />
            )}
        </GraphicContainer>
    )

// Component marked as static
}, (prevProps, nextProps) => { return prevProps.renderType === nextProps.renderType })

export default ChartStatic

const GraphicContainer = styled.div`
    .hidden {
        display: none;
    }
` 