import React from 'react'

export interface TourStep {
  target?: string
  title?: string
  content?: JSX.Element
  disableBeacon?: boolean
  spotlightClicks?: boolean
  hideCloseButton?: boolean
  disableOverlayClose?: boolean
  hideFooter?: boolean
  placement?: string
  placementBeacon?: string
  showSkipButton?: boolean
  hideBackButton?: boolean
  isFixed?: boolean
}

export interface TourSteps {
  section?: string
  disableOverlayClose?: boolean
  steps?: Array<TourStep>
}

export interface TourScript {
  sections: Array<TourSteps>
}

// Tour steps
const TS_NEW_FIXTURE: TourSteps = {
  section: 'NEW_FIXTURE',
  steps: [
    {
      target: '.tour-newfixture',
      content: (
        <>
          Let's create your first Spectral optimization. We will start by
          creating a new fixture.
        </>
      ),
      disableBeacon: true,
      spotlightClicks: true,
      hideFooter: true
    }
  ]
}

const TS_PCB_reference: TourSteps = {
  section: 'PCB_reference',
  steps: [
    {
      target: '.tour-pcb',
      content: (
        <>
          Now it’s time to name your fixture. Use a name that makes it easy to
          remember the fixture you are creating.
        </>
      ),
      disableBeacon: true,
      hideCloseButton: true
    }
  ]
}

const TS_PCB_Choose_leds: TourSteps = {
  section: 'PCB_ledTable',
  steps: [
    {
      target: '.tour-availableleds',
      content: (
        <>
          Choose at least 3 LEDs that best match your needs <br />
          and drag them to the left to start creating your fixture.
        </>
      ),
      disableBeacon: true,
      hideCloseButton: true,
      placement: 'top'
    }
  ]
}

const TS_PCB_Change_serial_parallell_ma: TourSteps = {
  section: 'PCB_DragledTable',

  steps: [
    {
      target: '.tour-dragleds',
      content: (
        <>
          Well done! Here you will find the chosen LEDs. Add the number of the
          LEDs in serial and parallel and <br />
          set the current (in mA) that your driver is going to supply to each
          branch.
        </>
      ),
      disableBeacon: true
    },
    {
      target: '.tour-spectral-distribution',
      content: (
        <>
          Here you can see the spectrum of the PCB you are creating with all the
          branches working at maximum power, <br />
          based on the number of LEDs in series and parallel and the current you
          have set before.
        </>
      ),
      disableBeacon: true,
      hideBackButton: true
    },
    {
      target: '.tour-createfixture',
      content: <>Click create and your first fixture will be ready!</>,
      disableBeacon: true,
      hideBackButton: true
    }
  ]
}

const TS_SIM_PROGRESS: TourSteps = {
  section: 'SIM_PROGRESS',
  steps: [
    {
      target: '.tour-simprogress',
      content: (
        <>
          Your fixture has been created and we are simulating all the possible
          spectra. Please wait or come back in a few minutes.
        </>
      ),
      disableBeacon: true,
      hideCloseButton: false,
      hideFooter: true
    }
  ]
}

const TS_SIM_CREATED: TourSteps = {
  section: 'SIM_CREATED',
  disableOverlayClose: false,
  steps: [
    {
      target: '.tour-simcreated',
      content: (
        <>
          By clicking here, you’ll be able to check your fixture simulation
          anytime you want.
        </>
      ),
      disableBeacon: true,
      hideCloseButton: false,
      hideFooter: true,
      spotlightClicks: true
    }
  ]
}

const TS_PAGE_LED: TourSteps = {
  section: 'PAGE_LED',
  steps: [
    {
      target: '.tour-new_led',
      content: (
        <>
          Create and save any new LEDS you need for your light from any brand.
        </>
      ),
      disableBeacon: true,
      spotlightClicks: false
    },
    {
      target: '.tour-led-catalog',
      content: (
        <>
          Here you’ll find an extensive LED database updated monthly, add to
          your list the ones that best match your needs
        </>
      ),
      disableBeacon: true
    },
    {
      target: '.tour-specific-led',
      content: (
        <>By clicking there, all the LED specifications will be shown.</>
      ),
      disableBeacon: true
    },
    {
      target: '.tour-excel',
      content: (
        <>
          Or, if you already have your own, downlowad our excel and import your
          data
        </>
      ),
      disableBeacon: true
    }
  ]
}

const TS_PAGE_SIMULATION: TourSteps = {
  section: 'SIMULATION',
  steps: [
    {
      target: '.tour-fixtures-ledsPCB',
      content: (
      <>
        In this page you will find all your simulation details. On the LEDs
        PCB you can see the LEDs chosen before.
      </>
      ),
      disableBeacon: true
    },
    {
      target: '.tour-scene_creator',
      content: (
        <>
          Let’s create your first scene together! The goal is to obtain one
          spectrum.
        </>
      )
    },
    {
      target: '.tour-colorimetric',
      content: (
        <>
          By adjusting the parameters values, you will end obtaining one last
          spectrum.
        </>
      )
    }
  ]
}
const TS_PAGE_SIMULATION_SCENE_CREATOR: TourSteps = {
  section: 'SIMULATION_SCENE_CREATOR',
  steps: [
    {
      target: '.tour-name-scene',
      content: <>And now give it a name and click on “create scene”!</>,
      disableBeacon: true,
      spotlightClicks: true,
      hideFooter: true
    }
  ]
}

const TS_PAGE_SIMULATION_SCENE_RESULTS: TourSteps = {
  section: 'SIMULATION_SCENE_RESULTS',
  steps: [
    {
      target: '.tour-number-results',
      content: <>Keep setting parameters to obtain just one result!</>,
      disableBeacon: true
    }
  ]
}

const TS_PAGE_SIMULATION_SCENE_CREATOR_FINISH: TourSteps = {
  section: 'SIMULATION_SCENE_CREATOR_FINISH',
  steps: [
    {
      target: '.tour-view-details',
      content: (
        <>
          Click on “view detail” to get the data from the scene you have just
          created.
        </>
      ),
      disableBeacon: true
    },
    {
      target: '.tour-export',
      content: <>Or export your results and add them to your device.</>,
      disableBeacon: true
    }
  ]
}

const TS_PAGE_NEW_LED: TourSteps = {
  section: 'SIMULATION_NEW_LED',
  steps: [
    {
      target: '.tour-general-info',
      content: <>Fill up the blank spaces with your LED information</>,
      disableBeacon: true,
      placement: 'top-start',
      placementBeacon: 'top'
    },
    {
      target: '.tour-company',
      content: (
        <>Let’s start by filling your company name, brand and LED reference</>
      ),
      disableBeacon: true
    },
    {
      target: '.tour-power',
      content: (
        <>
          Proceed with the current and voltage or power, one of the two fields.
          Temperature, luminous flux or radiant flux and your LED setup.
        </>
      ),
      disableBeacon: true
    },
    {
      target: '.tour-spectral-power',
      content: (
        <>
          Download the spectral power distribution file, fill the blanks and
          upload it back
        </>
      ),
      disableBeacon: true
    },
    {
      target: '.tour-create-led',
      content: <>Click CREATE and here it is! Your LED is created!</>,
      disableBeacon: true
    }
  ]
}

const TS_PAGE_PROJECT: TourSteps = {
  section: 'PAGE_PROJECT',
  steps: [
    {
      target: '.tour-new-project',
      content: (
        <>
          Let’s create our first project. Remember you need to have at least one
          fixture with scenes to create your project.
        </>
      ),
      disableBeacon: true,
      spotlightClicks: true,
      hideFooter: true
    }
  ]
}

const TS_PAGE_NEW_PROJECT: TourSteps = {
  section: 'PAGE_NEW_PROJECT',
  steps: [
    {
      target: '.tour-project-name',
      content: <>Introduce the name of your project. Example: Office</>,
      disableBeacon: true
    },
    {
      target: '.tour-project-description',
      content: (
        <>
          Brief description of your project. Example: In this project we are
          going to program an office circadian system
        </>
      ),
      disableBeacon: true
    },
    {
      target: '.tour-location-project',
      content: <>Introduce the location of the project.Example: Barcelona</>,
      disableBeacon: true
    },
    {
      target: '.tour-create-project',
      content: (
        <>
          And once you have filled all the information press here to create the
          project.
        </>
      ),
      disableBeacon: true
    }
  ]
}

const TS_PAGE_NEW_TIMELINE: TourSteps = {
  section: 'PAGE_NEW_TIMELINE',
  steps: [
    {
      target: '.tour-timeline-fixture',
      content: (
        <>To create a timeline first select a fixture from your catalog.</>
      ),
      disableBeacon: true
    },
    {
      target: '.tour-timeline-time-month-year',
      content: <>Select the month and the year.</>,
      disableBeacon: true
    },
    {
      target: '.tour-timeline-time',
      content: <>Select the starting and ending time of the day</>,
      disableBeacon: true
    },

    {
      target: '.tour-timeline-name',
      content: <>Introduce the name of the timeline.</>,
      disableBeacon: true
    },
    {
      target: '.tour-timeline-create',
      content: <>And press save...</>,
      disableBeacon: true
    }
  ]
}

const TS_PAGE_CREATE_TIMELINE: TourSteps = {
  section: 'PAGE_CREATE_TIMELINE',
  steps: [
    {
      target: '.tour-timeline-scene0',
      content: (
        <>
          Now let’s introduce a scene from the fixture in the timeline. Click
          here to choose one of them.
        </>
      ),
      disableBeacon: true
    },
    {
      target: '.tour-timeline-scene2',
      content: <>Introduce a second scene in the timeline.</>,
      disableBeacon: true
    }
  ]
}

const TS_PAGE_CREATE_TIMELINE_ADJUST_PARAMETERS: TourSteps = {
  section: 'PAGE_CREATE_TIMELINE_ADJUST_PARAMETERS',
  steps: [
    {
      target: '.param-container-middle-2',
      content: (
        <>
          An intermediate scene has been automatically created. They are a mix
          of the previously selected scenes.
        </>
      ),
      disableBeacon: true,
      placement: 'top',
      placementBeacon: 'left'
    },
    {
      target: '.param-container-cct-1',
      content: (
        <>
          You can adjust the parameters of the transition here. Try choosing a
          different cct.
        </>
      ),
      disableBeacon: true
    },
    {
      target: '.tour-timeline-save',
      content: (
        <>
          Remember to save the timeline whenever you want to keep your changes.
        </>
      ),
      disableBeacon: true
    }
  ]
}

const TS_PAGE_NEW_TIMELINE_LIST: TourSteps = {
  section: 'PAGE_NEW_TIMELINE_LIST',
  steps: [
    {
      target: '.new-timeline-list',
      content: (
        <>
          A timeline is an intuitive way to link fixtures and its scenes to
          different datetimes.
          <br />
          Click here to create a new one.
        </>
      ),
      disableBeacon: true,
      spotlightClicks: true,
      hideFooter: true
    }
  ]
}

const tourScript: TourScript = {
  sections: [
    TS_NEW_FIXTURE,
    TS_PCB_reference,
    TS_PCB_Choose_leds,
    TS_PCB_Change_serial_parallell_ma,
    TS_SIM_PROGRESS,
    TS_SIM_CREATED,
    TS_PAGE_LED,
    TS_PAGE_SIMULATION,
    TS_PAGE_SIMULATION_SCENE_CREATOR,
    TS_PAGE_SIMULATION_SCENE_CREATOR_FINISH,
    TS_PAGE_NEW_LED,
    TS_PAGE_SIMULATION_SCENE_RESULTS,
    TS_PAGE_PROJECT,
    TS_PAGE_NEW_PROJECT,
    TS_PAGE_NEW_TIMELINE,
    TS_PAGE_CREATE_TIMELINE,
    TS_PAGE_CREATE_TIMELINE_ADJUST_PARAMETERS,
    TS_PAGE_NEW_TIMELINE_LIST
  ]
}

export default tourScript
